import { useCallback, useMemo, useRef, useState } from 'react';

import { isMeetingsAvailable, useProlongationData } from '@wbnr/frontend-shared/lib/data/user';
import { appLocalStorage } from '@wbnr/frontend-shared/lib/utils/browserStorage';

const useKnockoutInitData = () => {
    const [canShow, setCanShow] = useState(false);
    const [decidedToShow, setDecidedToShow] = useState<boolean | undefined>(undefined);

    const resolveRef = useRef<() => void | undefined>();

    const prolongationData = useProlongationData();
    const isPopupsHidden = Boolean(appLocalStorage.getItem('disable_all_auto_popups'));
    const meetingPromoEnabled = !isPopupsHidden && isMeetingsAvailable(prolongationData);

    const promoDialogPromise = useMemo(() => {
        return new Promise<void>((resolve) => {
            resolveRef.current = resolve;

            if (!meetingPromoEnabled) {
                resolve();
            }
        });
    }, [meetingPromoEnabled]);

    const knockoutSyncData = useMemo(
        () => ({
            meetingsPromoDialogShown: decidedToShow,
            meetingPromoDialogPromise: promoDialogPromise,
        }),
        [decidedToShow, promoDialogPromise],
    );

    const onKnockoutLoad = useCallback(() => {
        setCanShow(true);
    }, []);

    const onDecideShow = useCallback((newDecidedToShow) => {
        setDecidedToShow(newDecidedToShow);
    }, []);

    const onFinish = useCallback(() => resolveRef.current && resolveRef.current(), []);

    return {
        meetingPromoEnabled,
        canShow,
        knockoutSyncData,
        onKnockoutLoad,
        onDecideShow,
        onFinish,
    };
};

export default useKnockoutInitData;
