import Cookie from 'js-cookie';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
    USER_AUTH_METHOD_KEY,
    USER_AUTH_METHOD_VALUE,
} from '@wbnr/frontend-shared/lib/api/user/constants';
import { needRedirectToMeetings, useUser } from '@wbnr/frontend-shared/lib/data/user';
import { isAccountUser, LoggedInUser } from '@wbnr/frontend-shared/lib/types/User';
import { appLocalStorage } from '@wbnr/frontend-shared/lib/utils/browserStorage';

import { useQualification } from 'shared/qualification/useQualification';

import { SELECTED_SSO_ORGANIZATION_KEY, SELECTED_SSO_ORGANIZATION_VALUE } from '../constants';

export const useSignIn = (params?: { joinToOrganization?: () => Promise<boolean> }) => {
    const { joinToOrganization } = params || {};
    const { state: locationState = {} } = useLocation<{ referrer?: string }>();
    const history = useHistory();
    const isCourses = window.location.pathname.includes('/courses');
    const [userInited, setUserInited] = useState(false);
    const [user, loading, error, , loadUser, userFulfilled] = useUser();
    const {
        goToOnboarding,
        checkQualification,
        goToQualification,
        checkNeedShowInitialOnboarding,
    } = useQualification();

    const handleLoadedUser = useCallback(
        async (userParam: LoggedInUser | null | undefined = user) => {
            if (!userParam) {
                return;
            }
            if (isCourses || userParam.type === 'STUDENT') {
                window.location.replace('/courses');
                return;
            } else if (isAccountUser(userParam)) {
                try {
                    const setLoginTime = () =>
                        appLocalStorage.setItem('loginTime', String(new Date().getTime()));

                    const needShowOnboarding = await checkNeedShowInitialOnboarding(userParam);

                    if (needShowOnboarding) {
                        setLoginTime();
                        return goToOnboarding(userParam, true);
                    }

                    const isQualified = await checkQualification(userParam);
                    setLoginTime();

                    if (!isQualified) {
                        return goToQualification(userParam, true);
                    }
                    appLocalStorage.setItem('isNewUser', 'false');
                    if (
                        (!locationState.referrer || locationState.referrer === '/') &&
                        needRedirectToMeetings(userParam)
                    ) {
                        history.replace('/meetings');
                    } else if (locationState.referrer) {
                        window.location.replace(locationState.referrer);
                    } else {
                        history.replace('/');
                    }

                    return;
                } catch (e) {
                    // TODO: catch is empty. check the reason.
                }
            }
            return true;
        },
        [
            user,
            isCourses,
            checkNeedShowInitialOnboarding,
            checkQualification,
            locationState.referrer,
            goToOnboarding,
            goToQualification,
            history,
        ],
    );

    useEffect(() => {
        if (joinToOrganization) {
            setUserInited(true);
        } else if (!loading && !user && !error) {
            loadUser().catch(() => {});
        }
    }, [error, loadUser, loading, user, joinToOrganization]);

    const isAuthTypeSSO = Cookie.get(USER_AUTH_METHOD_KEY) === USER_AUTH_METHOD_VALUE;
    const isSelectedSSOOrganization =
        appLocalStorage.getItem(SELECTED_SSO_ORGANIZATION_KEY) === SELECTED_SSO_ORGANIZATION_VALUE;

    useEffect(() => {
        if (!userInited) {
            if (user) {
                if (isAuthTypeSSO ? isSelectedSSOOrganization : true) {
                    handleLoadedUser(user).then((result) => {
                        if (result) {
                            setUserInited(true);
                        }
                    });
                }
            } else if (userFulfilled) {
                setUserInited(true);
            }
        }
    }, [
        userInited,
        user,
        userFulfilled,
        isAuthTypeSSO,
        isSelectedSSOOrganization,
        handleLoadedUser,
    ]);

    return {
        handleLoadedUser,
        userInited,
        isAuthTypeSSO,
        isSelectedSSOOrganization,
    };
};
