import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Typography } from '@wbnr/ui';

import { useDevices } from '../hooks/useDevices';
import { useModalMyDeviceAdd } from '../hooks/useModalMyDeviceAdd';

import styles from './MyDevicesList.module.scss';
import { MyDeviceTable } from './MyDeviceTable';
import { SearchField } from './SearchField';

export const MyDevicesList: FC = () => {
    const { t } = useTranslation();
    const [searchValue, setSearchdValue] = useState<string>('');

    const { hasDevices, hasSearchDevices } = useDevices();
    const { showModalMyDeviceAdd } = useModalMyDeviceAdd();

    const isSearchingDevices = Boolean(searchValue);
    const showSearchField = hasDevices || hasSearchDevices || isSearchingDevices;

    return (
        <>
            <div className={styles.root}>
                <div className={styles.header}>
                    <Typography variant="h6">{t('business.myDevicesPage.title')}</Typography>
                    <Button color="primary" variant="contained" onClick={showModalMyDeviceAdd}>
                        {t('business.myDevicesPage.myDevicesList.add')}
                    </Button>
                </div>
                <Typography variant="body1" className={styles.subtitle}>
                    {t('business.myDevicesPage.subtitle')}
                </Typography>
                {showSearchField && <SearchField setValue={setSearchdValue} value={searchValue} />}
                <MyDeviceTable
                    showTableHeader={showSearchField}
                    isSearchingDevices={isSearchingDevices}
                />
            </div>
        </>
    );
};
