import { BrandingFull } from '@wbnr/frontend-shared/lib/types/Branding';
import { SMTP } from '@wbnr/frontend-shared/lib/types/Smtp';

import { BrandingFormValues } from './types';

function getFormValuesPropName<
    K1 extends keyof BrandingFormValues,
    K2 extends keyof BrandingFormValues[K1],
>(object: K1, prop: K2) {
    // todo: TS: handle `prop` being possibly Symbol
    return `${object}.${String(prop)}`;
}

export const getBrandingPropName = (prop: keyof BrandingFull) => {
    return getFormValuesPropName('branding', prop);
};

export const getSmtpPropName = (prop: keyof SMTP) => {
    return getFormValuesPropName('smtp', prop);
};
