import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import { jwt as apiUserJwt } from '@wbnr/frontend-shared/lib/api/user';
import LoadingScreen from '@wbnr/frontend-shared/lib/components/LoadingScreen';
import * as config from '@wbnr/frontend-shared/lib/config';
import { Button, Typography } from '@wbnr/ui';

import image from './img.svg';
import styles from './SignInMobileApp.module.scss';

export const SignInMobileApp = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [token, setToken] = useState<string | null>();

    useEffect(() => {
        const root: HTMLElement | null = document.querySelector('#root');
        if (root) {
            root.classList.add(styles.minWidthAuto);

            return () => {
                root.classList.remove(styles.minWidthAuto);
            };
        }
    }, []);

    useEffect(() => {
        apiUserJwt({ platform: 'MOBILE' })
            .then(({ data: { token: newToken } }) => {
                setLoading(false);
                setToken(newToken);
            })
            .finally(() => setLoading(false));
    }, [setToken, setLoading]);

    if (loading) {
        return <LoadingScreen />;
    }

    const handleOpenApp = () => {
        window.location.href = `${config.MOBILE_SCHEMA}://${config.MOBILE_API_HOST}/mobile/login/${token}`;
    };

    const handleReload = () => {
        window.location.reload();
    };

    return (
        <div className={styles.root}>
            <div className={styles.content}>
                <div className={styles.title}>Webinar</div>
                <Typography variant="body1" className={styles.text}>
                    {token && (
                        <Trans
                            i18nKey={'auth.mobileApp.text'}
                            components={{
                                bold: <span className={styles.marked} />,
                            }}
                        />
                    )}
                    {!token && <Trans i18nKey={'auth.mobileApp.error'} />}
                </Typography>
                {token && (
                    <Button
                        color="primary"
                        variant="contained"
                        className={styles.button}
                        onClick={handleOpenApp}
                    >
                        <Trans i18nKey={'auth.mobileApp.button'} />
                    </Button>
                )}
                {!token && (
                    <Button
                        color="primary"
                        variant="contained"
                        className={styles.button}
                        onClick={handleReload}
                    >
                        <Trans i18nKey={'auth.mobileApp.reload'} />
                    </Button>
                )}
            </div>
            <div>
                <img src={image} alt="" />
            </div>
        </div>
    );
};
