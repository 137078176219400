import { loadAllDataByChunks } from '../../utils/pagination';
import { apiFormFetch } from '../apiFetch';
export var getContacts = function getContacts(_ref) {
  var userId = _ref.userId;
  return loadAllDataByChunks(function (perPage, cursor) {
    return apiFormFetch('GET', '/users/{userId}/contacts', {
      userId: userId,
      perPage: perPage,
      cursor: cursor
    });
  });
};