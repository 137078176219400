import { format, isToday, isYesterday } from 'date-fns';
import { createDate } from '@wbnr/ui';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
export function useFormattedDate(date) {
  var _useTranslation = useTranslation(),
    t = _useTranslation.t;
  return useMemo(function () {
    if (!date) {
      return null;
    }
    var newDate = date instanceof Date ? date : createDate(date);
    var timeString = format(newDate, 'HH:mm');
    var dateString;
    if (isToday(newDate)) {
      dateString = t('notifications.today');
    } else if (isYesterday(newDate)) {
      dateString = t('notifications.yesterday');
    } else {
      dateString = t('notifications.dateSettingValue', {
        date: newDate
      });
    }
    return "".concat(dateString, ", ").concat(timeString);
  }, [date, t]);
}