"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.calculateImageSize = calculateImageSize;
exports.getSizeStyle = getSizeStyle;
exports.getTransform = getTransform;
exports.is90DegRotated = is90DegRotated;
function isVertical() {
  var containerDims = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var imageDims = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var rotation = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  if (!containerDims.width || !containerDims.height || !imageDims.width || !imageDims.height) {
    return false;
  }
  if (is90DegRotated(rotation)) {
    return containerDims.width / containerDims.height > imageDims.height / imageDims.width;
  }
  return containerDims.width / containerDims.height > imageDims.width / imageDims.height;
}
function calculateImageSize(containerDims, imageDims) {
  var rotation = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  var _ref = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {},
    _ref$bothDimensions = _ref.bothDimensions,
    bothDimensions = _ref$bothDimensions === void 0 ? false : _ref$bothDimensions;
  var contWidth = containerDims.width,
    contHeight = containerDims.height;
  var imgWidth = imageDims.width,
    imgHeight = imageDims.height;
  if (!contWidth || !imgWidth || !contHeight || !imgHeight) {
    return {};
  }
  var result = {};
  if (isVertical(containerDims, imageDims, rotation)) {
    if (is90DegRotated(rotation)) {
      result.width = contHeight;
    } else {
      result.height = contHeight;
    }
  } else {
    if (is90DegRotated(rotation)) {
      result.height = contWidth;
    } else {
      result.width = contWidth;
    }
  }
  if (bothDimensions) {
    if (result.width) {
      result.height = result.width / (imgWidth / imgHeight);
    } else if (result.height) {
      result.width = result.height * (imgWidth / imgHeight);
    }
  }
  return result;
}
function is90DegRotated() {
  var rotation = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  return rotation % 90 === 0 && rotation % 180 !== 0;
}
var defaultTranslate = 'translate(-50%, -50%)';
function getTransform(offset, rotation, scale) {
  var transform = "".concat(defaultTranslate);
  if (offset) {
    var _offset$x = offset.x,
      x = _offset$x === void 0 ? 0 : _offset$x,
      _offset$y = offset.y,
      y = _offset$y === void 0 ? 0 : _offset$y;
    transform += " translate(".concat(x, "px, ").concat(y, "px)");
  }
  if (rotation) {
    transform += " rotate(".concat(rotation, "deg)");
  }
  if (scale) {
    transform += " scale(".concat(scale, ")");
  }
  return transform;
}
function getSizeStyle(_ref2) {
  var width = _ref2.width,
    height = _ref2.height;
  if (!width && !height) {
    return {
      width: '0px',
      height: '0px'
    };
  }
  return {
    width: width && width + 'px',
    height: height && height + 'px'
  };
}