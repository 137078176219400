import { commonTranslations } from '@wbnr/frontend-shared/lib/utils/i18n';

export const en = {
    ui: {
        second: 'second',
        second_plural: 'seconds',
        minute: 'minute',
        minute_plural: 'minutes',
        hour: 'hour',
        hour_plural: 'hours',
        day: 'day',
        day_plural: 'days',
        week: 'week',
        week_plural: 'weeks',
        month: 'month',
        month_plural: 'months',
        minutesSimple: 'minutes',
        slideNumber: '{{slideIndex}} out of {{maxSlideIndex}}',
        timePickerTitle: 'Choose a time',
        timePickerError: 'Times is wrong',
        cancel: 'Cancel',
        next: 'Next',
        save: 'Save',
        date: {
            dateStr: '{{date, d MMMM yyyy}}',
            timeStr: '{{time, HH:mm}}',
            weekDayShort: '{{day, EE}}',
            weekDay: '{{day, EEEE}}',
            weekDayShortLocal: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
            weekDayLocal: [
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
            ],
            month: '{{month, MMMM}}',
            timePickerTitle: 'Pick a time',
        },
        wysiwyg: {
            placeholder: {
                default: 'Write or copy text here',
                code: 'Insert link or code here',
                miro: 'Insert your desk code here. <0>What is it?</0>',
            },
            lineAttachmentToolbar: {
                code: 'Code',
                image: 'Image',
                file: 'File',
                upload: 'Upload',
                miro: 'Miro (Realtimeboard) desk',
            },
            styles: {
                default: 'Text',
                heading: 'Heading',
                list: 'List',
                blockquote: 'Blockquote',
            },
        },

        search: 'Search',
    },
    cp: {
        ...commonTranslations.en,
        preventNavigation: 'Changes you made may not be saved.',
        gotIt: 'Got it',
        next: 'Next',
        notIntrested: 'Skip',
        meetingsPromoDialog: {
            step1: {
                title: 'Meeting Calendar',
                text: 'Keep track of your own meetings and events to which you have been invited in a single calendar',
            },
            step2: {
                title: 'Plan your meetings in advance',
                text: 'Try planning a meeting in the new editor. It has been simplified and adapted for small gatherings.',
            },
            step3: {
                title: 'Leave this dashboard for webinars',
                text: 'And create meetings from your new one. Soon they will transfer completely to a separate section, to make it easier for you to work with various types of online events.',
            },
            notIntrested: 'Not interested',
            goToMeetings: 'Go to meetings',
        },
        webinarTrialDialog: {
            title: {
                common: 'Your trial period will end in {{days}}',
                warning: 'Your trial period is ending soon. There are {{days}} left.',
                over: 'The trial plan has ended',
                overSelfService: 'The trial plan has ended,\nthe free tariff was activated',
            },
            text: {
                common: 'Do not waste time, hold an event and evaluate all the possibilities of the paid functionality.',
                warning:
                    'When access ends, you will not be able to use marketing and engagement tools, the number of participants will be limited.',
                over: 'A free plan has been activated without the ability to download the event recording.',
            },
            comment: {
                common: 'Have questions? We will be happy to answer.',
                contact: 'Contact the manager to enable full access.',
            },
            disabled: 'Marketing and involvement tools are desactivated.',
            requested: 'The request has been sent. A manager will contact you soon.',
            hasNow: 'Available Now',
            afterTrialEnd: 'After {{days}}',
            noTimeLimits: {
                title: 'Ограничение\nпо времени 1 час',
                inTrial: 'No time limits',
            },
            users: {
                title: '30 participants at the meetings',
                inTrial: '150 participants at the webinar and 30 at the meetings',
            },
            storage: {
                title: '500 MB\nfor files',
                inTrial: '30 GB for files',
            },
            records: 'Converting and downloading recordings',
            multipleEvents: {
                title: 'Parallel events',
                description:
                    'Employees added to the organization will be able to hold parallel events',
            },
            banners: {
                title: 'Banners',
                description: 'Show your ads to webinar attendees and share important link',
            },
            branding: {
                title: 'Branding',
                description: 'Personalize the content that attendees see',
            },
            background: {
                title: 'Backgrounds',
                description: 'Use your background images for events like presswalls',
            },
            utm: {
                title: 'UTM tags',
                description: 'Each participant will have a UTM tag',
            },
            groupwork: {
                title: 'Division into groups',
                description: 'Conduct group trainings and divide into teams to work out cases',
            },
            miro: {
                title: 'Miro board',
                description:
                    'Miro can be embedded directly into a webinar to help you understand each other more quickly',
            },
            attentionControl: {
                title: 'Control of participants',
                description: 'Tools to help you find out who is watching the webinar closely',
            },
            statistics: {
                title: 'Detailed statistics',
                description:
                    'Detailed information about the activity of participants in the statistics',
            },
        },
        webinarTrialBanner: {
            title: 'Your trial period will end in {{days}}',
            continue: 'More details',
            opportunities: 'View opportunities',
        },
        westudyPromoDialog: {
            title: 'New possibilities for tackling training objectives',
            submit: 'Activate',
            beforelistText: 'Using We.Study, you can:',
            listitem1: 'Automate the adaptation and training of staff',
            listitem2: 'Store recordings of events and other content in a file library',
            listitem3: 'See viewing statistics for a recording, as well as progress of completion',
            listitem4: 'Gain additional earnings from the sale of online recordings',
            afterlistText:
                "Activate the free trial period and try out all of We.Study's features for 14 days",
        },
        selectedLabel: 'Selected {{selectedNumber}}',
        filesize: '{{size, filesize}}',
        date: '{{date, date}}',
        timeDuration: '{{time, timeDuration}}',
        filesizeUnit_tb: 'TB',
        validate: {
            length: 'Invalid number of characters',
        },
        validation: {
            wrongPassword: 'Wrong password',
            required: 'This field is required',
            phone: 'Seems like you provided the wrong number',
            passwordTooShort: 'Password must contain at least 8 characters',
            email: 'Wrong email address',
            nameFormat: 'Wrong format. You can use letters, digits, dot, minus, and space',
            host: 'The server address contains errors',
            port: 'Invalid port',
            link: 'Wrong link',
            sipLink: 'Invalid SIP URI',
            latin: 'Wrong format. You can use only latin letters.',
            noSpaces: 'This field cannot contain spaces.',
            notDigitStart: 'This field cannot start with digit.',
            onlyDigits: 'The field can only be numbers',
            specificValue: 'Field can only contain {{count}} digits',
            specificEnumValues: 'The field can contain {{firstCount}} or {{secondCount}} digits',
        },
        andSeparator: ' and ',
        unknownError: 'Unknown error',
        banners: {
            meetingParticipantsLimit:
                'The maximum number of participants({{limit}}) in the meeting has been  reached.',
            switchEventType: 'Switch to «webinar»',
            fileSize90AutoRecord:
                'Not much free storage space. The event recording function will not be available',
            fileSize90:
                'Not much free storage space. Delete unnecessary files or increase the file storage capacity.',
            fileSize100AutoRecord:
                'Not enough space for new recordings and files. Increase the file storage capacity',
            fileSize100:
                'Not enough free storage space. Delete unnecessary files or increase the file storage capacity.',
            buyRate: 'Activate subscription',
            buyFileSize: 'Increase the capacity',
            newFileSizeAvailable: 'Free space available',
            payUsTitle: 'Use the webinar to the full extent',
            webrtcTestUnsupportedBrowserBanner:
                'Your browser does not support the technology required for holding such events.',
            webrtcTestUnsupportedBrowserSummary: 'Please change your browser',
            webrtcTestUnsupportedBrowserDesc:
                'Your browser does not support the technology required for holding such events. It is recommended to use the latest version of Google Chrome to go on the air.',
            webrtcTestNetworkBlockedBanner:
                'Your network has been experiencing issues that interfere with events.',
            webrtcTestNetworkBlockedSummary: 'No connection',
            webrtcTestNetworkBlockedDesc:
                'Make sure that port 443 is not blocked in your network (TCP is mandatory, not just HTTPS) for the Webinar 37.130.192.0/22, 185.45.80.0/22 subnets and please review the technical requirements.',
            webrtcTestNetworkBlockedDescBox:
                'Make sure that port 443:TCP/UDP, 1030:TCP/UDP ports on {{hosts}} are not blocked in your network and please review the technical requirements.',
            webrtcTestNetworkBanner: 'We detect issues that may interfere with events.',
            webrtcTestNetworkSummary: 'Could not connect  to server',
            webrtcTestNetworkDesc:
                'Automatic network analysis identified an error. Please contact our support team. Make sure that port 443 is not blocked in your network (TCP is mandatory, not just HTTPS) for the Webinar 37.130.192.0/22, 185.45.80.0/22 subnets and please review the technical requirements.',
            webrtcTestNetworkDescBox:
                'Automatic network analysis identified an error. Make sure that 443:TCP/UDP, 1030:TCP/UDP ports on {{hosts}} are not blocked in your network and please review the technical requirements.',
            webrtcTestUnknownSummary: 'Unknown error',
            webrtcTestUnknownDesc:
                'Automatic connection analysis idetified an unknown error. It may interfere with events. Please contact our support team so that our staff members could investigate the cause of the error in detail.',
            webrtcTestContactSupport: 'Contact our support team',
            webrtcTestHelp: 'Requirements',
            webrtcTestDownloadChrome: 'Download Chrome',
            webrtcTestHideWarningTitle:
                'You can hide the warning, but the error will still be there',
            webrtcTestHideWarningText1:
                'We found an error, and we are afraid that it may jeopardize your events.',
            webrtcTestHideWarningText2:
                'We recommend contacting our support team ad fix the error.',
            webrtcTestFixWarning: 'Fix error',
            webrtcTestHideWarning: 'Hide warning',
            webrtcTestSupportContacts:
                'Webinar support: +7 495 118-44-44, <0>support@webinar.ru</0>',
            webrtcHelp: 'Help',
            endlessMeetingsPromo:
                'NEW! Endless meetings are meetings available at a permanent link',
            tryAction: 'Try it',
            signupOffer: {
                text: 'Host your own events. Sign up to get all premium options and host meetings and webinars',
                cancel: 'Skip',
                action1: 'Sign up',
                action2: 'Host your events',
            },
        },
        create: 'Create',
        files: {
            newFolder: 'New folder',
            cancelUpload: 'Cancel',
            uploadedNFromM: 'Uploaded {{N}} from {{M}}',
            uploadInProgress: 'Uploading in progress',
            uploadDone: 'The files were uploaded',
            uploadError: 'File upload error',
            uploadCanceled: 'Uploading of files canceled',
            newBannerAction: 'New banner',
            bannerCreateTitle: 'Banner creation',
            bannerEditTitle: 'Banner editing',
            bannerCreated: 'Banner created',
            bannerEdited: 'Banner edited',
            bannerCreateError: 'We were unable to create a banner',
            bannerEditError: 'We were unable to edit a banner',
            bannerDescription:
                'Banners help you to show your advertisement and call to action participants',
            bannerFollow: 'Follow',
            bannerName: 'Name',
            bannerText: 'Make short description',
            bannerTextNote: '80 symbols',
            bannerLink: 'Link a participant will follow',
            bannerUseImage: 'Use picture',
            bannerUploadImage:
                'Drag the file to this location in order to attach it or <0>select the file</0> on the computer.<br />SVG, PNG. TIFF, JPG. 44x44 px.',
            addMiroBoard: 'Add MIRO board',
            miroAdded: 'MIRO board added',
            miroAddedError: 'We were unable to add a MIRO board',
            scormAdded: 'SCORM package added',
            scormAddedError: 'We were unable to add a SCORM package',
            title: 'Files',
            revertDeletedFiles: 'Cancel',
            deleteFileAction: 'Delete',
            renameFileAction: 'Rename',
            openFileAction: 'Open',
            searchLabel: 'Search',
            newVideoAction: 'Add Youtube/Vimeo video',
            newVideoDialogTitle: 'Adding a Youtube/Vimeo video',
            newVideoDialogSubmut: 'Save',
            ownerLabel: 'Owner',
            createDateLabel: 'Date of creation',
            editPollLabel: 'Edit voting',
            pollHistoryTitle: 'History of results',
            chooseAllTooltip: 'Select all',
            downloadFile: 'Download',
            name: 'First Name',
            createDate: 'Date of creation',
            size: 'Size',
            owner: 'Owner',
            convertationCanceled: 'Cancelled',
            presentationErrorTitle: 'Error processing file',
            presentationErrorSubmit: 'Delete',
            testResultDate: 'Timing',
            testMaxDuration: 'Time of completion',
            testMaxPoints: 'Total points ',
            personalTestPassedLabel: 'Result',
            personalTestResult: 'Correct',
            personalTestTimeTaken: 'Time',
            emptyFolder: 'This folder is empty',
            fileRestoredNotification: '{{count}} file restored',
            fileRestoredNotification_plural: '{{count}} files restored',
            fileDeletedNotification: '{{count}} file deleted',
            fileDeletedNotification_plural: '{{count}} files deleted',
            fileNotCompletelyDeletedNotification:
                'Some of the files have not been deleted. The files are being processed or belong to non-deleted tests. Try again later.',
            fileDeleteErrorNotification: 'Delete files error',
            fileMovedNotification: '{{count}} file moved',
            fileMovedNotification_plural: '{{count}} files moved',
            fileMoveErrorNotification: 'Move files error',
            fileRenamedNotification: 'File renamed',
            fileRenameErrorNotification: 'Rename file error',
            selectedLabel: 'Selected {{selectedNumber}}',
            newFolderAction: 'Create folder',
            newPollAction: 'Add poll',
            newTestAction: 'Add test',
            newActivityAction: 'Add activity',
            newFileAction: 'Add from computer',
            newVideoDialogLabel: 'Video link',
            videoAdded: 'Video added',
            videoAddError: 'Add video error',
            maxStorageSize: 'Available from {{size, filesize}}',
            toggleViewTypeTooltip: {
                table: 'Display as grid',
                grid: 'Display as list',
            },
            sharedFolder: 'Shared files',
            allFilesFolder: 'All files',
            folderRoot: 'Root',
            myFiles: 'My files',
            presetationFailure:
                'Probably your file contains too many pages or it has the wrong format. Please read the details and try again.',
            fileStatusFailure: 'Error! <0>Details</0>',
            videoConvertCanceled: 'Convertation canceld',
            testMinPoints: 'Min number of points',
            testMaxAnswers: 'Number of right answers',
            testMinAnswers: 'Min number of answers',
            personalTestPassed: 'Passed',
            personalTestFailed: 'Failed',
            personalTestNumberOfRightAnswers: '{{count}} answer',
            personalTestNumberOfRightAnswers_plural: '{{count}} answers',
            personalTestNumberOfPoints: '{{count}} point',
            personalTestNumberOfPoints_plural: '{{count}} points',
            openMiro: 'Open MIRO',
        },
        business: {
            title: 'Business',
            tariffs: 'Tariffs',
            myTariff: 'My tariff',
            organization: 'Organization',
            branding: 'Branding',
            certificate: 'Certificate',
            analytics: 'Analytics',
            paidEvents: 'Paid events',
            proctoring: 'Proctoring',
            pointOfPresence: 'Point Of Presence',
            api: 'API',
            apiAndWebhooks: 'API / Webhooks',
            integrations: 'Integrations',
            ssoSettings: 'SSO settings',
            myDevices: 'My devices',
            siemSettings: 'SIEM settings',
            proctoringPage: {
                title: 'Proctoring',
                description:
                    'To connect, enter the data received from ProctorEdu, after saving the proctoring setting will be available in the test settings in We.Study. The proctoring session will automatically start for the participant who started the test, the data on the proctoring sessions of your participants will be available in your admin area in ProctorEdu.',
                save: 'Save',
                change: 'Change',
                saveChanges: 'Save changes',
                fields: {
                    url: 'Enter server URL',
                    secretOrKey: 'Enter secretOrKey',
                    apiKey: 'Enter x-api-key',
                },
                proctoringSettingsGetFail: 'Error while getting proctoring data',
                proctoringSettingsSaveSuccess: 'Proctoring data saved',
                proctoringSettingsSaveFail: 'Error while saving proctoring data',
            },
            brandingPage: {
                create: 'Create branding template',
                created: 'Created',
                updated: 'Changed',
                default: 'Default',
                setDefault: 'Make as default',
                duplicate: 'Duplicate',
                title: 'Template name',
                untitled: 'Untitled',
                newsletters: 'Mailouts',
                replyTo: 'Send reply to',
                replyToComment: "Participants' replies will be sent to this email address",
                emailFrom: 'From',
                emailFromComment:
                    'This name will be displayed on all messages sent to webinar participants',
                useSignature: 'Use the signature in the mailout',
                signature: 'Enter the signature text',
                useTemplateInvitation: 'Use your own HTML-template for an invitation letter',
                useTemplate: 'Use your own HTML-template for a reminder letter',
                template: 'Enter your code here',
                showTemplateResult: 'View the result',
                macros: {
                    title: 'Macros',
                    template: {
                        title: 'email title',
                        name: 'participant name (if persists)',
                        webinarName: 'name of webinar',
                        webinarDate: 'date of webinar in format hh:mm (UTC +n) yyyy-mm-dd',
                        imageUrl: 'event background image link',
                        text: 'remind text from event settings',
                        link: 'personal participant link (with token)',
                    },
                },
                useCustomSmtp: 'Use an external SMTP server',
                smtpLogin: 'Login',
                smtpPassword: 'Password',
                smtpEmail: 'Email',
                smtpName: "Sender's name",
                smtpHost: 'Server',
                smtpPort: 'Port',
                smtpSSL: 'SSL encryption',
                additionalAgreements: 'Additional agreements',
                additionalAgreementsDescription:
                    'Additional agreements will be shown to participant in webinar registration form',
                addAdditionalAgreement: 'Add agreement',
                agreementsDefaultChecked: 'Setup checkboxes by default',
                agreementsDefaultCheckedDescription:
                    'Checkboxes in the registration form will be set up by default, but attendees can uncheck them.',
                additionalAgreementLabel: 'Additional agreement text',
                additionalAgreementHint: 'You can add link with: [text](link)',
                agreementRequired: 'Mandatory condition',
                agreementRequiredHint:
                    'Attendees can not register to a webinar if they do not accept this agreement.',
                additionalAgreementMaxLength: 'Maximum length exceeded',
                webinar: 'Webinar',
                eventOrganizationName: 'Name of organization during webcasting',
                linksOrganizationName: 'Name of company in links',
                linksOrganizationNameExist: 'Name of company is already exist.',
                linkPageAfterWebinar: 'Link to the page after the webinar',
                linkPageAfterWebinarComment: 'This link will be used for readdressing participants',
                showSocialNetworkButtons:
                    "Show social networks buttons on the webinar's landing page",
                showQuestionButton: 'Show "ask a question" button on the event page',
                questionsReplyTo: 'Send questions to',
                questionsReplyToComment:
                    "Participants' questions from the event page will be sent to this email address",
                pauseBackgroundImage: 'Pause background',
                pauseBackgroundImageDescription:
                    'Use a branded background in your webinar room for pause mode. SVG, PNG, TIFF, JPG format.',
                brandColor: 'Special color of buttons and links',
                colorNumber: 'Color number',
                reset: 'Reset to default',
                uploadFile:
                    'Drag the file to this location in order to attach it or <0>select the file</0> on the computer.',
                notFound: 'Branding with this Id is not found',
                failed: {
                    delete: 'Failed to remove branding',
                    clone: 'Failed to duplicate branding',
                    default: 'Failed to setup branding',
                },
                brandingFeatureRequest: {
                    title: 'Make your events recognizable',
                    description:
                        'Customize all parts of your webinars: emails, buttons, logos and so on. This will give your webinar a unique look and brand awareness.',
                    submit: 'Request',
                },

                multibrandingFeatureRequest: {
                    title: 'Get branding templates',
                    description:
                        'To use branding templates that allow you to apply different design styles to events, contact the manager',
                    submit: 'Send request',
                },
            },
            organizationPage: {
                invitationLimitReachedRequest: {
                    title: 'The limit of employees in the team has been reached',
                    description:
                        'You cannot add or invite employees to the team using the link. Remove unnecessary employees or contact the manager to increase the limit.',
                },
            },
            apiPage: {
                title: 'API',
                text: 'The API allows seamless integration of Webinar platforms with third-party services such as CRM, mailing automation systems, LMS and others. Submit a connection request to use it. <0>Read more about API</0>',
                requested: 'The request has been sent. A manager will contact you soon.',
                action: 'Send a request',
                key: 'Key',
                toApiDescription: 'Go to API description',
                keyRefresh: 'Update key',
                copy: 'Copy',
                copyTooltip: 'Copy the API key',
                updateDialog: {
                    title: 'Updating an API key',
                    description:
                        'Attention: The current API key will be cancelled and replaced with a new one. Integrations with systems that were set up earlier will no longer work.',
                    cancel: 'Cancel',
                    renew: 'Renew',
                },
            },
            webhooksPage: {
                title: 'Webhooks',
                addWebhook: 'Add',
                description:
                    'Webhook allows you to get information about the end of the event or the exit of all participants.',
                learnMore: 'Learn more',
                example: {
                    title: 'Webhook notification content example',
                    description:
                        'The notification will be sent to the specified URL as a POST request.\nThe request body contains data in JSON format.',
                    eventComment: 'Event type',
                    occurredAtComment: 'Date on which event occurred',
                    eventSessionIdComment: 'event id',
                },
                counter: 'Added {{count}} of {{limit}} webhooks.',
                counterWarning:
                    'The limit on the number of webhooks has been reached. Please delete unnecessary webhooks to add new ones.',
                list: {
                    title: 'Title',
                    endpoint: 'Webhook endpoint',
                    dateCreated: 'Date created',
                    dateCreatedValue: '{{date, MM/d/yyyy}} at {{date, HH:mm}}',
                    webhookAdded: 'Webhook added successfully',
                    webhookDeleted: 'Webhook removed successfully',
                    undoDeletion: 'Undo',
                },
                addWebhookDialog: {
                    title: 'Add webhook',
                    nameField: 'Title',
                    endpointUrlField: 'Webhook endpoint',
                    addButton: 'Add',
                    cancelButton: 'Cancel',
                    limitExceeded:
                        'Webhooks quantity limit exceeded. Organization can have only {{limit}} webhooks',
                    nameAlreadyExists: 'Webhook with same name already exists',
                    urlAlreadyExists: 'Webhook already exists',
                    urlWrongValue: `Webhook endpoint URL must be valid URL and must starts with https://`,
                    maxLength: 'Maximum length exceeded',
                },
            },
            sso: {
                title: 'SSO settings',
                subtitle:
                    'After setting up SSO, company employees will be able to log in with a corporate account to access events or a personal account.',
                subtitleProtocol:
                    'Select a protocol and set up an ISP. You can only configure one protocol.',
                subtitleProvider: 'Enter these details in the SSO provider',
                help: 'To learn more',
                saml: {
                    title: 'Selecting a protocol and identity provider settings',
                    idpProtocol: 'Protocol',
                    idpProvider: 'SSO provider',
                    idpEntityId: 'Identity Provider (idP) Entity ID',
                    idpSingleSignOnServiceUrl: 'Sign-in page URL',
                    idpSingleLogoutServiceUrl: 'Sign-out page URL',
                    idpX509certBase64: 'Identity provider certificate',
                    spEntityId: 'Entity ID',
                    spAssertionConsumerService: 'URL ACS',
                    edit: 'Change',
                    next: 'Next',
                    cancel: 'Cancel',
                    back: 'Back',
                    show: 'Show',
                    hide: 'Hide',
                    wrongValue: 'Wrong value',
                    checkSSO: 'Check',
                    loading: 'Check is in progress.<br/>Wait...',
                    commonError: 'Something went wrong. Update the page and try again.',
                    validationSuccessfulModal: {
                        title: 'Validation was successful',
                        button: 'Ok',
                    },
                    validationFailedModal: {
                        title: 'Errors found during validation',
                        text: 'Errors were found during the verification of the IDP process. Below you can see the verification log. Try to change the settings and restart the check.',
                        cancelButton: 'Close',
                        copyButton: 'Copy log',
                    },
                },
                edit: {
                    course: {
                        label: 'Use SSO to limit access to courses and webinars for all "Organization" accounts',
                        hint: 'If enabled - login to courses and webinars created by any account belonging to your "Organization" will only be possible using SSO. If you want to enable the option for certain employees only, select them from the list below.',
                    },
                    login: {
                        label: 'Use SSO to log in to personal account',
                        hint: 'Employees of your company will be able to log into their personal account using SSO without additional registration on the platform. The first time they sign in, they will be automatically registered, added to the "Organization" and connected to your company\'s plan.',
                    },
                    signup: 'Register users with validated domains of my organization only via SSO',
                },
                domain: {
                    title: 'Enter an email domain',
                    subtitle:
                        'Users with the specified email domain will be directed to your Identity Provider page.',
                    label: 'Enter domain',
                    hint: 'Enter your email domain, for example webinar.ru',
                    add: 'Add',
                    error: 'Wrong domain',
                    duplicate: 'This domain has already been added',
                    approved: 'Verified',
                    unapproved: 'Not verified',
                    approve: 'Verify',
                    delete: 'Remove',
                    confirm: {
                        title: 'Confirm remove',
                        text: 'Are you sure want to remove domain {{domain}}?',
                    },
                    approveModal: {
                        title: 'Domain validation',
                        text:
                            'Ownership of a domain name can be confirmed through the domain registrar (usually the company from which you purchased the domain). The registrar keeps special settings - DNS records that direct Internet traffic to your domain name.<br /><br />' +
                            "Below is a TXT verification record, which should be added to the domain registrar's DNS records. The presence of this record confirms that the domain really belongs to you. The verification record does not affect the operation of your email or website. If you cannot confirm that the domain belongs to you via the txt record, please contact technical support.",
                        confirm: 'Check the txt-record',
                        copyLink: 'Copy txt-record',
                        success: 'TXT-record successfully found.',
                        fail: 'TXT-record was not found. Domain records update is usually delayed by the ISP, try again later. The maximum waiting time is 72 hours',
                        ok: 'Good',
                    },
                },
                memberships: {
                    title: 'Employees',
                    search: 'Search',
                    name: 'Name',
                    email: 'Email',
                },
            },
            myDevicesPage: {
                title: 'My devices',
                subtitle:
                    'The "My Devices" section allows you to add and save a list of supported devices that work using the SIP protocol.',
                myDevicesList: {
                    search: 'Search',
                    name: 'Device name',
                    address: 'SIP URI Address',
                    add: 'Add',
                    add_device: 'Add device',
                    add_device_success: 'Device added successfully',
                    edit_device: 'Edit device',
                    edit_device_success: ' Device saved successfully',
                    edit: 'Edit',
                    delete: 'Delete',
                    delete_success: 'Device removed',
                    deviceAlreadyExists: 'SIP Device with same URI already exists',
                    sipDeviceAlreadyExists: 'Device with this address already exists',
                    sipDeviceLimit: 'Device limit reached',
                    sipDeviceOtherOrganization: 'Unable to add this device',
                    sipDeviceNotFound: 'Device not found',
                    searchNotFound: 'Nothing found',
                    emptyDevices: 'No devices added yet',
                    maxLength: 'Maximum length exceeded',
                },
                approve: 'Verify',
                delete: 'Remove',
                confirmRemove: {
                    title: 'Confirm remove',
                    text: 'Are you sure want to remove device {{device}}?',
                },
            },
            siem: {
                title: 'SIEM settings',
                subtitle: 'For sending organization logs to your SIEM-system',
                SIEMSettingsForm: {
                    host: 'Address',
                    port: 'Port',
                },
                success: 'SIEM settings have been successfully updated',
            },
            pointOfPresencePage: {
                title: 'Point Of Presence',
                description:
                    'Activating Point Of Presence allows you to reduce the load on the office network during large events. ',
                switcherLabel: 'Activate Point Of Presence',
                token: 'Token',
                networks: 'Networks',
                host: 'Host',
                refresh: 'Generate new token',
                errorLoading: 'Error loading Point Of Presence',
                errorSwitching: 'Status switch error',
                errorRefresh: 'Token refresh error',
                aboutLink: 'Learn more',
                state: 'State',
                enabled: 'Enabled',
                disabled: 'Disabled',
            },
            apiKey: {
                keyRefreshing: 'The key is being updated',
                keyRefreshingDone: 'The key has been updated',
                keyRefreshingReject: 'The key has not been updated, please retry',
            },
        },
        statistics: {
            title: 'Statistics',
            visits: 'Hits',
            channels: 'Sources',
            participants: 'Participants',
            webinarSection: 'Webinars',
            coursesSection: 'Courses',
            coursesCountLabel: 'Courses',
            activeStudentsCountLabel: 'Students',
            averagePassingLabel: 'Average progress of the passage',
            averagePassingHelper: 'Average % of the course that student passes',
            finishedStudentsPartLabel: 'Convertion Rate into finishing',
            finishedStudentsPartHelper: 'Share of students who completed the course till the end',
            downloadXLS: 'Download XLS',
            courseFilters: {
                chooseCourses: 'Choose courses',
                allCourses: 'All courses',
                searchPlaceholder: 'Search',
            },
            intervals: {
                allTime: 'During all time',
                byWeek: 'For week',
                byMonth: 'For month',
                byYear: 'For year',
                byPeriod: 'For period',
            },
            chooseDateRange: 'Choose period',
        },
        editor: {
            save: 'Save',
            activitySettingsTitle: 'Group work',
            activityGroupsNumberLabel: 'The number of groups',
            activityGroupsNumberEqualityLabel:
                'The number of active (marked with a checkbox) tasks must be equal to the number of groups',
            activityAutoReturnToDefault: 'Return automatically',
            activityDurationLabel: 'Return in',
            activityIsPublicLabel: 'The participant chooses the group',
            activityPublicHelper: 'Participants will not be initially split into groups',
            activityNotPublicHelper: 'Participants will be automatically split into groups',
            activityDistributionTypeLabel: 'Different tasks for groups',
            activityIndividualDistributionHelper: 'Each group gets an individual task',
            activityCommonDistributionHelper: 'Same task for all groups',
            activityAllowRenameGroups: 'Participants can change the group name',
            textQuestion: 'Question with text',
            imageQuestion: 'Question, text, and picture',
            textAnswer: 'Answer with text',
            imageAnswer: 'Answer with pictures',
            freeAnswer: 'Free answer',
            minScoreLabel: 'Minimum number of answers',
            freeAnswerSwitchLabel: 'With an optional answer',
            passScoreLabel: 'Minimum number of points',
            pollTitleLabel: 'Think of a vote name',
            questionLabel: 'Question',
            scoreLabel: 'Points',
            answerLabel: 'Answer',
            freeAnswerLabel: 'Free answer',
            numberOfAnswers: 'The number of answers which can be given to a question',
            loadFromFiles: 'From files',
            testDurationLabel: 'Duration',
            maxScore: 'Max points {{maxScore}}',
            useScoreLabel: 'Evaluate according to the points system',
            loadFromSystem: 'From computer',
            activityTitleLabel: 'Activity Name',
            newGroupTaskLabel: 'New task',
            groupTaskIndexHelper: 'Task №{{index}}',
            activityTasksTitle: 'Tasks for training',
            fileMustBeImageError: 'Only images can be uploaded',
        },
        eventEditor: {
            dateSettingValue: '{{date, dd.MM.yyyy}}',
            filesTooltip: 'Files',
            settingsTooltip: 'Settings',
            streamSettingsTooltip: 'Additional settings',
            streamsettings: {
                background: 'Background image',
                general: 'General',
                guestAllowed: 'Allow particpants',
                title: 'Additional',
                chatmoderation: 'Moderation of chat and questions',
                chatmoderationHelperText:
                    'Participants`s messages will appear in chat after verification by moderator or administrator',
                allowGuestOnAir: 'Go on air',
                allowGuestOnAirHelperText: 'Makes "Go on air" button visible for participants',
                joinWithEnabledMicrophone: 'Connect with the microphone on',
                joinWithEnabledMicrophoneHelperText:
                    'In order not to distract participants from the meeting with extraneous sounds, disable this option',
                viewModeLabel: 'Display video of the speaker and participants',
                viewModeClassicComment:
                    'Mosaic - video thumbnails of the participants and the speaker have the same size.',
                viewModeActiveSpeakerComment:
                    "In the spotlight - the speaker's video occupies the entire presenter area. When the presentation is on, the speaker's video is next to it.",
                manageFiles: 'Manage files',
                manageFilesHelperText:
                    'Participants will be able to add files and manage them in the presenter',
                uploadFiles: 'Upload files',
                uploadFilesHelperText:
                    'Participants will be able to add, delete and download files',
                demonstrateFiles: 'Display files',
                demonstrateFilesHelperText:
                    'Participants can display files and manage others presentations',
                allowScreensharing: 'Share screen',
                allowScreensharingHelperText:
                    'Makes "Share screen" button visible for participants',
                mediaControls: 'Control media files',
                mediaControlsHelperText: 'Participant can control video player',
                allowUseChat: 'Send chat messages',
                showMinichat: 'Display mini-chat',
                allowUseQuestions: 'Ask questions',
                canSeeParticipnants: 'See participants list',
                onAirWithConfirmation: 'With confirmation',
                onAirFree: 'Automatically',
                standartVideoSize: 'Standard video size',
                largeVideoSize: 'Large video size',
                useActiveSpeaker: '100 participants in the meeting',
            },
            name: 'Event name',
            timePickerTitle: 'Event time',
            announceFileHasBeenAdded:
                'The file {{ data: fileName}} has already been added to the description',
            settings: {
                durationPlaceholder: 'Meeting duration',
                registrationFieldPlaceholder: 'Field name',
                createNewField: 'Add field',

                additionalFieldsName: 'First Name',
                additionalFieldsSecondName: 'Last Name',
                additionalFieldsEmail: 'E-mail',
                additionalFieldsPhone: 'Phone number',
                additionalFieldsCity: 'City',
                additionalFieldsCompany: 'Company',
                additionalFieldsPosition: 'Position',
                additionalFieldsSource: 'How did you find us?',
                title: 'Settings',
                date: 'Event date',
                duration: 'Duration',
                durationOhter: 'Other',
                repeat: {
                    'FREQ=DAILY;COUNT=1': 'Do not repeat',
                    'FREQ=DAILY': 'Every day',
                    'FREQ=WEEKLY': 'Every week',
                    'FREQ=WEEKLY;INTERVAL=2': 'Every two weeks',
                    'FREQ=MONTHLY': 'Every month',
                    CUSTOM: 'My schedule',
                },
                repeatWeekly: 'Every week',
                repeatWeekend: 'Weekends',
                repeatWorkDays: 'Week days',
                repaetRestriction: {
                    date: 'Limit by date',
                    count: 'Limit by number of webinars',
                },
                repaetBefore: 'to',
                repeatTimes: 'Number of webinars',
                eventRegistration: 'Invite to entire series',
                enterType: 'Access to event',
                freeAccessComment:
                    'Participants are not required to register. They only need to enter their chat names',
                registration: 'Registration',
                registrationComment:
                    'Participants will be required to fill out a registration form when they enter the event. Upon completion, you can download the data of participants in your personal account.',
                moderationComment:
                    'Participants must be approved after they register in order to join',
                password: 'Password for logging in',
                passwordComment:
                    'The password must be sent to the participants in a separate email',
                tags: 'Tags',
                cost: 'Cost',
                costSerial: 'Cost of series in ₽',
                language: 'Language of the event interface',
                languageHelperText:
                    "Affects only the interface of your event, does not affect the user's personal account. <0>More information</0>",
                remindersTitle: 'Reminders for participants',
                remindersTimeOffsetField: 'Send',
                remindersTimeOffsetFieldAfter: 'before',
                remindersActiveLabel: 'Enable mailout',
                reminderDisabled: 'Disabled',
                reminderSent: 'Sent',
                remindersSet: 'Configure notifications',
                remindersminute: 'minute',
                remindersminute_plural: 'minutes',
                remindershour: 'hour',
                remindershour_plural: 'hours',
                remindersday: 'day',
                remindersday_plural: 'days',
                remindersweek: 'week',
                remindersweek_plural: 'weeks',
                remindersmonth: 'month',
                remindersmonth_plural: 'months',
                defaultReminderTitle: 'Webinar "{{webinar_name}}" reminder',
                ru: 'Russian',
                en: 'English',
                encoder: 'Broadcast using encoder',
                encoderPopup: {
                    title: 'Broadcast settings using encoder',
                    server: 'Server URL (FMS URL)',
                    name: 'Stream name',
                    help: 'How to set up the encoder.',
                    warning: 'You have to use AAC codecs for sound and H.264 for video.',
                },
                pickAdditionalFields: 'Select fields to add to the registration form',
                additionalFieldsCustom: 'My own fields',
                newAdditionalTextField: 'Simple field',
                newAdditionalRadioField: 'Field with pre-configured answers',
                fieldsDialogTitle: 'Ask upon logging in',
                openFieldsDialog: 'Configure',
                moderationSettingLabel: 'Moderation of self-registered users',
                serial: 'Series',
                repeatWeekly_plural: 'Every {{num}} week',
                totalEvents: '{{num}} event',
                totalEvents_plural: '{{num}} events',
                freeAccess: 'Free access',
                owner: 'Owner',
                reminders: 'Reminders',
                remindersTitleLabel: 'Title',
                remindersTemplateLabel: 'Message template',
                reminderLength: '{{number}} {{unit}} before',
                reminderTitle: 'Reminder {{index}}',
                remindersTemplateRules:
                    'Macros for the text:\n{{webinar_name}} — inserts event\'s name\n{{name}} — inserts the recipient\'s name and surname, if available\n{{status}} — inserts the participant\'s status as "invited" or "registered"',
                defaultReminderContent: '{{name}},\n напоминаем, что Вы {{status}} на вебинар.',
                defaultMeetingReminderTitle: 'Meeting reminder "{{webinar_name}}"',
                defaultMeetingReminderContent:
                    '{{name}},\n we would like to remind you that you are checked in for the meeting.',
                newAdditionalFieldAnswer: 'New answer option',
                registrationWithModeration: 'Registration with moderation',
                branding: 'Branding',
                brandingCreateNote: 'Create new template at <0>Branding</0> page',
                autostart: {
                    title: 'Starting meeting',
                    switchFieldTitle: 'Autostart',
                    helperText:
                        'When activated, the event will automatically start at the specified start time. A deviation of +/- 2 minutes is possible - it is better to set the start time of the event 5 minutes before the actual one.',
                },
                waitingRoom: {
                    switchFieldTitle: 'Waiting room',
                    helperText:
                        'Participants will be able to join only after confirmation from the event organizer',
                },
            },
            copyLink: 'Copy link',
            copySipLink: 'Copy SIP URI',
            copySipLinkHint: 'SIP URI will be generated after clicking "Save"',
            save: 'Save',
            saved: 'Saved',
            delete: 'Delete',
            goToEvent: 'Go to webinar',
            loadNewBackImage: 'Upload new',
            resetBackImage: 'Return standart',
            description: 'Description',
            participants: 'Participants',
            eventLink: 'Link to event ',
            find: 'Find',
            selectAll: 'Select all',
            inviteContact: 'Invite',
            invited: 'Invited',
            roleAdmin: 'Administrator',
            roleLecturer: 'Presenter',
            onmoderation: 'Moderation',
            participantName: 'First Name',
            participantEmail: 'Mail',
            inviteByEmail: 'Invite by mail',
            makeParticipantLecturer: 'Make a host',
            makeParticipantAdmin: 'Make an administrator',
            sendInviteAgain: 'Resend the link',
            blockParticipant: 'Block',
            deleteParticipant: 'Delete',
            cancelInvite: 'Cancel invitation',
            inviteSent: 'The link was e-mailed',
            participantBlocked: 'Participant is blocked',
            filterParticipantsAll: 'All',
            filterParticipantsOnModeration: 'Moderation',
            saveDialogTitle: 'Save changes',
            saveDialogText: 'You changed the event settings. Save changes?',
            saveDialogActions: {
                event: 'For entire series',
                eventSession: 'For this event',
            },
            deleteDialog: {
                actionSessionWebinar: 'Delete webinar',
                actionEvent: 'Delete series',
                title: 'Delete',
                text: 'Confirm delete of the event',
                actionSessionMeeting: 'Delete meeting',
            },
            changeRuleDialogTitleRule: 'Schedule change',
            changeRuleDialogTitleDate: 'Events start date changed',
            files: {
                title: 'Files',
                addFiles: 'Add',
                openFile: 'Open',
                downloadFile: 'Download',
                unshareFile: 'Disallow downloading',
                shareFile: 'Allow viewing',
                removeFile: 'Remove from webinar',
            },
            import: 'Import',
            step1Import: 'Download the import template',
            step2Import: 'Fill out the template with the information on people',
            step3Import: 'Import the complete template to the website',
            downloadImportTemplate: 'Download a template',
            importError: 'Error occurred on contacts import',
            importErrorBadFile: 'The file is corrupted',
            importErrorNoContacts: 'No contacts found in the file',
            importErrorTooManyContacts: 'Unable to upload over 30 thousand contacts at once',
            cantInviteLectorWithEmail: 'The participant with current email is already invited',
            lectorsSendInviteLabel: 'Send invitation',
            lectorsEmailLabel: 'E-mail',
            lectorsShowOnLandingLabel: 'Show on landing',
            lectorsMakeAdminLabel: 'Make an administrator',
            lectorSecondNameLabel: 'Last Name',
            lectorNameLabel: 'First Name',
            lectorPositionLabel: 'Position',
            lectorDescriptionLabel: 'Description',
            lectorsSearchPlaceholder: 'Input name or email',
            lectorsListHeader: 'Hosts',
            lectorsWithInviteHelper: 'The presenter will receive an email with a login link',
            lectorsWithoutInviteHelper: 'The presenter will be added to the event description',
            errors: {
                validation: {
                    required: {
                        name: "Name can't be empty",
                    },
                    rules: {
                        noMoreOneDash: 'You can not use 2 characters "_", "-" in a row',
                        onlyMidleDash: 'You can not use "_", "-" at the beginning and end',
                        noOnlyNumbers: 'You can not use only numbers',
                        noOtherSymbols:
                            'You can not use characters other than letters, numbers, "-", "_"',
                    },
                },
                urlAliasNotUnique: 'Url "{{url}}" already in use',
            },
            editLink: 'Edit link',
            editLinkDescription:
                'Editable link must contain numbers and letters.\nFor example, MegaMeet-08-03',
            deselectAll: 'Deselect all',
            roleGuest: 'Guest',
            preparedForInvite: 'Not invited',
            participantRole: 'Role',
            participantStatus: 'Status',
            noParticipants: 'No participants',
            noSearchedParticipants: 'There are no participants matching your search',
            makeParticipantGuest: 'Change role to guest',
            makeGuestDone: 'Succesfully changed role to guest',
            makeLecturerDone: 'Succesfully changed role to lecturer',
            makingAdmin: 'Changing role to administrator',
            makeAdminDone: 'Succesfully changed role to administrator',
            participantDeleted: 'Pariticipant deleted',
            filterParticipantsInvited: 'Invited',
            filterParticipantsPreparedForInvite: 'Not invited',
            changeRuleDialogTextRule:
                'You have changed the schedule of the event.<br/> According to the new rule, this event will be adjourned to {{date, d MMMM yyyy}}. All the next will fit the rule "{{rule}}"',
            changeRuleDialogTextRuleSingle:
                'You have changed the schedule of the event.<br/>According to the new rule, this event will be moved to {{date, d MMMM yyyy}}. Also according to the rule, subsequent events will be deleted',
            changeRuleDialogTextDate:
                'You changed the start date of event series<br/>The first event will be adjourned to {{date, d MMMM yyyy}}',
            importInProgress: 'Importing',
            importDone: 'Import finished',
            importSidebarText:
                'Invite people from the address book, by mail or <0>import</0> your contacts.',
            contactsSidebarHeader: 'Invite participants',
            addNewLectorCandidate: 'Add new lecturer',
            noLectorCandidatesWithQuery: 'No lecturer found matching your query',
            noLectorCandidates: 'No lecturers',
            lectorsListEmpty: 'Lesturers wont be displayed on landing',
            lectorsMakeAdminHelper: 'Administrator can stop an event.<0></0> <1>Details</1>',
        },
        invitesInProgress: 'Inviting participants…',
        webinarNearEnd:
            'Webinar «{{name}}» will be stopped in less than {{seconds, timeDuration}} minutes',
        webinarAutoEnd: 'Webinar «{{name}}» automatically stopped',
        goToWebinar: 'Go to',
        download: 'Download',
        statsPrepareReady: 'The file is ready',
        statsPrepare: 'The file preparation is in progress...',
        statsPrepareExplain:
            'You can leave this page. The link will be available in notifications.',
        and: 'and',
        profile: {
            name: 'First Name',
            secondName: 'Last Name',
            nickname: 'Nickname',
            phone: 'Phone number',
            email: "Presenter's email",
            changePassword: 'Change password',
            dontChangePassword: 'Do not change password',
            oldPassword: 'Old password',
            password: 'New password',
            organization: 'Organization',
            position: 'Position',
            description: 'Brief profile',
            language: 'Language',
            russian: 'Russian',
            english: 'English',
            settings: 'Settings',
            autoRecord: 'Disable webinar recording',
            autoRecordHint: 'The recording setting will apply to newly launched events',
            autoRecordFeature: 'Start recording at the start of the event',
            autoRecordFeatureHint: 'The setting does not apply to endless meetings.',
            streamMode: 'Use WebRTC by default in the "Webinar" mode',
            eventSelfNotify: 'Add created events to my calendar',
            forceUseMeetingCabinet: 'Use the default personal meeting room',
            desktopAppForMe: 'Use <a>desktop app</a> when joining events by default',
            desktopAppForMyParticipation:
                'Use <a>desktop app</a> for my events participants by default',
        },
        contactSales: {
            title: 'Meet without limits!',
            viewTarrifsButton: 'Choose tariff',
            updateTarrif: 'Buy tariff',
            button: 'Contact manager',
        },
        pageTitle: {
            files: 'Files',
            signin: 'Log in',
            signup: 'Registration',
            webinarTemplate: '%s - Webinar',
            editor: 'Editor',
            passwordRecovery: 'Password recovery',
            emailConfirmation: 'Confirmation of the e-mail address',
            extendAccount: 'Set a password',
            organizationInvitation: {
                email: 'Invitation to the organization',
                link: 'Invitation to the organization by link',
            },
        },
        nav: {
            profile: 'Profile',
            payment: 'Payment',
            systemTest: 'System test',
            help: 'Instructions',
            webinars: 'Webinars',
            broadcasts: 'Broadcast',
            organization: 'Organization',
            people: 'People',
            files: 'Files',
            statistic: 'Statistics',
            business: 'Business',
            ssoSettings: 'SSO settings',
            myDevices: 'My devices',
            siemSettings: 'SIEM settings',
            tariffInfo: 'subscription info',
            education: 'Learning',
            search: 'Search',
            notifications: 'Notifications',
            applications: 'Applications',
            addFile: 'Add file',
            save: 'Save',
            meetings: 'Meetings',
            courses: 'Courses',
            logout: 'Log out',
            buyTariff: 'Buy tariff',
            myTariff: 'My tariff',
            download_app: 'Download app',
            integrations: 'Extensions and integrations',
            integrations_google_calendar: 'Google Calendar',
            integrations_outlook_calendar: 'Microsoft Outlook',
            integrations_google_chrome: 'Google Chrome extension',
            integrations_more: 'More',
        },
        core: {
            files: 'Files',
            loadNewPhoto: 'Upload a new photo',
            delete: 'Delete',
            rateOutOfSpace: 'Not enough space for new files',
            spaceLimitClose: 'Not much space for new files',
            hours: 'Clock',
            rateName: 'Tariff «{{name}}»',
            buyNewRate: 'ACTIVATE NEW SUBSCRIPTION',
            updateRate: 'UPDATE',
            dueAt: 'Expires at {{date, date}}',
            dueAtWarning: 'Expires at {{date, date}} — in {{daysRemaining}} days',
            dueAtInfinity: 'Expires at ∞',
            fileSizeUsed: '{{size, filesize}} used',
            fileSizeMax: 'from {{size, filesize}}',
            dropHere: 'Drop here',
            hoursUsed: '{{count}} used',
            hoursMax: 'from {{count}}',
        },
        onboarding: {
            signup: 'You are signed in ',
            trainingWebinar: 'Webinar tutorial ',
            repeatTrainingWebinar: 'Repeat',
            onProfileFill: 'Filling out the profile',
            onProfileFill_info:
                'To go to the profile, click the avatar image in the upper right corner and select "Profile" in the dropdown list',
            onEventCreate: 'Webinar creation',
            onEventCreate_info:
                'Click on the "Plan" button and create your first webinar by following the instructions: add the moderators and the information about the webinar, invite the participants and load the files',
            onRecord: 'Webinar recording',
            onRecord_info:
                'Click on the tab of the completed webinar, watch the recording, allow other participants of the webinar to view it. If necessary, the recording can be edited',
            onStat: 'Webinar statistics',
            onStat_info:
                'Click the menu button on the upper bar, go to the Statistics section, and view the information on the webinars held and participants',
            taskDescription: 'Perform all tasks and get 2Gb of additional disk space',
            trainingWebinarCreateFail: 'Something went wrong',
            NStepsFromM: '{{n}} from {{m}}',
        },
        checklist: {
            offers: {
                involvementAndMarketing3Months:
                    'Get "Marketing" & "Involvement" Toolkits Free for 3 months',
            },
            tasks: {
                inviteEmployee: 'Add a colleague to your organization',
                scheduleEventSession: 'Create a webinar, meeting or training',
                holdEventSessionWith2Guests: 'Hold an online meeting with two or more colleagues',
                uploadFile: 'Add any files to file storage',
                addContact: 'Add contacts to your address book',
            },
            completeTasksLeft: {
                commonLabel: 'Time left to complete tasks',
                commonLabel_plural: 'Time left to complete tasks',
                days: '{{ count }} day',
                days_plural: '{{ count }} days',
            },
            congratsDialog: {
                title: 'Congratulations!',
                defaultText:
                    'You have completed all the tasks and you got the Lootbox “Engagement and Marketing Package for Free for Six Months”. Now feature one and feature two are available to you for 6 months.',
                actionButton: 'Excellent!',
            },
        },
        notifications: {
            continue: 'Continue',
            answerSubmit: 'Respond',
            answerLabel: 'Answer',
            CHAT_EXPORT_body:
                'The chat messages from the event «{{data.abstractEvent.name}}» have been successfully exported to a file.',
            CHAT_EXPORT_title: 'Chat is ready to download',
            CONTACTS_EXPORT_body: 'Contacts have been exported',
            CONTACTS_EXPORT_title: 'Contacts exported',
            DEFAULT_REMINDER_body: '{name}, we remind you that you are registered for the webinar.',
            DEFAULT_REMINDER_title: 'Reminder of the webinar "{{webinar_name}}"',
            EMAIL_QUESTION_body1: 'User <0>{{data.name}} {{data.secondName}}</0> asked:',
            EMAIL_QUESTION_body2: '{{data.question}}',
            EMAIL_QUESTION_body3: '<0>Email:</0> {{data.email}}',
            EMAIL_QUESTION_title: 'Question to the webinar "{{data.title}}" ',
            EMAIL_QUESTION_COURSE_title: 'Question to the course "{{data.title}}" ',
            EMAIL_QUESTION_LESSON_title: 'Question to the lesson "{{data.title}}" ',
            EVENT_PARTICIPANTS_LIMIT_EXCEEDED_body:
                'The maximum number {{data.limit}} of participants in the {{data.type}} {{data.name}} has been reached.',
            EVENT_PARTICIPANTS_LIMIT_EXCEEDED_title: 'Max. number of participants has been reached',
            EVENTSESSION_PARTICIPANTS_LIMIT_EXCEEDED_body:
                'The maximum number {{data.limit}} of participants in the {{data.type}} {{data.name}} has been reached.',
            EVENTSESSION_PARTICIPANTS_LIMIT_EXCEEDED_title:
                'Max. number of participants has been reached',
            JOURNAL_SELECTION_body: 'Journal selection done and have been exported',
            JOURNAL_SELECTION_title: 'Journal selection done',
            OLOLO_body: 'Ololo, {{user.name}}',
            OLOLO_title: 'Ololo',
            ORGANIZATION_INVITATION_body:
                'You were invited to participate in the "{{data.organizationName}}" organization as a presenter <br/>  After the participation confirmation you can create your own online events and edit the existing ones. Read more about your opportunities in your personal account<br/>Please note that after joining the organization you lose the title to the created webinars and files, and you will not be able to manage your account',
            ORGANIZATION_INVITATION_title: 'Invitation to the organization',
            PAID_OPTION_body: '{{data.body}}',
            PAID_OPTION_title: '{{data.title}}',
            PAID_PRODUCT_body: 'Package {{data.name}}, will be activated {{data.date}}',
            PAID_PRODUCT_title: 'Add package',
            PARTICIPANTS_LIMIT_EXCEEDED_body:
                'The limit on the number of webinar participants has been reached {{data.name}}',
            PARTICIPANTS_LIMIT_EXCEEDED_title: 'Limit exceeded simultaneous participants',
            PARTICIPATIONS_LIMIT_EXCEEDED_UNKNOWN_LIMIT_body:
                'The maximum number of participants in the {{data.type}} {{data.name}} has been reached.',
            PARTICIPATIONS_LIMIT_EXCEEDED_UNKNOWN_LIMIT_title:
                'Max. number of participants has been reached',
            QUESTIONS_EXPORT_body:
                'The questions from the event «{{data.abstractEvent.name}}» have been successfully exported to a file.',
            QUESTIONS_EXPORT_title: 'Questions are ready to download',
            RECORD_CONVERTED_body: 'Webinar recording {{data.eventSession.name}} converted',
            RECORD_CONVERTED_title: 'Converted webinar recording',
            STATISTICS_CONVERTED_body: 'Statistics converted and you can download it',
            STATISTICS_CONVERTED_title: 'Statistics converted',
            STATS_EXPORT_body: 'Statistics have been exported',
            STATS_EXPORT_title: 'Statistics exported',
            STATS_PREEXPORT_body: 'Participations list have been exported',
            STATS_PREEXPORT_title: 'Participations list exported',
            STORAGE_MAX_SIZE_INCREASED_body:
                'Size of the file storage increased to {{data.maxStorage}}Gb',
            STORAGE_MAX_SIZE_INCREASED_title: 'File storage size increased',
            STORAGE_SIZE_LOW_body: 'User {{data.name} } is running out of disk space',
            STORAGE_SIZE_LOW_title: 'Storage low space',
            TARIFF_EXPIRED_body: 'The tariffs of the following users expired: {{data.users}}',
            TARIFF_EXPIRED_title: 'The tariffs are expired',
            TARIFF_EXPIRED_USER_body: 'Dear {{user.name}}, your tariff expired {{data.expires}}',
            TARIFF_EXPIRED_USER_title: 'The tariffs are expired',
            TARIFF_EXPIRES_body: 'The tariffs of the following users are expiring: {{data.users}}',
            TARIFF_EXPIRES_title: 'The tariffs are expiring',
            TARIFF_EXPIRES_USER_body:
                'Dear {{user.name}}, your tariff will expire {{data.expires}}',
            TARIFF_EXPIRES_USER_title: 'The tariffs are expiring',
            WEBINAR_AUTOMATICALLY_STOPPED_body:
                'Webinar {{data.eventSession.name}} automatically terminated due to no activity for more than 15 minutes.',
            WEBINAR_AUTOMATICALLY_STOPPED_title: 'Webinar finished.',
            WEBINAR_NEAR_TO_STOP_body:
                'Webinar {{data.eventSession.name}} will automatically be completed in 5 minutes.',
            WEBINAR_NEAR_TO_STOP_title: 'The webinar will be completed soon ',
            emptyContent: 'No notification',
            TARIFF_ADDED_body: 'New subscription added {{data.tariffName}}',
            TARIFF_ADDED_title: 'Subscription added',
            TARIFF_ADDED_USER_body: 'New subscription added {{data.tariffName}}',
            TARIFF_ADDED_USER_title: 'Subscription added',
            TARIFF_EXTENDED_body: 'Lifetime of subscription {{data.tariffName}} was extended',
            TARIFF_EXTENDED_title: 'Lifetime of subscription extended',
            TARIFF_EXTENDED_USER_body: 'Lifetime of subscription {{data.tariffName}} was extended',
            TARIFF_EXTENDED_USER_title: 'Lifetime of subscription extended',
            TARIFF_OPTION_ADDED_body: 'Service {{data.optionName}} succesfully enabled',
            TARIFF_OPTION_ADDED_title: 'Service enabled',
            TARIFF_OPTION_ADDED_USER_body: 'Service {{data.optionName}} succesfully enabled',
            TARIFF_OPTION_ADDED_USER_title: 'Service enabled',
            TARIFF_OPTION_REMOVED_USER_body: 'Service {{data.optionName}} disabled',
            TARIFF_OPTION_REMOVED_USER_title: 'Service disabled',
            TARIFF_REMOVED_body: 'Subscription {{data.tariffName}} disabled',
            TARIFF_REMOVED_title: 'Subscription disabled',
            TARIFF_REMOVED_USER_body: 'Subscription {{data.tariffName}} disabled',
            TARIFF_REMOVED_USER_title: 'Subscription disabled',
            TRANSFER_DATA_OFFER_body:
                'Продолжайте работу с уже созданными вебинарами, файлами и контактами, работайте с тестами и опросами, просматривайте и редактируйте онлайн и сконвертированные записи.',
            TRANSFER_DATA_OFFER_title: 'Transfer your data',
            STATS_COURSES_EXPORT_title: `Course statistics exported`,
            STATS_COURSES_EXPORT_body: `Course statistics have been exported`,
            ATTEMPT_TO_JOIN_ORGANIZATION_FOR_ADMIN_title: 'Your colleagues tried to join the team',
            USER_JOINED_ORGANIZATION_title:
                'You have been successfully added to the team {{data.organization_name}}',
            today: 'Today',
            yesterday: 'Yesterday',
            new: 'New',
            earlier: 'Earlier',
            dateSettingValue: '{{date, MM/d/yyyy}}',
        },
        events: {
            title: 'Webinars',
            schedule: 'Plan',
        },
        fs_folder_record: 'Records',
        fs_folder_endless_record: 'Endless meetings',
        fs_folder_record_converted: 'Converted records',
        fs_folder_test: 'Tests and voting',
        fs_folder_homework: 'Homework',
        fs_folder_banner: 'Banners',
        fs_folder_background: 'Backgrounds',
        fs_folder_agenda: 'Agendas',
        fs_folder_course_test: 'Tests for courses',
        filesizeUnit_b: 'B',
        filesizeUnit_kb: 'KB',
        filesizeUnit_mb: 'MB',
        filesizeUnit_gb: 'GB',
        abort: 'Cancel',
        apply: 'Apply',
        back: 'Back',
        continue: 'Continue',
        goto: 'Follow',
        ready: 'Done',
        send: 'Send',
        delete: 'Delete',
        change: 'Change',
        or: 'or',
        pageNotFound: {
            title: 'Sorry, the page not found',
            comment: 'The organizer deleted the event or record, or you entered an incorrect URL.',
            goToInstructions: 'View the user guide',
            goToSite: 'Do you want to organize a webinar?',
        },
        shareRecord: 'Share the record',
        landing: {
            askQuestion: 'Ask a question',
            askQuestionTitle: 'Ask the presenter a question',
            joinEvent: 'Join event',
            register: 'Sign up',
            eventEnterDialogSubmitText: 'Join event',
            alreadyEnteredWarningDialog: {
                title: 'Attention',
                content:
                    'You are logging in under the account data or personal link of a user who is already at the event. Only one user can be logged into a given account at a time. If you continue, the other user will be removed from the event.',
                submit: 'Continue',
            },
            eventRegisterDialog: {
                submit: 'Sign up',
                email: "Presenter's email",
                name: 'First Name',
                secondName: 'Last Name',
                title: 'Registration for the "{{name}}" event',
                validation: {
                    nameMaxLength: 'Name must contain no more than {{max}} symbols',
                    secondNameMaxLength: 'Second name must contain no more than {{max}} symbols',
                },
            },
            requireParticipantPasswordDialog: {
                title: 'Login required',
                submit: 'Log in',
                passwordPlaceholder: 'Enter your password',
                wrongPassword: 'Invalid password',
                recoverPassword: 'Recover password',
                cancel: 'Use diffrent accoun',
                description: 'To enter event, authenticate in account with {{email}} email',
            },
            passwordRecoveryDialog: {
                title: 'Have you forgotten your password?',
                submit: 'Recover password',
                label: "Presenter's email",
                cancel: 'Return to login',
                noSuchUser: 'We failed to find this e-mail address ',
                successTitle: 'Password recovered!',
                successContent:
                    'An e-mail with password recovery instructions was sent to your e-mail address',
            },
            eventRegisterCreatorDialog: {
                title: 'No registration required',
                text: 'You can go to the webinar from your personal account',
            },
            eventRegisterSuccessDialog: {
                title: 'Thank you!',
                text: 'You have signed in for the webinar «{{name}}», which will be held <0>{{date, d MMMM yyyy}} at {{date, HH:mm}} (timezone {{date, timezoneName}}, {{date, xxx}})</0>.<br/>Link with invitation has been send to your email <0>{{email}}</0>.',
            },
            maxUsersDialog: {
                title: 'No more vacancies are available',
                text: 'Unfortunately, the maximum number of participants has been reached for this webinar according to the selected tariff',
                meetingTitle: 'The maximum number of participants is reached',
                meetingText:
                    'All the places at this event are occupied. You can contact the organizer.',
                action: 'Get in touch with the organizer',
            },
            contactAdminDialog: {
                title: 'Get in touch with the organizer',
                name: 'First Name',
                secondName: 'Last Name',
                email: "Presenter's email",
                question: 'Your questions',
            },
            beforeEventStartDialog: {
                thanksTitle: 'Thank you!',
                meetingTitle: 'Meeting «{{name}}» not yet started.',
                webinarTitle: 'Webinar «{{name}}» not yet started.',
                accessAfterStart:
                    'Webinar starts <0>{{date, d MMMM yyyy}} at {{date, HH:mm}} (timezone {{date, timezoneName}}, {{date, xxx}})</0>.<br/>Connectinb will be available after start of the event.',
                meetingRegistarionSuccess:
                    '<0>{{nickname}}</0>, You have signed in for the meeting «{{name}}», which will be held <0>{{date, d MMMM yyyy}} at {{date, HH:mm}} (timezone {{date, timezoneName}}, {{date, xxx}})</0>.',
                webinarRegistrationSuccess:
                    '<0>{{nickname}}</0>, You have signed in for the webinar «{{name}}», which will be held <0>{{date, d MMMM yyyy}} at {{date, HH:mm}} (timezone {{date, timezoneName}}, {{date, xxx}})</0>.',
            },
            logoutDialog: {
                title: 'The owner of the account forcibly ended the sessions',
                text: 'New join link has been sent to {{email}}. If you do not have access to this email, use another or contact account owner',
            },
            notAcceptedDialog: {
                title: 'Access denied',
                text: 'The host has not yet approved your login to the event',
            },
            eventWiilStartAt: 'Event will start: <0/>',
            eventStarted: 'Event started',
            eventEnded: 'Event ended',
            eventEnterDialogTitle: 'Connecting to the event "{{name}}"',
            eventEnterDialogLabel: 'Please, introduce yourself',
            eventRegisterAlreadyRegisteredDialog: {
                webinarTitle: 'You have already signed in for the webinar «{{name}}»',
                meetingTitle: 'You have already signed in for the meeting «{{name}}»',
                webinarText:
                    'You have already signed in for the webinar «{{name}}», which will be held <0>{{date, d MMMM yyyy}} at {{date, HH:mm}}</0>',
                meetingText:
                    'You have already signed in for the meeting «{{name}}», which will be held <0>{{date, d MMMM yyyy}} at {{date, HH:mm}}</0>',
                noLink: 'Did not get access link?',
                sendAgain: 'Send again',
            },
            eventRegisterResendSuccessDialog: {
                text: 'Link with invitation has been send to your email <0>{{email}}</0>',
            },
        },
        auth: {
            ru: 'RU',
            en: 'ENG',
            signin: {
                title: 'Log in',
                infoTitle:
                    'To access the system, use your organizations login credentials and join it.',
                email: 'Email',
                ldapDnOrEmail: 'LDAP login',
                password: 'Password',
                show: 'Show',
                rememberMe: 'Remember me',
                submit: 'Log in',
                restorePassword: 'Recover password',
                register: 'Create an account',
                ssoLogin: 'Log in with SSO',
                errors: {
                    wrongLogin: 'The login and password contain errors',
                    noProlongation: 'Tariff not found. Please <0>contact support</0>',
                    participant:
                        'Looking for a webinar? <0>This is the place for you</0>. Planning to host your own events? <1>Sign up</1>',
                    ssoOnly:
                        'This account is managed by the organization.\nYou can only log in via SSO.',
                },
            },
            sso: {
                title: 'Log in with SSO',
                email: 'Corporate email',
                next: 'Continue',
                signInByEmail: 'Log in with email',
                back: 'Go back',
                backLogin: 'Back to login page',
                organization: 'Select an organization',
                organizationError: 'Email does not belong to any organization',
                invalidEmail: 'Invalid value',
                notVerifiedEmail: 'The user has not verified his email',
                invalidParams:
                    'Invalid input parameters / Organization not suitable for transition / Organization not found',
                noTokenPassed: 'Authorization token not passed/authenticated',
                notRightsToGo: 'The user has not confirmed his mail / no rights to go',
                defaultOrganization: 'Stay with current organization',
            },
            ssoSignup: {
                title: 'Registration with SSO',
                subtitle:
                    'Administrator of your organization has set a restriction on registering via email. To continue registration via SSO (Single Sing On) click the "Continue" button',
            },
            signup: {
                title: 'Registration',
                name: 'First name',
                secondName: 'Last name',
                phone: 'Phone',
                email: 'Email',
                emailExist: 'This address have been registered already',
                password: 'Password',
                organization: 'Company that you work with',
                submit: 'Sign up',
                login: 'Log in',
                terms: 'By clicking the "Sign up" button, I accept the terms of <0>the license agreement</0>, confirm that I\'ve read the <1>"Personal data processing Policy"</1>, <2>consent to the processing of my personal data</2> and communication with me in the ways specified in the agreement, in order to fulfill the terms of the agreement. <3>Company Information.</3>',
                companyInformation: {
                    title: 'WEBINAR TECHNOLOGIES LLC',
                    content:
                        'PSRN 1157746417575<br />TIN 7707340808<br />IEC 770701001<br /><br />Street address: 3 Priyutsky Ln., Bldg. 1, Moscow 127055<br /><br />Mailing address: 21 Praskovyina St., Moscow 129515<br /><br />Bank information:<br />JSC Alfa-Bank (Moscow)<br />Operating Account 40702810701100005539<br />Correspondent Account 30101810200000000593<br />BIC 044525593<br /><br />A. V. Alpern, CEO',
                },
                success: {
                    title: 'You are signed in!',
                    content:
                        'You need to confirm your email. The letter has been sent to your email <0>{{email}}</0>',
                },
            },
            passwordRecovery: {
                title: 'Have you forgotten your password?',
                description: 'Please enter your registration email address.',
                email: 'Email',
                submit: 'Recover password',
                back: 'Back to login page',
                restored: 'Password recovered!',
                sent: 'An e-mail with password recovery instructions was sent to your email <0>{{email}}</0>',
                newPassword: 'New password',
                password: 'Password',
                passwordChanged: 'The password has been successfully changed',
                emailNotFound: 'We failed to find this e-mail address',
                weakPassword: 'The password must contain no less than 8 characters',
                errors: {
                    ssoRecovery:
                        'This account is managed by the organization.\nTo change password, please, contact your administrator.',
                    passwordAttemptsLimit:
                        'Too many failed attempts. Please try again in 15 minutes.',
                },
            },
            emailSubmit: {
                title: 'Your e-mail address was successfully confirmed.',
            },
            extendAccount: {
                title: 'Set a password',
                success: 'Password set successfully',
                error: 'Failed to set password. Try again later',
                submit: 'Set',
                password: 'Password',
                passwordConfirmation: 'Repeat password',
                passwordNotMatch: 'Passwords do not match',
            },
            desktopApp: {
                text: "We've redirected you to the desktop application. If you are not automatically redirected, click <bold>“Open App”</bold> below",
                button: 'Open App',
                troubles: 'Having trouble launching the application?',
                browserLink: 'Sign in to your personal account in the browser',
            },
            mobileApp: {
                text: 'To go to your personal account in mobile application, click <bold>“Open App”</bold> below',
                button: 'Open App',
                error: 'Oops, something went wrong. Try later',
                reload: 'Reload',
            },
        },
        organization: {
            invitation: {
                email: {
                    setPassword: 'Set up your account password',
                    password: 'Password',
                    joined: 'Your account has been successfully added to the organization!',
                    tariffRestriction:
                        'You can not accept an invitation to the organization, because you have an active paid tariff. Please contact the administrator of this organization.',
                    existPaidTariff: 'You can not invite a user with an active paid tariff',
                },
                link: {
                    join: 'Join the <0>"{{name}}"</0> team',
                    enterPassword:
                        'Please enter the password you received along with the invitation link',
                    password: 'Security password',
                    passwordHelperText:
                        'If you do not know the password, contact the person who invited you',
                    wrongPassword: 'Wrong password',
                    selectAuth: 'To join the organization you need to register or log in',
                    signup: 'Sign up',
                    signin: 'Log in',
                    invalidToken: '<0>Oops!</0> The link is no longer valid',
                    invalidPassword: '<0>Oops!</0> Password is no longer valid',
                    limit: '<0>Oops!</0> The organization you want to join no longer has open slots in the team',
                    contact: 'Please contact the person who invited you',
                    member: 'You are already a member of this team',
                    memberDescription:
                        'You tried to join an organization you are already a member of',
                    anotherMember:
                        'You are a member of another team and cannot be added to another',
                    anotherMemberDescription:
                        'To join another organization, you need to leave the existing team',
                    paidTariff: 'You have a paid plan',
                    paidTariffDescription: 'Paid user cannot join another team',
                    studentNeedRegister: 'Please register',
                    studentNeedRegisterDescription: 'Please register to join the organization',
                },
            },
        },
        timezone: {
            'Pacific/Samoa': 'Samoa',
            'Pacific/Honolulu': 'Honolulu',
            'America/Anchorage': 'Anchorage',
            'America/Los_Angeles': 'Los Angeles',
            'America/Tijuana': 'Tijuana',
            'America/Chihuahua': 'Chihuahua',
            'America/Denver': 'Denver',
            'America/Phoenix': 'Phoenix',
            'America/Chicago': 'Chicago',
            'America/Tegucigalpa': 'Tegucigalpa',
            'America/Regina': 'Regina',
            'America/Mexico_City': 'Mexico City',
            'America/Bogota': 'Bogota',
            'America/New_York': 'New York',
            'America/Caracas': 'Caracas',
            'America/Manaus': 'Manaus',
            'America/Halifax': 'Halifax',
            'America/St_Johns': 'St Johns',
            'America/Buenos_Aires': 'Buenos Aires',
            'America/Fortaleza': 'Fortaleza',
            'America/Godthab': 'Godthab',
            'America/Santiago': 'Santiago',
            'America/Cuiaba': 'Cuiaba',
            'America/Asuncion': 'Asuncion',
            'America/Sao_Paulo': 'Sao_Paulo',
            'America/Montevideo': 'Montevideo',
            'Atlantic/Cape_Verde': 'Cape Verde',
            'Atlantic/Azores': 'Azores',
            'Africa/Casablanca': 'Casablanca',
            'Atlantic/Reykjavik': 'Reykjavik',
            'Europe/London': 'London',
            'Africa/Ndjamena': 'Ndjamena',
            'Europe/Brussels': 'Brussels',
            'Europe/Warsaw': 'Warsaw',
            'Europe/Belgrade': 'Belgrade',
            'Europe/Berlin': 'Berlin',
            'Africa/Tripoli': 'Tripoli',
            'Africa/Cairo': 'Cairo',
            'Africa/Harare': 'Harare',
            'Asia/Jerusalem': 'Jerusalem',
            'Europe/Kaliningrad': 'Kaliningrad',
            'Asia/Amman': 'Amman',
            'Asia/Istanbul': 'Istanbul',
            'Africa/Windhoek': 'Windhoek',
            'Europe/Athens': 'Athens',
            'Asia/Beirut': 'Beirut',
            'Asia/Damascus': 'Damascus',
            'Europe/Kiev': 'Kiev',
            'Europe/Ulyanovsk': 'Ulyanovsk',
            'Europe/Astrakhan': 'Astrakhan',
            'Europe/Samara': 'Samara',
            'Asia/Kuwait': 'Kuwait',
            'Africa/Nairobi': 'Nairobi',
            'Asia/Baghdad': 'Baghdad',
            'Europe/Moscow': 'Moscow',
            'Asia/Tehran': 'Tehran',
            'Asia/Tbilisi': 'Tbilisi',
            'Asia/Yerevan': 'Yerevan',
            'Asia/Muscat': 'Muscat',
            'Asia/Baku': 'Baku',
            'Asia/Kabul': 'Kabul',
            'Asia/Karachi': 'Karachi',
            'Asia/Tashkent': 'Tashkent',
            'Asia/Yekaterinburg': 'Yekaterinburg, Perm',
            'Asia/Kolkata': 'Kolkata',
            'Asia/Katmandu': 'Katmandu',
            'Asia/Kamchatka': 'Kamchatka',
            'Asia/Dacca': 'Dacca',
            'Asia/Almaty': 'Astana',
            'Asia/Novosibirsk': 'Novosibirsk',
            'Asia/Rangoon': 'Rangoon',
            'Asia/Krasnoyarsk': 'Krasnoyarsk',
            'Asia/Bangkok': 'Bangkok',
            'Asia/Ulan_Bator': 'Ulan Bator',
            'Asia/Hong_Kong': 'Hong Kong',
            'Asia/Taipei': 'Taipei',
            'Asia/Irkutsk': 'Irkutsk',
            'Asia/Kuala_Lumpur': 'Kuala Lumpur',
            'Australia/Perth': 'Perth',
            'Asia/Seoul': 'Seoul',
            'Asia/Yakutsk': 'Yakutsk',
            'Asia/Tokyo': 'Tokyo',
            'Australia/Darwin': 'Darwin',
            'Asia/Vladivostok': 'Vladivostok',
            'Asia/Magadan': 'Magadan',
            'Australia/Brisbane': 'Brisbane',
            'Pacific/Port_Moresby': 'Port Moresby',
            'Australia/Adelaide': 'Adelaide',
            'Australia/Hobart': 'Hobart',
            'Pacific/Noumea': 'Noumea',
            'Australia/Sydney': 'Sydney',
            'Pacific/Fiji': 'Fiji',
            'Pacific/Tongatapu': 'Tongatapu',
            'Pacific/Auckland': 'Auckland',
        },
        copiedToClipboard: 'Copied to clipboard',
        wrongFileType: 'Wrong file type',
        modalFeatureRequest: {
            submit: 'Send a request',
            requested: 'Thank you, the request has already been sent',
            contactManager: 'Contact the manager',
            requestedFeedback: 'The request has been sent. The manager will contact you soon.',
        },
        eventSelfNotifyOfferDialog: {
            title: 'Do you want events to be added to your work calendar?',
            text: 'You will receive an email about a scheduled event, which will automatically create an event in your calendar.',
            comment:
                'This setting is in your personal cabinet, click on your avatar and open the Profile section.',
            submit: 'Yes I do',
            cancel: 'No, thanks',
        },
        integrationInfoDialog: {
            title: 'Extensions and integrations',
            text: 'Create and run meetings through familiar tools and calendars',
            submit: 'Learn more',
            cancel: 'Close',
        },
        contacts: {
            itemDeleted: 'The contact was deleted',
            itemsDeleted: 'The contacts were deleted',
            importSuccess: 'Contacts have been imported: {{contactsNum}}',
            importFail: 'Import error',
        },
        confirmEmailDialog: {
            title: 'Confirm email',
            sendButton: 'Resend the link',
            beforeSentText:
                'Before the beginning, confirm your email. Actual email is needed to receive important system notifications such as questions from event participants, occupied file storage, etc.',
            afterSentText:
                'Account confirmation email was sent to your address {{email}}. To confrim account press button in the email.',
        },
        admin: {
            smtp: {
                confirmation: 'SMTP settings are confirmed',
                adminLink: 'To Admin Panel',
                errors: {
                    notFound: {
                        text: 'There seems to be something wrong with this link! It does not work. Try to copy it from the letter again.',
                    },
                    network: {
                        text: 'Something went wrong! Try again, or contact your network administrator.',
                        button: 'Try again!',
                    },
                },
            },
        },
        addEmployee: {
            inviteEmployee: 'Invite employees to use one tariff',
            manageEmployee:
                'You can manage your employees and organization settings in the <a>Organization</a> page',
            inviteLink: 'Invite link',
            inviteDescription: 'Through the link, the employee will join your organization',
            employee: 'employee',
        },
    },
    'voting-components': {
        test: {
            passed: 'passed',
            notPassed: 'not passed',
            participantsHeader: 'Participants',
            resultHeader: 'Result',
            correctAnswersNumberHeader: 'Correct',
            pointsNumberHeader: 'Gained',
            timeHeader: 'Time',
            passTime: '{{time, timeDuration}}',
            numberOfAnswers: '{{count}} answer',
            numberOfAnswers_plural: '{{count}} answers',
            numberOfPoints: '{{count}} point',
            numberOfPoints_plural: '{{count}} points',
        },
    },
};
