import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router';

import {
    validateSAMLSettings,
    getValidationResults,
    ValidationError,
} from '@wbnr/frontend-shared/lib/api/sso';
import { Button, createTestIdProps } from '@wbnr/ui';

import { IDP_OPTIONS, IdpProtocols, SETTINGS_LIST, SSOStep } from '../constants';
import { SettingViewField } from '../SettingViewFIeld';
import { useSSO } from '../SSOContext';

import { Preloader } from './Preloader';
import styles from './SAMLSettingsView.module.scss';
import { ValidationFailedModal } from './ValidationFailedModal';
import { ValidationSuccessfulModal } from './ValidationSuccessfulModal';

const BASE_TEST_ID = 'SAMLSettingsView';

enum STATE {
    INITIAL = 'initial',
    LOADING = 'loading',
    SUCCESSFUL = 'successful',
    FAILED = 'failed',
}

const SHOW_VALIDATION_PARAM_KEY = 'showValidation';
const SHOW_VALIDATION_PARAM_VALUE = 'true';

export const SAMLSettingsView = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { samlSettings, setStep, organizationId } = useSSO();
    const { search, pathname } = useLocation();
    const history = useHistory();
    const params = new URLSearchParams(search);
    const showValidation = params.get(SHOW_VALIDATION_PARAM_KEY) === SHOW_VALIDATION_PARAM_VALUE;
    const [state, setState] = useState<STATE>(showValidation ? STATE.LOADING : STATE.INITIAL);
    const [error, setError] = useState<ValidationError | null>(null);

    useEffect(() => {
        if (showValidation) {
            processValidationResult();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const processValidationResult = () => {
        if (!organizationId) {
            if (state !== STATE.INITIAL) {
                setState(STATE.INITIAL);
            }
            return;
        }

        return getValidationResults({ organizationId })
            .then(({ hasValidation, isValid, error: validationResultsError }) => {
                if (!hasValidation) {
                    history.push(pathname);
                    setState(STATE.INITIAL);
                    return;
                }

                if (isValid) {
                    setState(STATE.SUCCESSFUL);
                    return;
                }

                if (validationResultsError) {
                    setState(STATE.FAILED);
                    setError(validationResultsError);
                }
            })
            .catch(() => {
                enqueueSnackbar(t('business.sso.saml.commonError'), { variant: 'error' });
            });
    };

    const checkSettingsHandler = () => {
        if (!organizationId) {
            if (state !== STATE.INITIAL) {
                setState(STATE.INITIAL);
            }
            return;
        }
        setState(STATE.LOADING);
        return validateSAMLSettings({ organizationId })
            .then((idpUrl) => {
                if (idpUrl) {
                    window.open(idpUrl, '_self');

                    return;
                }
                return processValidationResult();
            })
            .catch(() => {
                setState(STATE.INITIAL);
                enqueueSnackbar(t('business.sso.saml.commonError'), { variant: 'error' });
            });
    };

    const onCloseModal = () => {
        setState(STATE.INITIAL);
        history.push(pathname);
    };

    if (!samlSettings) {
        return null;
    }

    return (
        <>
            {state === STATE.LOADING && <Preloader />}
            <div className={styles.root}>
                <SettingViewField
                    text={t(`business.sso.saml.idpProtocol`)}
                    value={IdpProtocols.SAML}
                />
                <SettingViewField
                    text={t(`business.sso.saml.idpProvider`)}
                    value={IDP_OPTIONS.find((item) => item.id === samlSettings.idpProvider)?.label}
                />
                {SETTINGS_LIST.map((settingKey) => (
                    <SettingViewField
                        key={settingKey}
                        text={t(`business.sso.saml.${settingKey}`)}
                        value={samlSettings[settingKey]}
                    />
                ))}
                <div className={styles.buttons}>
                    <Button
                        color={'primary'}
                        onClick={checkSettingsHandler}
                        className={styles.button}
                        {...createTestIdProps(BASE_TEST_ID, 'checkSSO')}
                    >
                        <Trans i18nKey={'business.sso.saml.checkSSO'} />
                    </Button>
                    <Button
                        color={'primary'}
                        variant="contained"
                        className={styles.button}
                        onClick={() => setStep?.(SSOStep.IDP)}
                        {...createTestIdProps(BASE_TEST_ID, 'edit')}
                    >
                        <Trans i18nKey={'business.sso.saml.edit'} />
                    </Button>
                </div>
            </div>
            <ValidationSuccessfulModal opened={state === STATE.SUCCESSFUL} onClose={onCloseModal} />
            {error && (
                <ValidationFailedModal
                    opened={state === STATE.FAILED}
                    onClose={onCloseModal}
                    error={error}
                />
            )}
        </>
    );
};
