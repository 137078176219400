import img1_en from './assets/img1_en@2x.png';
import img2_en from './assets/img2_en@2x.png';
import img3_en from './assets/img3_en@2x.png';
import img4_en from './assets/img4_en@2x.png';
import img5_en from './assets/img5_en@2x.png';
import img6_en from './assets/img6_en@2x.png';
import img7_en from './assets/img7_en@2x.png';
import img8_en from './assets/img8_en@2x.png';
import img1_ru from './assets/img1_ru@2x.png';
import img2_ru from './assets/img2_ru@2x.png';
import img3_ru from './assets/img3_ru@2x.png';
import img4_ru from './assets/img4_ru@2x.png';
import img5_ru from './assets/img5_ru@2x.png';
import img6_ru from './assets/img6_ru@2x.png';
import img7_ru from './assets/img7_ru@2x.png';
import img8_ru from './assets/img8_ru@2x.png';
var trialImages = {
  img1_en: img1_en,
  img2_en: img2_en,
  img3_en: img3_en,
  img4_en: img4_en,
  img5_en: img5_en,
  img6_en: img6_en,
  img7_en: img7_en,
  img8_en: img8_en,
  img1_ru: img1_ru,
  img2_ru: img2_ru,
  img3_ru: img3_ru,
  img4_ru: img4_ru,
  img5_ru: img5_ru,
  img6_ru: img6_ru,
  img7_ru: img7_ru,
  img8_ru: img8_ru
};
export default trialImages;