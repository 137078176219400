import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DevicesItem } from '@wbnr/frontend-shared/lib/api/business-api/types';
import { useForm, useField, FormRef } from '@wbnr/frontend-shared/lib/forms';
import { validateLength, validateSipLink } from '@wbnr/frontend-shared/lib/utils/validators';
import { ComponentTestIdProps, createTestIdProps, dataTestIdKey, TextField } from '@wbnr/ui';

export type MyDeviceFormValues = Omit<DevicesItem, 'id'>;

export interface MyDevicesProps extends ComponentTestIdProps {
    defaultValues?: MyDeviceFormValues;
    formRef: FormRef;
    onSubmit: (values: MyDeviceFormValues) => Promise<void>;
}

const MAX_LENGTH = 255;

const INITIAL_VALUES: MyDeviceFormValues = {
    name: '',
    uri: '',
};

const I18N_PATH = 'business.myDevicesPage.myDevicesList';

export const MyDevicesForm: FC<MyDevicesProps> = ({
    defaultValues = INITIAL_VALUES,
    formRef,
    onSubmit,
    [dataTestIdKey]: dataTestId,
}) => {
    const { t } = useTranslation();

    const { control } = useForm({ defaultValues }, { onSubmit }, formRef);

    const fieldName = useField(control, 'name', {
        rules: { required: true, validate: validateMaxLength },
    });

    const fieldUri = useField(control, 'uri', {
        rules: {
            required: true,
            validate: {
                sipLink: validateSipLink,
                length: validateMaxLength,
            },
        },
    });

    return (
        <>
            <TextField
                fullWidth
                label={t(`${I18N_PATH}.name`)}
                {...fieldName}
                {...createTestIdProps(dataTestId, 'input', 'name')}
            />
            <TextField
                fullWidth
                label={t(`${I18N_PATH}.address`)}
                {...fieldUri}
                {...createTestIdProps(dataTestId, 'input', 'uri')}
            />
        </>
    );
};

const validateMaxLength = (value: string) => {
    return validateLength(MAX_LENGTH)(value) && `${I18N_PATH}.maxLength`;
};
