import { useTranslation } from 'react-i18next';

import UploadImage from '@wbnr/frontend-shared/lib/components/UploadImage';
import { useField, useFormContext } from '@wbnr/frontend-shared/lib/forms';

import { BrandingFormValues } from '../../types';
import { getBrandingPropName } from '../../utils';
import BrandingHeading from '../BrandingHeading';

import styles from './BrandingPauseBackground.module.scss';

const i18nPath = 'business.brandingPage';

const BrandingPauseBackground = () => {
    const { t } = useTranslation();

    const { control } = useFormContext();

    const { value: pauseImageValue, onChange: onPauseImageChange } = useField(
        control,
        getBrandingPropName('eventPauseImage'),
    );

    const pauseImage: BrandingFormValues['branding']['eventPauseImage'] = pauseImageValue;

    return (
        <>
            <BrandingHeading>{t(`${i18nPath}.pauseBackgroundImage`)}</BrandingHeading>

            <UploadImage
                image={pauseImage instanceof File ? pauseImage : pauseImage?.url}
                imageClassName={styles.image}
                description={t(`${i18nPath}.pauseBackgroundImageDescription`)}
                onUpload={onPauseImageChange}
                onDelete={() => onPauseImageChange(null)}
                dark
            />
        </>
    );
};

export default BrandingPauseBackground;
