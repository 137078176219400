import cn from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SortDescription } from '@wbnr/frontend-shared/lib/utils/sort';
import { ListItem, ListItemText, SortLabel } from '@wbnr/ui';

import styles from './MyDeviceTable.module.scss';

interface MyDeviceTableHeaderProps {
    sort: SortDescription;
    setSort: (sort: SortDescription) => void;
}

export const MyDeviceTableHeader: FC<MyDeviceTableHeaderProps> = ({ sort, setSort }) => {
    const { t } = useTranslation();

    return (
        <ListItem
            classes={{
                root: styles.row,
            }}
        >
            <ListItemText className={styles.cell}>
                <SortLabel name="name" sort={sort} setSort={setSort}>
                    {t('business.myDevicesPage.myDevicesList.name')}
                </SortLabel>
            </ListItemText>
            <ListItemText className={styles.cell}>
                <SortLabel name="uri" sort={sort} setSort={setSort}>
                    {t('business.myDevicesPage.myDevicesList.address')}
                </SortLabel>
            </ListItemText>
            <ListItemText className={cn(styles.cell, styles.actionCell)} />
        </ListItem>
    );
};
