"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTimeStringFromSeconds = exports.getTimeStringFromDate = exports.getTimeComponents = exports.getSecondsFromTimeString = exports.getDateFromTimeString = exports.formatPad = exports.checkValidTime = exports.changeDateTime = void 0;
var _createDate = require("../utils/createDate");
var _constants = require("./constants");
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var checkTime = function checkTime(time, comparator) {
  return Number(time.replace(/_/g, '0')) > comparator;
};

/**
 * валидация введенного времени в зависимости от типа времени
 * часы 0-23, минуты 0-59, секунды 0-59
 */
var checkValidTime = function checkValidTime(value, timeMask) {
  if (timeMask === _constants.TIME_MASK.HMS) {
    var _value$split = value.split(':'),
      _value$split2 = _slicedToArray(_value$split, 3),
      hours = _value$split2[0],
      minutes = _value$split2[1],
      seconds = _value$split2[2];
    if (checkTime(hours, 23)) {
      return false;
    }
    if (checkTime(minutes, 59)) {
      return false;
    }
    return !checkTime(seconds, 59);
  }
  if (timeMask === _constants.TIME_MASK.HM) {
    var _value$split3 = value.split(':'),
      _value$split4 = _slicedToArray(_value$split3, 2),
      _hours = _value$split4[0],
      _minutes = _value$split4[1];
    if (checkTime(_hours, 23)) {
      return false;
    }
    return !checkTime(_minutes, 59);
  }
  if (timeMask === _constants.TIME_MASK.MS) {
    var _value$split5 = value.split(':'),
      _value$split6 = _slicedToArray(_value$split5, 2),
      _minutes2 = _value$split6[0],
      _seconds = _value$split6[1];
    if (checkTime(_minutes2, 59)) {
      return false;
    }
    return !checkTime(_seconds, 59);
  }
};
exports.checkValidTime = checkValidTime;
var changeDateTime = function changeDateTime(newValue, timeMask) {
  if (!newValue) {
    return {
      value: newValue,
      valid: true
    };
  }
  var validTime = checkValidTime(newValue, timeMask);
  return {
    value: newValue,
    valid: validTime
  };
};
exports.changeDateTime = changeDateTime;
var formatPad = function formatPad(num) {
  return "00".concat(num).slice(-2);
};
exports.formatPad = formatPad;
var getTimeComponents = function getTimeComponents(timeString) {
  return timeString.replace(/\s/g, '').split(':').map(function (s) {
    return Number(s);
  });
};
exports.getTimeComponents = getTimeComponents;
var getTimeStringFromSeconds = function getTimeStringFromSeconds(timeInSeconds, timeMask) {
  var hours = Math.floor(timeInSeconds % (60 * 60 * 60) / (60 * 60));
  var minutes = Math.floor(timeInSeconds % (60 * 60) / 60);
  var seconds = Math.floor(timeInSeconds % 60);
  if (timeMask === _constants.TIME_MASK.HM) {
    return "".concat(formatPad(hours), ":").concat(formatPad(minutes));
  }
  if (timeMask === _constants.TIME_MASK.HMS) {
    return "".concat(formatPad(hours), ":").concat(formatPad(minutes), ":").concat(formatPad(seconds));
  }
  return "".concat(formatPad(minutes), ":").concat(formatPad(seconds));
};
exports.getTimeStringFromSeconds = getTimeStringFromSeconds;
var getSecondsFromTimeString = function getSecondsFromTimeString(timeString, timeMask) {
  var hours = 0;
  var minutes = 0;
  var seconds = 0;
  if (timeMask === _constants.TIME_MASK.HMS) {
    var _getTimeComponents = getTimeComponents(timeString);
    var _getTimeComponents2 = _slicedToArray(_getTimeComponents, 3);
    hours = _getTimeComponents2[0];
    minutes = _getTimeComponents2[1];
    seconds = _getTimeComponents2[2];
  }
  if (timeMask === _constants.TIME_MASK.HM) {
    var _getTimeComponents3 = getTimeComponents(timeString);
    var _getTimeComponents4 = _slicedToArray(_getTimeComponents3, 2);
    hours = _getTimeComponents4[0];
    minutes = _getTimeComponents4[1];
  }
  if (timeMask === _constants.TIME_MASK.MS) {
    var _getTimeComponents5 = getTimeComponents(timeString);
    var _getTimeComponents6 = _slicedToArray(_getTimeComponents5, 2);
    minutes = _getTimeComponents6[0];
    seconds = _getTimeComponents6[1];
  }
  return hours * 60 * 60 + minutes * 60 + seconds;
};
exports.getSecondsFromTimeString = getSecondsFromTimeString;
var getDateFromTimeString = function getDateFromTimeString(date, timeString, timeMask) {
  var newDate = (0, _createDate.createDate)(date);
  var hour = 0;
  var minute = 0;
  var second = 0;
  if (timeMask === _constants.TIME_MASK.HMS) {
    var _getTimeComponents7 = getTimeComponents(timeString);
    var _getTimeComponents8 = _slicedToArray(_getTimeComponents7, 3);
    hour = _getTimeComponents8[0];
    minute = _getTimeComponents8[1];
    second = _getTimeComponents8[2];
  }
  if (timeMask === _constants.TIME_MASK.HM) {
    var _getTimeComponents9 = getTimeComponents(timeString);
    var _getTimeComponents10 = _slicedToArray(_getTimeComponents9, 2);
    hour = _getTimeComponents10[0];
    minute = _getTimeComponents10[1];
  }
  if (timeMask === _constants.TIME_MASK.MS) {
    var _getTimeComponents11 = getTimeComponents(timeString);
    var _getTimeComponents12 = _slicedToArray(_getTimeComponents11, 2);
    minute = _getTimeComponents12[0];
    second = _getTimeComponents12[1];
  }
  newDate.setHours(hour, minute, second);
  return newDate;
};
exports.getDateFromTimeString = getDateFromTimeString;
var getTimeStringFromDate = function getTimeStringFromDate(date, timeMask) {
  var initDate = date || new Date();
  var resultStr = '';
  var hour = initDate.getHours() || 0;
  var minute = initDate.getMinutes() || 0;
  var second = initDate.getSeconds() || 0;
  var hourStr = hour.toString().padStart(2, '0');
  var minuteStr = minute.toString().padStart(2, '0');
  var secondStr = second.toString().padStart(2, '0');
  if (timeMask === _constants.TIME_MASK.HM || timeMask === _constants.TIME_MASK.HMS) {
    resultStr += "".concat(hourStr, ":");
  }
  resultStr += minuteStr;
  if (timeMask === _constants.TIME_MASK.MS || timeMask === _constants.TIME_MASK.HMS) {
    resultStr += ":".concat(secondStr);
  }
  return resultStr;
};
exports.getTimeStringFromDate = getTimeStringFromDate;