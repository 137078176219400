import { validateLink } from './validators';
export var prependProtocolToLink = function prependProtocolToLink(link) {
  var isValid = !validateLink(link);
  if (isValid) {
    return link;
  } else {
    return "http://".concat(link);
  }
};
export function openUrl(url) {
  var newWindow = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  if (newWindow) {
    var link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    window.location.href = url;
  }
}