import { useSnackbar } from 'notistack';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useMixpanelTracking } from '@wbnr/frontend-shared/lib/analytics/mixpanel/useMixpanelTracking';
import { useAccountUser } from '@wbnr/frontend-shared/lib/data/user';
import { useForm, useField, useSpecificLengthValidator } from '@wbnr/frontend-shared/lib/forms';
import { injectTranslations } from '@wbnr/frontend-shared/lib/utils/i18n';
import {
    composeValidators,
    validateEmail,
    validateRequired,
    validateLength,
    onlyDigitsValidator,
} from '@wbnr/frontend-shared/lib/utils/validators';
import { TextField, createTestIdProps, Typography, ActionDialog } from '@wbnr/ui';

import { sendInvoiceData } from '../api/sendInvoiceInfo';

import styles from './InvoiceForm.module.scss';
import { SubmitResult } from './SubmitResult/SubmitResult';
import translations from './translations';
import { FormProps } from './useInvoiceFormDialog';

export type Props = FormProps & {
    opened: boolean;
    onClose: () => void;
};

const INVOICE_FORM_NS = 'invoiceForm';
injectTranslations(INVOICE_FORM_NS, translations);

const DIGITS_PATTERN = /[0-9]/;
const INN_MIN_LENGTH = 10;
const INN_MAX_LENGTH = 12;
const KPP_LENGTH = 9;
const COMPANY_NAME_MAX_LENGTH = 250;
const ADDRESS_MAX_LENGTH = 250;
const CEO_FULL_NAME_MAX_LENGTH = 50;
const EMAIL_MAX_LENGTH = 64;
const addressLengthValidator = validateLength(ADDRESS_MAX_LENGTH);
const companyNameLengthValidator = validateLength(COMPANY_NAME_MAX_LENGTH);
const ceoFullNameLengthValidator = validateLength(CEO_FULL_NAME_MAX_LENGTH);
const BASE_TEST_ID = 'buyTariffByInvoice';

export const InvoiceForm = ({
    price,
    tariffName,
    productIds,
    duration,
    email,
    opened,
    onClose,
}: Props) => {
    const { t } = useTranslation(INVOICE_FORM_NS);
    const [user] = useAccountUser();
    const { track } = useMixpanelTracking();
    const { enqueueSnackbar } = useSnackbar();
    const [isSuccess, setRequestStatus] = useState<boolean>(false);

    const apiError = () => {
        enqueueSnackbar(t(`submitError`), { variant: 'error' });
    };

    const { control, submit, getValues } = useForm(
        {
            defaultValues: {
                inn: '',
                kpp: '',
                companyName: '',
                address: '',
                ceoFullName: '',
                email: email || '',
            },
        },
        {
            onSubmit: async (data) => {
                try {
                    await sendInvoiceData(data, {
                        productIds,
                        type: 'invoice',
                        duration: duration || 3,
                        userId: user.id,
                    });

                    setRequestStatus((status) => !status);
                } catch (error) {
                    apiError();
                }
            },
        },
    );

    const currentEmail = getValues()?.email || email;

    const validateInn = useCustomInnValidator();
    const validateKpp = useSpecificLengthValidator(KPP_LENGTH, 'validation.specificValue');

    const innField = useField(control, 'inn', {
        rules: {
            validate: composeValidators([onlyDigitsValidator, validateRequired, validateInn]),
        },
    });

    const kppField = useField(control, 'kpp', {
        rules: {
            validate: composeValidators([onlyDigitsValidator, validateKpp]),
        },
    });

    const companyNameField = useField(control, 'companyName', {
        rules: {
            validate: composeValidators([validateRequired, companyNameLengthValidator]),
        },
    });

    const addressField = useField(control, 'address', {
        rules: {
            validate: composeValidators([validateRequired, addressLengthValidator]),
        },
    });

    const ceoFullNameField = useField(control, 'ceoFullName', {
        rules: {
            validate: composeValidators([validateRequired, ceoFullNameLengthValidator]),
        },
    });

    const emailField = useField(control, 'email', {
        rules: {
            validate: composeValidators([validateEmail, validateRequired]),
        },
    });

    const keyDownPatternValidator = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const { key } = event;
        const isValidInput = DIGITS_PATTERN.test(key) || key === 'Backspace';
        if (!isValidInput) {
            event.preventDefault();
        }
    };

    useEffect(() => {
        track('tariff_buy_invoice_form_shown', { tariff_name: tariffName, period: duration });
    }, [track, tariffName, duration]);

    const dialogSubmit = isSuccess ? undefined : submit;
    const dialogCancelText = t(`${isSuccess ? 'close' : 'cancel'}`);
    const dialogSubmitText = isSuccess ? undefined : t(`getInvoice`);

    return (
        <ActionDialog
            containedSubmit
            opened={Boolean(opened)}
            submitText={dialogSubmitText}
            cancelText={dialogCancelText}
            onSubmit={dialogSubmit}
            onClose={onClose}
        >
            {isSuccess ? (
                <SubmitResult
                    title={t('sent')}
                    description={t('invoiceToEmail', { currentEmail })}
                />
            ) : (
                <div className={styles.mainContainer} {...createTestIdProps(BASE_TEST_ID, 'form')}>
                    <Typography variant="h6">{t('title')}</Typography>
                    <div>
                        <Typography variant="body1">
                            {t('description.tariffName', {
                                tariffName,
                                duration,
                            })}
                        </Typography>
                        <Typography variant="body1">
                            {t('description.price', {
                                price,
                            })}
                        </Typography>
                        <Typography variant="body1">{t('description.inputDetails')}</Typography>
                    </div>
                    <div className={styles.fieldsContainer}>
                        <TextField
                            {...innField}
                            onKeyDown={keyDownPatternValidator}
                            label={t('fields.inn')}
                            inputProps={{ maxLength: INN_MAX_LENGTH }}
                            {...createTestIdProps(BASE_TEST_ID, 'form', 'inn')}
                        />
                        <TextField
                            {...kppField}
                            onKeyDown={keyDownPatternValidator}
                            inputProps={{ maxLength: KPP_LENGTH }}
                            label={t('fields.kpp')}
                            {...createTestIdProps(BASE_TEST_ID, 'form', 'kpp')}
                        />
                        <TextField
                            {...companyNameField}
                            inputProps={{ maxLength: COMPANY_NAME_MAX_LENGTH }}
                            label={t('fields.companyName')}
                            {...createTestIdProps(BASE_TEST_ID, 'form', 'companyName')}
                            fullWidth
                        />
                        <TextField
                            {...addressField}
                            inputProps={{ maxLength: ADDRESS_MAX_LENGTH }}
                            label={t('fields.address')}
                            {...createTestIdProps(BASE_TEST_ID, 'form', 'address')}
                            fullWidth
                        />
                        <TextField
                            {...ceoFullNameField}
                            inputProps={{ maxLength: CEO_FULL_NAME_MAX_LENGTH }}
                            label={t('fields.ceoFullName')}
                            {...createTestIdProps(BASE_TEST_ID, 'form', 'ceoFullName')}
                            fullWidth
                        />
                        <TextField
                            {...emailField}
                            inputProps={{ maxLength: EMAIL_MAX_LENGTH }}
                            helperText={
                                emailField.helperText
                                    ? emailField.helperText
                                    : t('fields.emailHelperText')
                            }
                            label={t('fields.email')}
                            {...createTestIdProps(BASE_TEST_ID, 'form', 'email')}
                            fullWidth
                        />
                    </div>
                </div>
            )}
        </ActionDialog>
    );
};

const useCustomInnValidator = () => {
    const { t } = useTranslation();
    const message = t('validation.specificEnumValues', {
        firstCount: INN_MIN_LENGTH,
        secondCount: INN_MAX_LENGTH,
    });

    return (value?: string) => {
        if (
            value?.length !== INN_MIN_LENGTH &&
            value?.length !== INN_MAX_LENGTH &&
            Number(value?.length) > 0
        ) {
            return message;
        }
    };
};
