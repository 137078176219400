import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { getVerificationMethod } from '@wbnr/frontend-shared/lib/api/business-api';
import { Domain } from '@wbnr/frontend-shared/lib/api/business-api/types';
import CopyToClipboard from '@wbnr/frontend-shared/lib/components/CopyToClipboard';
import { useResource } from '@wbnr/frontend-shared/lib/data/useResource';
import { useBinded } from '@wbnr/frontend-shared/lib/hooks/useBinded';
import { FileCopyIcon } from '@wbnr/icons';
import {
    A,
    ActionDialog,
    createTestIdProps,
    DialogContentText,
    IconButton,
    Tooltip,
} from '@wbnr/ui';

import { useSSO } from '../../SSOContext';

import styles from './DomainAppoveModal.module.scss';

type Props = {
    domain: Domain;
    onClose: () => void;
};

const HELP_LINK = 'https://help.webinar.ru/ru/articles/6285596-подтверждение-домена-для-sso';

const BASE_TEST_ID = 'DomainApproveModal';

export const DomainApproveModal = ({ domain, onClose }: Props) => {
    const { t } = useTranslation();
    const { approveDomain } = useSSO();
    const { data: domainVerification } = useResource({
        request: useBinded(getVerificationMethod, {
            domainId: domain.id,
        }),
    });
    const [result, setResult] = useState<'success' | 'fail' | null>(null);
    const handleApproveDomain = async () => {
        if (!domainVerification || !approveDomain || result === 'success') {
            return;
        }
        try {
            await approveDomain(domain);
            setResult('success');
        } catch (error) {
            setResult('fail');
        }
    };
    return (
        <ActionDialog
            maxWidth="lg"
            title={t('business.sso.domain.approveModal.title')}
            opened={true}
            onSubmit={handleApproveDomain}
            onClose={onClose}
            submitText={result === 'success' ? null : t('business.sso.domain.approveModal.confirm')}
            containedSubmit
            cancelText={result === 'success' ? t('business.sso.domain.approveModal.ok') : undefined}
            {...createTestIdProps(BASE_TEST_ID, 'approveDialog')}
        >
            <DialogContentText>
                <div className={styles.text}>
                    <Trans i18nKey={'business.sso.domain.approveModal.text'} />
                </div>
                <div className={styles.help}>
                    <A color="primary" href={HELP_LINK} rel="noopener noreferrer" target="_blank">
                        <Trans i18nKey={'business.sso.help'} />
                    </A>
                </div>
                {domainVerification && (
                    <div className={styles.dnsTxtRecord}>
                        {domainVerification.dnsTxtRecord}
                        <CopyToClipboard text={domainVerification.dnsTxtRecord}>
                            <Tooltip title={t('business.sso.domain.approveModal.copyLink') || ''}>
                                <IconButton>
                                    <FileCopyIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </CopyToClipboard>
                    </div>
                )}
                {result && (
                    <div className={result === 'success' ? styles.success : styles.fail}>
                        <Trans i18nKey={`business.sso.domain.approveModal.${result}`} />
                    </div>
                )}
            </DialogContentText>
        </ActionDialog>
    );
};
