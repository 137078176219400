import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { APIError } from '@wbnr/frontend-shared/lib/api';
import { useFormDialog } from '@wbnr/frontend-shared/lib/dialogs';
import { makeFormErrors } from '@wbnr/frontend-shared/lib/forms';
import { createTestIdProps } from '@wbnr/ui';

import { ErrorCodes, ERROR_CODES } from '../interfaces';
import { MyDevicesForm, MyDevicesProps, MyDeviceFormValues } from '../MyDevicesList/MyDevicesForm';

import { useDevices } from './useDevices';

const BASE_TEST_ID = 'ModalMyDeviceAdd';

type ShowModalMyDeviceAdd = () => void;

export const useModalMyDeviceAdd = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { createDevice } = useDevices();

    const [showModalMyDeviceAdd, closeModalMyDeviceAdd] = useFormDialog({
        formComponent: MyDevicesForm,
        title: t('business.myDevicesPage.myDevicesList.add_device'),
        containedSubmit: 'contained',
        submitText: t('business.myDevicesPage.myDevicesList.add'),
        cancelText: t('cancel'),
        maxWidth: 'md',
        onSubmit: async (values: MyDeviceFormValues) => {
            try {
                await createDevice(values);
                enqueueSnackbar(t('business.myDevicesPage.myDevicesList.add_device_success'));
                closeModalMyDeviceAdd();
            } catch (error) {
                let errorMessage = '';

                if (error instanceof APIError && ERROR_CODES[error.body.error.code as ErrorCodes]) {
                    errorMessage = ERROR_CODES[error.body.error.code as ErrorCodes];
                }

                if (errorMessage) {
                    throw makeFormErrors({
                        uri: t(errorMessage),
                    });
                }

                enqueueSnackbar(t('unknownError'), { variant: 'error' });
                throw error;
            }
        },
        formProps: {
            ...createTestIdProps(BASE_TEST_ID),
        } as Pick<MyDevicesProps, 'defaultValues' | 'data-testid'>,
    });

    return {
        showModalMyDeviceAdd: showModalMyDeviceAdd as ShowModalMyDeviceAdd,
    };
};
