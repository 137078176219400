import { useTranslation, Trans } from 'react-i18next';

import { useFormContext, useFieldArray, useField } from '@wbnr/frontend-shared/lib/forms';
import { AdditionalAgreement } from '@wbnr/frontend-shared/lib/types/Branding';
import { AddIcon } from '@wbnr/icons';
import { Button, Typography } from '@wbnr/ui';

import { BrandingFormValues } from '../../types';
import { getBrandingPropName } from '../../utils';
import BrandingHeading from '../BrandingHeading';
import BrandingSwitchField from '../BrandingSwitchField';

import AdditionalAgreementField from './AdditionalAgreementField';
import styles from './AdditionalAgreements.module.scss';

interface AdditionalAgreementsParams {
    customizableAgreements: boolean;
}

const MAX_AGREEMENTS = 15;
const i18nPath = 'business.brandingPage';

const AdditionalAgreements = ({ customizableAgreements }: AdditionalAgreementsParams) => {
    const { t } = useTranslation();

    const { control } = useFormContext<BrandingFormValues>();
    const { fields, append, remove } = useFieldArray<AdditionalAgreement, 'key', typeof control>({
        control,
        name: getBrandingPropName('additionalAgreements'),
        keyName: 'key',
    });

    const isAgreementsDefaultCheckedField = useField(
        control,
        getBrandingPropName('isAgreementsDefaultChecked'),
    );

    return (
        <>
            <BrandingHeading small>
                <Trans i18nKey="business.brandingPage.additionalAgreements" />
            </BrandingHeading>

            <Typography color="textSecondary" noWrap>
                <Trans i18nKey="business.brandingPage.additionalAgreementsDescription" />
            </Typography>

            <div className={styles.agreements}>
                {fields.map((field, index) => (
                    <AdditionalAgreementField
                        key={field.key}
                        index={index}
                        id={field.id}
                        description={field.description}
                        isRequired={field.isRequired}
                        exportName={field.exportName}
                        customizableAgreements={customizableAgreements}
                        onDelete={() => remove(index)}
                    />
                ))}
            </div>

            {fields.length < MAX_AGREEMENTS && (
                <Button
                    color="primary"
                    startIcon={<AddIcon color="inherit" />}
                    onClick={() =>
                        append({
                            description: '',
                            isRequired: false,
                            exportName: getRandomString(),
                        })
                    }
                >
                    <Trans i18nKey={`${i18nPath}.addAdditionalAgreement`} />
                </Button>
            )}

            {customizableAgreements && (
                <BrandingSwitchField
                    label={t(`${i18nPath}.agreementsDefaultChecked`)}
                    helperText={t(`${i18nPath}.agreementsDefaultCheckedDescription`)}
                    {...isAgreementsDefaultCheckedField}
                />
            )}
        </>
    );
};

export default AdditionalAgreements;

function getRandomString() {
    return (
        Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    );
}
