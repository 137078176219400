import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { getQualification } from '@wbnr/frontend-shared/lib/api/qualification';
import { getUserActivity } from '@wbnr/frontend-shared/lib/api/userActivity';
import { isParticipantFlow, needRedirectToMeetings } from '@wbnr/frontend-shared/lib/data/user';
import { Qualification } from '@wbnr/frontend-shared/lib/types/Qualification';
import { AccountUser } from '@wbnr/frontend-shared/lib/types/User';
import { isMobile } from '@wbnr/frontend-shared/lib/utils/browser';
import { appLocalStorage } from '@wbnr/frontend-shared/lib/utils/browserStorage';

export const INITIAL_ONBOARDING_ACTIVITY = 'initialOnboardingStarted';
export const PARTICIPANT_FLOW_ONBOARDING_ACTIVITY = 'participantFlowOnboardingStarted';

export const useQualification = () => {
    const history = useHistory();

    const checkQualification = useCallback(async (user: AccountUser): Promise<boolean> => {
        if (isParticipantFlow(user)) {
            return Promise.resolve(true);
        }

        return checkProductQualification('qualified' + user.id, getQualification);
    }, []);

    const checkNeedShowInitialOnboarding = useCallback(
        async (user: AccountUser): Promise<boolean> => {
            const activity = await getUserActivity({
                token: isParticipantFlow(user)
                    ? PARTICIPANT_FLOW_ONBOARDING_ACTIVITY
                    : INITIAL_ONBOARDING_ACTIVITY,
            });

            return !activity;
        },
        [],
    );

    const goToOnboarding = useCallback((user: AccountUser, replaceUrl?: boolean) => {
        appLocalStorage.setItem('isNewUser', 'true');
        if (isParticipantFlow(user)) {
            redirect('/meetings/onboarding', replaceUrl);
        } else {
            redirect('/onboarding', replaceUrl);
        }
    }, []);

    const goToQualification = useCallback((user: AccountUser, replaceUrl?: boolean) => {
        redirect('/qualification/webinar', replaceUrl);
    }, []);

    const goToOnboardingOrQualification = useCallback(
        async (user: AccountUser, replaceUrl?: boolean) => {
            const needShowOnboarding = await checkNeedShowInitialOnboarding(user);

            if (needShowOnboarding) {
                goToOnboarding(user, replaceUrl);
            } else {
                appLocalStorage.setItem('isNewUser', 'false');

                const isQualified = await checkQualification(user);

                if (isQualified) {
                    const nextUrl = needRedirectToMeetings(user) ? '/meetings' : '/webinars';
                    if (replaceUrl) {
                        history.replace(nextUrl);
                    } else {
                        history.push(nextUrl);
                    }
                } else {
                    goToQualification(user, replaceUrl);
                }
            }
        },
        [
            checkQualification,
            checkNeedShowInitialOnboarding,
            goToOnboarding,
            goToQualification,
            history,
        ],
    );

    return useMemo(
        () => ({
            checkQualification,
            checkNeedShowInitialOnboarding,
            goToOnboarding,
            goToQualification,
            goToOnboardingOrQualification,
        }),
        [
            checkQualification,
            checkNeedShowInitialOnboarding,
            goToOnboarding,
            goToQualification,
            goToOnboardingOrQualification,
        ],
    );
};

async function checkProductQualification(
    key: string,
    loadQualification: () => Promise<Qualification | null | undefined>,
): Promise<boolean> {
    return appLocalStorage.getItem(key)
        ? Promise.resolve(true)
        : loadQualification()
              .catch(() => {})
              .then((qualification) => {
                  const isQualified = Boolean(qualification && qualification.target);

                  if (isQualified) {
                      appLocalStorage.setItem(key, 'true');
                  }

                  return isQualified || isMobile;
              });
}

function redirect(url: string, replaceUrl?: boolean) {
    if (replaceUrl) {
        window.location.replace(url);
    } else {
        window.location.href = url;
    }
}
