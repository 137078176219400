import classnames from 'classnames';
import { SnackbarProvider as NotistackProvider } from 'notistack';
import { FC } from 'react';

import styles from './SnackbarProvider.module.scss';

const SnackbarProvider: FC = ({ children }) => {
    return (
        <NotistackProvider
            hideIconVariant
            classes={{
                containerRoot: classnames(styles.root, styles.specificity),
                variantError: styles.variantError,
            }}
        >
            {children}
        </NotistackProvider>
    );
};

export default SnackbarProvider;
