function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import Cookies from 'js-cookie';
import { wait } from '../wait';
import { checkHost } from './checkWebrtcHost';
import { WEBRTC_TEST_HOSTS } from '../../config';
import { WEBRTC_TEST_RESULT_KEY, WEBRTC_TEST_RESULT_GOOD_LIFETIME, WAIT_TIME_BEFORE_NEXT_SERVER_MS, IMMEDIATE_RESULT_ERRORS } from './constants';
var webrtcTestHosts = WEBRTC_TEST_HOSTS;
export var clearTestResult = function clearTestResult() {
  Cookies.remove(WEBRTC_TEST_RESULT_KEY, true);
};
export var checkWebrtc = function checkWebrtc() {
  var prevResult = Cookies.get(WEBRTC_TEST_RESULT_KEY, true);
  if (prevResult === 'ok') {
    return Promise.resolve();
  }
  return checkRemoteWebRTCServers.apply(void 0, arguments).then(function () {
    Cookies.set(WEBRTC_TEST_RESULT_KEY, 'ok', {
      expires: new Date(Date.now() + WEBRTC_TEST_RESULT_GOOD_LIFETIME)
    });
  });
};
function checkRemoteWebRTCServers() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref$slaveServers = _ref.slaveServers,
    externalSlaveServers = _ref$slaveServers === void 0 ? [] : _ref$slaveServers,
    allowYaBrowser = _ref.allowYaBrowser;
  var slaveServers = _toConsumableArray(externalSlaveServers);
  var masterServers = _toConsumableArray(webrtcTestHosts);
  var startServer = pickAnyAndRemoveFromArray(slaveServers) || pickAnyAndRemoveFromArray(masterServers);
  var completed = false;
  var callIfNotCompleted = function callIfNotCompleted(func) {
    return function () {
      return !completed ? func.apply(void 0, arguments) : undefined;
    };
  };
  var promises = [];
  return new Promise(function (resolve, reject) {
    var checkFunc = callIfNotCompleted(function (serverToTest) {
      console.log('Webrtc server check start', serverToTest);
      var promise = checkHost(serverToTest, {
        allowYaBrowser: allowYaBrowser
      }).then(callIfNotCompleted(function () {
        console.log('Webrtc server check complete', serverToTest, 'ok');
        resolve();
        completed = true;
      })).catch(callIfNotCompleted(function (error) {
        console.log('Webrtc server check complete', serverToTest, error);
        if (IMMEDIATE_RESULT_ERRORS.includes(error)) {
          reject(error);
          completed = true;
        }
        return error;
      }));
      promises.push(promise);
      var nextServerToCheck = pickAnyAndRemoveFromArray(masterServers);
      if (nextServerToCheck) {
        wait(WAIT_TIME_BEFORE_NEXT_SERVER_MS).then(function () {
          checkFunc(nextServerToCheck);
        });
      } else {
        Promise.all(promises).then(callIfNotCompleted(function (errors) {
          reject(pickAnyAndRemoveFromArray(errors));
        }));
      }
    });
    checkFunc(startServer);
  });
}
function pickAnyAndRemoveFromArray() {
  var arr = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var index = Math.floor(Math.random() * arr.length);
  var elem = arr[index];
  if (arr.length > 0) {
    arr.splice(index, 1);
  }
  return elem;
}