import classnames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import UserAvatar from '@wbnr/frontend-shared/lib/components/UserAvatar';
import { useAccountUser } from '@wbnr/frontend-shared/lib/data/user';
import { BrandingFull } from '@wbnr/frontend-shared/lib/types/Branding';
import { createDate } from '@wbnr/frontend-shared/lib/utils/dates';
import { formatDate } from '@wbnr/frontend-shared/lib/utils/i18n/formatDate';
import { getUserName } from '@wbnr/frontend-shared/lib/utils/user';
import { Card, Chip, Typography, Tooltip } from '@wbnr/ui';

import logoBlack from '../../assets/logo-black.png';
import { MultibrandingMethods } from '../../types';
import BrandingListCardMenu from '../BrandingListCardMenu';

import styles from './BrandingListCard.module.scss';

interface Props extends Omit<MultibrandingMethods, 'onCreateBranding'> {
    id: number;
    title: string;
    logo: string | undefined;
    isDefault: boolean;
    createAt: BrandingFull['createAt'];
    updateAt: BrandingFull['updateAt'];
    user: BrandingFull['updateUser'];
    isMultibranding: boolean;
}

const i18nPath = 'business.brandingPage';

const BrandingListCard = ({
    id,
    title,
    logo,
    isDefault,
    createAt,
    updateAt,
    user,
    isMultibranding,
    ...methods
}: Props) => {
    const { t } = useTranslation();

    const [{ locale }] = useAccountUser();

    const dateString: string = useMemo(() => {
        const date: Date = updateAt ? createDate(updateAt) : createDate(createAt);
        const text = updateAt ? t(`${i18nPath}.updated`) : t(`${i18nPath}.created`);

        return text + ': ' + formatDate(date, 'd MMM yyyy', locale);
    }, [createAt, updateAt, locale, t]);

    return (
        <Card className={styles.root}>
            <Link to={`/business/branding/${id}`} className={styles.link} />

            <div className={styles.menu}>
                <BrandingListCardMenu
                    id={id}
                    isDefault={isDefault}
                    isMultibranding={isMultibranding}
                    {...methods}
                />
            </div>

            <Chip
                label={t(`${i18nPath}.default`)}
                color="primary"
                className={classnames(styles.default, { [styles.visible]: isDefault })}
            />

            <div className={styles.media}>
                <img src={logo || logoBlack} alt="" className={styles.image} />
            </div>

            <div className={styles.body}>
                <div className={styles.text}>
                    <Typography noWrap>{title || t(`${i18nPath}.untitled`)}</Typography>

                    <Typography variant="body2" color="textSecondary">
                        {dateString}
                    </Typography>
                </div>

                {user && (
                    <Tooltip title={getUserName(user)}>
                        <UserAvatar user={user} className={styles.user} />
                    </Tooltip>
                )}
            </div>
        </Card>
    );
};

export default BrandingListCard;
