import punycode from 'punycode';
var validateHost = function validateHost(value) {
  value = value.trim();
  if (!value || value.length === 0) {
    return;
  }
  var parts = value.split('.');
  if (parts.every(Boolean)) {
    if (parts.reverse()[0].match(/\d/)) {
      if (parts.length === 4 && parts.some(function (part) {
        var num = Number(part);
        return part.length > 0 && !isNaN(num) && num >= 0 && num < 256;
      })) {
        return;
      }
    } else if (isDomain(punycode.toASCII(value))) {
      return;
    }
  }
  return 'validation.host';
};
export default validateHost;
function isDomain(value) {
  var domainNameRegexPattern = '[a-z0-9](([a-z0-9]{0,62})|([-a-z0-9]{0,61}[a-z0-9]))?(\\.[a-z0-9](([a-z0-9]{0,62})|([-a-z0-9]{0,61}[a-z0-9]))?){1,126}?';
  var re = new RegExp('^' + domainNameRegexPattern + '$', 'i');
  value = value.replace(/(\.$)/, '');
  return value.length > 0 && value.length <= 253 && re.test(value);
}