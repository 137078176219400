function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import { useEffect, useCallback, useMemo, useState } from 'react';
import { createRegisterGroupAction, createUnregisterGroupAction, createLoadGroupDoneAction, createFinishGroupStepAction, createToggleGroupRunAction, createResetGroupAction, createActivateGroupStepAction } from './onboardingReducer';
import { useOnboardingContext } from './useOnboardingContext';
export var useOnboarding = function useOnboarding(_ref) {
  var groupId = _ref.groupId,
    stepIds = _ref.stepIds,
    priority = _ref.priority,
    onGroupSkip = _ref.onGroupSkip,
    onLoadData = _ref.onLoadData,
    onClearData = _ref.onClearData,
    onFinishStep = _ref.onFinishStep,
    onActivateGroup = _ref.onActivateGroup;
  var _useOnboardingContext = useOnboardingContext(),
    state = _useOnboardingContext.state,
    dispatch = _useOnboardingContext.dispatch;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    loading = _useState2[0],
    setLoading = _useState2[1];
  var _useState3 = useState(false),
    _useState4 = _slicedToArray(_useState3, 2),
    fulfilled = _useState4[0],
    setFulfilled = _useState4[1];
  var groupLoaded = useMemo(function () {
    var _state$groups$groupId;
    return (_state$groups$groupId = state.groups[groupId]) === null || _state$groups$groupId === void 0 ? void 0 : _state$groups$groupId.loaded;
  }, [groupId, state.groups]);
  var steps = useMemo(function () {
    var _state$groups$groupId2;
    return (_state$groups$groupId2 = state.groups[groupId]) === null || _state$groups$groupId2 === void 0 ? void 0 : _state$groups$groupId2.steps;
  }, [groupId, state.groups]);
  useEffect(function () {
    dispatch(createRegisterGroupAction(groupId, priority, stepIds));
  }, [dispatch, groupId, priority, stepIds]);
  useEffect(function () {
    setFulfilled(false);
  }, [groupId]);
  useEffect(function () {
    if (fulfilled || loading || groupLoaded) return;
    setLoading(true);
    onLoadData(groupId, stepIds).then(function (result) {
      var isAllStepsFinished = Object.keys(result.steps).every(function (key) {
        return result.steps[key].finished;
      });
      dispatch(createLoadGroupDoneAction(result.groupId, isAllStepsFinished, result.steps));
      setFulfilled(true);
      setLoading(false);
    }).catch(function (e) {
      setFulfilled(true);
      setLoading(false);
    });
  }, [groupLoaded, groupId, stepIds, dispatch, onLoadData, loading, fulfilled]);
  useEffect(function () {
    return function () {
      dispatch(createUnregisterGroupAction(groupId));
    };
  }, [groupId, dispatch]);
  var finishStep = useCallback(function (stepId, insideFinishAll) {
    dispatch(createFinishGroupStepAction(groupId, stepId));
    onFinishStep(groupId, stepId, insideFinishAll);
  }, [dispatch, groupId, onFinishStep]);
  var activateGroup = useCallback(function () {
    var active = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
    dispatch(createToggleGroupRunAction(groupId, active));
    onActivateGroup && onActivateGroup(groupId, active);
  }, [dispatch, groupId, onActivateGroup]);
  var finishAllSteps = useCallback(function () {
    if (!steps) {
      return;
    }
    Object.keys(steps).filter(function (stepKey) {
      return !steps[stepKey].finished;
    }).forEach(function (stepKey) {
      finishStep(stepKey, true);
    });
    if (onGroupSkip) {
      onGroupSkip(groupId);
    }
  }, [steps, finishStep, onGroupSkip, groupId]);
  var resetGroup = useCallback(function () {
    dispatch(createResetGroupAction(groupId));
    onClearData(groupId);
  }, [dispatch, groupId, onClearData]);
  var setActiveStep = useCallback(function (stepId) {
    dispatch(createActivateGroupStepAction(groupId, stepId));
  }, [dispatch, groupId]);
  return {
    finishStep: finishStep,
    activateGroup: activateGroup,
    finishAllSteps: finishAllSteps,
    resetGroup: resetGroup,
    setActiveStep: setActiveStep
  };
};