function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import { useEffect } from 'react';
import { useUser } from '../data/user';
import { isAccountUser } from '../types/User';
import { createDate } from '@wbnr/ui';
import { analyticsConfig } from './analyticsConfig';
export var Wootric = function Wootric() {
  var _useUser = useUser(),
    _useUser2 = _slicedToArray(_useUser, 1),
    user = _useUser2[0];
  useEffect(function () {
    if (analyticsConfig.wootricToken) {
      insertWootricScript();
    }
  }, []);
  useEffect(function () {
    if (analyticsConfig.wootricToken && window.wootric) {
      if (isAccountUser(user) && shouldShowNpsForUser(user)) {
        window.wootricSettings = {
          email: user.email,
          external_id: user.id,
          created_at: createDate(user.firstEventDate).getTime() / 1000,
          account_token: analyticsConfig.wootricToken
        };
        try {
          window.wootric('run');
        } catch (e) {
          console.log('wootric error:', e);
        }
      } else {
        try {
          if (window.WootricSurvey) {
            window.WootricSurvey.stop();
          }
        } catch (e) {
          console.log('wootric error:', e);
        }
      }
    }
  }, [user]);
  return null;
};
var shouldShowNpsForUser = function shouldShowNpsForUser(user) {
  return Boolean(user.firstEventDate) && Boolean(user.isNpsEnabled);
};
var insertWootricScript = function insertWootricScript() {
  var _first$parentNode;
  if (document.querySelector('#wootricScript')) {
    return;
  }
  var wScript = document.createElement('script');
  wScript.type = 'text/javascript';
  wScript.async = true;
  wScript.src = 'https://cdn.wootric.com/wootric-sdk.js';
  wScript.id = 'wootricScript';
  var first = document.getElementsByTagName('script')[0];
  first === null || first === void 0 ? void 0 : (_first$parentNode = first.parentNode) === null || _first$parentNode === void 0 ? void 0 : _first$parentNode.insertBefore(wScript, first);
};