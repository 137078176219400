import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { MoreVertIcon } from '@wbnr/icons';
import { Dropdown, IconButton, Menu, MenuItem } from '@wbnr/ui';

import { MultibrandingMethods } from '../../types';

const i18nPath = 'business.brandingPage';

interface Props extends Omit<MultibrandingMethods, 'onCreateBranding'> {
    id: number;
    isDefault: boolean;
    isMultibranding: boolean;
}

const BrandingListCardMenu = ({
    id,
    isDefault,
    isMultibranding,
    onDeleteBranding,
    onCloneBranding,
    onSetDefaultBranding,
}: Props) => {
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <Dropdown
            renderAnchor={({ toggle, anchorRef }) => (
                <IconButton
                    buttonRef={anchorRef}
                    onClick={(e) => {
                        e.stopPropagation();
                        toggle(true);
                    }}
                >
                    <MoreVertIcon />
                </IconButton>
            )}
            component={Menu}
            disableAutoFocusItem
            disableRestoreFocus
            closeOnClick
        >
            {!isDefault && (
                <MenuItem onClick={() => onSetDefaultBranding(id)}>
                    {t(`${i18nPath}.setDefault`)}
                </MenuItem>
            )}
            <MenuItem onClick={() => history.push(`/business/branding/${id}`)}>
                {t('change')}
            </MenuItem>
            {isMultibranding && (
                <MenuItem onClick={() => onCloneBranding(id)}>
                    {t(`${i18nPath}.duplicate`)}
                </MenuItem>
            )}
            {!isDefault && <MenuItem onClick={() => onDeleteBranding(id)}>{t('delete')}</MenuItem>}
        </Dropdown>
    );
};

export default BrandingListCardMenu;
