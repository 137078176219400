import React, { useCallback, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { APIError, useApi } from '@wbnr/frontend-shared/lib/api';
import { getBoxPublicSettings } from '@wbnr/frontend-shared/lib/api/box';
import { login } from '@wbnr/frontend-shared/lib/api/user';
import {
    AuthLayout,
    AuthContent,
    AuthForm,
    AuthButtons,
    AuthTextField,
    AuthPasswordField,
    ButtonData,
} from '@wbnr/frontend-shared/lib/components/auth';
import { PARTICIPANT_SIGNUP_LINK } from '@wbnr/frontend-shared/lib/components/auth/constants';
import {
    getLoginError,
    LoginErrors,
    NO_PROLONGATION,
    PARTICIPANT,
} from '@wbnr/frontend-shared/lib/components/auth/errors';
import LoadingScreen from '@wbnr/frontend-shared/lib/components/LoadingScreen';
import { IS_BOX_MODE } from '@wbnr/frontend-shared/lib/config';
import { useUser } from '@wbnr/frontend-shared/lib/data/user';
import { useForm, useField, makeFormErrors } from '@wbnr/frontend-shared/lib/forms';
import {
    composeValidators,
    validateEmail,
    validateRequired,
} from '@wbnr/frontend-shared/lib/utils/validators';
import { CheckboxField, createTestIdProps } from '@wbnr/ui';

import { useSignIn } from '../hooks/useSignIn';

interface Props {
    joinToOrganization?: () => Promise<boolean>;
    // Prop to use in Route component from react-router
    children?: React.ReactNode;
}

type LocationSignin = Partial<{
    referrer: string;
    email: string;
    isParticipant: boolean;
    noProlongation: boolean;
}>;

const EMPTY_LOCATION_SIGNIN: LocationSignin = {};

const baseTestId = 'SignIn';

const i18nPath = 'auth.signin';

const SignIn = ({ joinToOrganization }: Props) => {
    const { handleLoadedUser, userInited } = useSignIn({ joinToOrganization });
    const history = useHistory();
    const { state: locationState = EMPTY_LOCATION_SIGNIN } = useLocation<LocationSignin>();
    const { t } = useTranslation();

    const isCourses = window.location.pathname.includes('/courses');
    const [boxSettings, , , , boxSettingsFulfilled] = useApi(
        IS_BOX_MODE ? getBoxPublicSettings : null,
    );

    const ssoLoginAllowed = boxSettings?.sso;

    useEffect(() => {
        if (ssoLoginAllowed && history.location.state !== '/sso-signin') {
            history.push('/sso-signin');
        }
    }, [ssoLoginAllowed, history]);

    const ldapLoginAllowed = boxSettings?.ldap;
    const [, , , , loadUser] = useUser();
    const { control, errors, submit, setValue, setError } = useForm(
        {
            defaultValues: {
                email: '',
                password: '',
                rememberMe: true,
            },
        },
        {
            onSubmit: useCallback(
                async (data) => {
                    try {
                        await login(data);

                        if (joinToOrganization) {
                            const isSuccess = await joinToOrganization();
                            if (!isSuccess) {
                                return;
                            }
                        }

                        if (isCourses) {
                            window.location.replace('/courses');
                            return;
                        }

                        const user = await loadUser().catch(() => {});

                        if (user && 'id' in user) {
                            await handleLoadedUser(user);
                        }
                    } catch (err) {
                        if (err instanceof APIError) {
                            const message = getLoginError(err);

                            if (isCourses && message === PARTICIPANT) {
                                history.push('/courses/signup');
                            } else {
                                throw makeFormErrors({
                                    email: message,
                                });
                            }
                        }
                    }
                },
                [isCourses, loadUser, handleLoadedUser, joinToOrganization, history],
            ),
        },
    );

    const emailField = useField(control, 'email', {
        rules: {
            validate: ldapLoginAllowed
                ? validateRequired
                : composeValidators([validateEmail, validateRequired]),
        },
    });
    const passwordField = useField(control, 'password', {
        rules: {
            required: true,
        },
    });
    const rememberMeField = useField(control, 'rememberMe');

    const message: string = errors.email ? errors.email.message : '';
    const { errorKey, errorLink } = useMemo(() => {
        const loginError = LoginErrors[message] || { key: '' };
        const errorKeyValue = loginError.key || message;
        const errorLinkValue = loginError.link;

        return { errorKey: errorKeyValue, errorLink: errorLinkValue };
    }, [message]);

    useEffect(() => {
        let errorMessage = '';

        if (locationState.isParticipant) {
            errorMessage = PARTICIPANT;
        } else if (locationState.noProlongation) {
            errorMessage = NO_PROLONGATION;
        }

        if (errorMessage) {
            setError('email', { message: errorMessage });
            locationState.email && setValue('email', locationState.email);
        }
    }, [locationState, setValue, setError]);

    const mainAuthButton = useMemo(
        () => ({
            text: t(`${i18nPath}.submit`),
            onClick: submit,
            ...createTestIdProps(baseTestId, 'action', 'submit'),
        }),
        [submit, t],
    );

    const otherAuthButtons = useMemo(() => {
        const result: ButtonData[] = [];
        if (!joinToOrganization) {
            result.push({
                text: t(`${i18nPath}.ssoLogin`),
                onClick: () => history.push('/sso-signin'),
                ...createTestIdProps(baseTestId, 'action', 'go-to-sso-signin'),
            });
        }
        result.push({
            text: t(`${i18nPath}.restorePassword`),
            onClick: () => history.push(isCourses ? '/courses/signin-reset' : '/signin-reset'),
            ...createTestIdProps(baseTestId, 'action', 'go-to-password-recovery'),
        });

        if (boxSettings && !boxSettings.signUp) {
            return result;
        }

        result.push({
            text: t(`${i18nPath}.register`),
            href: IS_BOX_MODE ? undefined : 'https://webinar.ru/#form',
            onClick: IS_BOX_MODE ? () => history.push('/signup') : undefined,
            ...createTestIdProps(baseTestId, 'action', 'go-to-signup'),
        });

        return result;
    }, [boxSettings, isCourses, history, t, joinToOrganization]);

    if (!boxSettingsFulfilled || !userInited) {
        return <LoadingScreen />;
    }

    return (
        <>
            {!joinToOrganization && (
                <Helmet>
                    <title>{t('pageTitle.signin')}</title>
                </Helmet>
            )}

            <AuthLayout>
                <AuthContent title={t(`${i18nPath}.title`)}>
                    <AuthForm {...createTestIdProps(baseTestId, 'form')}>
                        <AuthTextField
                            {...emailField}
                            type={ldapLoginAllowed ? 'text' : 'email'}
                            label={
                                ldapLoginAllowed
                                    ? t(`${i18nPath}.ldapDnOrEmail`)
                                    : t(`${i18nPath}.email`)
                            }
                            helperText={
                                errorKey ? (
                                    <span>
                                        <Trans
                                            i18nKey={errorKey}
                                            components={[
                                                <a key="errorLink" href={errorLink}>
                                                    text
                                                </a>,
                                                <a
                                                    key="PARTICIPANT_SIGNUP_LINK"
                                                    href={PARTICIPANT_SIGNUP_LINK}
                                                >
                                                    text
                                                </a>,
                                            ]}
                                        />
                                    </span>
                                ) : null
                            }
                            {...createTestIdProps(baseTestId, 'field', 'email')}
                        />

                        <AuthPasswordField
                            {...passwordField}
                            label={t(`${i18nPath}.password`)}
                            {...createTestIdProps(baseTestId, 'field', 'password')}
                        />

                        <CheckboxField
                            {...rememberMeField}
                            label={t(`${i18nPath}.rememberMe`)}
                            {...createTestIdProps(baseTestId, 'field', 'remember-me')}
                            edge
                        />

                        <AuthButtons main={mainAuthButton} buttons={otherAuthButtons} />
                    </AuthForm>
                </AuthContent>
            </AuthLayout>
        </>
    );
};

export default SignIn;
