import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DropdownMenu, MenuAction } from '@wbnr/ui';

interface MyDeviceTableMenuProps {
    onClickEdit: () => void;
    onClickDelete: () => void;
}

export const MyDeviceTableMenu: FC<MyDeviceTableMenuProps> = ({ onClickEdit, onClickDelete }) => {
    const { t } = useTranslation();

    return (
        <DropdownMenu>
            {({ toggle }) => [
                <MenuAction
                    key="dropdown_menu_action_edit"
                    label={t('business.myDevicesPage.myDevicesList.edit')}
                    onClick={() => {
                        toggle();
                        onClickEdit();
                    }}
                />,
                <MenuAction
                    key="dropdown_menu_action_delete"
                    label={t('business.myDevicesPage.myDevicesList.delete')}
                    onClick={() => {
                        toggle();
                        onClickDelete();
                    }}
                />,
            ]}
        </DropdownMenu>
    );
};
