var _LoginErrors;
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { PARTICIPANT_HELP_LINK } from './constants';
var i18nPath = 'auth.signin';
var UNKNOWN = 'unknown';
var WRONG_LOGIN = 'wrong-login';
var SSO_ONLY = 'sso-only';
var PASSWORD_ATTEMPTS_LIMIT = 'password-attempts-limit';
export var NO_PROLONGATION = 'no-prolongation';
export var PARTICIPANT = 'participant';
export var LoginErrors = (_LoginErrors = {}, _defineProperty(_LoginErrors, UNKNOWN, {
  key: 'unknownError'
}), _defineProperty(_LoginErrors, WRONG_LOGIN, {
  key: "".concat(i18nPath, ".errors.wrongLogin")
}), _defineProperty(_LoginErrors, NO_PROLONGATION, {
  key: "".concat(i18nPath, ".errors.noProlongation"),
  link: 'https://webinar.ru/faq_and_support/'
}), _defineProperty(_LoginErrors, PARTICIPANT, {
  key: "".concat(i18nPath, ".errors.participant"),
  link: PARTICIPANT_HELP_LINK
}), _defineProperty(_LoginErrors, SSO_ONLY, {
  key: "".concat(i18nPath, ".errors.ssoOnly")
}), _defineProperty(_LoginErrors, PASSWORD_ATTEMPTS_LIMIT, {
  key: "".concat(i18nPath, ".errors.passwordAttemptsLimit")
}), _LoginErrors);
export function getLoginError(err) {
  switch (err.status) {
    case 400:
    case 404:
      return WRONG_LOGIN;
    case 401:
      return err.errorMessage === 'Sso user cannot login with password' ? SSO_ONLY : UNKNOWN;
    case 402:
      return NO_PROLONGATION;
    case 403:
      return PASSWORD_ATTEMPTS_LIMIT;
    case 418:
      return PARTICIPANT;
    default:
      return UNKNOWN;
  }
}