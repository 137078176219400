const timeDividers = [60, 60];
const timeDividersI18nKeys = ['second', 'minute', 'hour'];

export function formatTimeDuration(i18n, valueSeconds) {
    let currentValue = valueSeconds;

    let parts = timeDividers.reduce((memo, currentDivider, index, arr) => {
        if (currentValue === 0) {
            return memo;
        }

        memo.push(currentValue % currentDivider);
        currentValue = Math.floor(currentValue / currentDivider);

        if (index === arr.length - 1 && currentValue) {
            memo.push(currentValue);
        }

        return memo;
    }, []);

    return parts
        .map((value, index) =>
            value
                ? `${value} ${i18n.t(`ui:${timeDividersI18nKeys[index]}`, { count: value })}`
                : '',
        )
        .reverse()
        .join(' ');
}
