import { useSnackbar } from 'notistack';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { APIError } from '@wbnr/frontend-shared/lib/api';
import { SIEMSettings } from '@wbnr/frontend-shared/lib/api/business-api/types';
import { makeFormErrors, useForm, useField } from '@wbnr/frontend-shared/lib/forms';
import { validateHost, validatePort } from '@wbnr/frontend-shared/lib/utils/validators';
import { createTestIdProps, TextField, SwitchField, LoadableButton, Collapse } from '@wbnr/ui';

import { useSIEM } from '../hooks/useSIEM';

import styles from './SIEMSettingsForm.module.scss';

const BASE_TEST_ID = 'SIEMSettingsForm';

const DEFAULT_VALUES: SIEMSettings = {
    isEnabled: false,
    host: '',
    port: 0,
};

const FIELDS_NAME = ['host', 'port'] as const;
const FIELDS_ERROR_MESSAGE: Record<keyof Pick<SIEMSettings, 'host' | 'port'>, string> = {
    host: 'validation.host',
    port: 'validation.port',
};

interface ValidationErrors {
    code: string;
    field: string;
    message: string;
}

type FormErrors = Partial<Record<keyof SIEMSettings, string>>;

export const SIEMSettingsForm: FC = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { data, hasData, updateSIEMSettings } = useSIEM();

    const {
        control,
        formState: { errors, isSubmitting, isDirty },
        submit,
        watch,
        reset,
        clearErrors,
        getValues,
        setValue,
    } = useForm<SIEMSettings, void>(
        {
            defaultValues: DEFAULT_VALUES,
        },
        {
            onSubmit: async ({ host, port, isEnabled }) => {
                try {
                    const values: SIEMSettings = {
                        isEnabled,
                        host,
                        port,
                    };
                    await updateSIEMSettings(values);
                    reset(values, { isDirty: false });
                    enqueueSnackbar(t('business.siem.success'));
                } catch (error) {
                    const errorMessages: FormErrors = {};

                    if (
                        error instanceof APIError &&
                        error.body.error.validationErrors instanceof Array
                    ) {
                        (error.body.error.validationErrors as ValidationErrors[]).forEach(
                            ({ field }) => {
                                const fieldName = field as (typeof FIELDS_NAME)[number];

                                if (FIELDS_NAME.includes(fieldName)) {
                                    errorMessages[fieldName] = FIELDS_ERROR_MESSAGE[fieldName];
                                }
                            },
                        );
                    }

                    if (Object.keys(errorMessages).length) {
                        throw makeFormErrors<SIEMSettings>(errorMessages);
                    }

                    enqueueSnackbar(t('unknownError'), {
                        variant: 'error',
                    });
                    throw error;
                }
            },
        },
    );

    const { isEnabled: isEnabledValue } = watch();

    const hostField = useField(control, 'host', {
        rules: {
            required: isEnabledValue,
            validate: (value: string) => {
                if (!isEnabledValue) {
                    return;
                }
                return validateHost(value);
            },
        },
    });

    const { onBlur: onBlurPort, ...portField } = useField(control, 'port', {
        rules: {
            required: isEnabledValue,
            validate: (value: string) => {
                const isEnabledFormValue = getValues('isEnabled');

                if (!isEnabledFormValue && Number(value) === 0) {
                    return;
                }

                return validatePort(value);
            },
        },
    });

    const handleBlurPort = (event: React.FocusEvent<HTMLInputElement>) => {
        const value = String(event.target.value);
        const regex = /^(0+)/;

        /* если вначале строки есть нули (01|007), удаляем их */
        if (regex.test(value)) {
            setValue('port', value.replace(regex, ''));
        }

        onBlurPort();
    };

    const isEnableField = useField(control, 'isEnabled');

    const hasErrorsFields = Boolean(Object.keys(errors).length);

    // Обновление значений при первой загрузке страницы с данными из сервера.
    useEffect(() => {
        if (hasData) {
            reset(data);
        }
    }, [reset, hasData, data]);

    // Очистка ошибок хоста и порта при переходе тумблера в состояние "выключен"
    // В случае выключения тумблера, обновляем значение поля "Адрес" как пустая строка
    // и значение поля "Порт" как 0
    useEffect(() => {
        if (!isEnabledValue) {
            setValue('host', DEFAULT_VALUES.host, {
                shouldDirty: true,
            });
            setValue('port', DEFAULT_VALUES.port, {
                shouldDirty: true,
            });
            clearErrors();
        }
    }, [isEnabledValue, clearErrors, setValue]);

    useEffect(() => {
        if (isEnabledValue || !hasErrorsFields || (!isEnabledValue && hasErrorsFields)) {
            return;
        }

        clearErrors(['host', 'port']);
    }, [clearErrors, hasErrorsFields, isEnabledValue]);

    return (
        <div className={styles.root}>
            <SwitchField
                label={t('business.siem.subtitle')}
                classes={{
                    root: styles.switchField,
                }}
                disabled={isSubmitting}
                {...isEnableField}
                {...createTestIdProps(BASE_TEST_ID, 'switch', 'enable')}
            />
            <Collapse in={isEnabledValue}>
                <div className={styles.fieldsInputContainer}>
                    <TextField
                        fullWidth
                        label={t('business.siem.SIEMSettingsForm.host')}
                        className={styles.inputFields}
                        disabled={isSubmitting || !isEnabledValue}
                        {...hostField}
                        {...createTestIdProps(BASE_TEST_ID, 'input', 'host')}
                    />
                    <TextField
                        fullWidth
                        label={t('business.siem.SIEMSettingsForm.port')}
                        className={styles.inputFields}
                        disabled={isSubmitting || !isEnabledValue}
                        onBlur={handleBlurPort}
                        {...portField}
                        {...createTestIdProps(BASE_TEST_ID, 'input', 'port')}
                    />
                </div>
            </Collapse>

            <LoadableButton
                color="primary"
                variant="contained"
                onClick={submit}
                className={styles.saveBtn}
                disabled={!isDirty}
                {...createTestIdProps(BASE_TEST_ID, 'button', 'saveSIEM')}
            >
                {t('save')}
            </LoadableButton>
        </div>
    );
};
