import { FC, createContext, useCallback } from 'react';

import { getSIEMSettings, updateSIEMSettings } from '@wbnr/frontend-shared/lib/api/business-api';
import { SIEMSettings } from '@wbnr/frontend-shared/lib/api/business-api/types';
import { getUserOrganizationId, useAccountUser } from '@wbnr/frontend-shared/lib/data/user';
import { ResetStateAction, useResource } from '@wbnr/frontend-shared/lib/data/useResource';
import { useBinded } from '@wbnr/frontend-shared/lib/hooks/useBinded';

import type {
    SIEMAction,
    SIEMUpdateAction,
    SIEMContextData,
    GetSIEMSettings,
    UpdateSIEMSettings,
} from './interfaces';

const INITIAL_VALUES: Partial<SIEMSettings> = {};

const ERROR_MESSAGE_ORIGANIZATION_ID = 'Organization Id is required';

const siemReducer = (
    state: Partial<SIEMSettings> | null = INITIAL_VALUES,
    action: SIEMAction | SIEMUpdateAction | ResetStateAction<Partial<SIEMSettings>, any>,
): Partial<SIEMSettings> => {
    switch (action.type) {
        case 'get':
        case 'update':
            return {
                ...(state || INITIAL_VALUES),
                ...action.payload,
            };
        default:
            throw new Error(`Unsupported action type ${(action as any).type}`);
    }
};

export const SIEMContext = createContext<SIEMContextData>({} as SIEMContextData);

export const SIEMContextProvider: FC = ({ children }) => {
    const [user] = useAccountUser();
    const organizationId = getUserOrganizationId(user);
    const hasOrganizationId = typeof organizationId === 'number';

    const {
        data,
        loading: loadingSIEM,
        dispatch: dispatchSIEM,
    } = useResource(
        {
            request: useBinded(hasOrganizationId ? getSIEMSettings : undefined, { organizationId }),
        },
        siemReducer,
    );

    const hasData = Boolean(data && Object.keys(data).length);

    const getSIEMSettingsDispatch = useCallback<GetSIEMSettings>(async () => {
        if (!hasOrganizationId) {
            return Promise.reject(ERROR_MESSAGE_ORIGANIZATION_ID);
        }

        const result = await getSIEMSettings({
            organizationId,
        });

        dispatchSIEM({ type: 'get', payload: result });

        return result;
    }, [dispatchSIEM, hasOrganizationId, organizationId]);

    const updateSIEMSettingsDispatch = useCallback<UpdateSIEMSettings>(
        async (params: SIEMSettings) => {
            if (!hasOrganizationId) {
                return Promise.reject(ERROR_MESSAGE_ORIGANIZATION_ID);
            }

            await updateSIEMSettings({
                ...params,
                port: Number(params.port),
                organizationId,
            });

            const payload: SIEMSettings = {
                isEnabled: params.isEnabled,
                host: params.host,
                port: params.port,
            };

            dispatchSIEM({
                type: 'update',
                payload,
            });

            return payload;
        },
        [dispatchSIEM, hasOrganizationId, organizationId],
    );

    return (
        <SIEMContext.Provider
            value={{
                data: (data || INITIAL_VALUES) as Partial<SIEMSettings>,
                hasData,
                organizationId,
                loadingSIEM,
                getSIEMSettings: getSIEMSettingsDispatch,
                updateSIEMSettings: updateSIEMSettingsDispatch,
            }}
        >
            {children}
        </SIEMContext.Provider>
    );
};
