import { useSnackbar } from 'notistack';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { APIError } from '@wbnr/frontend-shared/lib/api';
import { useCustomDialog } from '@wbnr/frontend-shared/lib/dialogs';
import { ActionDialog, createTestIdProps, DialogContentText } from '@wbnr/ui';

import { ErrorCodes, ERROR_CODES } from '../interfaces';

import styles from './useModalMyDevice.module.scss';

const BASE_TEST_ID = 'ModalMyDeviceDelete';

interface ModalMyDeviceDeleteProps {
    name: string;
    opened: boolean;
    onClose: () => void;
    onSubmit: () => Promise<void>;
}

type MyDeviceEditDialogProps = Pick<ModalMyDeviceDeleteProps, 'name' | 'onSubmit'>;

const ModalMyDeviceDelete: FC<ModalMyDeviceDeleteProps> = ({ name, opened, onClose, onSubmit }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const handleSubmit = async () => {
        try {
            await onSubmit();
            enqueueSnackbar(t('business.myDevicesPage.myDevicesList.delete_success'));
            onClose();
        } catch (error) {
            let errorMessage = 'unknownError';

            if (error instanceof APIError && ERROR_CODES[error.body.error.code as ErrorCodes]) {
                errorMessage = ERROR_CODES[error.body.error.code as ErrorCodes];
            }

            enqueueSnackbar(t(errorMessage), { variant: 'error' });
            throw error;
        }
    };

    return (
        <ActionDialog
            maxWidth="md"
            title={t('business.myDevicesPage.confirmRemove.title')}
            opened={opened}
            onSubmit={handleSubmit}
            onClose={onClose}
            submitText={t('business.myDevicesPage.delete')}
            containedSubmit
            {...createTestIdProps(BASE_TEST_ID, 'confirmDialog')}
        >
            <DialogContentText className={styles.title}>
                {t('business.myDevicesPage.confirmRemove.text', { device: name })}
            </DialogContentText>
        </ActionDialog>
    );
};

export const useModalMyDeviceDelete = () => {
    const [showModalMyDeviceDelete] = useCustomDialog<MyDeviceEditDialogProps>(
        ({ opened, closeDialog, props: { name, onSubmit } }) => (
            <ModalMyDeviceDelete
                name={name}
                opened={opened}
                onClose={closeDialog}
                onSubmit={onSubmit}
            />
        ),
    );

    return { showModalMyDeviceDelete };
};
