import { useUser } from '@wbnr/frontend-shared/lib/data/user';

import EndlessMeetingsBanner from './EndlessMeetingsBanner';
import FileSizeBanner from './FileSizeBanner';
import MeetingLimitBanner from './MeetingLimitBanner';
import PayUsBanner from './PayUsBanner';
import WebrtcWarningBanner from './WebrtcWarningBanner';

const Banners = () => {
    const [user, loading] = useUser();

    if (!user && !loading) {
        return null;
    }

    return (
        <>
            <FileSizeBanner />
            <PayUsBanner />
            <WebrtcWarningBanner />
            <MeetingLimitBanner />
            <EndlessMeetingsBanner />
        </>
    );
};

export default Banners;
