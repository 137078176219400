"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useBounds = void 0;
var _react = require("react");
var _useRequestAnimationFrameThrottle = require("./useRequestAnimationFrameThrottle");
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var getPosition = function getPosition(el) {
  var rect = el.getBoundingClientRect();
  var scrollLeft = document.body.scrollLeft || document.documentElement.scrollLeft;
  var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
  return {
    top: rect.top + scrollTop,
    left: rect.left + scrollLeft,
    bottom: rect.bottom + scrollTop,
    right: rect.right + scrollLeft,
    width: rect.width,
    height: rect.height
  };
};
var UPDATE_POSITION_TIMEOUT = 500;
var useBounds = function useBounds(el) {
  var _useState = (0, _react.useState)({
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      width: 0,
      height: 0
    }),
    _useState2 = _slicedToArray(_useState, 2),
    bounds = _useState2[0],
    setBounds = _useState2[1];
  var updateBounds = (0, _useRequestAnimationFrameThrottle.useRequestAnimationFrameThrottle)((0, _react.useCallback)(function () {
    if (el) setBounds(getPosition(el));
  }, [el]));
  (0, _react.useEffect)(function () {
    var timer;
    if (el instanceof HTMLElement) {
      var scheduleUpdate = function scheduleUpdate() {
        return setTimeout(function () {
          updateBounds();
          timer = scheduleUpdate();
        }, UPDATE_POSITION_TIMEOUT);
      };
      updateBounds();
      timer = scheduleUpdate();
    }
    return function () {
      timer && clearTimeout(timer);
    };
  }, [el, updateBounds]);
  (0, _react.useEffect)(function () {
    window.addEventListener('resize', updateBounds);
    window.addEventListener('scroll', updateBounds);
    return function () {
      window.removeEventListener('resize', updateBounds);
      window.removeEventListener('scroll', updateBounds);
    };
  }, [updateBounds]);
  return bounds;
};
exports.useBounds = useBounds;