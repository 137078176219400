import React, { useState, ReactNode, useEffect } from 'react';

export const AppContext = React.createContext<{
    setActive: (newActive: boolean) => void;
    active: boolean;
}>({
    setActive: () => {},
    active: true,
});

export const AppContextProvider = ({ children }: { children: ReactNode }) => {
    const [active, setActive] = useState(true);

    useEffect(() => {
        const root: HTMLElement | null = document.querySelector('#root');
        if (root) {
            if (active) {
                root.classList.remove('rootHidden');
            } else {
                root.classList.add('rootHidden');
            }
        }
    }, [active]);

    return (
        <AppContext.Provider
            value={{
                active,
                setActive,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};
