"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _draftJs = _interopRequireDefault(require("draft-js"));
var _block = require("../utils/block");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var EditorState = _draftJs.default.EditorState,
  Modifier = _draftJs.default.Modifier,
  SelectionState = _draftJs.default.SelectionState;
var IFRAME_BLOCK = _block.ATOMIC_BLOCK_TYPES.IFRAME,
  IMAGE_BLOCK = _block.ATOMIC_BLOCK_TYPES.IMAGE;
var REPLACE_TYPES = {
  YOUTUBE: {
    regex: /(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?(?:$|\s)/g,
    template: function template(id) {
      return "https://www.youtube.com/embed/".concat(id);
    },
    atomicBlockType: IFRAME_BLOCK
  },
  VIMEO: {
    regex: /(?:https?:\/\/)?(?:www\.|player\.)?(?:vimeo.com\/)(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)(?:$|\s)/g,
    template: function template(id) {
      return "https://player.vimeo.com/video/".concat(id, "?title=0&byline=0&portrait=0");
    },
    atomicBlockType: IFRAME_BLOCK
  },
  MIRO: {
    regex: /(?:https?:\/\/)?(?:www\.)?(?:miro.com\/app\/)(?:board\/|embed\/)([\w-]{11})(?:=)(?:\/?\??)(?:$|\s)/g,
    template: function template(id) {
      return "https://miro.com/app/embed/".concat(id, "=/");
    },
    atomicBlockType: IFRAME_BLOCK
  },
  IMAGE: {
    regex: /(?:^|\s)(https?:\/\/.*\.(?:png|jpg|jpeg|gif))(?:$|\s)/gi,
    template: function template(src) {
      return src;
    },
    atomicBlockType: IMAGE_BLOCK
  }
};
var REPLACE_TYPES_LIST = Object.keys(REPLACE_TYPES);
var createReplacerPlugin = function createReplacerPlugin() {
  return {
    decorators: [{
      strategy: function strategy(block, callback, contentState) {
        var matches = parseLinksInBlock(contentState, block);
        matches.forEach(function (_ref) {
          var start = _ref.start,
            end = _ref.end;
          return callback(start, end);
        });
      },
      component: function component() {
        return null;
      }
    }],
    onChange: function onChange(editorState) {
      var selectionState = editorState.getSelection();
      var contentState = editorState.getCurrentContent();
      var currentBlockKey = selectionState.getAnchorKey();
      var currentBlock = contentState.getBlockForKey(currentBlockKey);
      return replaceLinksInBlock(editorState, currentBlock);
    }
  };
};
var replaceLinksInBlock = function replaceLinksInBlock(editorState, block) {
  var contentState = editorState.getCurrentContent();
  var links = parseLinksInBlock(contentState, block);
  var deletedLength = 0;
  return links.reduce(function (currentEditorState, link) {
    var linkLength = link.rawLink.length;
    var linkParams = _objectSpread(_objectSpread({}, link), {}, {
      start: link.start - deletedLength,
      end: link.end - deletedLength
    });
    deletedLength += linkLength;
    return replaceLink(currentEditorState, linkParams);
  }, editorState);
};
var parseLinksInBlock = function parseLinksInBlock(contentState, block) {
  var links = [];
  REPLACE_TYPES_LIST.forEach(function (type) {
    var typeLinks = parseLinksByType(contentState, block, type);
    links.push.apply(links, _toConsumableArray(typeLinks));
  });
  return links.sort(function (a, b) {
    return a.start - b.start;
  });
};
var parseLinksByType = function parseLinksByType(contentState, block, type) {
  var text = block.getText();
  var regex = REPLACE_TYPES[type].regex;
  var matches = Array.from(text.matchAll(regex));
  return matches.map(function (match) {
    var res = regexResultMapper(match);
    return res && _objectSpread(_objectSpread({}, res), {}, {
      blockKey: block.getKey(),
      type: type
    });
  }).filter(function (data) {
    if (!data) {
      return false;
    }
    var start = data.start;
    var entityKey = block.getEntityAt(start);
    var entityType = (0, _block.getEntityType)(contentState, entityKey);
    return entityType !== IFRAME_BLOCK && entityType !== IMAGE_BLOCK;
  });
};
var regexResultMapper = function regexResultMapper(match) {
  return match ? {
    rawLink: match[0],
    id: match[1],
    start: match.index,
    end: match.index + match[0].length
  } : null;
};
var replaceLink = function replaceLink(editorState, linkParams) {
  var _ref2 = linkParams || {},
    id = _ref2.id,
    type = _ref2.type,
    blockKey = _ref2.blockKey,
    start = _ref2.start,
    end = _ref2.end;
  var src = REPLACE_TYPES[type].template(id);
  var newEditorState = removeIframeLink(editorState, blockKey, start, end);
  var atomicBlockType = REPLACE_TYPES[type].atomicBlockType;
  return (0, _block.addAtomicBlock)(newEditorState, atomicBlockType, {
    id: id,
    src: src
  });
};
var removeIframeLink = function removeIframeLink(editorState, blockKey, start, end) {
  var content = editorState.getCurrentContent();
  var targetRange = new SelectionState({
    anchorKey: blockKey,
    anchorOffset: start,
    focusKey: blockKey,
    focusOffset: end
  });
  content = Modifier.setBlockType(content, targetRange, 'unstyled');
  content = Modifier.removeRange(content, targetRange, 'backward');
  return EditorState.push(editorState, content, 'remove-link');
};
var _default = createReplacerPlugin;
exports.default = _default;