import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { appLocalStorage } from '@wbnr/frontend-shared/lib/utils/browserStorage';
import { createDate } from '@wbnr/frontend-shared/lib/utils/dates';
import { DATE_FORMATS, formatDate } from '@wbnr/frontend-shared/lib/utils/i18n/formatDate';

import { formatFileSize } from './formatFileSize';
import { formatTimeDuration } from './formatTimeDuration';
import { en } from './translations/en';
import { ru } from './translations/ru';

const LOCALE_RU = 'RU';
const LOCALE_EN = 'EN';

function getDefaultLocale() {
    let locale = appLocalStorage.getItem('locale');

    if (!locale || ![LOCALE_RU, LOCALE_EN].includes(locale.toUpperCase())) {
        const nav = window.navigator;
        const acceptLangs = nav.languages || [nav.userLanguage || nav.language];
        const russianAccepted = acceptLangs.some(
            (lang) => lang.substring(0, 2).toUpperCase() === LOCALE_RU,
        );
        const englishAccepted = acceptLangs.some(
            (lang) => lang.substring(0, 2).toUpperCase() === LOCALE_EN,
        );

        locale = russianAccepted || !englishAccepted ? LOCALE_RU : LOCALE_EN;
    }

    return locale.toLowerCase();
}

i18n.use(initReactI18next).init({
    resources: {
        en,
        ru,
    },

    lng: getDefaultLocale(),
    fallbackLng: 'ru',
    whitelist: ['en', 'ru'],
    defaultNS: 'cp',
    saveMissing: true,
    saveMissingPlurals: true,
    simplifyPluralSuffix: true,

    missingKeyHandler: (lng, ns, key, fallbackValue) => {
        console.warn(
            `Translation of key '${ns}:${key}' for language ${lng} hasn't been found, using '${fallbackValue}'`,
        );
    },
    debug: true,
    react: {
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br'],
    },
    interpolation: {
        escapeValue: false,
        format: function (value, format, lng) {
            if (format === 'filesize') {
                const [size, unit] = formatFileSize(value);

                return `${size} ${i18n.t(`filesizeUnit_${unit}`)}`;
            } else if (format === 'date') {
                return createDate(value).toLocaleDateString(lng);
            } else if (format === 'timeDuration') {
                return formatTimeDuration(i18n, value);
            } else if (format === 'timezoneName') {
                const { timeZone } = Intl.DateTimeFormat().resolvedOptions() || {};
                const tz = timeZone && i18n.t(`timezone.${timeZone}`);

                return !tz || tz === timeZone ? formatDate(value, 'xxx', lng) : tz;
            } else if (format === 'readableDayMonth') {
                return new Intl.DateTimeFormat(lng, {
                    weekday: undefined,
                    year: undefined,
                    month: 'long',
                    day: 'numeric',
                }).format(value);
            } else if (DATE_FORMATS.includes(format)) {
                return formatDate(value, format, lng);
            }

            return value;
        },
    },
});

export default i18n;
