function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import { useCallback, useEffect, useRef } from 'react';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useDrop } from 'react-dnd';
import { createTestId } from '@wbnr/ui';
var useImageDrop = function useImageDrop(change) {
  return useDrop({
    accept: [NativeTypes.FILE],
    drop: function drop(_ref) {
      var files = _ref.files;
      return change(files[0]);
    },
    collect: function collect(monitor) {
      return {
        isOver: monitor.isOver()
      };
    }
  });
};
export var useImageField = function useImageField(onChange, dataTestId) {
  var _useImageDrop = useImageDrop(onChange),
    _useImageDrop2 = _slicedToArray(_useImageDrop, 2),
    status = _useImageDrop2[0],
    drop = _useImageDrop2[1];
  var inputRef = useRef(null);
  var memoRemoveInput = useCallback(function () {
    return removeInput(inputRef);
  }, []);
  var onSelectImage = useCallback(function () {
    return createInput(inputRef, memoRemoveInput, onChange, dataTestId);
  }, [onChange, memoRemoveInput, dataTestId]);
  useEffect(function () {
    return function () {
      return memoRemoveInput();
    };
  }, [memoRemoveInput]);
  return {
    drop: drop,
    isOver: status.isOver,
    onSelectImage: onSelectImage
  };
};
function removeInput(ref) {
  if (ref.current) {
    document.body.removeChild(ref.current);
    ref.current = null;
  }
}
function createInput(ref, remove, onChange, dataTestId) {
  remove();
  ref.current = document.createElement('input');
  ref.current.type = 'file';
  ref.current.accept = 'image/*';
  ref.current.hidden = true;
  ref.current.addEventListener('change', function (event) {
    var _ref2 = event.target,
      files = _ref2.files;
    if (files !== null && files !== void 0 && files.length && files[0].type.includes('image')) {
      onChange(files[0]);
    }
    remove();
  }, {
    once: true
  });
  if (dataTestId) {
    ref.current.dataset.testid = createTestId(dataTestId, 'uploadInput');
  }
  document.body.appendChild(ref.current);
  ref.current.click();
}