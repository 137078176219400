import { TFunction } from 'i18next';
import { useMemo, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation, Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { APIError } from '@wbnr/frontend-shared/lib/api';
import {
    checkOrganizationInvitationLink,
    isOrganizationInvitationLinkValid,
    joinOrganizationInvitationLink,
} from '@wbnr/frontend-shared/lib/api/organization';
import { AuthContent, AuthLayout, AuthButtons } from '@wbnr/frontend-shared/lib/components/auth';
import LoadingScreen from '@wbnr/frontend-shared/lib/components/LoadingScreen';
import { useResource } from '@wbnr/frontend-shared/lib/data/useResource';
import { makeFormErrors } from '@wbnr/frontend-shared/lib/forms';
import { useBinded } from '@wbnr/frontend-shared/lib/hooks/useBinded';
import { createTestIdProps, Typography } from '@wbnr/ui';

import SignIn from '../../auth/SignIn';
import Signup from '../../auth/Signup';

import AuthScreen from './AuthScreen';
import { ORGANIZATION_INVITATION_LINK_ERRORS } from './errors';
import PasswordScreen from './PasswordScreen';

const SCREEN = {
    empty: 0,
    invalidToken: 1,
    invalidPassword: 2,
    password: 3,
    auth: 4,
    signin: 5,
    signup: 6,
    limit: 7,
    member: 8,
    anotherMember: 9,
    paidTariff: 10,
    student: 11,
};

const baseTestId = 'OrganizationInvitationLink';

const i18nPath = 'organization.invitation.link';

const OrganizationInvitationLink = () => {
    const { token } = useParams<{ token: string }>();
    const { t } = useTranslation();

    const [screen, setScreen] = useState(SCREEN.empty);
    const [password, setPassword] = useState('');

    const message = useMemo(() => getMessage(screen, t), [screen, t]);

    const checkPassword = useCallback(
        async ({ password: invitationPassword }: { password: string }) => {
            try {
                await checkOrganizationInvitationLink({ token, password: invitationPassword });
                setPassword(invitationPassword);
                setScreen(SCREEN.auth);
            } catch (err) {
                if (err instanceof APIError) {
                    if (err.status === ORGANIZATION_INVITATION_LINK_ERRORS.INVALID_PASSWORD) {
                        throw makeFormErrors({
                            password: t(`${i18nPath}.wrongPassword`),
                        });
                    } else if (err.status === ORGANIZATION_INVITATION_LINK_ERRORS.INVALID_TOKEN) {
                        setScreen(SCREEN.invalidToken);
                    }
                }
            }
        },
        [token, t],
    );

    const handleErrors = useCallback((err: APIError) => {
        if (err.status === ORGANIZATION_INVITATION_LINK_ERRORS.INVALID_TOKEN) {
            setScreen(SCREEN.invalidToken);
        } else if (err.status === ORGANIZATION_INVITATION_LINK_ERRORS.INVALID_PASSWORD) {
            setScreen(SCREEN.invalidPassword);
        } else if (
            err.status === ORGANIZATION_INVITATION_LINK_ERRORS.INVALID_HANDLING &&
            err.errorMessage
        ) {
            setScreen(getInvalidHandlingErrorScreen(err.errorMessage));
        }
    }, []);

    const organizationInvitation = useMemo(
        () => ({
            token,
            password,
            handleErrors,
        }),
        [token, password, handleErrors],
    );

    const joinToOrganization = useCallback(async () => {
        let isSuccess = false;

        const { token: invitationToken, password: invitationPassword } = organizationInvitation;
        try {
            await joinOrganizationInvitationLink({
                token: invitationToken,
                password: invitationPassword,
            });
            isSuccess = true;
        } catch (err) {
            if (err instanceof APIError) {
                organizationInvitation.handleErrors(err);
            }
        }

        return isSuccess;
    }, [organizationInvitation]);

    const PageTitle = useCallback(() => {
        return (
            <Helmet>
                <title>{t('pageTitle.organizationInvitation.link')}</title>
            </Helmet>
        );
    }, [t]);

    const { data, error } = useResource({
        request: useBinded(isOrganizationInvitationLinkValid, token),
    });

    const isPasswordRequired = useMemo(() => data?.isPasswordRequired, [data]);

    useEffect(() => {
        if (isPasswordRequired !== undefined) {
            if (isPasswordRequired) {
                setScreen(SCREEN.password);
            } else {
                setScreen(SCREEN.auth);
            }
        }
    }, [isPasswordRequired]);

    useEffect(() => {
        if (
            error instanceof APIError &&
            error.status === ORGANIZATION_INVITATION_LINK_ERRORS.INVALID_TOKEN
        ) {
            setScreen(SCREEN.invalidToken);
        }
    }, [error]);

    if (!screen) {
        return <LoadingScreen />;
    }

    if (screen === SCREEN.signin) {
        return (
            <>
                <PageTitle />
                <SignIn joinToOrganization={joinToOrganization} />
            </>
        );
    }

    if (screen === SCREEN.signup) {
        return (
            <>
                <PageTitle />
                <Signup organizationInvitation={organizationInvitation} />
            </>
        );
    }

    return (
        <>
            <PageTitle />
            <AuthLayout>
                {[SCREEN.password, SCREEN.auth].includes(screen) && data && (
                    <AuthContent
                        title={
                            <Trans
                                i18nKey={`${i18nPath}.join`}
                                values={{ name: data.organizationName }}
                                components={[<b key="0" />]}
                            />
                        }
                    >
                        {screen === SCREEN.password && (
                            <PasswordScreen
                                onSubmit={checkPassword}
                                {...createTestIdProps(baseTestId, 'password-screen')}
                            />
                        )}

                        {screen === SCREEN.auth && (
                            <AuthScreen
                                onSignin={() => setScreen(SCREEN.signin)}
                                onSignup={() => setScreen(SCREEN.signup)}
                                {...createTestIdProps(baseTestId, 'auth-screen')}
                            />
                        )}
                    </AuthContent>
                )}

                {message && (
                    <AuthContent
                        title={<Trans i18nKey={message.titleKey} components={[<b key="0" />]} />}
                    >
                        <Typography paragraph>{message.description}</Typography>
                        {screen === SCREEN.student && (
                            <AuthButtons
                                main={{
                                    text: t(`${i18nPath}.signup`),
                                    onClick: () => {
                                        setScreen(SCREEN.signup);
                                    },
                                    ...createTestIdProps(
                                        baseTestId,
                                        'student-need-register',
                                        'action',
                                        'signup',
                                    ),
                                }}
                            />
                        )}
                    </AuthContent>
                )}
            </AuthLayout>
        </>
    );
};

function getMessage(screen: number, t: TFunction) {
    switch (screen) {
        case SCREEN.invalidToken:
            return { titleKey: `${i18nPath}.invalidToken`, description: t(`${i18nPath}.contact`) };

        case SCREEN.invalidPassword:
            return {
                titleKey: `${i18nPath}.invalidPassword`,
                description: t(`${i18nPath}.contact`),
            };

        case SCREEN.limit:
            return { titleKey: `${i18nPath}.limit`, description: t(`${i18nPath}.contact`) };

        case SCREEN.member:
            return {
                titleKey: `${i18nPath}.member`,
                description: t(`${i18nPath}.memberDescription`),
            };

        case SCREEN.anotherMember:
            return {
                titleKey: `${i18nPath}.anotherMember`,
                description: t(`${i18nPath}.anotherMemberDescription`),
            };

        case SCREEN.paidTariff:
            return {
                titleKey: `${i18nPath}.paidTariff`,
                description: t(`${i18nPath}.paidTariffDescription`),
            };

        case SCREEN.student:
            return {
                titleKey: `${i18nPath}.studentNeedRegister`,
                description: t(`${i18nPath}.studentNeedRegisterDescription`),
            };

        default:
            return undefined;
    }
}

function getInvalidHandlingErrorScreen(code: string) {
    switch (code) {
        case 'DONT_HAVE_FEATURE_ADD_TEAM_LINK':
        case 'DONT_HAVE_OPTION_IS_ORGANIZATION':
            return SCREEN.invalidToken;

        case 'LIMIT_USERS_IN_ORGANIZATION':
            return SCREEN.limit;

        case 'USER_ALREADY_IN_ORGANIZATION':
            return SCREEN.member;

        case 'USER_ALREADY_IN_ANOTHER_ORGANIZATION':
            return SCREEN.anotherMember;

        case 'USER_HAVE_PAID_TARIFF':
            return SCREEN.paidTariff;

        case 'USER_TYPE_STUDENT':
            return SCREEN.student;

        default:
            return SCREEN.empty;
    }
}

export default OrganizationInvitationLink;
