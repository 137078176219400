import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useMixpanelTracking } from '@wbnr/frontend-shared/lib/analytics/mixpanel/useMixpanelTracking';
import LoadingScreen from '@wbnr/frontend-shared/lib/components/LoadingScreen';
import { makeFormErrors } from '@wbnr/frontend-shared/lib/forms';
import { BrandingFull } from '@wbnr/frontend-shared/lib/types/Branding';

import { saveBranding } from '../../api';
import { useSMTPResource } from '../../hooks/useSMTPResource';
import { BrandingFormValues, BrandingInitialValues } from '../../types';
import { getBrandingPropName } from '../../utils';
import BrandingForm from '../BrandingForm';

interface Props {
    organizationId: number;
    branding: BrandingFull;
    brandingLoading: boolean;
    customizableAgreements: boolean;
    onBrandingUpdate: () => Promise<void>;
}

const Branding = ({
    organizationId,
    branding,
    brandingLoading,
    customizableAgreements,
    onBrandingUpdate,
}: Props) => {
    const initialValuesRef = useRef<BrandingInitialValues | undefined>(undefined);

    const history = useHistory();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { track } = useMixpanelTracking();

    const {
        data: smtp,
        error: smtpError,
        loading: smtpLoading,
        fulfilled: smtpFulfilled,
        dispatch: smtpDispatch,
        update: smtpUpdate,
    } = useSMTPResource(branding.id);

    const initialValues: BrandingInitialValues | undefined = useMemo(() => {
        if (!brandingLoading && !smtpLoading && smtp) {
            initialValuesRef.current = { branding, smtp };
        }

        return initialValuesRef.current;
    }, [branding, smtp, brandingLoading, smtpLoading]);

    const onSave = useCallback(
        async (values: BrandingFormValues) => {
            if (organizationId && initialValues) {
                try {
                    const brandingId = await saveBranding(organizationId, initialValues, values);
                    track('branding', getMixpanelData(values));

                    smtpDispatch({ type: 'reset' });

                    if (initialValues.branding.id !== brandingId) {
                        await onBrandingUpdate();
                        history.replace(`/business/branding/${brandingId}`);
                    } else {
                        await Promise.all([onBrandingUpdate(), smtpUpdate()]);
                    }

                    enqueueSnackbar(t('ready'));
                } catch (err) {
                    if (err instanceof Error && err.message === 'eventCustomLinkExist') {
                        throw makeFormErrors({
                            [getBrandingPropName('eventCustomLink')]: t(
                                'business.brandingPage.linksOrganizationNameExist',
                            ),
                        });
                    }

                    enqueueSnackbar(t('unknownError'));
                }
            }
        },
        [
            organizationId,
            initialValues,
            onBrandingUpdate,
            smtpDispatch,
            smtpUpdate,
            history,
            track,
            t,
            enqueueSnackbar,
        ],
    );

    useEffect(() => {
        if (smtpError) {
            enqueueSnackbar(t('unknownError'));
        }
    }, [smtpError, t, enqueueSnackbar]);

    if (!smtpFulfilled) {
        return <LoadingScreen />;
    }

    return initialValues ? (
        <BrandingForm
            defaultValues={initialValues}
            onSubmit={onSave}
            customizableAgreements={customizableAgreements}
        />
    ) : null;
};

export default Branding;

const getMixpanelData = (data: BrandingFormValues) => {
    const {
        interfaceLogoImage,
        landingLogoImage,
        eventPauseImage,
        eventExitLink,
        eventButtonAndLinkColor,
        isSignature,
        eventOrganizationTitle,
        isTemplateInvitation,
    } = data.branding;

    return {
        branding_after: Boolean(eventExitLink) || undefined,
        branding_color: Boolean(eventButtonAndLinkColor) || undefined,
        branding_signature_add: isSignature || undefined,
        branding_name: Boolean(eventOrganizationTitle) || undefined,
        branding_logo_add: interfaceLogoImage instanceof File || undefined,
        branding_logo_dark_add: landingLogoImage instanceof File || undefined,
        branding_pause: eventPauseImage instanceof File || undefined,
        branding_htmlinvitation: isTemplateInvitation,
    };
};
