import debounce from 'lodash/debounce';
import { useEffect, useRef } from 'react';

interface Props {
    template: string;
}

const EmailTemplatePreview = ({ template }: Props) => {
    const iframeRef = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
        const element = iframeRef.current;

        if (element && element.contentDocument) {
            const doc = element.contentDocument;

            doc.write(template);
            element.style.width = '100%';
            element.style.border = 'none';

            const setHeight = debounce(() => {
                if (element.contentDocument) {
                    element.style.height = doc.documentElement.scrollHeight + 20 + 'px';
                }
            }, 200);

            const images = doc.querySelectorAll('img');

            images.forEach((image) => {
                image.onload = setHeight;
                image.onerror = setHeight;
            });

            setHeight();
        }
    }, [template]);

    return (
        <iframe
            ref={iframeRef}
            title="template-preview"
            allow="none"
            referrerPolicy="no-referrer"
            sandbox="allow-same-origin"
        >
            Your browser does not support iframes
        </iframe>
    );
};

export default EmailTemplatePreview;
