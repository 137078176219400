import { useTranslation } from 'react-i18next';

import { ActionDialogBase, DialogActions, Button, createTestIdProps } from '@wbnr/ui';

const BASE_TEST_ID = 'ValidationSuccessfulModal';

type Props = {
    opened: boolean;
    onClose: () => void;
};

export const ValidationSuccessfulModal = ({ opened, onClose }: Props) => {
    const { t } = useTranslation();
    return (
        <ActionDialogBase
            opened={opened}
            onClose={onClose}
            title={t('business.sso.saml.validationSuccessfulModal.title')}
            {...createTestIdProps(BASE_TEST_ID)}
        >
            <DialogActions disableGutters>
                <Button
                    onClick={onClose}
                    variant="contained"
                    color="primary"
                    {...createTestIdProps(BASE_TEST_ID, 'close-button')}
                >
                    {t('business.sso.saml.validationSuccessfulModal.button')}
                </Button>
            </DialogActions>
        </ActionDialogBase>
    );
};
