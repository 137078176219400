import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router';

import { isOrganizationAdmin, useAccountUser } from '@wbnr/frontend-shared/lib/data/user';

import { SSOContextProvider } from './SSOContext';
import { SSOSettings } from './SSOSettings';

export const SSOSettingsPage = () => {
    const { t } = useTranslation();
    const [user] = useAccountUser();
    const isAdmin = useMemo(() => isOrganizationAdmin(user), [user]);
    if (!isAdmin) {
        return <Redirect to="/business/tariffs" />;
    }
    return (
        <SSOContextProvider>
            <Helmet>
                <title>{t('nav.ssoSettings')} - Webinar</title>
            </Helmet>
            <SSOSettings />
        </SSOContextProvider>
    );
};
