import { useState, useMemo, useCallback } from 'react';

import { IS_BOX_MODE } from '@wbnr/frontend-shared/lib/config';
import { useFeatureSettings } from '@wbnr/frontend-shared/lib/data/featureSettings';
import { useUser, isMeetingsAvailable } from '@wbnr/frontend-shared/lib/data/user';
import { appLocalStorage } from '@wbnr/frontend-shared/lib/utils/browserStorage';

const ENDLESS_MEETINGS_KEY = 'endless_meetings_closed';

export const useEndlessMeetingsBanner = ({ pathname }) => {
    const [show, setShow] = useState(true);
    const hasMeetings = useMeetingsAvailable();
    const { data: featureSettings } = useFeatureSettings();
    const { isEndlessMeetingsEnabled } = featureSettings;

    const isClosed = Boolean(appLocalStorage.getItem(ENDLESS_MEETINGS_KEY));
    const isEventEditorPage = /^\/event\/\d+\/?\d*\/edit$/.test(pathname);

    const handleClose = useCallback(() => {
        setShow(false);
        appLocalStorage.setItem(ENDLESS_MEETINGS_KEY, true);
    }, [setShow]);

    return useMemo(
        () => ({
            show:
                show &&
                isEventEditorPage &&
                !isClosed &&
                hasMeetings &&
                isEndlessMeetingsEnabled &&
                !IS_BOX_MODE,
            onClose: handleClose,
        }),
        [show, handleClose, isEventEditorPage, isClosed, hasMeetings, isEndlessMeetingsEnabled],
    );
};

const useMeetingsAvailable = () => {
    const [user] = useUser();
    const { activeProlongation } = user || {};
    const { data } = activeProlongation || {};

    if (!data) {
        return false;
    }

    try {
        const parsedProlongation = JSON.parse(data);

        return isMeetingsAvailable(parsedProlongation);
    } catch {
        return false;
    }
};
