"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "StylesProvider", {
  enumerable: true,
  get: function get() {
    return _styles.StylesProvider;
  }
});
Object.defineProperty(exports, "ThemeProvider", {
  enumerable: true,
  get: function get() {
    return _styles.MuiThemeProvider;
  }
});
Object.defineProperty(exports, "createTheme", {
  enumerable: true,
  get: function get() {
    return _styles.createMuiTheme;
  }
});
Object.defineProperty(exports, "makeStyles", {
  enumerable: true,
  get: function get() {
    return _styles.makeStyles;
  }
});
Object.defineProperty(exports, "useTheme", {
  enumerable: true,
  get: function get() {
    return _styles.useTheme;
  }
});
var _styles = require("@material-ui/core/styles");