const translations = {
    ru: {
        tariffs: 'Тарифы',
        month: '{{count}} МЕС',
    },
    en: {
        tariffs: 'Tariffs',
        month: '{{count}} mon',
    },
};

export default translations;
