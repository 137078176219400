import { Switch, Route } from 'react-router';

import { useFeatureSettings } from '@wbnr/frontend-shared/lib/data/featureSettings';
import { useAccountUser } from '@wbnr/frontend-shared/lib/data/user';

import { LazyKnockout as Knockout } from 'containers/KnockoutAdapter/LazyKnockout';

import { ApiAndWebhooksPage } from './apiAndWebhooks/ApiAndWebhooksPage';
import { MyDeviceSettingsPage } from './devices';
import { IntegrationsPage } from './integrations/IntegrationsPage';
import Multibranding from './multibranding';
import { OrganizationPage } from './organization';
import { PointOfPresence } from './pop';
import Proctoring from './proctoring';
import { SIEMSettingsPage } from './siem';
import { SSOSettingsPage } from './sso/SSOSettingsPage';
import Tariffs from './tariffs';

const BusinessRoute = () => {
    const { data: features } = useFeatureSettings();
    const [user] = useAccountUser();

    return (
        <Switch>
            {features?.newTariffsPage && user.isSelfService && (
                <Route path={'/business/buytariffs'} component={Tariffs} />
            )}
            <Route path={'/business/sso-settings'} component={SSOSettingsPage} />
            <Route path={'/business/siem-settings'} component={SIEMSettingsPage} />
            <Route path={'/business/proctoring'} component={Proctoring} />
            <Route path={'/business/branding/:id?'} component={Multibranding} />
            <Route path={'/business/api'} component={ApiAndWebhooksPage} />
            <Route path={'/business/webhooks'} component={ApiAndWebhooksPage} />
            <Route path={'/business/integrations'} component={IntegrationsPage} />
            <Route path={'/business/point-of-presence'} component={PointOfPresence} />
            <Route path={'/business/payment/:result'} component={Knockout} />
            <Route path={'/business/my-devices'} component={MyDeviceSettingsPage} />
            <Route path={'/business/organization'} component={OrganizationPage} />
            <Route path={'/business/:part/:extraParam?'} component={Knockout} />
        </Switch>
    );
};

export default BusinessRoute;
