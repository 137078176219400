import classNames from 'classnames';
import { useState, Children, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useMixpanelTracking } from '@wbnr/frontend-shared/lib/analytics/mixpanel/useMixpanelTracking';
import { injectTranslations } from '@wbnr/frontend-shared/lib/utils/i18n';
import { ArrowBackIosIcon as Arrow } from '@wbnr/icons';
import { Button, ButtonsGroup, IconButton } from '@wbnr/ui';

import styles from './Carousel.module.scss';
import translations from './translations';

type CarouselProps = {
    hasFreeTariff: boolean;
    children: JSX.Element[];
};

const TARIFFS_CAROUSEL_NS = 'carousel';
injectTranslations(TARIFFS_CAROUSEL_NS, translations);

const MAX_WIDTH = 900;
const MIN_WIDTH = 600;
const STEP = 300;
const DURATIONS = [1, 3, 6, 12];
const WIDTH_DIFFERENCE_WITH_FREE = 615;

const Carousel = ({ children, hasFreeTariff }: CarouselProps) => {
    const widthDifference = hasFreeTariff ? WIDTH_DIFFERENCE_WITH_FREE : STEP;
    const [translate, setTranslate] = useState<number>(0);
    const [activeTab, setActiveTab] = useState<number>(1);
    const [carouselWidth, setCarouselWidth] = useState<number>(window.innerWidth - widthDifference);
    const { track } = useMixpanelTracking();
    const { t } = useTranslation(TARIFFS_CAROUSEL_NS);
    const childrenLength = Children.count(children);
    const countCardsInSlider = carouselWidth >= MAX_WIDTH ? 3 : carouselWidth <= MIN_WIDTH ? 1 : 2;
    const isDisableRight = translate <= -STEP * (childrenLength - countCardsInSlider);

    children = Children.map(children, (child, i) => ({
        ...child,
        props: {
            ...child.props,
            duration: activeTab,
            isVisible: -translate < (i + 1) * STEP,
        },
    }));

    useEffect(() => {
        const handleResize = () => {
            setCarouselWidth(window.innerWidth - widthDifference);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [widthDifference]);

    const moveRight = () => {
        setTranslate((tX) => {
            const newTranslate = Math.max(
                tX - STEP,
                -(STEP * (childrenLength - countCardsInSlider)),
            );
            return newTranslate;
        });
    };

    const moveLeft = () => {
        setTranslate((tX) => {
            const newTranslate = Math.min(tX + STEP, 0);
            return newTranslate;
        });
    };

    const handleDurationSelect = (period: number) => {
        setActiveTab(period);
        track('tariff_period', { period });
    };

    return (
        <div className={styles.mainContainer}>
            <div className={styles.controlls}>
                <p className={styles.pageName}>{t('tariffs')}</p>
                <ButtonsGroup activeIndex={activeTab}>
                    {DURATIONS.map((count) => (
                        <Button
                            key={count}
                            onClick={() => handleDurationSelect(count)}
                            value={count}
                        >
                            {t('month', { count })}
                        </Button>
                    ))}
                </ButtonsGroup>
                <div className={styles.sliderControl}>
                    <IconButton
                        className={classNames(styles.arrowBtn, styles.specifity, {
                            [styles.disable]: translate === 0,
                        })}
                        onClick={moveLeft}
                    >
                        <Arrow
                            className={classNames(styles.arrow, styles.specifity)}
                            color={translate === 0 ? 'disabled' : 'primary'}
                        />
                    </IconButton>
                    <IconButton
                        className={classNames(styles.arrowBtn, styles.specifity, {
                            [styles.disable]: isDisableRight,
                        })}
                        onClick={moveRight}
                    >
                        <Arrow
                            className={classNames(styles.arrow, styles.specifity)}
                            color={isDisableRight ? 'disabled' : 'primary'}
                        />
                    </IconButton>
                </div>
            </div>
            <div className={styles.window} style={{ width: `${carouselWidth}px` }}>
                <div
                    className={classNames(styles.allChildren, {
                        [styles.leftCardBorderRadius]: !hasFreeTariff,
                    })}
                    style={{ transform: `translateX(${translate}px)` }}
                >
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Carousel;
