import { useTranslation } from 'react-i18next';

import { useField, useFormContext } from '@wbnr/frontend-shared/lib/forms';
import { BrandingFull } from '@wbnr/frontend-shared/lib/types/Branding';
import { VisibilityIcon } from '@wbnr/icons';
import { Button, IconButton, Tooltip, Typography } from '@wbnr/ui';

import { useTemplateDialog } from '../../hooks/useTemplateDialog';
import { getBrandingPropName } from '../../utils';

import styles from './EmailTemplate.module.scss';

interface Props {
    fieldName: keyof Pick<BrandingFull, 'emailTemplateInvitation' | 'emailTemplate'>;
    show: boolean;
    defaultTemplate: string;
    macros: { term: string; definition: string }[];
}

const i18nPath = 'business.brandingPage';

const EmailTemplate = ({ fieldName, show, defaultTemplate, macros }: Props) => {
    const { t } = useTranslation();

    const { control } = useFormContext();

    const templateField = useField(control, getBrandingPropName(fieldName));

    const [openTemplateDialog] = useTemplateDialog();

    if (!show) {
        return null;
    }

    return (
        <>
            <div className={styles.wrapper}>
                <textarea
                    {...templateField}
                    placeholder={t(`${i18nPath}.template`)}
                    className={styles.template}
                />

                <div className={styles.controls}>
                    {defaultTemplate !== templateField.value && (
                        <Button
                            color="inherit"
                            onClick={() => templateField.onChange(defaultTemplate)}
                            className={styles.default}
                        >
                            {t(`${i18nPath}.reset`)}
                        </Button>
                    )}

                    <Tooltip title={t(`${i18nPath}.showTemplateResult`) || ''}>
                        <IconButton
                            color="inherit"
                            onClick={() =>
                                openTemplateDialog({
                                    template: templateField.value,
                                })
                            }
                            className={styles.show}
                        >
                            <VisibilityIcon color="inherit" />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>

            <Typography variant="body2" component="p">
                {t(`${i18nPath}.macros.title`)}
            </Typography>

            <div className={styles.macros}>
                {macros.map(({ term, definition }) => (
                    <Macro key={term} term={term} definition={definition} />
                ))}
            </div>
        </>
    );
};

const Macro = ({ term, definition }: { term: string; definition: string }) => {
    return (
        <Typography variant="body2" component="p" color="textSecondary">
            <Typography variant="body2" component="span" color="primary">
                {`{{${term}}}`}
            </Typography>{' '}
            — {definition}
        </Typography>
    );
};

export default EmailTemplate;
