import { useMemo } from 'react';

import { getSMTP } from '@wbnr/frontend-shared/lib/api/smtp';
import { ResetStateAction, useResource } from '@wbnr/frontend-shared/lib/data/useResource';
import { useBinded } from '@wbnr/frontend-shared/lib/hooks/useBinded';
import { ReducerAction } from '@wbnr/frontend-shared/lib/types/ReducerAction';
import { SMTP } from '@wbnr/frontend-shared/lib/types/Smtp';

export const useSMTPResource = (brandingId: number) => {
    const isNewBranding = brandingId === -1;

    const resource = useResource(
        {
            request: useBinded(!isNewBranding ? getSMTP : undefined, brandingId),
        },
        reducer,
    );

    return {
        ...resource,
        data: useMemo(() => {
            if (isNewBranding) {
                return getSMTPInitialValues();
            }

            return resource.data;
        }, [isNewBranding, resource.data]),
    };
};

function reducer(
    state: SMTP | null = null,
    action: ReducerAction<'reset'> | ResetStateAction<SMTP, any>,
) {
    if (!state) {
        return state;
    }

    switch (action.type) {
        case 'reset':
            return null;

        default:
            throw new Error(`Unsupported action type ${action.type}`);
    }
}

function getSMTPInitialValues(): SMTP {
    return {
        isActive: false,
        login: '',
        password: '',
        email: '',
        name: '',
        host: '',
        ssl: false,
    };
}
