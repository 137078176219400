"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useRequestAnimationFrameThrottle = void 0;
var _react = require("react");
var useRequestAnimationFrameThrottle = function useRequestAnimationFrameThrottle(callback) {
  var rafRef = (0, _react.useRef)();
  var throttledCallback = (0, _react.useCallback)(function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    if (!rafRef.current) {
      rafRef.current = requestAnimationFrame(function () {
        callback.apply(void 0, args);
        rafRef.current = undefined;
      });
    }
  }, [callback]);
  return throttledCallback;
};
exports.useRequestAnimationFrameThrottle = useRequestAnimationFrameThrottle;