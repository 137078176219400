import React, { FC } from 'react';

import { CssBaseline } from '@wbnr/ui';
import './Root.scss';

const Root: FC = ({ children }) => {
    return (
        <React.Fragment>
            <CssBaseline />
            {children}
        </React.Fragment>
    );
};

export default Root;
