import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthButtons, AuthForm, AuthTextField } from '@wbnr/frontend-shared/lib/components/auth';
import { useField, useForm } from '@wbnr/frontend-shared/lib/forms';
import { ComponentTestIdProps, createTestIdProps, dataTestIdKey, Typography } from '@wbnr/ui';

import styles from './PasswordScreen.module.scss';

type Props = {
    onSubmit: (data: { password: string }) => Promise<void>;
} & ComponentTestIdProps;

const i18nPath = 'organization.invitation.link';

const PasswordScreen = ({ [dataTestIdKey]: dataTestId, onSubmit }: Props) => {
    const { t } = useTranslation();

    const { control, submit } = useForm(
        {
            defaultValues: {
                password: '',
            },
        },
        {
            onSubmit,
        },
    );

    const passwordField = useField(control, 'password', {
        rules: {
            required: true,
        },
    });

    return (
        <AuthForm {...createTestIdProps(dataTestId, 'form')}>
            <Typography paragraph>{t(`${i18nPath}.enterPassword`)}</Typography>

            <AuthTextField
                label={t(`${i18nPath}.password`)}
                helperText={t(`${i18nPath}.passwordHelperText`)}
                {...passwordField}
                {...createTestIdProps(dataTestId, 'field', 'password')}
            />

            <div className={styles.top} />
            <AuthButtons
                main={useMemo(
                    () => ({
                        text: t('continue'),
                        onClick: submit,
                        ...createTestIdProps(dataTestId, 'action', 'submit'),
                    }),
                    [dataTestId, submit, t],
                )}
            />
        </AuthForm>
    );
};

export default PasswordScreen;
