import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory } from 'react-router';

import { useFeatureSettings } from '@wbnr/frontend-shared/lib/data/featureSettings';
import { Tabs, Tab } from '@wbnr/ui';

import BusinessLayout from '../BusinessLayout';

import { Api } from './API';
import styles from './ApiAndWebhooks.module.scss';
import { Webhooks } from './Webhooks';

const TABS = [
    {
        nameKey: 'business.apiPage.title',
        route: '/business/api',
    },
    {
        nameKey: 'business.webhooksPage.title',
        route: '/business/webhooks',
    },
];

export const ApiAndWebhooks = () => {
    const history = useHistory();
    const { t } = useTranslation();

    const { data: features } = useFeatureSettings();
    const { webhooks: webhooksAvailable } = features;

    const handleChangeTab = (
        event: React.ChangeEvent<Record<string, unknown>>,
        newRoute: string,
    ) => {
        history.push(newRoute);
    };

    const renderTabContent = () => {
        return (
            <Switch>
                {webhooksAvailable && <Route path={'/business/webhooks'} component={Webhooks} />}
                <Route path={'/business/api'} component={Api} />
            </Switch>
        );
    };

    return (
        <BusinessLayout className={styles.content}>
            {webhooksAvailable && (
                <Tabs
                    indicatorColor="primary"
                    value={history.location.pathname}
                    onChange={handleChangeTab}
                    classes={{
                        root: styles.tabsRoot,
                        flexContainer: styles.tabsWrapper,
                    }}
                >
                    {TABS.map((tab) => (
                        <Tab
                            key={tab.route}
                            value={tab.route}
                            label={t(tab.nameKey)}
                            classes={{
                                root: styles.tabRoot,
                            }}
                            className={styles.tabWrapper}
                        />
                    ))}
                </Tabs>
            )}
            <div className={classnames(styles.root)}>{renderTabContent()}</div>
        </BusinessLayout>
    );
};
