import cn from 'classnames';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BannersContext } from '@wbnr/frontend-shared/lib/banners/context';
import WebinarPageTopbar from '@wbnr/frontend-shared/lib/components/WebinarPageTopbar';
import { makeStyles, SidebarLayout, TopbarLayout } from '@wbnr/ui';

import styles from './BusinessLayout.module.scss';
import BusinessNavigation from './BusinessNavigation';

interface Props {
    children: React.ReactNode;
    className?: string;
}

const useStyles = makeStyles(() => ({
    root: {
        height: (props: { offsetTop?: string }) => `calc(100% - ${props.offsetTop})` || '100%',
    },
}));

const BusinessLayout = ({ children, className }: Props) => {
    const { t } = useTranslation();
    const { bannerHeight } = useContext(BannersContext);
    const localStyles = useStyles({ offsetTop: bannerHeight });
    return (
        <TopbarLayout
            topbar={
                <WebinarPageTopbar
                    title={t('business.title')}
                    showControls
                    offsetTop={bannerHeight}
                />
            }
            className={localStyles.root}
            offsetTop={bannerHeight}
        >
            <SidebarLayout
                side="left"
                bar={<BusinessNavigation />}
                barClassName={styles.bar}
                contentClassName={cn(styles.content, className)}
            >
                {children}
            </SidebarLayout>
        </TopbarLayout>
    );
};

export default BusinessLayout;
