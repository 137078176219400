import { Trans } from 'react-i18next';

import { A, Button, Typography } from '@wbnr/ui';

import styles from './NotFoundPage.module.scss';

const INSTRUCTIONS_LINK = 'https://help.webinar.ru/ru/collections/161580-помощь-участникам';

const NotFoundPage = () => (
    <div className={styles.root}>
        <Typography variant="h3" component="h1" color="textSecondary" align="center">
            <Trans i18nKey="pageNotFound.title" />
        </Typography>
        <p className={styles.comment}>
            <Trans i18nKey="pageNotFound.comment" />
        </p>
        <Button variant="contained" color="primary" href={INSTRUCTIONS_LINK}>
            <Trans i18nKey="pageNotFound.goToInstructions" />
        </Button>
        <A href="https://webinar.ru" className={styles.siteLink}>
            <Typography color="primary">
                <Trans i18nKey="pageNotFound.goToSite" />
            </Typography>
        </A>
    </div>
);

export default NotFoundPage;
