import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { getOrganizationById } from '@wbnr/frontend-shared/lib/api/business-api';
import { getUserOrganizationId, useAccountUser } from '@wbnr/frontend-shared/lib/data/user';
import { useResource } from '@wbnr/frontend-shared/lib/data/useResource';
import { useBinded } from '@wbnr/frontend-shared/lib/hooks/useBinded';
import { injectTranslations } from '@wbnr/frontend-shared/lib/utils/i18n';
import { CircularProgress } from '@wbnr/ui';

import BusinessLayout from '../BusinessLayout';

import { TRANS_NS } from './constants';
import styles from './Organization.module.scss';
import { OrganizationForm } from './OrganizationForm';
import translations from './translations.json';

injectTranslations(TRANS_NS, translations);

export const Organization = () => {
    const [user] = useAccountUser();
    const { t } = useTranslation(TRANS_NS);
    const organizationId = getUserOrganizationId(user);
    const { data: organization, loading } = useResource({
        request: useBinded(getOrganizationById, organizationId),
    });

    if (loading || !organization) {
        return (
            <div className={styles.spinner}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>{t('title')}</title>
            </Helmet>

            <BusinessLayout>
                <div className={styles.topSection}>
                    <OrganizationForm organization={organization} />
                    <div className={styles.files}></div>
                </div>
            </BusinessLayout>
        </>
    );
};
