const translations = {
    ru: {
        logos: 'Логотипы',
        menu: 'Используйте свой логотип в меню сервиса. Рекомендуем использовать изображение размером 200 х 50 пикселей в формате SVG, PNG, TIFF, JPG.',
        landingsAndRecords:
            'Для лендингов и записей используйте светлую версию логотипа. Рекомендуем использовать изображение размером 200 х 50 пикселей в формате SVG, PNG, TIFF, JPG.',
        logoLink: 'Ссылка для логотипа',
    },
    en: {
        logos: 'Logotypes',
        menu: 'Use your logo оf a service menu. We recommend using image with size 200 x 50 pixel image in SVG, PNG, TIFF, JPG format.',
        landingsAndRecords:
            'For landing pages and posts, use the light version of the logo. We recommend using a 200 x 50 pixel image in SVG, PNG, TIFF, JPG format.',
        logoLink: 'Link for logotype',
    },
};

export default translations;
