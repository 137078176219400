export var appLocalStorage = {
  setItem: function setItem(key, value) {
    try {
      window.localStorage.setItem(key, value);
    } catch (e) {
      console.error(e);
      return false;
    }
    return true;
  },
  getItem: function getItem(key) {
    return window.localStorage.getItem(key);
  },
  removeItem: function removeItem(key) {
    try {
      window.localStorage.removeItem(key);
    } catch (e) {
      console.error(e);
    }
  }
};
export var appSessionStorage = {
  setItem: function setItem(key, value) {
    try {
      window.sessionStorage.setItem(key, value);
    } catch (e) {
      console.error(e);
      return false;
    }
    return true;
  },
  getItem: function getItem(key) {
    return window.sessionStorage.getItem(key);
  },
  removeItem: function removeItem(key) {
    try {
      window.sessionStorage.removeItem(key);
    } catch (e) {
      console.error(e);
    }
  }
};