import qs from 'qs';
import { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

import { AuthTextField } from '@wbnr/frontend-shared/lib/components/auth';
import { useSSOSignIn } from '@wbnr/frontend-shared/lib/hooks/useSSOSignIn';
import { SSOOrganization } from '@wbnr/frontend-shared/lib/types/User';
import {
    ComponentTestIdProps,
    createTestIdProps,
    MenuItem,
    SelectField,
    Typography,
    dataTestIdKey,
} from '@wbnr/ui';

import { FormButtons } from '../FormButtons';

import styles from './AuthFormOrganization.module.scss';

type Props = {
    isSignUp?: boolean;
} & ComponentTestIdProps;

type LocationState = {
    email?: string;
    referrer?: string;
};

export const AuthFormOrganization = ({ isSignUp, [dataTestIdKey]: baseTestId }: Props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { search, state } = useLocation<LocationState>();
    const params = useMemo(() => qs.parse(search, { ignoreQueryPrefix: true }), [search]);
    const defaultEmail = (typeof params?.email === 'string' ? params.email : '') || state?.email;
    const referrer =
        (typeof params?.referrer === 'string' ? params.referrer : '') || state?.referrer;

    const redirectToSSOLogin = useCallback(
        (organization: SSOOrganization) => {
            const returnUrl = referrer || document.location.href;
            window.location.href = `${organization.redirectUrl}&returnUrl=${encodeURIComponent(
                returnUrl,
            )}`;
        },
        [referrer],
    );

    const { emailField, currentEmail, organizations, organizationField, setOrganizations, submit } =
        useSSOSignIn({
            redirectToSSOLogin,
            defaultEmail,
        });

    const handleBack = useCallback(() => {
        if (organizations.length > 0) {
            setOrganizations([]);
        } else if (isSignUp) {
            if (history.location.key) {
                history.goBack();
            } else if (referrer) {
                window.location.pathname = referrer;
            } else {
                window.location.pathname = 'https://webinar.ru/#form';
            }
        } else {
            history.push('/signin', history.location.pathname);
        }
    }, [organizations, history, setOrganizations, isSignUp, referrer]);

    const backButtonText = useMemo(
        () => (organizations.length || isSignUp ? t('auth.sso.back') : t('auth.sso.signInByEmail')),
        [organizations.length, isSignUp, t],
    );

    return (
        <div className={styles.root}>
            {isSignUp && (
                <Typography variant="body2" className={styles.subtitle}>
                    <Trans i18nKey={'auth.ssoSignup.subtitle'} />
                </Typography>
            )}
            {organizations.length === 0 ? (
                <AuthTextField
                    {...emailField}
                    autoFocus
                    type={'email'}
                    label={t('auth.sso.email')}
                    {...createTestIdProps(baseTestId, 'email')}
                />
            ) : (
                <Typography variant="body1" className={styles.email}>
                    {currentEmail}
                </Typography>
            )}
            {organizations.length > 0 && (
                <SelectField
                    {...organizationField}
                    fullWidth
                    label={t('auth.sso.organization')}
                    {...createTestIdProps(baseTestId, 'organization')}
                    FormHelperTextProps={{ defaultColor: true }}
                >
                    {organizations.map(({ organizationId, name }) => (
                        <MenuItem key={organizationId} value={organizationId}>
                            {name}
                        </MenuItem>
                    ))}
                </SelectField>
            )}
            <FormButtons
                {...createTestIdProps(baseTestId, 'action')}
                submitButtonText={t('continue')}
                backButtonText={backButtonText}
                onBackClick={handleBack}
                onSubmitClick={submit}
            />
        </div>
    );
};
