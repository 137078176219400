import classnames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { WebhooksListItem } from '@wbnr/frontend-shared/lib/api/business-api/types';
import LoadingScreen from '@wbnr/frontend-shared/lib/components/LoadingScreen';
import { useSortedData } from '@wbnr/frontend-shared/lib/hooks/useSortedData';
import { SortOrderType } from '@wbnr/frontend-shared/lib/types/CollectionRequestParams';
import { DeleteOutlinedIcon } from '@wbnr/icons';
import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    SwitchField,
    Typography,
    Tooltip,
} from '@wbnr/ui';

import { HeadCell, SortingColumn } from './types';
import { getCellProps } from './utils/getCellProps';
import { isSortingColumn } from './utils/isSortingColumn';
import { getWebhooksComparator } from './utils/sorting';
import styles from './WebhooksList.module.scss';

interface WebhooksListProps {
    webhooks?: WebhooksListItem[] | null;
    loading: boolean;
    webhooksLimit: number;
    webhooksLimitReached: boolean;
    deleteWebhook(webhookId: number): void;
    enableWebhook(webhookId: number): void;
    disableWebhook(webhookId: number): void;
}

export const WebhooksList = ({
    webhooks,
    loading,
    webhooksLimit,
    webhooksLimitReached,
    deleteWebhook,
    enableWebhook,
    disableWebhook,
}: WebhooksListProps) => {
    const { t } = useTranslation();
    const [order, setOrder] = useState<SortOrderType>('asc');
    const [orderBy, setOrderBy] = useState<SortingColumn>('createdAt');

    const sortedWebhooks = useSortedData<WebhooksListItem>(webhooks, [
        getWebhooksComparator(order, orderBy),
    ]);

    if (loading) {
        return (
            <div>
                <LoadingScreen />
            </div>
        );
    }

    if (!sortedWebhooks) {
        return null;
    }

    const handleRequestSort = (column: SortingColumn) => {
        const isAsc = orderBy === column && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(column);
    };

    const headCells: HeadCell[] = [
        { id: 'isEnabled', label: null },
        { id: 'name', label: t('business.webhooksPage.list.title') },
        { id: 'endpointUrl', label: t('business.webhooksPage.list.endpoint') },
        { id: 'createdAt', label: t('business.webhooksPage.list.dateCreated') },
        { id: 'delete', label: null },
    ];

    return (
        <div className={styles.root}>
            <Typography
                color={!webhooksLimitReached ? 'textSecondary' : 'error'}
                className={styles.counter}
                variant="body2"
            >
                {t('business.webhooksPage.counter', {
                    count: sortedWebhooks.length,
                    limit: webhooksLimit,
                })}
                {webhooksLimitReached && <> {t('business.webhooksPage.counterWarning')}</>}
            </Typography>

            <TableContainer>
                <Table
                    size="medium"
                    padding="normal"
                    aria-label="webhooks table"
                    classes={{
                        root: styles.table,
                    }}
                    className={styles.tableWrapper}
                >
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => {
                                const { id: columnId } = headCell;

                                const title = (
                                    <Typography color="textSecondary" className={styles.headCell}>
                                        {headCell.label}
                                    </Typography>
                                );

                                const props = getCellProps(headCell.id);

                                return (
                                    <TableCell
                                        key={headCell.id}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                        {...props}
                                        className={classnames(
                                            styles.headCellWrapper,
                                            props.className,
                                        )}
                                    >
                                        {isSortingColumn(columnId) ? (
                                            <TableSortLabel
                                                active
                                                direction={order}
                                                onClick={() => handleRequestSort(columnId)}
                                            >
                                                {title}
                                            </TableSortLabel>
                                        ) : (
                                            title
                                        )}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedWebhooks.map((webhook) => {
                            return (
                                <TableRow key={webhook.id} className={styles.row} hover>
                                    <TableCell {...getCellProps('isEnabled')}>
                                        <SwitchField
                                            value={webhook.isEnabled}
                                            onChange={(isEnabled) =>
                                                isEnabled
                                                    ? enableWebhook(webhook.id)
                                                    : disableWebhook(webhook.id)
                                            }
                                            label={null}
                                            className={styles.switchWrapper}
                                        />
                                    </TableCell>
                                    <TableCell {...getCellProps('name')}>
                                        <Tooltip title={webhook.name}>
                                            <span>{webhook.name}</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell {...getCellProps('endpointUrl')}>
                                        <Tooltip title={webhook.endpointUrl}>
                                            <span>{webhook.endpointUrl}</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell {...getCellProps('createdAt')}>
                                        {t('business.webhooksPage.list.dateCreatedValue', {
                                            date: new Date(webhook.createdAt),
                                        })}
                                    </TableCell>
                                    <TableCell {...getCellProps('delete')}>
                                        <IconButton
                                            color="default"
                                            classes={{ root: styles.delete }}
                                            className={styles.deleteWrapper}
                                            onClick={() => deleteWebhook(webhook.id)}
                                        >
                                            <DeleteOutlinedIcon color="inherit" />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
