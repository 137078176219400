import { useTranslation, Trans } from 'react-i18next';

import { IS_BOX_MODE } from '@wbnr/frontend-shared/lib/config';
import { ActionDialogBase, DialogActions, Button, A, DialogContentText } from '@wbnr/ui';

import { errorTypeTextKeys } from './errorTypeTextKeys';

export const WebrtcWarningHelpDialog = ({
    errorType,
    closeDialog,
    opened,
}: {
    errorType: keyof typeof errorTypeTextKeys;
    closeDialog: () => void;
    opened: boolean;
}) => {
    const { t } = useTranslation();
    const { title = '', text = '' } = errorTypeTextKeys[errorType] || {};

    return (
        <ActionDialogBase
            maxWidth={'xl'}
            opened={opened}
            onClose={closeDialog}
            title={t(title)}
            actions={
                <DialogActions>
                    <A
                        target="_blank"
                        href={
                            errorType === 'browser'
                                ? 'https://www.google.com/chrome/'
                                : 'https://help.webinar.ru/ru/articles/1518973-технические-требования'
                        }
                    >
                        <Button onClick={closeDialog}>
                            {t(
                                errorType === 'browser'
                                    ? 'banners.webrtcTestDownloadChrome'
                                    : 'banners.webrtcTestHelp',
                            )}
                        </Button>
                    </A>
                    {window.Intercom && !IS_BOX_MODE && (
                        <Button
                            onClick={() => {
                                closeDialog();
                            }}
                        >
                            {t('banners.webrtcTestContactSupport')}
                        </Button>
                    )}
                </DialogActions>
            }
        >
            <DialogContentText>{Array.isArray(text) ? t(...text) : t(text)}</DialogContentText>
            {window.Intercom && !IS_BOX_MODE && (
                <DialogContentText>
                    <Trans
                        i18nKey={'banners.webrtcTestSupportContacts'}
                        components={[
                            <a key="webrtcTestSupportContacts" href="mailto:support@webinar.ru">
                                support@webinar.ru
                            </a>,
                        ]}
                    />
                </DialogContentText>
            )}
        </ActionDialogBase>
    );
};
