const translations = {
    ru: {
        title: 'Запрос на выставление счета',
        description: {
            tariffName: 'Тариф {{tariffName}} на {{duration}} мес.',
            price: '{{price}} руб.',
            inputDetails: 'Для выставления счёта укажите свои реквизиты.',
        },
        fields: {
            inn: 'ИНН',
            kpp: 'КПП',
            companyName: 'Наименование организации',
            address: 'Юридический адрес',
            ceoFullName: 'Генеральный директор',
            email: 'Email',
            emailHelperText: 'На данный адрес отправим счет на оплату',
        },
        getInvoice: 'Получить счет',
        cancel: 'Отмена',
        close: 'Закрыть',
        sent: 'Запрос отправлен',
        invoiceToEmail: 'Счет придёт в течение 1 дня на почту {{currentEmail}}',
        submitError: 'Произошла ошибка. Попробуйте отправить запрос на получение счёта позже',
    },
    en: {
        title: 'Invoice Request',
        description: {
            tariffName: '{{tariffName}} plan for {{duration}} months.',
            price: 'RUB {{price}}.',
            inputDetails: 'Please provide your details for invoicing.',
        },
        fields: {
            inn: 'INN',
            kpp: 'KPP',
            companyName: 'Name of company',
            address: 'Legal address',
            ceoFullName: 'CEO',
            email: 'Email',
            emailHelperText: 'We will send an invoice to this address',
        },
        getInvoice: 'Get invoice',
        cancel: 'Cancel',
        close: 'Сlose',
        sent: 'Request sent',
        invoiceToEmail: 'The invoice will be sent within 1 day to the mail {{currentEmail}}',
        submitError: 'An error has occurred. Please try submitting an invoice request later',
    },
};

export default translations;
