import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
    cloneBranding,
    deleteBranding,
    setDefaultBranding,
} from '@wbnr/frontend-shared/lib/api/branding';
import {
    useBrandingsResource,
    DEFAULT_EMAIL_TEMPLATE_INVITATION,
    DEFAULT_EMAIL_TEMPLATE,
} from '@wbnr/frontend-shared/lib/data/branding';
import { ResetStateAction } from '@wbnr/frontend-shared/lib/data/useResource';
import { useBranding } from '@wbnr/frontend-shared/lib/hooks/useBranding';
import { BrandingFull } from '@wbnr/frontend-shared/lib/types/Branding';
import { ReducerAction } from '@wbnr/frontend-shared/lib/types/ReducerAction';
import { createDate } from '@wbnr/frontend-shared/lib/utils/dates';
import { DEFAULT_BRANDING_COLOR } from '@wbnr/ui';

const i18nPath = 'business.brandingPage';

export const useBrandingsFullResource = (organizationId: number, isBrandingEnabled: boolean) => {
    const history = useHistory();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const {
        data: brandings,
        error,
        loading,
        fulfilled,
        update,
        dispatch,
    } = useBrandingsResource(organizationId, reducer, { preventAutoLoad: true });

    useEffect(() => {
        if (isBrandingEnabled) {
            update();
        }
    }, [isBrandingEnabled, update]);

    const data = useMemo(() => {
        if (!brandings) {
            return brandings;
        }

        return brandings.sort((b1, b2) => {
            const d1: Date = b1.updateAt ? createDate(b1.updateAt) : createDate(b1.createAt);
            const d2: Date = b2.updateAt ? createDate(b2.updateAt) : createDate(b2.createAt);

            return d1 > d2 ? -1 : 1;
        });
    }, [brandings]);

    const onBrandingUpdate = useCallback(async () => {
        await update();
    }, [update]);

    const onCreateBranding = useCallback(() => {
        history.push('/business/branding/-1', { branding: getBrandingFullInitialValues() });
    }, [history]);

    const onDeleteBranding = useCallback(
        (brandingId: number) => {
            return deleteBranding(organizationId, brandingId)
                .then(() => {
                    dispatch({ type: 'delete', payload: brandingId });
                })
                .catch(() => {
                    enqueueSnackbar(t(`${i18nPath}.failed.delete`));
                });
        },
        [organizationId, dispatch, t, enqueueSnackbar],
    );

    const onCloneBranding = useCallback(
        (brandingId: number) => {
            return cloneBranding(brandingId)
                .then((clonedBranding) => {
                    dispatch({ type: 'clone', payload: clonedBranding });
                })
                .catch(() => {
                    enqueueSnackbar(t(`${i18nPath}.failed.clone`));
                });
        },
        [dispatch, t, enqueueSnackbar],
    );

    const onSetDefaultBranding = useCallback(
        (brandingId: number) => {
            return setDefaultBranding(brandingId)
                .then(() => {
                    dispatch({ type: 'default', payload: brandingId });
                })
                .catch(() => {
                    enqueueSnackbar(t(`${i18nPath}.failed.default`));
                });
        },
        [dispatch, t, enqueueSnackbar],
    );

    const defaultBranding = useMemo(
        () => (brandings || []).find(({ isDefault }) => isDefault),
        [brandings],
    );
    const [, , , defaultBrandingDispatch] = useBranding();

    useEffect(() => {
        if (defaultBranding) {
            defaultBrandingDispatch({ type: 'update', payload: defaultBranding });
        }
    }, [defaultBranding, defaultBrandingDispatch]);

    useEffect(() => {
        if (error) {
            enqueueSnackbar(t('unknownError'));
        }
    }, [error, t, enqueueSnackbar]);

    return {
        data,
        loading,
        fulfilled,
        onBrandingUpdate,
        onCreateBranding,
        onDeleteBranding,
        onCloneBranding,
        onSetDefaultBranding,
    };
};

interface TemplateAction<Type, Payload> extends ReducerAction<Type> {
    payload: Payload;
}

type Action =
    | TemplateAction<'delete', number>
    | TemplateAction<'clone', BrandingFull>
    | TemplateAction<'default', number>
    | ResetStateAction<BrandingFull[], any>;

function reducer(state: BrandingFull[] | null = [], action: Action) {
    if (!state) {
        return state;
    }

    switch (action.type) {
        case 'clone':
            return [action.payload, ...state];

        case 'delete':
            return state.filter((branding) => branding.id !== action.payload);

        case 'default':
            return state.map((branding) => {
                const isNewDefaultBranding = branding.id === action.payload;

                if (branding.isDefault || isNewDefaultBranding) {
                    return { ...branding, isDefault: isNewDefaultBranding };
                }

                return branding;
            });

        default:
            throw new Error(`Unsupported action type ${action.type}`);
    }
}

function getBrandingFullInitialValues(): BrandingFull {
    return {
        id: -1,
        title: '',
        isDefault: false,

        emailReplyTo: '',
        emailFromName: '',
        isSignature: false,
        emailSignature: '',
        isTemplateInvitation: false,
        emailTemplateInvitation: DEFAULT_EMAIL_TEMPLATE_INVITATION,
        isTemplate: false,
        emailTemplate: DEFAULT_EMAIL_TEMPLATE,

        eventOrganizationTitle: '',
        eventCustomLink: '',
        eventExitLink: '',
        showSocialButtons: false,
        isQuestions: false,
        questionsEmail: '',

        interfaceLogoLink: '',
        eventButtonAndLinkColor: DEFAULT_BRANDING_COLOR.replace(/#/, ''),

        additionalAgreements: [],
        isAgreementsDefaultChecked: false,

        interfaceLogoImage: null,
        landingLogoImage: null,
        eventPauseImage: null,
    };
}
