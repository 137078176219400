import { useTranslation } from 'react-i18next';

import {
    ActionDialogBase,
    DialogActions,
    DialogContentText,
    Button,
    createTestIdProps,
} from '@wbnr/ui';

const baseTestId = 'RefreshApiKeyDialog';

type ConfirmRefreshKeyDialogProps = {
    opened: boolean;
    onSubmit: () => void;
    onClose: () => void;
};

const ConfirmRefreshApiKeyDialog = ({
    opened,
    onSubmit,
    onClose,
}: ConfirmRefreshKeyDialogProps) => {
    const { t } = useTranslation();
    return (
        <ActionDialogBase
            opened={opened}
            title={t('business.apiPage.updateDialog.title')}
            onClose={onClose}
            actions={
                <DialogActions>
                    <Button
                        variant="text"
                        color="primary"
                        onClick={onClose}
                        {...createTestIdProps(baseTestId, 'close')}
                    >
                        {t('business.apiPage.updateDialog.cancel')}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onSubmit}
                        {...createTestIdProps(baseTestId, 'renew')}
                    >
                        {t('business.apiPage.updateDialog.renew')}
                    </Button>
                </DialogActions>
            }
        >
            <DialogContentText>{t('business.apiPage.updateDialog.description')}</DialogContentText>
        </ActionDialogBase>
    );
};

export default ConfirmRefreshApiKeyDialog;
