import { useCallback, useState } from 'react';
import { Trans } from 'react-i18next';

import { Membership } from '@wbnr/frontend-shared/lib/api/organization/types';
import { useFilteredData } from '@wbnr/frontend-shared/lib/hooks/useFilteredData';
import { useSortedData } from '@wbnr/frontend-shared/lib/hooks/useSortedData';
import { SortDescription } from '@wbnr/frontend-shared/lib/utils/sort';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
} from '@wbnr/ui';

import { useSSO } from '../../SSOContext';

import { MembershipItem } from './MembershipItem';
import styles from './MembershipList.module.scss';
import { SearchField } from './SearchField';

export type SortFields = 'email' | 'name';

export const MembershipList = () => {
    const { memberships } = useSSO();
    const [sort, setSort] = useState<SortDescription<SortFields> | null>(null);
    const [query, setQuery] = useState('');
    const filterFn = (item: Membership) => {
        const { email = '', displayName = '' } = item.user || {};

        return [displayName, email].some((str) => str.toLowerCase().includes(query.toLowerCase()));
    };
    const displayedMembers = useFilteredData<Membership, string>(
        memberships || [],
        filterFn,
        query,
    );
    const handleQueryChange = useCallback((val) => setQuery(val), [setQuery]);

    const sortFn = useCallback(
        (memberA: Membership, memberB: Membership) => {
            if (!sort) {
                return 0;
            }
            const {
                user: { email: userAEmail, displayName: userNameA },
            } = memberA;
            const {
                user: { email: userBEmail, displayName: userNameB },
            } = memberB;

            const valueA = sort.by === 'name' ? userNameA.toLowerCase() : userAEmail;
            const valueB = sort.by === 'name' ? userNameB.toLowerCase() : userBEmail;
            const direction = (valueA > valueB && 1) || (valueA < valueB && -1) || 0;

            return sort.asc ? direction : -1 * direction;
        },
        [sort],
    );

    const sortedMembers = useSortedData<Membership>(displayedMembers, [sortFn]);

    const handleSort = (field: SortFields) => () => {
        setSort((prev) => {
            if (prev?.by === field && !prev?.asc) {
                return null;
            }
            if (prev?.by === field && prev) {
                return { by: field, asc: false };
            }
            return { by: field, asc: true };
        });
    };

    return (
        <div className={styles.root}>
            <Typography variant="h6" className={styles.title}>
                <Trans i18nKey={'business.sso.memberships.title'} />
            </Typography>
            <SearchField query={query} onChange={handleQueryChange} />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell className={styles.tableHeadCell}>
                            <TableSortLabel
                                active={sort?.by === 'name'}
                                direction={(sort && (sort.asc ? 'asc' : 'desc')) || undefined}
                                onClick={handleSort('name')}
                                className={styles.text}
                            >
                                <Trans i18nKey={'business.sso.memberships.name'} />
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className={styles.tableHeadCell}>
                            <TableSortLabel
                                active={sort?.by === 'email'}
                                direction={(sort && (sort.asc ? 'asc' : 'desc')) || undefined}
                                onClick={handleSort('email')}
                                className={styles.text}
                            >
                                <Trans i18nKey={'business.sso.memberships.email'} />
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedMembers?.map((member) => (
                        <MembershipItem key={member.id} membership={member} />
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};
