function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { useCallback } from 'react';
import { appLocalStorage } from '../../utils/browserStorage';
import { useOnboarding } from './useOnboarding';
var onboardingLocalStorage = {
  getGroup: function getGroup(groupId) {
    var rawFinishedSteps = appLocalStorage.getItem(groupId) || '{}';
    var finishedSteps = {};
    try {
      finishedSteps = JSON.parse(rawFinishedSteps);
    } catch (e) {
      // catch parse json
    }
    return {
      groupId: groupId,
      steps: finishedSteps
    };
  },
  finishStepInGroup: function finishStepInGroup(groupId, stepId) {
    var group = onboardingLocalStorage.getGroup(groupId);
    appLocalStorage.setItem(groupId, JSON.stringify(_objectSpread(_objectSpread({}, group.steps), {}, _defineProperty({}, stepId, {
      id: stepId,
      finished: true
    }))));
  }
};
export var useOnboardingLocal = function useOnboardingLocal(_ref) {
  var groupId = _ref.groupId,
    stepIds = _ref.stepIds,
    priority = _ref.priority,
    onGroupSkip = _ref.onGroupSkip;
  var loadData = useCallback(function (groupId, stepIds) {
    return new Promise(function (resolve, reject) {
      try {
        var group = onboardingLocalStorage.getGroup(groupId);
        resolve(_objectSpread(_objectSpread({}, group), {}, {
          steps: stepIds.reduce(function (obj, stepKey) {
            obj[stepKey] = {
              id: stepKey,
              finished: group.steps[stepKey] ? group.steps[stepKey].finished : false
            };
            return obj;
          }, {})
        }));
      } catch (e) {
        return reject(e);
      }
    });
  }, []);
  var clearData = useCallback(function (groupId) {
    return Promise.resolve(appLocalStorage.removeItem(groupId));
  }, []);
  var handleFinishStep = useCallback(function (groupId, stepId) {
    onboardingLocalStorage.finishStepInGroup(groupId, stepId);
  }, []);
  var _useOnboarding = useOnboarding({
      groupId: groupId,
      stepIds: stepIds,
      priority: priority,
      onGroupSkip: onGroupSkip,
      onLoadData: loadData,
      onClearData: clearData,
      onFinishStep: handleFinishStep
    }),
    finishStep = _useOnboarding.finishStep,
    finishAllSteps = _useOnboarding.finishAllSteps,
    activateGroup = _useOnboarding.activateGroup,
    resetGroup = _useOnboarding.resetGroup,
    setActiveStep = _useOnboarding.setActiveStep;
  return {
    finishStep: finishStep,
    activateGroup: activateGroup,
    finishAllSteps: finishAllSteps,
    resetGroup: resetGroup,
    setActiveStep: setActiveStep
  };
};