export var convertObject = function convertObject(data, config) {
  return Object.keys(data).reduce(function (memo, key) {
    //@ts-ignore
    var value = data[key];
    //@ts-ignore
    var converter = config[key];
    if (converter) {
      memo[key] = converter(value);
    } else {
      memo[key] = value;
    }
    return memo;
  }, {});
};