import { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { APIError } from '@wbnr/frontend-shared/lib/api';
import { restorePassword, restorePasswordCourses } from '@wbnr/frontend-shared/lib/api/user';
import {
    AuthLayout,
    AuthContent,
    AuthForm,
    AuthButtons,
    AuthTextField,
} from '@wbnr/frontend-shared/lib/components/auth';
import {
    PARTICIPANT_SIGNUP_LINK,
    PARTICIPANT_HELP_LINK,
} from '@wbnr/frontend-shared/lib/components/auth/constants';
import { useForm, useField, makeFormErrors } from '@wbnr/frontend-shared/lib/forms';
import { validateEmail } from '@wbnr/frontend-shared/lib/utils/validators';
import { createTestIdProps, Typography } from '@wbnr/ui';

const baseTestId = 'PasswordRecovery';

const i18nPath = 'auth.passwordRecovery';

const getRestorePasswordErrorKey = (err: APIError) => {
    if (err.status === 403 && err.errorMessage === 'User cannot restore password') {
        return `${i18nPath}.errors.ssoRecovery`;
    } else if (err.status === 418) {
        return 'auth.signin.errors.participant';
    } else if (err.status === 403 && err.errorMessage === 'RESTORE_PASSWORD_ATTEMPTS_LIMIT') {
        return `${i18nPath}.errors.passwordAttemptsLimit`;
    } else {
        return `${i18nPath}.emailNotFound`;
    }
};

const PasswordRecovery = () => {
    const history = useHistory();
    const { t } = useTranslation();

    const isCourses = window.location.pathname.includes('/courses');

    const [isSuccess, setSuccess] = useState(false);

    const { control, errors, submit } = useForm(
        {
            defaultValues: {
                email: '',
            },
        },
        {
            onSubmit: useCallback(
                async (data: { email: string }) => {
                    try {
                        if (isCourses) {
                            await restorePasswordCourses(data);
                        } else {
                            await restorePassword(data);
                        }

                        setSuccess(true);
                    } catch (err) {
                        if (err instanceof APIError) {
                            throw makeFormErrors({
                                email: getRestorePasswordErrorKey(err),
                            });
                        }
                    }
                },
                [isCourses],
            ),
        },
    );

    const emailField = useField(control, 'email', {
        rules: {
            required: true,
            validate: validateEmail,
        },
    });

    return (
        <>
            <Helmet>
                <title>{t('pageTitle.passwordRecovery')}</title>
            </Helmet>

            <AuthLayout>
                <AuthContent title={isSuccess ? t(`${i18nPath}.restored`) : t(`${i18nPath}.title`)}>
                    {isSuccess ? (
                        <Typography>
                            <Trans
                                i18nKey={`${i18nPath}.sent`}
                                values={{ email: emailField.value }}
                                components={[<b key="0" />]}
                            />
                        </Typography>
                    ) : (
                        <AuthForm {...createTestIdProps(baseTestId, 'form')}>
                            <Typography paragraph>{t(`${i18nPath}.description`)}</Typography>

                            <AuthTextField
                                {...emailField}
                                type="email"
                                label={t(`${i18nPath}.email`)}
                                helperText={
                                    errors.email ? (
                                        <span>
                                            <Trans
                                                i18nKey={errors.email.message}
                                                components={[
                                                    <a
                                                        key="PARTICIPANT_HELP_LINK"
                                                        href={PARTICIPANT_HELP_LINK}
                                                    >
                                                        text
                                                    </a>,
                                                    <a
                                                        key="PARTICIPANT_SIGNUP_LINK"
                                                        href={PARTICIPANT_SIGNUP_LINK}
                                                    >
                                                        text
                                                    </a>,
                                                ]}
                                            />
                                        </span>
                                    ) : null
                                }
                                {...createTestIdProps(baseTestId, 'field', 'email')}
                            />

                            <AuthButtons
                                main={{
                                    text: t(`${i18nPath}.submit`),
                                    onClick: submit,
                                    ...createTestIdProps(baseTestId, 'action', 'submit'),
                                }}
                                buttons={[
                                    {
                                        text: t(`${i18nPath}.back`),
                                        onClick: () =>
                                            history.push(isCourses ? '/courses/signin' : '/signin'),
                                        ...createTestIdProps(baseTestId, 'action', 'back-to-login'),
                                    },
                                ]}
                            />
                        </AuthForm>
                    )}
                </AuthContent>
            </AuthLayout>
        </>
    );
};

export default PasswordRecovery;
