function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import { getNotifications } from '../../api/user/getNotifications';
import { useResource } from '../../data/useResource';
import { useAccountUser } from '../../data/user';
import { useCometListeners } from '../../comet';
import { apiFetch } from '../../api';
import { useBinded } from '../../hooks/useBinded';
export var useNotificationResource = function useNotificationResource() {
  var _useAccountUser = useAccountUser(),
    _useAccountUser2 = _slicedToArray(_useAccountUser, 1),
    id = _useAccountUser2[0].id;
  var _useResource = useResource({
      request: useBinded(id ? getNotifications : undefined, {
        userId: id,
        lang: 'RU'
      })
    }, notificationsReducer),
    data = _useResource.data,
    loading = _useResource.loading,
    error = _useResource.error,
    dispatch = _useResource.dispatch;
  useCometListeners(function () {
    return {
      'notification.add': function notificationAdd(_ref) {
        var delivery = _ref.delivery;
        apiFetch("/deliveries/".concat(delivery)).then(function (res) {
          dispatch({
            type: 'addNotification',
            payload: res
          });
        });
      }
    };
  }, [dispatch]);
  return {
    loading: loading,
    data: data,
    error: error,
    dispatch: dispatch
  };
};
function notificationsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case 'addNotification':
      return [action.payload].concat(_toConsumableArray(state || []));
    case 'updateNotification':
      return (state || []).map(function (old) {
        if (old.delivery.id === action.payload.delivery.id) {
          return action.payload;
        }
        return old;
      });
    default:
      throw new Error("Unsupported action type ".concat(action.type));
  }
}