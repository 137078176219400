import classnames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import Link from '@wbnr/frontend-shared/lib/components/Link';
import { useFeatureSettings } from '@wbnr/frontend-shared/lib/data/featureSettings';
import { useAccountUser, isOrganizationAdmin } from '@wbnr/frontend-shared/lib/data/user';
import { AccountUser } from '@wbnr/frontend-shared/lib/types/User';
import { MenuItem, MenuList, makeStyles } from '@wbnr/ui';

import styles from './BusinessNavigation.module.scss';

interface Item {
    title: string;
    url: string;
    adminOnly?: boolean;
    matchPaths?: string[];
}

const useStyles = makeStyles((theme) => ({
    selected: {
        '&, &:hover': {
            color: '#ffffff',
            backgroundColor: theme.palette.primary.main,
        },
    },
}));

const i18nPath = 'business';

const BusinessNavigation = () => {
    const [user] = useAccountUser();
    const items = useGetNavigationItems(user);

    const isCompanyAdmin = useMemo(() => isOrganizationAdmin(user), [user]);

    return (
        <MenuList className={styles.list}>
            {items.map(
                (item) =>
                    (item.adminOnly ? isCompanyAdmin : true) && (
                        <BusinessNavigationItem
                            key={item.url}
                            title={item.title}
                            url={item.url}
                            matchPaths={item.matchPaths}
                        />
                    ),
            )}
        </MenuList>
    );
};

const BusinessNavigationItem = ({
    title,
    url,
    matchPaths,
}: Pick<Item, 'title' | 'url' | 'matchPaths'>) => {
    const localStyles = useStyles();

    const path = `/business/${url}`;
    const match = useRouteMatch({ path: matchPaths || path, exact: true });

    return (
        <MenuItem className={classnames(styles.item, { [localStyles.selected]: Boolean(match) })}>
            <Link to={path} className={styles.link}>
                {title}
            </Link>
        </MenuItem>
    );
};

function useGetNavigationItems(user: AccountUser) {
    const { t } = useTranslation();
    const { data: features } = useFeatureSettings();

    return useMemo(() => {
        const items: Item[] = [
            {
                title: t(
                    `${i18nPath}.${
                        features?.newTariffsPage && user.isSelfService ? 'myTariff' : 'tariffs'
                    }`,
                ),
                url: 'tariffs',
            },
        ];

        if (features?.newTariffsPage && user.isSelfService) {
            items.push({
                title: t(`${i18nPath}.tariffs`),
                url: 'buytariffs',
            });
        }

        if (user.featureFlags?.isOrganizationEnabled) {
            items.push({
                title: t(`${i18nPath}.organization`),
                url: 'organization',
                adminOnly: true,
            });
        }

        items.push(
            {
                title: t(`${i18nPath}.branding`),
                url: 'branding',
                adminOnly: true,
            },
            {
                title: t(`${i18nPath}.certificate`),
                url: 'certificate',
                adminOnly: true,
            },
            {
                title: t(`${i18nPath}.analytics`),
                url: 'analytics',
            },
            {
                title: t(`${i18nPath}.paidEvents`),
                url: 'revenue',
            },
        );

        if (features.isProctoringEnabled) {
            items.push({
                title: t(`${i18nPath}.proctoring`),
                url: 'proctoring',
                adminOnly: true,
            });
        }

        if (features.webhooks) {
            items.push({
                title: t(`${i18nPath}.apiAndWebhooks`),
                url: 'api',
                adminOnly: true,
                matchPaths: ['/business/api', '/business/webhooks'],
            });
        } else {
            items.push({
                title: t(`${i18nPath}.api`),
                url: 'api',
                adminOnly: true,
            });
        }

        items.push({
            title: t(`${i18nPath}.integrations`),
            url: 'integrations',
            matchPaths: [
                '/business/integrations/marketplace',
                '/business/integrations/constructor',
                '/business/integrations/extensions',
            ],
        });

        items.push({
            title: t(`${i18nPath}.ssoSettings`),
            url: 'sso-settings',
            adminOnly: true,
        });

        if (features.useOutgoingSipCalls) {
            items.push({
                title: t(`${i18nPath}.myDevicesPage.title`),
                url: 'my-devices',
                adminOnly: true,
            });
        }

        if (features.siemLogs) {
            items.push({
                title: t(`${i18nPath}.siemSettings`),
                url: 'siem-settings',
                adminOnly: true,
            });
        }

        if (features.useMediaPop) {
            items.push({
                title: t(`${i18nPath}.pointOfPresence`),
                url: 'point-of-presence',
                adminOnly: true,
            });
        }

        return items;
    }, [
        t,
        features.siemLogs,
        features?.newTariffsPage,
        features.isProctoringEnabled,
        features.webhooks,
        features.useMediaPop,
        features.useOutgoingSipCalls,
        user.featureFlags?.isOrganizationEnabled,
        user.isSelfService,
    ]);
}

export default BusinessNavigation;
