export var composeValidators = function composeValidators(validators) {
  return function (value) {
    for (var i = 0; i < validators.length; i++) {
      var maybeError = validators[i](value);
      if (maybeError) {
        return maybeError;
      }
    }
    return undefined;
  };
};