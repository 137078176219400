import { useSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Membership } from '@wbnr/frontend-shared/lib/api/organization/types';
import { CheckboxField, createTestIdProps, TableCell, TableRow } from '@wbnr/ui';

import { useSSO } from '../../../SSOContext';

import styles from './MembershipItem.module.scss';

const BASE_TEST_ID = 'MembershipItem';

export const MembershipItem: FC<{ membership: Membership }> = ({ membership }) => {
    const { updateMembership } = useSSO();
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const handleMemberClick = (member: Membership) => () => {
        if (loading) {
            return;
        }
        try {
            setLoading(true);
            updateMembership?.(member);
        } catch {
            enqueueSnackbar(t('unknownError'), { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };
    return (
        <TableRow onClick={handleMemberClick(membership)} className={styles.row}>
            <TableCell className={styles.checkboxCell}>
                <CheckboxField
                    value={membership.isSsoEnabled}
                    onChange={() => {}}
                    classes={{ root: styles.checkbox }}
                    disabled={loading}
                    {...createTestIdProps(BASE_TEST_ID, membership.id, 'isSsoEnabled')}
                />
            </TableCell>
            <TableCell className={styles.cell}>{membership.user.displayName}</TableCell>
            <TableCell className={styles.cell}>{membership.user.email}</TableCell>
        </TableRow>
    );
};
