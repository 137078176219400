"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DEFAULT_BRANDING_COLOR = void 0;
Object.defineProperty(exports, "Theme", {
  enumerable: true,
  get: function get() {
    return _core.Theme;
  }
});
exports.createLightTheme = exports.createDarkTheme = void 0;
var _paperStyles = require("./Paper/paperStyles");
var _styles = require("./styles");
var _variablesModule = _interopRequireDefault(require("./variables.module.scss"));
var _core = require("@material-ui/core");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var DEFAULT_BRANDING_COLOR = _variablesModule.default.WBNR_UI_BRANDING_COLOR;
exports.DEFAULT_BRANDING_COLOR = DEFAULT_BRANDING_COLOR;
var typography = {
  fontFamily: 'AktivGrotesk, Arial, sans-serif',
  h2: {
    fontSize: '32px',
    lineHeight: '48px',
    fontWeight: 500
  },
  h6: {
    fontSize: '20px',
    lineHeight: '32px',
    fontWeight: 500
  },
  body1: {
    lineHeight: '28px'
  }
};
var overrides = _objectSpread(_objectSpread({}, _paperStyles.paperStyles), {}, {
  MuiSelect: {
    icon: {
      top: 'calc(50% - 15px)'
    }
  }
});
var createLightTheme = function createLightTheme(_ref) {
  var color = _ref.color;
  return (0, _styles.createTheme)({
    palette: {
      type: 'light',
      primary: {
        main: color,
        contrastText: '#ffffff'
      },
      error: {
        dark: _variablesModule.default.WBNR_UI_ERROR_COLOR,
        main: _variablesModule.default.WBNR_UI_ERROR_COLOR,
        light: _variablesModule.default.WBNR_UI_ERROR_COLOR
      },
      info: {
        main: color,
        dark: 'rgba(0,0,0,0.08)',
        contrastText: 'rgba(0,0,0,0.54)'
      }
    },
    typography: typography,
    overrides: _objectSpread(_objectSpread({}, overrides), {}, {
      MuiInput: {
        underline: {
          '&:after': {
            borderBottomColor: _variablesModule.default.WBNR_UI_INPUT_DARK_HOVER_COLOR
          }
        }
      },
      MuiInputLabel: {
        root: {
          '&.Mui-focused': {
            color: _variablesModule.default.WBNR_UI_INPUT_DARK_HOVER_COLOR
          }
        }
      },
      MuiOutlinedInput: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: _variablesModule.default.WBNR_UI_INPUT_DARK_HOVER_COLOR
          }
        }
      },
      MuiMenu: {
        paper: {
          backgroundColor: '#ffffff'
        }
      }
    }),
    props: {
      MuiSelect: {
        MenuProps: {
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          }
        }
      },
      MuiButtonBase: {
        disableRipple: true
      },
      MuiCheckbox: {
        color: 'primary'
      },
      MuiRadio: {
        color: 'primary'
      },
      MuiSwitch: {
        color: 'primary'
      }
    }
  });
};
exports.createLightTheme = createLightTheme;
var createDarkTheme = function createDarkTheme(_ref2) {
  var color = _ref2.color;
  return (0, _styles.createTheme)({
    palette: {
      type: 'dark',
      primary: {
        main: color,
        contrastText: '#ffffff'
      },
      error: {
        dark: _variablesModule.default.WBNR_UI_ERROR_COLOR,
        main: _variablesModule.default.WBNR_UI_ERROR_COLOR,
        light: _variablesModule.default.WBNR_UI_ERROR_COLOR
      },
      info: {
        main: '#ffffff',
        dark: '#3f3f3f',
        contrastText: '#ffffff'
      }
    },
    typography: typography,
    overrides: _objectSpread(_objectSpread({}, overrides), {}, {
      MuiInputLabel: {
        root: {
          '&.Mui-focused:not(&.Mui-error)': {
            color: '#ffffff'
          }
        }
      },
      MuiMenuItem: {
        root: {
          color: '#333333'
        }
      },
      MuiInput: {
        underline: {
          '&:after': {
            borderBottomColor: '#ffffff'
          }
        }
      },
      MuiSelect: {
        select: {
          '&:focus': {
            backgroundColor: 'unset'
          }
        }
      },
      MuiMenu: {
        paper: {
          backgroundColor: '#343434',
          borderRadius: '12px',
          border: '1px solid #3d3d3d',
          boxShadow: 'none',
          '& li': {
            color: '#ffffff'
          }
        },
        list: {
          padding: _variablesModule.default.WBNR_UI_SPACING_15
        }
      },
      MuiListItem: {
        button: {
          '&:hover': {
            backgroundColor: '#3f3f3f'
          }
        }
      },
      MuiSwitch: {
        switchBase: {
          '&$disabled$disabled': {
            color: '#9a9a9a'
          },
          '&$checked$checked': {
            color: '#fafafa',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.08)'
            }
          }
        },
        track: {
          '$disabled$disabled + &': {
            opacity: 0.12
          },
          '$checked$checked + &': {
            backgroundColor: '#ffffff'
          }
        }
      },
      MuiPaper: {
        root: {
          backgroundColor: '#343434',
          '& > .MuiMenuItem-root': {
            color: '#ffffff'
          }
        }
      }
    })
  });
};
exports.createDarkTheme = createDarkTheme;