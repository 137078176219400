function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
export var sortDescriptionToApiParam = function sortDescriptionToApiParam(sortDescription) {
  var order = sortDescription.asc ? 'asc' : 'desc';
  var sort = {};
  sort[sortDescription.by] = order;
  if (isValidSortApiParam(sort)) {
    return sort;
  }
  return undefined;
};
var isValidSortApiParam = function isValidSortApiParam(sort) {
  var keys = Object.keys(sort);
  return keys.length === 1 && sort[keys[0]] !== undefined;
};
export var sortUsingComparators = function sortUsingComparators() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var comparators = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return _toConsumableArray(data).sort(function (a, b) {
    var res = 0;
    for (var i = 0; i < comparators.length; i++) {
      res = comparators[i](a, b);
      if (res !== 0) {
        return res;
      }
    }
    return res;
  });
};
export var createComparatorBy = function createComparatorBy(sort) {
  return function (left, right) {
    var res = 0;
    if (left[sort.by] > right[sort.by]) {
      res = 1;
    } else if (left[sort.by] < right[sort.by]) {
      res = -1;
    }
    return res * (sort.asc ? 1 : -1);
  };
};