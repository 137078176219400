import { apiFormFetch } from '../apiFetch';
export var importContactsFromFile = function importContactsFromFile(_ref) {
  var userId = _ref.userId,
    file = _ref.file;
  return apiFormFetch('POST', '/users/{userId}/contacts/import', {
    userId: userId,
    file: file,
    hasHeader: true
  }).then(function (res) {
    return res;
  });
};