import { useTranslation } from 'react-i18next';

import UploadImage from '@wbnr/frontend-shared/lib/components/UploadImage';
import { useField, useFormContext } from '@wbnr/frontend-shared/lib/forms';
import { injectTranslations } from '@wbnr/frontend-shared/lib/utils/i18n';
import { prependProtocolToLink } from '@wbnr/frontend-shared/lib/utils/links';
import { validateLink } from '@wbnr/frontend-shared/lib/utils/validators';
import { TextField, createTestIdProps } from '@wbnr/ui';

import logoBlack from '../../assets/logo-black.png';
import logoWhite from '../../assets/logo-white.png';
import { BrandingFormValues } from '../../types';
import { getBrandingPropName } from '../../utils';
import BrandingHeading from '../BrandingHeading';

import styles from './BrandingLogo.module.scss';
import translations from './translations';

const TRANS_NS = 'businessBrandingPage';
injectTranslations(TRANS_NS, translations);

const BrandingLogo = () => {
    const { t } = useTranslation(TRANS_NS);

    const { control } = useFormContext();

    const { value: interfaceImageValue, onChange: onInterfaceImageChange } = useField(
        control,
        getBrandingPropName('interfaceLogoImage'),
    );
    const { value: landingImageValue, onChange: onLandingImageChange } = useField(
        control,
        getBrandingPropName('landingLogoImage'),
    );
    const interfaceLogoLinkField = useField(control, getBrandingPropName('interfaceLogoLink'), {
        rules: { validate: validateLink },
    });

    const interfaceImage: BrandingFormValues['branding']['interfaceLogoImage'] =
        interfaceImageValue;
    const landingImage: BrandingFormValues['branding']['landingLogoImage'] = landingImageValue;

    return (
        <>
            <BrandingHeading>{t('logos')}</BrandingHeading>

            <UploadImage
                image={interfaceImage instanceof File ? interfaceImage : interfaceImage?.url}
                imageClassName={styles.image}
                defaultImage={logoBlack}
                description={t('menu')}
                onUpload={onInterfaceImageChange}
                onDelete={() => onInterfaceImageChange(null)}
                {...createTestIdProps('brandingInterfaceImage')}
            />

            <div className={styles.block}>
                <UploadImage
                    image={landingImage instanceof File ? landingImage : landingImage?.url}
                    imageClassName={styles.image}
                    defaultImage={logoWhite}
                    description={t('landingsAndRecords')}
                    onUpload={onLandingImageChange}
                    onDelete={() => onLandingImageChange(null)}
                    dark
                    {...createTestIdProps('brandingLangingImage')}
                />
            </div>

            <div className={styles.block}>
                <TextField
                    {...interfaceLogoLinkField}
                    label={t('logoLink')}
                    onBlur={(e) =>
                        interfaceLogoLinkField.onChange(prependProtocolToLink(e.target.value))
                    }
                    fullWidth
                />
            </div>
        </>
    );
};

export default BrandingLogo;
