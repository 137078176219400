"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.paperStyles = void 0;
var _variablesModule = _interopRequireDefault(require("../variables.module.scss"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var paperStyles = {
  MuiPaper: {
    root: {
      backgroundColor: _variablesModule.default.WBNR_UI_DEFAULT_PAPER_BACKGROUND
    },
    elevation0: {
      boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12)'
    },
    elevation1: {
      boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12)'
    },
    elevation2: {
      boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12)'
    },
    elevation3: {
      boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12)'
    },
    elevation4: {
      boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12)'
    },
    elevation5: {
      boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12)'
    },
    elevation6: {
      boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12)'
    },
    elevation7: {
      boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12)'
    },
    elevation8: {
      boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12)'
    },
    elevation9: {
      boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12)'
    },
    elevation10: {
      boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12)'
    },
    elevation11: {
      boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12)'
    },
    elevation12: {
      boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12)'
    },
    elevation13: {
      boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12)'
    },
    elevation15: {
      boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12)'
    },
    elevation16: {
      boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12)'
    },
    elevation17: {
      boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12)'
    },
    elevation18: {
      boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12)'
    },
    elevation19: {
      boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12)'
    },
    elevation20: {
      boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12)'
    },
    elevation21: {
      boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12)'
    },
    elevation22: {
      boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12)'
    },
    elevation23: {
      boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12)'
    },
    elevation24: {
      boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.12)'
    }
  }
};
exports.paperStyles = paperStyles;