import { debounce } from 'lodash';
import { SnackbarKey, useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
    getWebhooks,
    createWebhook as createWebhookRequest,
    deleteWebhook as deleteWebhookRequest,
    enableWebhook as enableWebhookRequest,
    disableWebhook as disableWebhookRequest,
} from '@wbnr/frontend-shared/lib/api/business-api';
import { WebhooksListItem } from '@wbnr/frontend-shared/lib/api/business-api/types';
import { useResource, ResetStateAction } from '@wbnr/frontend-shared/lib/data/useResource';
import { useBinded } from '@wbnr/frontend-shared/lib/hooks/useBinded';
import { ReducerAction } from '@wbnr/frontend-shared/lib/types/ReducerAction';
import { CloseIcon } from '@wbnr/icons';
import { Button, IconButton, SnackbarContent } from '@wbnr/ui';

import styles from '../Webhooks.module.scss';

interface UseWebhooksReturned {
    webhooks?: WebhooksListItem[] | null;
    loading: boolean;
    webhooksLimit: number;
    createWebhook(name: string, endpointUrl: string): Promise<void>;
    deleteWebhook(webhookId: number): void;
    enableWebhook(webhookId: number): void;
    disableWebhook(webhookId: number): void;
}

export const useWebhooks = (): UseWebhooksReturned => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const { data, loading, dispatch } = useResource(
        {
            request: useBinded(getWebhooks),
        },
        reducer,
    );

    const { items: webhooks, quantityLimit: webhooksLimit } = data || {
        items: [],
        quantityLimit: 0,
    };

    const createWebhook = useCallback(
        async (name: string, endpointUrl: string) => {
            const webhook = await createWebhookRequest({ name, endpointUrl });
            dispatch({ type: 'add', payload: { webhook } });

            const notificationCreateKey = enqueueSnackbar('', {
                content: (
                    <SnackbarContent
                        message={t('business.webhooksPage.list.webhookAdded')}
                        action={
                            <IconButton
                                onClick={() => closeSnackbar(notificationCreateKey)}
                                className={styles.notification}
                            >
                                <CloseIcon className={styles.notificationIcon} />
                            </IconButton>
                        }
                    />
                ),
            });
        },
        [closeSnackbar, dispatch, enqueueSnackbar, t],
    );

    const deleteWebhook = useCallback(
        async (webhookId: number) => {
            const webhook = webhooks?.find((webhookEl) => webhookEl.id === webhookId);
            if (!webhook) {
                return;
            }

            const onUndo = (removeNotificationKey: SnackbarKey) => {
                createWebhook(webhook.name, webhook.endpointUrl);
                closeSnackbar(removeNotificationKey);
            };

            try {
                dispatch({ type: 'delete', payload: { webhookId } });
                await deleteWebhookRequest({ webhookId });

                const notificationRemoveKey = enqueueSnackbar('', {
                    content: (
                        <SnackbarContent
                            message={t('business.webhooksPage.list.webhookDeleted')}
                            action={
                                <Button
                                    key="close"
                                    color="inherit"
                                    onClick={() => onUndo(notificationRemoveKey)}
                                >
                                    {t('business.webhooksPage.list.undoDeletion')}
                                </Button>
                            }
                        />
                    ),
                });
            } catch (e) {
                dispatch({ type: 'add', payload: { webhook } });
                throw e;
            }
        },
        [closeSnackbar, createWebhook, dispatch, enqueueSnackbar, t, webhooks],
    );

    const handleToggleWebhook = useMemo(
        () =>
            debounce(
                async (webhookId: number, type: 'enable' | 'disable', onError?: () => void) => {
                    const request =
                        type === 'enable' ? enableWebhookRequest : disableWebhookRequest;

                    try {
                        await request({ webhookId });
                    } catch (e) {
                        onError?.();
                        throw e;
                    }
                },
                200,
            ),
        [],
    );

    useEffect(() => {
        return () => {
            handleToggleWebhook.cancel();
        };
    }, [handleToggleWebhook]);

    const enableWebhook = useCallback(
        async (webhookId: number) => {
            dispatch({ type: 'enable', payload: { webhookId } });
            await handleToggleWebhook(webhookId, 'enable', () =>
                dispatch({ type: 'disable', payload: { webhookId } }),
            );
        },
        [dispatch, handleToggleWebhook],
    );

    const disableWebhook = useCallback(
        async (webhookId: number) => {
            dispatch({ type: 'disable', payload: { webhookId } });
            await handleToggleWebhook(webhookId, 'disable', () =>
                dispatch({ type: 'enable', payload: { webhookId } }),
            );
        },
        [dispatch, handleToggleWebhook],
    );

    return {
        webhooks,
        loading,
        webhooksLimit,
        createWebhook,
        deleteWebhook,
        enableWebhook,
        disableWebhook,
    };
};

interface AddAction extends ReducerAction<'add'> {
    payload: {
        webhook: WebhooksListItem;
    };
}

interface DeleteAction extends ReducerAction<'delete'> {
    payload: {
        webhookId: number;
    };
}

interface EnableAction extends ReducerAction<'enable'> {
    payload: {
        webhookId: number;
    };
}

interface DisableAction extends ReducerAction<'disable'> {
    payload: {
        webhookId: number;
    };
}

type WebhookAction =
    | AddAction
    | DeleteAction
    | EnableAction
    | DisableAction
    | ResetStateAction<ReducerState, any>;

interface ReducerState {
    items: WebhooksListItem[];
    quantityLimit: number;
}

function reducer(state: ReducerState | null | undefined, action: WebhookAction) {
    switch (action.type) {
        case 'add': {
            const newItems = [action.payload.webhook, ...(state?.items || [])];

            return { quantityLimit: state?.quantityLimit || 0, items: newItems };
        }
        case 'delete': {
            const newItems =
                state?.items?.filter(({ id }) => id !== action.payload.webhookId) || [];

            return { quantityLimit: state?.quantityLimit || 0, items: newItems };
        }
        case 'enable': {
            const newItems =
                state?.items?.map((webhook) =>
                    webhook.id === action.payload.webhookId
                        ? { ...webhook, isEnabled: true }
                        : webhook,
                ) || [];

            return { quantityLimit: state?.quantityLimit || 0, items: newItems };
        }
        case 'disable': {
            const newItems =
                state?.items?.map((webhook) =>
                    webhook.id === action.payload.webhookId
                        ? { ...webhook, isEnabled: false }
                        : webhook,
                ) || [];

            return { quantityLimit: state?.quantityLimit || 0, items: newItems };
        }
        default:
            throw new Error(`Unsupported action type ${action.type}`);
    }
}
