import { useTranslation } from 'react-i18next';

import { ActionDialogBase, DialogActions, Button, DialogContentText } from '@wbnr/ui';

export const WebrtcWarningConfirmIgnore = ({
    onConfirm,
    onGetHelp,
    closeDialog,
    opened,
}: {
    onConfirm: () => void;
    onGetHelp: () => void;
    closeDialog: () => void;
    opened: boolean;
}) => {
    const { t } = useTranslation();

    return (
        <ActionDialogBase
            maxWidth={'xl'}
            opened={opened}
            onClose={closeDialog}
            title={t('banners.webrtcTestHideWarningTitle')}
            actions={
                <DialogActions>
                    <Button
                        onClick={() => {
                            closeDialog();
                            onGetHelp();
                        }}
                    >
                        {t('banners.webrtcTestFixWarning')}
                    </Button>
                    <Button
                        onClick={() => {
                            onConfirm();
                            closeDialog();
                        }}
                    >
                        {t('banners.webrtcTestHideWarning')}
                    </Button>
                </DialogActions>
            }
        >
            <DialogContentText>{t('banners.webrtcTestHideWarningText1')}</DialogContentText>
            <DialogContentText>{t('banners.webrtcTestHideWarningText2')}</DialogContentText>
        </ActionDialogBase>
    );
};
