import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { injectTranslations } from '@wbnr/frontend-shared/lib/utils/i18n';

import { TRANS_NS } from './constants';
import { Integrations } from './Integrations';
import translations from './translations.json';

injectTranslations(TRANS_NS, translations);

export const IntegrationsPage = () => {
    const { t } = useTranslation(TRANS_NS);

    return (
        <>
            <Helmet>
                <title>{t('title')}</title>
            </Helmet>

            <Integrations />
        </>
    );
};
