import classnames from 'classnames';
import { ReactNode } from 'react';

import { Typography } from '@wbnr/ui';

import styles from './BrandingHeading.module.scss';

interface Props {
    small?: boolean;
    children: ReactNode;
}

const BrandingHeading = ({ small, children }: Props) => {
    return (
        <Typography variant="h6" className={classnames(styles.heading, { [styles.small]: small })}>
            {children}
        </Typography>
    );
};

export default BrandingHeading;
