function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import { useCallback, useEffect, useRef, useState } from 'react';
var useTextFieldClipboard = function useTextFieldClipboard(_ref) {
  var value = _ref.value,
    defaultValue = _ref.defaultValue,
    clipboardProps = _ref.clipboardProps,
    onChange = _ref.onChange;
  var _useState = useState(value || defaultValue),
    _useState2 = _slicedToArray(_useState, 2),
    copyText = _useState2[0],
    setCopyText = _useState2[1];
  var _useState3 = useState(false),
    _useState4 = _slicedToArray(_useState3, 2),
    isCopied = _useState4[0],
    setIsCopied = _useState4[1];
  var copyTimeout = useRef(null);
  var onCopy = useCallback(function (text, result) {
    if (!clipboardProps) {
      return;
    }
    var onCopy = clipboardProps.onCopy,
      timeout = clipboardProps.timeout;
    onCopy && onCopy(text, result);
    if (timeout) {
      copyTimeout.current && clearTimeout(copyTimeout.current);
      setIsCopied(true);
      copyTimeout.current = window.setTimeout(function () {
        copyTimeout.current = null;
        setIsCopied(false);
      }, timeout);
    }
  }, [clipboardProps]);
  var onChangeHandler = function onChangeHandler(e) {
    if (value === undefined) {
      setCopyText(e.target.value);
    }
    onChange && onChange(e);
  };
  useEffect(function () {
    if (value !== undefined) {
      setCopyText(value);
    }
  }, [value]);
  useEffect(function () {
    return function () {
      copyTimeout.current && clearTimeout(copyTimeout.current);
    };
  }, []);
  return {
    copyText: copyText,
    isCopied: isCopied,
    onCopy: onCopy,
    onChangeHandler: onChangeHandler
  };
};
export default useTextFieldClipboard;