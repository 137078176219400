import { DEFAULT_BRANDING_COLOR } from '@wbnr/ui';
import { DEFAULT_EMAIL_TEMPLATE_INVITATION, DEFAULT_EMAIL_TEMPLATE } from '../../data/branding';
export var parseFullBranding = function parseFullBranding(branding) {
  var _branding$emailTempla, _branding$emailTempla2;
  var eventButtonAndLinkColor = branding.eventButtonAndLinkColor;
  var brandingColor = DEFAULT_BRANDING_COLOR.replace(/#/, '');
  if (eventButtonAndLinkColor && eventButtonAndLinkColor !== '50ae55') {
    brandingColor = eventButtonAndLinkColor;
  }
  return {
    id: branding.id,
    title: branding.title,
    isDefault: branding.isDefault,
    updateUser: branding.updateUser,
    createAt: branding.createAt,
    updateAt: branding.updateAt,
    emailReplyTo: branding.emailReplyTo || '',
    emailFromName: branding.emailFromName || '',
    isSignature: branding.isSignature,
    emailSignature: branding.emailSignature || '',
    isTemplateInvitation: branding.isTemplateInvitation,
    emailTemplateInvitation: (_branding$emailTempla = branding.emailTemplateInvitation) !== null && _branding$emailTempla !== void 0 ? _branding$emailTempla : DEFAULT_EMAIL_TEMPLATE_INVITATION,
    isTemplate: branding.isTemplate,
    emailTemplate: (_branding$emailTempla2 = branding.emailTemplate) !== null && _branding$emailTempla2 !== void 0 ? _branding$emailTempla2 : DEFAULT_EMAIL_TEMPLATE,
    eventOrganizationTitle: branding.eventOrganizationTitle || '',
    eventCustomLink: branding.eventCustomLink || '',
    eventExitLink: branding.eventExitLink || '',
    showSocialButtons: branding.showSocialButtons,
    isQuestions: branding.isQuestions,
    questionsEmail: branding.questionsEmail || '',
    interfaceLogoLink: branding.interfaceLogoLink || '',
    eventButtonAndLinkColor: brandingColor,
    additionalAgreements: branding.additionalAgreements || [],
    isAgreementsDefaultChecked: branding.isAgreementsDefaultChecked,
    interfaceLogoImage: branding.interfaceLogoImage || null,
    landingLogoImage: branding.landingLogoImage || null,
    eventPauseImage: branding.eventPauseImage || null
  };
};