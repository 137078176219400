var _ERRORS_BY_TYPE;
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
export var WebrtcTestResultError;
(function (WebrtcTestResultError) {
  WebrtcTestResultError["NOT_SUPPORTED"] = "NotSupportedError";
  WebrtcTestResultError["PRODUCT_NOT_SUPPORTED"] = "ProductNotSupportedError";
  WebrtcTestResultError["PRODUCT_VERSION_NOT_SUPPORTED"] = "ProductVersionNotSupportedError";
  WebrtcTestResultError["PEER_NETWORK_ERROR"] = "PeerNetworkError";
  WebrtcTestResultError["AUDIO_NOT_SUPPORTED"] = "AudioNotSupportedError";
  WebrtcTestResultError["VIDEO_NOT_SUPPORTED"] = "VideoNotSupportedError";
  WebrtcTestResultError["SIGNAL_NETWORK_ERROR"] = "SignalingNetworkError";
  WebrtcTestResultError["UNHANDLED_CREATE_OFFER_ERROR"] = "UnhandledCreateOfferError";
  WebrtcTestResultError["TIMEOUT_ERROR"] = "TimeoutError";
  WebrtcTestResultError["UNHANDLED_ERROR"] = "UnhandledError";
})(WebrtcTestResultError || (WebrtcTestResultError = {}));
export var WebrtcTestResultErrorType;
(function (WebrtcTestResultErrorType) {
  WebrtcTestResultErrorType["NETWORK"] = "network";
  WebrtcTestResultErrorType["NETWORK_BLOCKED"] = "network_blocked";
  WebrtcTestResultErrorType["UNKNOWN"] = "unknown";
  WebrtcTestResultErrorType["BROWSER"] = "browser";
})(WebrtcTestResultErrorType || (WebrtcTestResultErrorType = {}));
var ERRORS_BY_TYPE = (_ERRORS_BY_TYPE = {}, _defineProperty(_ERRORS_BY_TYPE, WebrtcTestResultError.SIGNAL_NETWORK_ERROR, WebrtcTestResultErrorType.NETWORK), _defineProperty(_ERRORS_BY_TYPE, WebrtcTestResultError.PEER_NETWORK_ERROR, WebrtcTestResultErrorType.NETWORK_BLOCKED), _defineProperty(_ERRORS_BY_TYPE, WebrtcTestResultError.TIMEOUT_ERROR, WebrtcTestResultErrorType.NETWORK_BLOCKED), _defineProperty(_ERRORS_BY_TYPE, WebrtcTestResultError.UNHANDLED_CREATE_OFFER_ERROR, WebrtcTestResultErrorType.UNKNOWN), _defineProperty(_ERRORS_BY_TYPE, WebrtcTestResultError.UNHANDLED_ERROR, WebrtcTestResultErrorType.UNKNOWN), _defineProperty(_ERRORS_BY_TYPE, WebrtcTestResultError.NOT_SUPPORTED, WebrtcTestResultErrorType.BROWSER), _defineProperty(_ERRORS_BY_TYPE, WebrtcTestResultError.PRODUCT_NOT_SUPPORTED, WebrtcTestResultErrorType.BROWSER), _defineProperty(_ERRORS_BY_TYPE, WebrtcTestResultError.PRODUCT_VERSION_NOT_SUPPORTED, WebrtcTestResultErrorType.BROWSER), _defineProperty(_ERRORS_BY_TYPE, WebrtcTestResultError.AUDIO_NOT_SUPPORTED, WebrtcTestResultErrorType.BROWSER), _defineProperty(_ERRORS_BY_TYPE, WebrtcTestResultError.VIDEO_NOT_SUPPORTED, WebrtcTestResultErrorType.BROWSER), _ERRORS_BY_TYPE);
export var getErrorType = function getErrorType(err) {
  return ERRORS_BY_TYPE[err] || WebrtcTestResultErrorType.UNKNOWN;
};