import { apiFormFetch } from '../apiFetch';
export var getValidationResults = function getValidationResults(_ref) {
  var organizationId = _ref.organizationId;
  return apiFormFetch('GET', "/organization/{organizationId}/saml-settings-validations/last-finished", {
    organizationId: organizationId
  }).then(function (response) {
    var error = response.data.error;
    if (!error) {
      return {
        hasValidation: true,
        isValid: true
      };
    }
    return {
      hasValidation: true,
      isValid: false,
      error: error
    };
  }).catch(function () {
    return {
      hasValidation: false
    };
  });
};