import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { APIError } from '@wbnr/frontend-shared/lib/api';
import { getSSOTransferOrganizations } from '@wbnr/frontend-shared/lib/api/user';
import { makeFormErrors } from '@wbnr/frontend-shared/lib/forms';
import { SSOTransferOrganization } from '@wbnr/frontend-shared/lib/types/User';
import { appLocalStorage } from '@wbnr/frontend-shared/lib/utils/browserStorage';

import {
    DEFAULT_SSO_ORGANIZATION_ID,
    SELECTED_SSO_ORGANIZATION_KEY,
    SELECTED_SSO_ORGANIZATION_VALUE,
} from '../constants';

type Props = {
    isSelectedSSOOrganization?: boolean;
    onSubmitClick: () => void;
};

export const useGetSSOTransferOrganizations = ({
    isSelectedSSOOrganization = false,
    onSubmitClick,
}: Props) => {
    const [transferOrganizations, setTransferOrganizations] = useState<
        SSOTransferOrganization[] | null
    >(null);
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const defaultOrganization = useMemo(
        () => ({
            id: DEFAULT_SSO_ORGANIZATION_ID,
            name: t('auth.sso.defaultOrganization'),
        }),
        [t],
    );

    const handleShowError = useCallback(
        (error: unknown) => {
            if (!(error instanceof APIError)) {
                enqueueSnackbar(t('unknownError'), {
                    variant: 'error',
                });
                return;
            }
            if (error.status === 401 && error.body.error.code === 'UNAUTHORIZED') {
                throw makeFormErrors({
                    email: 'auth.sso.organizationError',
                });
            }
            if (error.status === 403) {
                throw makeFormErrors({
                    email: 'auth.sso.notVerifiedEmail',
                });
            }
        },
        [enqueueSnackbar, t],
    );

    const isRequsted = useRef(false);

    const handleGetSSOTransferOrganizations = useCallback(async () => {
        try {
            const response = await getSSOTransferOrganizations();
            if (response.length > 0) {
                setTransferOrganizations([...response, defaultOrganization]);
            } else {
                appLocalStorage.setItem(
                    SELECTED_SSO_ORGANIZATION_KEY,
                    SELECTED_SSO_ORGANIZATION_VALUE,
                );
                onSubmitClick();
            }
        } catch (error) {
            handleShowError(error);
        }
    }, [defaultOrganization, handleShowError, onSubmitClick]);

    useEffect(() => {
        if (!isSelectedSSOOrganization && !isRequsted.current) {
            isRequsted.current = true;
            handleGetSSOTransferOrganizations();
        }
    }, [isSelectedSSOOrganization, handleGetSSOTransferOrganizations]);

    return { transferOrganizations, setTransferOrganizations };
};
