import mapValues from 'lodash/mapValues';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
    getProctoringSettings,
    setProctoringSettings,
} from '@wbnr/frontend-shared/lib/api/proctoring/settings';
import LoadingScreen from '@wbnr/frontend-shared/lib/components/LoadingScreen';
import { useFeatureSettings } from '@wbnr/frontend-shared/lib/data/featureSettings';
import { useAccountUser, isOrganizationAdmin } from '@wbnr/frontend-shared/lib/data/user';
import { useResource } from '@wbnr/frontend-shared/lib/data/useResource';
import { ProctoringSettings } from '@wbnr/frontend-shared/lib/types/Proctoring';
import { Typography } from '@wbnr/ui';

import NotFoundPage from 'routes/notFound';

import BusinessLayout from '../BusinessLayout';

import ProctoringForm from './ProctoringSettingsForm';
import styles from './ProctoringSettingsPage.module.scss';

const i18nPath = 'business.proctoringPage';

const ProctoringSettingsPage = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const { data: features, fulfilled: featuresFulfilled } = useFeatureSettings();
    const { data, update, fulfilled, error } = useResource({ request: getProctoringSettings });

    const [user] = useAccountUser();
    const isCompanyAdmin = useMemo(() => isOrganizationAdmin(user), [user]);

    const handleSettingsSubmit = useCallback(
        async (settings: ProctoringSettings) => {
            const processedSettings = mapValues(settings, (setting) => setting.trim());

            try {
                await setProctoringSettings(processedSettings);
                enqueueSnackbar(t(`${i18nPath}.proctoringSettingsSaveSuccess`));
                update();
            } catch (e) {
                enqueueSnackbar(t(`${i18nPath}.proctoringSettingsSaveFail`), {
                    variant: 'error',
                });
            }
        },
        [update, enqueueSnackbar, t],
    );

    useEffect(() => {
        if (error) {
            enqueueSnackbar(t(`${i18nPath}.proctoringSettingsGetFail`), {
                variant: 'error',
            });
        }
    }, [error, enqueueSnackbar, t]);

    if (!featuresFulfilled || !fulfilled) {
        return <LoadingScreen />;
    }

    if (!features.isProctoringEnabled || !isCompanyAdmin) {
        return <NotFoundPage />;
    }

    return (
        <BusinessLayout>
            <div className={styles.root}>
                <Typography variant="h5" className={styles.title}>
                    {t(`${i18nPath}.title`)}
                </Typography>
                <Typography variant="body1" className={styles.text}>
                    {t(`${i18nPath}.description`)}
                </Typography>
                {!error && <ProctoringForm data={data} submitAction={handleSettingsSubmit} />}
            </div>
        </BusinessLayout>
    );
};

export default ProctoringSettingsPage;
