import { Switch, Route } from 'react-router';

import { LazyKnockout as Knockout } from 'containers/KnockoutAdapter/LazyKnockout';

import Statistics from './Statistics';

const StatisticsRoute = () => {
    return (
        <Switch>
            <Route path={'/statistics/courses/:item'} component={Statistics} />
            <Route path={'/statistics/:statPage/:item'} component={Knockout} />
        </Switch>
    );
};

export default StatisticsRoute;
