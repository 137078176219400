"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAssigneesFromBlock = exports.createSearchEntityStrategy = exports.createMentionsFromAssignees = exports.createMentionEntityDataFromAssignee = exports.createMentionAtSelection = exports.createLinkAtSelection = exports.createEntityAtSelection = exports.createButtonLinkAtSelection = void 0;
var _draftJs = _interopRequireDefault(require("draft-js"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var EditorState = _draftJs.default.EditorState,
  Modifier = _draftJs.default.Modifier,
  SelectionState = _draftJs.default.SelectionState;
var createEntityAtSelection = function createEntityAtSelection(_ref) {
  var editorState = _ref.editorState,
    selection = _ref.selection,
    type = _ref.type,
    isMutable = _ref.isMutable,
    params = _ref.params;
  var contentState = editorState.getCurrentContent().createEntity(type, isMutable ? 'MUTABLE' : 'IMMUTABLE', params);
  var entityKey = contentState.getLastCreatedEntityKey();
  var contentWithEntity = Modifier.applyEntity(contentState, selection, entityKey);
  var newEditorState = EditorState.push(editorState, contentWithEntity, 'create-entity');
  return EditorState.forceSelection(newEditorState, selection);
};
exports.createEntityAtSelection = createEntityAtSelection;
var createLinkAtSelection = function createLinkAtSelection(editorState, selection, url) {
  return createEntityAtSelection({
    editorState: editorState,
    selection: selection,
    type: 'LINK',
    isMutable: true,
    params: {
      url: url
    }
  });
};
exports.createLinkAtSelection = createLinkAtSelection;
var createButtonLinkAtSelection = function createButtonLinkAtSelection(editorState, selection, url) {
  return createEntityAtSelection({
    editorState: editorState,
    selection: selection,
    type: 'BUTTON-LINK',
    params: {
      url: url
    }
  });
};
exports.createButtonLinkAtSelection = createButtonLinkAtSelection;
var createMentionsFromAssignees = function createMentionsFromAssignees(_ref2) {
  var editorState = _ref2.editorState,
    assignees = _ref2.assignees,
    nodeText = _ref2.nodeText,
    block = _ref2.block;
  var editorStateWithMentionEnteties = assignees.reduce(function (editorState, assignee) {
    var _createMentionEntityD = createMentionEntityDataFromAssignee(assignee, nodeText, block),
      mentionSelection = _createMentionEntityD.mentionSelection,
      mention = _createMentionEntityD.mention;
    return createMentionAtSelection(editorState, mentionSelection, mention);
  }, editorState);
  return editorStateWithMentionEnteties;
};
exports.createMentionsFromAssignees = createMentionsFromAssignees;
var createMentionAtSelection = function createMentionAtSelection(editorState, selection, mention) {
  return createEntityAtSelection({
    editorState: editorState,
    selection: selection,
    type: 'mention',
    isMutable: false,
    params: mention
  });
};
exports.createMentionAtSelection = createMentionAtSelection;
var createMentionEntityDataFromAssignee = function createMentionEntityDataFromAssignee(assignee, nodeText, block) {
  var blockKey = block.getKey();
  var id = assignee.id,
    start = assignee.start,
    end = assignee.end;
  var name = nodeText.substr(start, end);
  var emptySelection = SelectionState.createEmpty(blockKey);
  var mentionSelection = emptySelection.merge({
    anchorKey: blockKey,
    anchorOffset: start,
    focusKey: blockKey,
    focusOffset: end
  });
  var mention = {
    mention: {
      id: id,
      name: name
    }
  };
  return {
    mentionSelection: mentionSelection,
    mention: mention
  };
};
exports.createMentionEntityDataFromAssignee = createMentionEntityDataFromAssignee;
var createSearchEntityStrategy = function createSearchEntityStrategy(entityType) {
  return function (contentBlock, callback, contentState) {
    if (!contentState) {
      return;
    }
    contentBlock.findEntityRanges(function (character) {
      var entityKey = character.getEntity();
      if (entityKey === null) {
        return false;
      }
      var entity = contentState.getEntity(entityKey);
      var type = entity.getType();
      return type === entityType;
    }, callback);
  };
};
exports.createSearchEntityStrategy = createSearchEntityStrategy;
var getAssigneesFromBlock = function getAssigneesFromBlock(editorState, block) {
  var content = editorState.getCurrentContent();
  var entities = [];
  var selectedEntity = null;
  var entityType = 'mention';
  block.findEntityRanges(function (character) {
    if (character.getEntity() !== null) {
      var entity = content.getEntity(character.getEntity());
      if (entity.getType() === entityType) {
        selectedEntity = content.getEntity(character.getEntity());
        return true;
      }
    }
    return false;
  }, function (start, end) {
    entities.push({
      entity: selectedEntity,
      start: start,
      end: end
    });
  });
  return entities.map(function (_ref3) {
    var entity = _ref3.entity,
      start = _ref3.start,
      end = _ref3.end;
    return {
      id: entity.data.mention.id,
      start: start,
      end: end
    };
  });
};
exports.getAssigneesFromBlock = getAssigneesFromBlock;