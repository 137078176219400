import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { FormProvider, useForm } from '@wbnr/frontend-shared/lib/forms';
import { IconButton, LoadableButton, Typography } from '@wbnr/ui';

import { BrandingFormValues, BrandingInitialValues } from '../../types';
import { getBrandingPropName } from '../../utils';
import AdditionalAgreements from '../AdditionalAgreements';
import BrandingBrandColor from '../BrandingBrandColor';
import BrandingLogo from '../BrandingLogo';
import BrandingNewsletters from '../BrandingNewsletters';
import BrandingPauseBackground from '../BrandingPauseBackground';
import BrandingTitle from '../BrandingTitle';
import BrandingWebinar from '../BrandingWebinar';

import styles from './BrandingForm.module.scss';

interface Props {
    defaultValues: BrandingInitialValues;
    onSubmit: (data: BrandingFormValues) => Promise<void>;
    customizableAgreements: boolean;
}

const i18nPath = 'business.brandingPage';

const BrandingForm = ({ defaultValues, onSubmit, customizableAgreements }: Props) => {
    const history = useHistory();
    const { t } = useTranslation();

    const { submit, ...form } = useForm({ defaultValues }, { onSubmit });
    const {
        formState: { isDirty },
        watch,
        reset,
    } = form;

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);

    return (
        <div className={styles.root}>
            <FormProvider {...form}>
                <form>
                    <div className={styles.header}>
                        <IconButton onClick={() => history.push('/business/branding')}>
                            <ArrowBackIcon />
                        </IconButton>

                        <Typography variant="h6" className={styles.title}>
                            {watch(getBrandingPropName('title')) || t(`${i18nPath}.untitled`)}
                        </Typography>

                        <LoadableButton
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={!isDirty}
                            onClick={submit}
                            className={styles.submit}
                        >
                            {t('save')}
                        </LoadableButton>
                    </div>

                    <div className={styles.body}>
                        <div className={styles.section}>
                            <BrandingTitle />
                        </div>
                        <div className={styles.section}>
                            <BrandingNewsletters />
                        </div>
                        <div className={styles.section}>
                            <BrandingWebinar />
                        </div>
                        <div className={styles.section}>
                            <BrandingLogo />
                        </div>
                        <div className={styles.section}>
                            <BrandingPauseBackground />
                        </div>
                        <div className={styles.section}>
                            <BrandingBrandColor />
                        </div>
                        <div className={styles.section}>
                            <AdditionalAgreements customizableAgreements={customizableAgreements} />
                        </div>
                    </div>
                </form>
            </FormProvider>
        </div>
    );
};

export default BrandingForm;
