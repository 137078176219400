import styles from './SettingViewField.module.scss';

type Props = {
    text: string;
    value?: string | null;
};

export const SettingViewField = ({ text, value }: Props) => {
    if (!value) {
        return null;
    }
    return (
        <div className={styles.field}>
            <span className={styles.label}>{text}</span>
            <span className={styles.value}>{value}</span>
        </div>
    );
};
