import uniq from 'lodash/uniq';
import { FC, createContext, useCallback } from 'react';

import {
    getDevices,
    getDevicesSearch,
    createDevice,
    updateDevice,
    deleteDevice,
} from '@wbnr/frontend-shared/lib/api/business-api';
import { Devices, DevicesItem } from '@wbnr/frontend-shared/lib/api/business-api/types';
import { getUserOrganizationId, useAccountUser } from '@wbnr/frontend-shared/lib/data/user';
import { ResetStateAction, useResource } from '@wbnr/frontend-shared/lib/data/useResource';
import { useBinded } from '@wbnr/frontend-shared/lib/hooks/useBinded';

import type {
    DevicesAction,
    DeviceAddAction,
    DevicesSearchAction,
    DeviceCreateAction,
    DeviceDeleteAction,
    DeviceUpdateAction,
    DevicesContextData,
    GetDevices,
    GetDevicesSearch,
    CreateDevice,
    UpdateDevice,
    DeleteDevice,
    DeviceDeleteActionPayload,
} from './interfaces';

const DEFAULT_VALUES: Devices = {
    nextPageCursor: '',
    items: [],
};

const INITIAL_VALUES: DevicesReducerState = {
    devices: DEFAULT_VALUES,
    searchDevices: null,
};

const ERROR_MESSAGE_ORIGANIZATION_ID = 'Organization Id is required';

interface DevicesReducerState {
    devices: Devices;
    searchDevices: Devices | null;
}

const devicesReducer = (
    state: DevicesReducerState | null = INITIAL_VALUES,
    action:
        | DevicesAction
        | DeviceAddAction
        | DevicesSearchAction
        | DeviceCreateAction
        | DeviceDeleteAction
        | DeviceUpdateAction
        | ResetStateAction<Partial<DevicesReducerState>, any>,
): DevicesReducerState => {
    switch (action.type) {
        case 'get':
            return {
                ...(state || INITIAL_VALUES),
                devices: {
                    ...state?.devices,
                    ...action.payload,
                },
            };
        case 'getSearch':
            return {
                ...(state || INITIAL_VALUES),
                searchDevices: action?.payload,
            };
        case 'add': {
            const prevState = state || INITIAL_VALUES;

            return {
                ...prevState,
                devices: {
                    ...prevState.devices,
                    nextPageCursor: action.payload.nextPageCursor,
                    items: uniq([...prevState.devices.items, ...action.payload.items]),
                },
            };
        }
        case 'create':
            return {
                ...(state || INITIAL_VALUES),
                devices: {
                    ...state?.devices,
                    items: [
                        ...(state?.devices.items || INITIAL_VALUES.devices.items),
                        action.payload,
                    ],
                },
            };
        case 'delete':
            return {
                ...(state || INITIAL_VALUES),
                devices: {
                    ...state?.devices,
                    items: state?.devices?.items
                        ? state.devices.items.filter((device) => device.id !== action.payload.id)
                        : INITIAL_VALUES.devices.items,
                },
            };
        case 'update':
            return {
                ...(state || INITIAL_VALUES),
                devices: {
                    ...state?.devices,
                    items: state?.devices.items
                        ? state?.devices.items.map((device) =>
                              device.id === action.payload.id
                                  ? { ...device, ...action.payload }
                                  : device,
                          )
                        : INITIAL_VALUES.devices.items,
                },
            };
        default:
            throw new Error(`Unsupported action type ${(action as any).type}`);
    }
};

const initialGetDevices = async (...args: Parameters<typeof getDevices>) => {
    const devices = await getDevices(...args);

    return {
        devices,
        searchDevices: INITIAL_VALUES.searchDevices,
    };
};

export const DevicesContext = createContext<DevicesContextData>({} as DevicesContextData);

export const DevicesContextProvider: FC = ({ children }) => {
    const [user] = useAccountUser();
    const organizationId = getUserOrganizationId(user);
    const hasOrganizationId = typeof organizationId === 'number';

    const {
        data,
        loading: loadingDevices,
        dispatch: dispatchDevice,
    } = useResource(
        {
            request: useBinded(hasOrganizationId ? initialGetDevices : undefined, {
                organizationId,
            }),
        },
        devicesReducer,
    );

    const fetchDevicesDispatch = useCallback<GetDevices>(
        async (params) => {
            if (!hasOrganizationId) {
                return Promise.reject(ERROR_MESSAGE_ORIGANIZATION_ID);
            }

            const result = await getDevices({
                ...params,
                organizationId,
            });

            dispatchDevice({ type: 'add', payload: result });

            return result;
        },
        [dispatchDevice, hasOrganizationId, organizationId],
    );

    const getDevicesDispatch = useCallback<GetDevices>(
        async (params) => {
            if (!hasOrganizationId) {
                return Promise.reject(ERROR_MESSAGE_ORIGANIZATION_ID);
            }

            const result = await getDevices({
                ...params,
                organizationId,
            });

            dispatchDevice({ type: 'get', payload: result });

            return result;
        },
        [dispatchDevice, hasOrganizationId, organizationId],
    );

    const getDevicesSearchDispatch = useCallback<GetDevicesSearch>(
        async (params) => {
            if (!hasOrganizationId) {
                return Promise.reject(ERROR_MESSAGE_ORIGANIZATION_ID);
            }

            const result = await getDevicesSearch({
                ...params,
                organizationId,
            });

            dispatchDevice({ type: 'getSearch', payload: result });

            return result;
        },
        [dispatchDevice, hasOrganizationId, organizationId],
    );

    const clearDevicesSearchDispatch = useCallback(() => {
        dispatchDevice({ type: 'getSearch', payload: null });
    }, [dispatchDevice]);

    const createDeviceDispatch = useCallback<CreateDevice>(
        async (params) => {
            if (!hasOrganizationId) {
                return Promise.reject(ERROR_MESSAGE_ORIGANIZATION_ID);
            }

            const result = await createDevice({
                ...params,
                organizationId,
            });

            dispatchDevice({ type: 'create', payload: result });

            return result;
        },
        [dispatchDevice, hasOrganizationId, organizationId],
    );

    const updateDeviceDispatch = useCallback<UpdateDevice>(
        async (params) => {
            if (!hasOrganizationId) {
                return Promise.reject(ERROR_MESSAGE_ORIGANIZATION_ID);
            }

            await updateDevice({
                ...params,
                organizationId,
            });

            const payload: DevicesItem = {
                id: params.deviceId,
                name: params.name,
                uri: params.uri,
            };

            dispatchDevice({
                type: 'update',
                payload,
            });

            return payload;
        },
        [dispatchDevice, hasOrganizationId, organizationId],
    );

    const deleteDeviceDispatch = useCallback<DeleteDevice>(
        async (params) => {
            if (!hasOrganizationId) {
                return Promise.reject(ERROR_MESSAGE_ORIGANIZATION_ID);
            }

            await deleteDevice({
                ...params,
                organizationId,
            });

            const payload: DeviceDeleteActionPayload = {
                id: params.deviceId,
            };

            dispatchDevice({
                type: 'delete',
                payload,
            });
        },
        [dispatchDevice, hasOrganizationId, organizationId],
    );

    const hasDevices = Boolean(data?.devices?.items.length);
    const hasSearchDevices = Boolean(data?.searchDevices?.items.length);

    return (
        <DevicesContext.Provider
            value={{
                devices: data?.devices || ({} as Devices),
                searchDevices: data?.searchDevices || ({} as Devices),
                fetchDevices: fetchDevicesDispatch,
                getDevices: getDevicesDispatch,
                getDevicesSearch: getDevicesSearchDispatch,
                clearDevicesSearch: clearDevicesSearchDispatch,
                createDevice: createDeviceDispatch,
                updateDevice: updateDeviceDispatch,
                deleteDevice: deleteDeviceDispatch,
                organizationId,
                loadingDevices,
                hasDevices,
                hasSearchDevices,
            }}
        >
            {children}
        </DevicesContext.Provider>
    );
};
