import customProtocolCheck from 'custom-protocol-check';
import { useCallback, useEffect, useMemo } from 'react';
import { Trans } from 'react-i18next';

import { useAccountUser } from '@wbnr/frontend-shared/lib/data/user';
import { A, Button, InfoScreenHorizontal, Typography } from '@wbnr/ui';

import image from './illustration_059.png';
import styles from './SignInDesktopApp.module.scss';

const OPEN_APP_WAIT = 1500;

export const SignInDesktopApp = () => {
    const [user] = useAccountUser();
    const desktopAppLink = useMemo(
        () => `wbnr://${document.location.host}/?sessionId=${user.sessionId}`,
        [user.sessionId],
    );
    const tryOpenApp = useCallback(() => {
        customProtocolCheck(
            desktopAppLink,
            () => {},
            () => {},
            OPEN_APP_WAIT,
            () => {
                // браузер не поддерживается в custom-protocol-check, пытаемся открыть приложение
                window.location.href = desktopAppLink;
            },
        );
    }, [desktopAppLink]);
    useEffect(() => {
        tryOpenApp();
    }, [tryOpenApp]);
    return (
        <InfoScreenHorizontal img={<img src={image} alt="" />} classes={{ root: styles.root }}>
            <Typography variant="body1" className={styles.text}>
                <Trans
                    i18nKey={'auth.desktopApp.text'}
                    components={{
                        bold: <span className={styles.marked} />,
                    }}
                />
            </Typography>
            <Button
                color="primary"
                variant="contained"
                onClick={tryOpenApp}
                className={styles.button}
            >
                <Trans i18nKey={'auth.desktopApp.button'} />
            </Button>
            <Typography variant="body1">
                <Trans i18nKey={'auth.desktopApp.troubles'} />
                <br />
                <A href="/meetings" color={'primary'} className={styles.marked}>
                    <Trans i18nKey={'auth.desktopApp.browserLink'} />
                </A>
            </Typography>
        </InfoScreenHorizontal>
    );
};
