import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useForm, useField } from '@wbnr/frontend-shared/lib/forms';
import { ProctoringSettings } from '@wbnr/frontend-shared/lib/types/Proctoring';
import { TextField, Button } from '@wbnr/ui';

const i18nPath = 'business.proctoringPage';

interface ProctoringSettingsFormProps {
    data: ProctoringSettings | null | undefined;
    submitAction: (settings: ProctoringSettings) => void;
}

const defaultData = { url: '', jwtSecretKey: '', apiKey: '' };

const ProctoringSettingsForm = ({ data, submitAction }: ProctoringSettingsFormProps) => {
    const { t } = useTranslation();

    const [canCreateSettings, setCanCreateSettings] = useState(false);
    const [isFormBlocked, setIsFormBlocked] = useState(true);
    const isFieldsBlocked = Boolean(data) && isFormBlocked;

    const { control, submit, watch } = useForm(
        { defaultValues: data || defaultData },
        {
            onSubmit: async (settings) => {
                setIsFormBlocked(true);
                submitAction(settings);
            },
        },
    );

    const fieldConfig = { rules: { required: true } };
    const urlField = useField(control, 'url', fieldConfig);
    const jwtField = useField(control, 'jwtSecretKey', fieldConfig);
    const apiField = useField(control, 'apiKey', fieldConfig);
    const formWatch = watch();

    useEffect(() => {
        if (!data) {
            const { url, jwtSecretKey, apiKey } = formWatch;
            if (url.length > 0 && jwtSecretKey.length > 0 && apiKey.length > 0) {
                setCanCreateSettings(true);
            } else {
                setCanCreateSettings(false);
            }
        }
    }, [formWatch, data]);

    return (
        <form>
            <TextField
                {...urlField}
                placeholder={t(`${i18nPath}.fields.url`)}
                disabled={isFieldsBlocked}
                fullWidth
            />
            <TextField
                {...jwtField}
                placeholder={t(`${i18nPath}.fields.secretOrKey`)}
                disabled={isFieldsBlocked}
                fullWidth
            />
            <TextField
                {...apiField}
                placeholder={t(`${i18nPath}.fields.apiKey`)}
                disabled={isFieldsBlocked}
                fullWidth
            />
            {data ? (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={isFormBlocked ? () => setIsFormBlocked(false) : submit}
                >
                    {isFormBlocked ? t(`${i18nPath}.change`) : t(`${i18nPath}.saveChanges`)}
                </Button>
            ) : (
                <Button
                    disabled={!canCreateSettings}
                    variant="contained"
                    color="primary"
                    onClick={submit}
                >
                    {t(`${i18nPath}.save`)}
                </Button>
            )}
        </form>
    );
};

export default ProctoringSettingsForm;
