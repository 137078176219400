import { UserIcon, CloudIcon, RecordIcon, ClockIcon } from '../../components/TrialDescriptionFeatures';
import trialImages from '../../components/TrialDescriptionFeatures/images';
import useTrialFeatures from '../../components/TrialDescriptionFeatures/useTrialFeatures';
var i18nPath = 'webinarTrialDialog';
var MAIN_FEATURES = [{
  Icon: ClockIcon,
  trialText: "".concat(i18nPath, ".noTimeLimits.inTrial"),
  text: "".concat(i18nPath, ".noTimeLimits.title")
}, {
  Icon: UserIcon,
  trialText: "".concat(i18nPath, ".users.inTrial"),
  text: "".concat(i18nPath, ".users.title")
}, {
  Icon: CloudIcon,
  trialText: "".concat(i18nPath, ".storage.inTrial"),
  text: "".concat(i18nPath, ".storage.title")
}, {
  Icon: RecordIcon,
  text: "".concat(i18nPath, ".records"),
  isInTrial: true
}];
var OTHER_FEATURES = [{
  text: "".concat(i18nPath, ".miro.title"),
  description: "".concat(i18nPath, ".miro.description"),
  testId: 'miro',
  ruImage: trialImages.img8_ru,
  enImage: trialImages.img8_en
}, {
  text: "".concat(i18nPath, ".utm.title"),
  description: "".concat(i18nPath, ".utm.description"),
  testId: 'utm',
  ruImage: trialImages.img2_ru,
  enImage: trialImages.img2_en,
  isInTrial: true
}, {
  text: "".concat(i18nPath, ".attentionControl.title"),
  description: "".concat(i18nPath, ".attentionControl.description"),
  testId: 'attentionControl',
  ruImage: trialImages.img3_ru,
  enImage: trialImages.img3_en,
  isInTrial: true
}, {
  text: "".concat(i18nPath, ".background.title"),
  description: "".concat(i18nPath, ".background.description"),
  testId: 'background',
  ruImage: trialImages.img7_ru,
  enImage: trialImages.img7_en
}, {
  text: "".concat(i18nPath, ".banners.title"),
  description: "".concat(i18nPath, ".banners.description"),
  testId: 'banners',
  ruImage: trialImages.img1_ru,
  enImage: trialImages.img1_en,
  isInTrial: true
}, {
  text: "".concat(i18nPath, ".groupwork.title"),
  description: "".concat(i18nPath, ".groupwork.description"),
  testId: 'groupwork',
  ruImage: trialImages.img5_ru,
  enImage: trialImages.img5_en,
  isInTrial: true
}, {
  text: "".concat(i18nPath, ".statistics.title"),
  description: "".concat(i18nPath, ".statistics.description"),
  testId: 'statistics',
  ruImage: trialImages.img6_ru,
  enImage: trialImages.img6_en
}, {
  text: "".concat(i18nPath, ".branding.title"),
  description: "".concat(i18nPath, ".branding.description"),
  testId: 'branding',
  ruImage: trialImages.img4_ru,
  enImage: trialImages.img4_en,
  isInTrial: true
}, {
  text: "".concat(i18nPath, ".multipleEvents.title"),
  description: "".concat(i18nPath, ".multipleEvents.description"),
  testId: 'multipleEvents',
  isInTrial: true
}];
var useFeaturesDescription = function useFeaturesDescription() {
  var _useTrialFeatures = useTrialFeatures({
      mainFeatures: MAIN_FEATURES,
      otherFeatures: OTHER_FEATURES
    }),
    mainFeaturesMemoized = _useTrialFeatures.mainFeaturesMemoized,
    otherFeaturesMemoized = _useTrialFeatures.otherFeaturesMemoized;
  return {
    mainFeatures: mainFeaturesMemoized,
    otherFeatures: otherFeaturesMemoized
  };
};
export default useFeaturesDescription;