function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var ua = navigator.userAgent;
export var OS_WIN = 'win';
export var OS_MAC = 'mac';
export var OS_LINUX = 'linux';
export var OS_IOS = 'ios';
export var OS_ANDROID = 'android';
export var isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints;
export var os;
export var isMacOsBigSur = false;
export var isIOS = checkIsIOS();
export var isAndroid = checkIsAndroid();
export var isMobile = isIOS || isAndroid;
export var isIOSPretendsToBeMac = checkIsIOSPretendsToBeMac();
if (/Macintosh/gi.test(ua)) {
  os = OS_MAC;
  if (/((Mac OS X 10_16)|(Mac OS X 10\.16)|(Mac OS X 11))/gi.test(ua)) {
    isMacOsBigSur = true;
  }
} else if (/Android/gi.test(ua)) {
  os = OS_ANDROID;
} else if (/Linux/gi.test(ua)) {
  os = OS_LINUX;
} else if (/iPhone|iPod|iPad/.test(ua) && !window.MSStream) {
  os = OS_IOS;
} else {
  os = OS_WIN;
}
export var isElectron = false;
if (/Electron/gi.test(ua)) {
  isElectron = true;
}
var _ref = ua.match(/(opera|yabrowser|chrome|safari|firefox|msie|trident|edge(?=\/))\/?\s*([\d.]+)/i) || [],
  _ref2 = _slicedToArray(_ref, 3),
  name = _ref2[1],
  version = _ref2[2];
export { name, version };
if (!name) {
  name = 'Unknown';
} else if (/trident/i.test(name)) {
  name = 'Internet Explorer';
  var _ref3 = /\brv[ :]+([\d.]+)/g.exec(ua) || [];
  var _ref4 = _slicedToArray(_ref3, 2);
  version = _ref4[1];
} else if (name.toLowerCase() === 'msie') {
  name = 'Internet Explorer';
} else if (name === 'Chrome') {
  var opera = ua.match(/\bOPR\/([\d.]+)/);
  var edge = ua.match(/\bEdge\/([\d.]+)/);
  var chromiumEdge = ua.match(/\bEdg\/([\d.]+)/);
  var yaBrowser = ua.match(/\bYaBrowser\/([\d.]+)/);
  if (opera) {
    name = 'Opera';
    version = opera[1];
  }
  if (edge) {
    name = 'Edge';
    version = edge[1];
  }
  if (chromiumEdge) {
    name = 'ChromiumEdge';
    version = chromiumEdge[1];
  }
  if (yaBrowser) {
    name = 'YaBrowser';
    version = yaBrowser[1];
  }
} else if (name === 'Safari') {
  var tmp = ua.match(/version\/([\d.]+)/i);
  if (tmp) {
    version = tmp[1];
  }
}
if (!version) {
  version = '';
}
function getTransitionEndEventName() {
  var transitionsMap = {
    transition: 'transitionend',
    OTransition: 'otransitionend',
    MozTransition: 'transitionend',
    WebkitTransition: 'webkitTransitionEnd'
  };
  var el = document.createElement('div');
  var key = Object.keys(transitionsMap).find(function (k) {
    return typeof el.style[k] !== 'undefined';
  });
  return key ? transitionsMap[key] : null;
}
export var numericVersion = parseInt(version, 10);
export var isFirefox = name.toLowerCase() === 'firefox';
export var isEdge = name.toLowerCase() === 'edge';
export var isChromiumEdge = name.toLowerCase() === 'chromiumedge';
export var isIE = name.toLowerCase() === 'internet explorer';
export var isChrome = name.toLowerCase() === 'chrome';
export var isOpera = name.toLowerCase() === 'opera';
export var isYaBrowser = name.toLowerCase() === 'yabrowser';
export var isSafari = name.toLowerCase() === 'safari';
export var isRealSafariOniOS = isIOS && isSafari && !/(CriOS|FxiOS|OPT|EdgiOS)/.test(ua);
export var endTransitionEvent = getTransitionEndEventName();
var isPictureInPictureSupported = function isPictureInPictureSupported() {
  var video = document.createElement('video');
  return !!video.requestPictureInPicture && typeof video.requestPictureInPicture === 'function' && document.pictureInPictureEnabled && !isYaBrowser;
};
export var PICTURE_IN_PICTURE_SUPPORTED = isPictureInPictureSupported();
var isMac = os === OS_MAC;
function checkIsIOS() {
  if (/iPhone|iPod|iPad/.test(navigator.userAgent) && !window.MSStream) {
    return true;
  }
  return false;
}
function checkIsIOSPretendsToBeMac() {
  if (/iPhone|iPod|iPad/.test(navigator.userAgent) && !window.MSStream) {
    return false;
  }
  if (isMac) {
    if (Number(window.navigator.maxTouchPoints) > 0) {
      return true;
    }
    if (/CriOS/.test(ua)) {
      return true;
    }
    if (/FxiOS/.test(ua)) {
      return true;
    }
    if (/EdgiOS/.test(ua)) {
      return true;
    }
  }
  return false;
}
function checkIsAndroid() {
  return navigator.userAgent.toLowerCase().indexOf('android') > -1;
}