import { useEffect } from 'react';

import { useMixpanelTracking } from '@wbnr/frontend-shared/lib/analytics/mixpanel/useMixpanelTracking';
import { formatProlongationData } from '@wbnr/frontend-shared/lib/api/tariffs/formatTariffsData';
import LoadingScreen from '@wbnr/frontend-shared/lib/components/LoadingScreen';
import { useFeatureSettings } from '@wbnr/frontend-shared/lib/data/featureSettings';
import { useTariffsResource } from '@wbnr/frontend-shared/lib/data/tariffs/useTariffsResource';
import { useAccountUser, isFreeProlongation } from '@wbnr/frontend-shared/lib/data/user';

import BusinessLayout from '../BusinessLayout';

import { getPaymentUrl } from './api/getPaymentUrl';
import Carousel from './carousel/Carousel';
import TariffCard from './tariffCard/TariffCard';
import styles from './Tariffs.module.scss';
import { CardOrder } from './types';

const Tariffs = () => {
    const [user] = useAccountUser();
    const { data } = useTariffsResource();
    const { track } = useMixpanelTracking();
    const features = useFeatureSettings();
    const isInvoicing = features?.data?.invoicing;
    const hasFreeTariff = Boolean(isFreeProlongation(user?.activeProlongation));

    const currentTariffData = formatProlongationData({
        ...user.activeProlongation.product,
        paidBefore: user.activeProlongation.paidBefore,
    });

    const allTariffs = hasFreeTariff
        ? data
        : data?.map((el) =>
              el.tariffId === currentTariffData.tariffId
                  ? { ...currentTariffData, prices: el.prices }
                  : el,
          );

    const canRender = allTariffs && user && features?.data;

    const onPay = async (order: CardOrder) => {
        const { tariffName, ...orderData } = order;
        if (isInvoicing) {
            track('tariff_buy_payment_method_selected', {
                tariff_name: order.tariffName,
                period: order.duration,
                payment_method: 'card',
            });
        } else {
            track('press_buybtn', { tariff_name: tariffName, period: order.duration });
        }
        const { paymentUrl } = await getPaymentUrl({ ...orderData, userId: user.id });
        if (paymentUrl) {
            window.location.href = paymentUrl;
        }
    };

    useEffect(() => {
        let recommendedTariffName = null;
        if (user.recommendedTariffId) {
            const tariff = data?.find((d) => d.tariffId === user.recommendedTariffId);
            recommendedTariffName = tariff?.name;
        }

        if (data) {
            track('tariffs_page_view', { suggested_tariff_name: recommendedTariffName });
        }
    }, [track, data, user.recommendedTariffId]);

    const { recommendedTariffId } = user;

    return (
        <BusinessLayout>
            {canRender ? (
                <>
                    <div className={styles.tariffsListContainer}>
                        {hasFreeTariff && (
                            <TariffCard
                                tariff={currentTariffData}
                                isAutoAS={features.data.autoAS}
                                isInvoicing={isInvoicing}
                                email={user.email}
                            />
                        )}

                        <Carousel hasFreeTariff={hasFreeTariff}>
                            {allTariffs.map((tariff) => (
                                <TariffCard
                                    isRecommended={tariff.tariffId === recommendedTariffId}
                                    key={tariff.tariffId}
                                    onPay={onPay}
                                    tariff={tariff}
                                    isAutoAS={features.data.autoAS}
                                    isInvoicing={isInvoicing}
                                    email={user.email}
                                />
                            ))}
                        </Carousel>
                    </div>
                </>
            ) : (
                <LoadingScreen />
            )}
        </BusinessLayout>
    );
};

export default Tariffs;
