import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SSOSetting } from '@wbnr/frontend-shared/lib/api/business-api/types';
import { createTestIdProps, makeStyles, SwitchField } from '@wbnr/ui';

import { useSSO } from '../SSOContext';

import { MembershipList } from './MembershipList';

const BASE_TEST_ID = 'SSOSettingsEdit';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: '600px',
    },
}));

export const SSOSettingsEdit = () => {
    const { t } = useTranslation();
    const localStyles = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { ssoSettings, loadingSSO, updateSSO } = useSSO();
    const [loading, setLoading] = useState(false);
    const handleChange = (field: keyof SSOSetting) => async (value: boolean) => {
        if (loading) {
            return;
        }
        const newValue: Partial<SSOSetting> = { [field]: value };
        if (field === 'loginToPersonalAccount' && !value) {
            newValue.prohibitionOfRegistrationWithoutSso = false;
        }
        if (updateSSO) {
            setLoading(true);
            try {
                await updateSSO({ ...ssoSettings, ...newValue });
            } catch (error) {
                enqueueSnackbar(t('unknownError'), { variant: 'error' });
            } finally {
                setLoading(false);
            }
        }
    };
    if (loadingSSO) {
        return null;
    }

    return (
        <>
            <div className={localStyles.root}>
                <SwitchField
                    value={ssoSettings?.loginToPersonalAccount || false}
                    onChange={handleChange('loginToPersonalAccount')}
                    label={t('business.sso.edit.login.label')}
                    helperText={t('business.sso.edit.login.hint')}
                    {...createTestIdProps(BASE_TEST_ID, 'loginToPersonalAccount')}
                />
                {ssoSettings?.loginToPersonalAccount && (
                    <SwitchField
                        value={ssoSettings?.prohibitionOfRegistrationWithoutSso || false}
                        onChange={handleChange('prohibitionOfRegistrationWithoutSso')}
                        label={t('business.sso.edit.signup')}
                        {...createTestIdProps(BASE_TEST_ID, 'prohibitionOfRegistrationWithoutSso')}
                    />
                )}
                <SwitchField
                    value={ssoSettings?.isNeedSsoForAll || false}
                    onChange={handleChange('isNeedSsoForAll')}
                    label={t('business.sso.edit.course.label')}
                    helperText={t('business.sso.edit.course.hint')}
                    {...createTestIdProps(BASE_TEST_ID, 'isNeedSsoForAll')}
                />
            </div>
            {!ssoSettings?.isNeedSsoForAll && <MembershipList />}
        </>
    );
};
