import { BrandingFull } from '@wbnr/frontend-shared/lib/types/Branding';

import { MultibrandingMethods } from '../../types';
import BrandingListCard from '../BrandingListCard';
import BrandingListCreate from '../BrandingListCreate';

import styles from './BrandingList.module.scss';

interface Props extends MultibrandingMethods {
    list: BrandingFull[];
    isBrandingEnabled: boolean;
    isMultibranding: boolean;
}

const BrandingList = ({
    list,
    isBrandingEnabled,
    isMultibranding,
    onCreateBranding,
    ...methods
}: Props) => {
    return (
        <div className={styles.root}>
            <ul className={styles.list}>
                <li className={styles.item}>
                    <BrandingListCreate
                        suggestBranding={!isBrandingEnabled}
                        suggestMultibranding={isBrandingEnabled && !isMultibranding}
                        onCreateBranding={onCreateBranding}
                    />
                </li>
                {list.map((item) => (
                    <li key={item.id} className={styles.item}>
                        <BrandingListCard
                            id={item.id}
                            title={item.title}
                            logo={item.interfaceLogoImage?.url}
                            isDefault={item.isDefault}
                            createAt={item.createAt}
                            updateAt={item.updateAt}
                            user={item.updateUser}
                            isMultibranding={isMultibranding}
                            {...methods}
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default BrandingList;
