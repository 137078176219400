import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';

import { useMixpanelTracking } from '@wbnr/frontend-shared/lib/analytics/mixpanel/useMixpanelTracking';
import { getQualification } from '@wbnr/frontend-shared/lib/api/qualification';
import { useUserActivity } from '@wbnr/frontend-shared/lib/data/userActivity/useUserActivity';
import { useUnmountedRef } from '@wbnr/frontend-shared/lib/hooks/useUnmountedRef';
import { useMeetingsVisitedActivity } from '@wbnr/meetings-control-panel/lib/hooks/useMeetingsVisitedActivity';

import MeetingsPromoDialog from '../MeetingsPromoDialog';

const TIME_TO_SHOW = 3;

const MeetingsControlPanelPromo = ({
    onDecideShow,
    onFinish,
}: {
    onDecideShow: (decidedToShow: boolean) => void;
    onFinish: () => void;
}) => {
    const { push } = useHistory();

    const decision = useRef<boolean | undefined>(undefined);
    const unmountedRef = useUnmountedRef();
    const [promoShow, setPromoShow] = useState(false);

    const { track } = useMixpanelTracking();

    const { activityData, fulfilled, setUserActivity } = useUserActivity({
        token: USER_ACTIVITY_TOKEN,
    });
    const { visited, fulfilled: meetingsVisitedFulfilled } = useMeetingsVisitedActivity();

    const activityCounter = activityData?.activityCounter || 0;

    useEffect(() => {
        if (promoShow) {
            track('meetings_offer_show', {
                source: MIXPANEL_SOURCE_IDENTIFIER,
            });
        }
    }, [setUserActivity, promoShow, track]);

    useEffect(() => {
        if (fulfilled && meetingsVisitedFulfilled && decision.current === undefined) {
            decision.current = true;

            const qualificationPromise =
                activityCounter <= TIME_TO_SHOW && !visited
                    ? getQualification()
                          .then((qualification) => qualification.target)
                          .catch(() => {})
                    : Promise.resolve();

            qualificationPromise.then((target) => {
                const timeToShow = target === 'meetings' ? 0 : TIME_TO_SHOW;
                const shouldPromoShow = !visited && activityCounter === timeToShow;

                if (!visited && activityCounter <= timeToShow && !unmountedRef.current) {
                    onDecideShow(shouldPromoShow);
                    setPromoShow(shouldPromoShow);
                    setUserActivity();
                }
                if (!shouldPromoShow) {
                    onFinish();
                }
            });
        }
    }, [
        fulfilled,
        meetingsVisitedFulfilled,
        visited,
        activityCounter,
        setUserActivity,
        onDecideShow,
        unmountedRef,
        onFinish,
    ]);

    return (
        <MeetingsPromoDialog
            onPromoAgreeClick={() => {
                track('meetings_offer_interest', {
                    source: MIXPANEL_SOURCE_IDENTIFIER,
                });

                onFinish();

                push('/meetings');
            }}
            onPromoDenyClick={() => {
                track('meetings_offer_close', {
                    source: MIXPANEL_SOURCE_IDENTIFIER,
                });

                setPromoShow(false);
                onFinish();
            }}
            opened={promoShow}
        />
    );
};

const USER_ACTIVITY_TOKEN = 'meetingsPromoDialogShown';
const MIXPANEL_SOURCE_IDENTIFIER = 'webinar_list_dialog';

export default MeetingsControlPanelPromo;
