import { useCustomDialog } from '@wbnr/frontend-shared/lib/dialogs';

import { AddWebhookDialog } from './AddWebhookDialog';

export const useAddWebhookDialog = ({
    webhooksLimit,
    createWebhook,
}: {
    webhooksLimit: number;
    createWebhook: (name: string, endpointUrl: string) => Promise<void>;
}) => {
    const [openDialog, closeDialog] = useCustomDialog(
        ({ opened, closeDialog: closeDialogHandler }) => {
            const onSubmit = async (name: string, endpointUrl: string) => {
                await createWebhook(name, endpointUrl);
                closeDialogHandler();
            };

            return (
                <AddWebhookDialog
                    opened={opened}
                    webhooksLimit={webhooksLimit}
                    onSubmit={onSubmit}
                    onClose={closeDialogHandler}
                />
            );
        },
        {},
    );
    return [openDialog, closeDialog];
};
