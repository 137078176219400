var calculatedScrollBarWidth = null;
export function getScrollBarWidth() {
  if (calculatedScrollBarWidth !== null) {
    return calculatedScrollBarWidth;
  }

  // Creating invisible container
  var outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  var inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  var scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer);
  calculatedScrollBarWidth = scrollbarWidth;
  return scrollbarWidth;
}