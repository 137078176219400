import { apiFormFetch } from '../apiFetch';
export var createWebhook = function createWebhook(_ref) {
  var name = _ref.name,
    endpointUrl = _ref.endpointUrl;
  return apiFormFetch('POST', '/webhooks/create', {
    name: name,
    endpointUrl: endpointUrl
  }).then(function (res) {
    return res.data;
  });
};