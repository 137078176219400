import classnames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import Link from '@wbnr/frontend-shared/lib/components/Link';
import {
    MenuItem,
    MenuList,
    makeStyles,
    createTestId,
    createTestIdProps,
    dataTestIdKey,
    ComponentTestIdProps,
} from '@wbnr/ui';

import styles from './StatisticsNavigation.module.scss';

interface Item {
    title: string;
    url: string;
    adminOnly?: boolean;
    testKey?: string;
}

const useStyles = makeStyles((theme) => ({
    selected: {
        '&, &:hover': {
            color: '#ffffff',
            backgroundColor: theme.palette.primary.main,
        },
    },
}));

const ROOT_TEST_ID = createTestId('StatisticsNavigation');
const i18nPath = 'statistics';

const BusinessNavigation = () => {
    const { t } = useTranslation();
    const [webinarItems, courseItems] = useGetNavigationItems();

    return (
        <MenuList className={styles.list}>
            <SectionItem
                {...createTestIdProps(ROOT_TEST_ID, 'SectionTitle', 'webinar')}
                title={t(`${i18nPath}.webinarSection`)}
            />
            {webinarItems.map((item) => (
                <NavigationItem key={item.url} title={item.title} url={item.url} />
            ))}
            <SectionItem
                {...createTestIdProps(ROOT_TEST_ID, 'SectionTitle', 'course')}
                title={t(`${i18nPath}.coursesSection`)}
            />
            {courseItems.map((item) => (
                <NavigationItem
                    key={item.url}
                    title={item.title}
                    url={item.url}
                    testKey={item.testKey}
                />
            ))}
        </MenuList>
    );
};

const SectionItem = ({
    title,
    [dataTestIdKey]: dataTestId,
}: { title: string } & ComponentTestIdProps) => {
    return (
        <div className={styles.sectionItem} {...createTestIdProps(dataTestId)}>
            {title}
        </div>
    );
};

const NavigationItem = ({ title, url, testKey }: Pick<Item, 'title' | 'url' | 'testKey'>) => {
    const localStyles = useStyles();

    const path = `/statistics/${url}`;
    const match = useRouteMatch({ path, exact: true });

    return (
        <MenuItem
            {...createTestIdProps(ROOT_TEST_ID, 'NavItem', testKey)}
            className={classnames(styles.item, { [localStyles.selected]: Boolean(match) })}
        >
            <Link to={path} className={styles.link}>
                {title}
            </Link>
        </MenuItem>
    );
};

function useGetNavigationItems() {
    const { t } = useTranslation();

    return useMemo(() => {
        const webinarItems: Item[] = [
            {
                title: t(`${i18nPath}.visits`),
                url: 'visits/all',
                testKey: 'vistits',
                adminOnly: true,
            },
            {
                title: t(`${i18nPath}.channels`),
                url: 'channels/all',
                testKey: 'channels',
                adminOnly: true,
            },
            {
                title: t(`${i18nPath}.participants`),
                url: 'participants/all',
                testKey: 'participants',
                adminOnly: true,
            },
        ];
        const courseItems: Item[] = [
            {
                title: t(`${i18nPath}.visits`),
                url: 'courses/visits/all',
                testKey: 'courses/visits',
                adminOnly: true,
            },
        ];

        return [webinarItems, courseItems];
    }, [t]);
}

export default BusinessNavigation;
