import canvasConfetti, { Options } from 'canvas-confetti';
import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { OfflineBoltIcon } from '@wbnr/icons';
import { ActionDialogBase, DialogContentText, DialogActions, Button } from '@wbnr/ui';

import backdropImageSrc from './assets/backdrop-icon.png';
import styles from './ChecklistCongratsDialog.module.scss';

const CONFETTI_FIRE_INTERVAL = 1500;
const CONFETTI_DEFAULT_PARAMS: Options = {
    particleCount: 400,
    spread: 360,
};

interface ChecklistCongratsDialogProps {
    text?: string;
    onClose: () => void;
}

const ChecklistCongratsDialog = ({ text, onClose }: ChecklistCongratsDialogProps) => {
    const { t } = useTranslation();

    return (
        <ActionDialogBase
            style={{ zIndex: 32000 }}
            maxWidth="lg"
            opened={true}
            onClose={onClose}
            title={t('checklist.congratsDialog.title')}
            classes={{ mainImage: styles.dialogImageWrapper }}
            image={<ImageBlock />}
            actions={
                <DialogActions>
                    <Button color="primary" onClick={onClose}>
                        {t('checklist.congratsDialog.actionButton')}
                    </Button>
                </DialogActions>
            }
        >
            <DialogContentText className={styles.dialogText}>
                {text || t('checklist.congratsDialog.defaultText')}
            </DialogContentText>
        </ActionDialogBase>
    );
};

const ImageBlock = () => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const confettiIntervalRef = useRef<number | null>(null);

    useEffect(() => {
        const confettiFn =
            canvasRef.current &&
            canvasConfetti.create(canvasRef.current, {
                resize: true,
                useWorker: true,
            });

        if (confettiFn) {
            confettiFn(CONFETTI_DEFAULT_PARAMS);

            confettiIntervalRef.current = window.setInterval(() => {
                confettiFn(CONFETTI_DEFAULT_PARAMS);
            }, CONFETTI_FIRE_INTERVAL);
        }

        return () => {
            confettiIntervalRef.current && clearInterval(confettiIntervalRef.current);
        };
    }, []);

    return (
        <div className={styles.imageBlock}>
            <canvas ref={canvasRef} className={styles.confettiCanvas}></canvas>
            <div className={styles.backdropImageContainer}>
                <img src={backdropImageSrc} alt="backdrop" className={styles.backdropImage} />
            </div>
            <OfflineBoltIcon className={styles.checklistIcon} />
        </div>
    );
};

export default ChecklistCongratsDialog;
