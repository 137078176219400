import {
    getIntegrationApiKey,
    renewIntegrationApiKey,
} from '@wbnr/frontend-shared/lib/api/business-api';
import { IntegrationApiKeyEndpointType } from '@wbnr/frontend-shared/lib/api/business-api/types';
import { useApiKey } from '@wbnr/frontend-shared/lib/hooks/useApiKey';
import { useBinded } from '@wbnr/frontend-shared/lib/hooks/useBinded';

import { ExtensionType } from '../types';

export const useApiLogic = ({ extensionType }: ApiLogicParams): ApiLogicReturnedValue => {
    const endpointType = getEndpointType(extensionType);

    const onGetApiKey = useBinded(getIntegrationApiKey, { type: endpointType });
    const onRenewApiKey = useBinded(renewIntegrationApiKey, { type: endpointType });

    const { apiKey, onApiKeyRefresh, isKeyRefreshing } = useApiKey({
        getApiKey: onGetApiKey,
        renewApiKey: onRenewApiKey,
    });

    return {
        apiKey,
        isKeyRefreshing,
        onApiKeyRefresh,
    };
};

const getEndpointType = (extensionType: ExtensionType): IntegrationApiKeyEndpointType => {
    const extensionToEndpointTypeMap: { [key in ExtensionType]: IntegrationApiKeyEndpointType } = {
        googleCalendar: 'google',
        outlook: 'outlook',
    };

    return extensionToEndpointTypeMap[extensionType];
};

type ApiLogicParams = {
    extensionType: ExtensionType;
};

type ApiLogicReturnedValue = {
    apiKey: string;
    isKeyRefreshing: boolean;
    onApiKeyRefresh(): void;
};
