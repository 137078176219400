"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _draftJs = _interopRequireDefault(require("draft-js"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var EditorState = _draftJs.default.EditorState,
  Modifier = _draftJs.default.Modifier;
var blockDataPlugin = function blockDataPlugin() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref$returnDataFilter = _ref.returnDataFilter,
    returnDataFilter = _ref$returnDataFilter === void 0 ? defaultReturnDataFilter : _ref$returnDataFilter;
  return {
    handleReturn: function handleReturn(e, editorState, _ref2) {
      var setEditorState = _ref2.setEditorState;
      var contentState = editorState.getCurrentContent();
      var selection = editorState.getSelection();
      var block = contentState.getBlockForKey(selection.getStartKey());
      if (block) {
        var data = block.getData();
        var newContentState = Modifier.splitBlock(contentState, selection);
        var splitState = EditorState.push(editorState, newContentState, 'split-block');
        var newContentStateWithData = Modifier.mergeBlockData(splitState.getCurrentContent(), splitState.getSelection(), returnDataFilter(data, splitState));
        setEditorState(EditorState.push(editorState, newContentStateWithData, 'change-block-data'));
        return 'handled';
      }
      return 'not-handled';
    }
  };
};
var _default = blockDataPlugin;
exports.default = _default;
function defaultReturnDataFilter(data) {
  return data;
}