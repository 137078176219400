export var MEETINGS_EDITOR_ONBOARDING_GROUP = 'meetings_editor';
export var MEETINGS_EDITOR_ONBOARDING_IDS = {
  TITLE: 'title',
  DATE: 'dateSetting',
  ADD_PARTICIPANTS: 'addParticipants',
  GO_TO_MEETING: 'goToMeeting',
  USE_ACTIVE_SPEAKER: 'useActiveSpeaker'
};
export var MEETINGS_EDITOR_ONBOARDING_STEPS = [MEETINGS_EDITOR_ONBOARDING_IDS.TITLE, MEETINGS_EDITOR_ONBOARDING_IDS.DATE, MEETINGS_EDITOR_ONBOARDING_IDS.ADD_PARTICIPANTS, MEETINGS_EDITOR_ONBOARDING_IDS.GO_TO_MEETING, MEETINGS_EDITOR_ONBOARDING_IDS.USE_ACTIVE_SPEAKER];
export var MEETINGS_INTEGRATIONS_BANNER_GROUP = 'meetings_integrations_banner';
export var MEETINGS_INTEGRATIONS_BANNER_IDS = {
  MENU: 'menu'
};
export var MEETINGS_INTEGRATIONS_BANNER_STEPS = [MEETINGS_INTEGRATIONS_BANNER_IDS.MENU];
export var MEETINGS_EDITOR_SAVE_ONBOARDING_GROUP = 'meetings_editor_save';
export var MEETINGS_EDITOR_SAVE_ONBOARDING_IDS = {
  SAVE: 'save_button'
};
export var MEETINGS_EDITOR_SAVE_ONBOARDING_STEPS = [MEETINGS_EDITOR_SAVE_ONBOARDING_IDS.SAVE];
export var ONBOARDING_STEP_PASSED_EVENT_KEY = 'onboarding_meetings_step_passed';
export var ONBOARDING_GROUP_SKIPPED_EVENT_KEY = 'onboarding_meetings_group_skipped';
export var MEETINGS_NEW_ACCOUNT_ONBOARDING_GROUP = 'meetings_list';
export var MEETINGS_NEW_ACCOUNT_ONBOARDING_STEPS_IDS = {
  LIST_ITEM: 'list_item',
  NEW_MEETING: 'new_meeting'
};
export var MEETINGS_NEW_ACCOUNT_ONBOARDING_STEPS = [MEETINGS_NEW_ACCOUNT_ONBOARDING_STEPS_IDS.NEW_MEETING, MEETINGS_NEW_ACCOUNT_ONBOARDING_STEPS_IDS.LIST_ITEM];