import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useHistory } from 'react-router';

import { useFeatureSettings } from '@wbnr/frontend-shared/lib/data/featureSettings';
import { injectTranslations } from '@wbnr/frontend-shared/lib/utils/i18n';
import { Tabs, Tab, Typography, createTestIdProps } from '@wbnr/ui';

import BusinessLayout from '../BusinessLayout';

import { TRANS_NS } from './constants';
import { Extensions } from './Extensions';
import styles from './Integrations.module.scss';
import translations from './translations.json';

const BASE_TEST_ID = 'integrations';

const TABS = [
    {
        nameKey: 'marketplace.title',
        route: '/business/integrations/marketplace',
        testId: 'marketplace',
    },
    {
        nameKey: 'constructor.title',
        route: '/business/integrations/constructor',
        testId: 'constructor',
    },
    {
        nameKey: 'extensions.title',
        route: '/business/integrations/extensions',
        testId: 'extensions',
    },
];

injectTranslations(TRANS_NS, translations);

export const Integrations = () => {
    const history = useHistory();
    const { t } = useTranslation(TRANS_NS);

    const { data: features } = useFeatureSettings();
    const { albato } = features;

    const handleChangeTab = (
        event: React.ChangeEvent<Record<string, unknown>>,
        newRoute: string,
    ) => {
        history.push(newRoute);
    };

    return (
        <BusinessLayout className={styles.content}>
            <div className={styles.header}>
                <Typography variant="h6" className={styles.title}>
                    {t('title')}
                </Typography>
            </div>
            {albato && (
                <Tabs
                    indicatorColor="primary"
                    value={history.location.pathname}
                    onChange={handleChangeTab}
                    classes={{
                        root: styles.tabsRoot,
                        flexContainer: styles.tabsWrapper,
                    }}
                >
                    {TABS.map((tab) => (
                        <Tab
                            key={tab.route}
                            value={tab.route}
                            label={t(tab.nameKey)}
                            classes={{
                                root: styles.tabRoot,
                            }}
                            className={styles.tabWrapper}
                            {...createTestIdProps(BASE_TEST_ID, 'tab', tab.testId)}
                        />
                    ))}
                </Tabs>
            )}
            <div className={classnames(styles.root)}>
                <Switch>
                    <Route path={'/business/integrations'} exact>
                        <Redirect to="/business/integrations/extensions" />
                    </Route>
                    {albato && (
                        <Route path={'/business/integrations/marketplace'} component={() => null} />
                    )}
                    {albato && (
                        <Route path={'/business/integrations/constructor'} component={() => null} />
                    )}
                    <Route path={'/business/integrations/extensions'} component={Extensions} />
                </Switch>
            </div>
        </BusinessLayout>
    );
};
