import React, { useEffect, useState, useCallback } from 'react';
import { Redirect, useLocation } from 'react-router';

import { needRedirectToMeetings, useUser } from '@wbnr/frontend-shared/lib/data/user';
import { isAccountUser } from '@wbnr/frontend-shared/lib/types/User';

import { LazyKnockout } from 'containers/KnockoutAdapter/LazyKnockout';

import ChecklistCongratsDialog from './components/ChecklistCongratsDialog';
import MeetingsPromo from './components/MeetingsControlPanelPromo';
import useKnockoutInitData from './useKnockoutInitData';

declare global {
    interface Window {
        openChecklistCongratsDialog: (textData?: string) => void;
        closeChecklistCongratsDialog: () => void;
    }
}

const WebinarsPage = () => {
    const {
        meetingPromoEnabled,
        canShow,
        knockoutSyncData,
        onKnockoutLoad,
        onDecideShow,
        onFinish,
    } = useKnockoutInitData();

    const {
        open: checklistCongratsOpen,
        text: congratsText,
        onClose: onCongratsClose,
    } = useChecklistCongratsDialog();

    const [user] = useUser();

    const location = useLocation<{ isRedirectFromWebinars?: boolean }>();

    if (
        !location.state?.isRedirectFromWebinars &&
        isAccountUser(user) &&
        needRedirectToMeetings(user)
    ) {
        return <Redirect to="/meetings" />;
    }

    return (
        <React.Fragment>
            {meetingPromoEnabled && canShow && (
                <MeetingsPromo onDecideShow={onDecideShow} onFinish={onFinish} />
            )}
            {checklistCongratsOpen && (
                <ChecklistCongratsDialog text={congratsText} onClose={onCongratsClose} />
            )}
            <LazyKnockout syncData={knockoutSyncData} onLoad={onKnockoutLoad} />
        </React.Fragment>
    );
};

const useChecklistCongratsDialog = () => {
    const [open, setOpen] = useState(false);
    const [text, setText] = useState();

    const onOpen = useCallback(
        (textData) => {
            textData && setText(textData);
            setOpen(true);
        },
        [setText, setOpen],
    );

    const onClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    useEffect(() => {
        window.openChecklistCongratsDialog = (textData?: string) => onOpen(textData);
        window.closeChecklistCongratsDialog = () => onClose();
    }, [onOpen, onClose]);

    return { open, text, onClose };
};

export default WebinarsPage;
