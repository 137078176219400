import { Redirect } from 'react-router';

import { useCabinetEnterTrack } from '@wbnr/frontend-shared/lib/hooks/useCabinetEnterTrack';

const WebinarsInitCabinetRoute = () => {
    useCabinetEnterTrack('webinar');

    return (
        <Redirect
            to={{
                pathname: '/',
                state: { isRedirectFromWebinars: true },
            }}
        />
    );
};

export default WebinarsInitCabinetRoute;
