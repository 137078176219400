import { useTranslation } from 'react-i18next';

import { useFeatureSettings } from '@wbnr/frontend-shared/lib/data/featureSettings';
import { AddIcon } from '@wbnr/icons';
import { Typography } from '@wbnr/ui';

import { MultibrandingMethods } from '../../types';
import { useFeatureSuggestionDialogs } from '../BrandingList/useFeatureSuggestionDialogs';

import styles from './BrandingListCreate.module.scss';

const I18N_PATH = 'business.brandingPage';

interface Props {
    suggestBranding: boolean;
    suggestMultibranding: boolean;
    onCreateBranding: MultibrandingMethods['onCreateBranding'];
}

const BrandingListCreate = ({ suggestBranding, suggestMultibranding, onCreateBranding }: Props) => {
    const { t } = useTranslation();
    const { data: features } = useFeatureSettings();
    const { openBrandingSuggestion, openMultibrandingSuggestion, openNewBrandingSuggestion } =
        useFeatureSuggestionDialogs();

    return (
        <div className={styles.root}>
            <button
                onClick={() => {
                    if (suggestBranding) {
                        features?.newPlg ? openNewBrandingSuggestion() : openBrandingSuggestion();
                    } else if (suggestMultibranding) {
                        openMultibrandingSuggestion();
                    } else {
                        onCreateBranding();
                    }
                }}
                className={styles.button}
            />

            <AddIcon color="disabled" fontSize="large" />
            <Typography color="textSecondary">{t(`${I18N_PATH}.create`)}</Typography>
        </div>
    );
};

export default BrandingListCreate;
