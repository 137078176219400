var translations = {
  ru: {
    cancel: 'Закрыть',
    submit: 'Отправить запрос',
    cancelRequest: 'Отменить запрос',
    phonePlaceholder: 'Номер телефона пользователя',
    phoneHelperText: 'Свяжемся с вами по этому номеру и расскажем, как подключить',
    requestedFeedback: 'Запрос отправлен, менеджер скоро с вами свяжется по номеру {{phone}}',
    validation: {
      required: 'Это поле не может быть пустым',
      phone: 'Кажется, вы ввели некорректный номер телефона'
    }
  },
  en: {
    cancel: 'Close',
    submit: 'Send request',
    cancelRequest: 'Cancel request',
    phonePlaceholder: 'Phone number',
    phoneHelperText: 'We will contact you on this number and tell you how to connect',
    requestedFeedback: 'The request has been sent, the manager will contact you soon by number {{phone}}',
    validation: {
      required: 'This field is required',
      phone: 'Seems like you provided the wrong number'
    }
  }
};
export default translations;