import { useCustomDialog } from '@wbnr/frontend-shared/lib/dialogs';

import ConfirmRefreshApiKeyDialog from './ConfirmRefreshApiKeyDialog';

const useConfirmRefreshApiKeyDialog = ({ onSubmit }: { onSubmit: () => void }) => {
    const [openDialog, closeDialog] = useCustomDialog(
        ({ opened, closeDialog: closeDialogHandler }) => {
            return (
                <ConfirmRefreshApiKeyDialog
                    opened={opened}
                    onSubmit={() => {
                        onSubmit();
                        closeDialogHandler();
                    }}
                    onClose={closeDialogHandler}
                />
            );
        },
        {},
    );
    return [openDialog, closeDialog];
};

export default useConfirmRefreshApiKeyDialog;
