import { FC, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router';

import LoadingScreen from '@wbnr/frontend-shared/lib/components/LoadingScreen';
import { useFeatureSettings } from '@wbnr/frontend-shared/lib/data/featureSettings';
import { isOrganizationAdmin, useAccountUser } from '@wbnr/frontend-shared/lib/data/user';

import { DevicesContextProvider } from './context/DevciesContext';
import { MyDevicesSettings } from './MyDevicesSettings';

export const MyDeviceSettingsPage: FC = () => {
    const { t } = useTranslation();
    const [user] = useAccountUser();
    const isAdmin = useMemo(() => isOrganizationAdmin(user), [user]);

    const { fulfilled, data: features } = useFeatureSettings();

    if (!fulfilled) {
        return <LoadingScreen />;
    }

    if (!features?.useOutgoingSipCalls || !isAdmin) {
        return <Redirect to="/business/tariffs" />;
    }

    return (
        <DevicesContextProvider>
            <Helmet>
                <title>{t('nav.myDevices')} - Webinar</title>
            </Helmet>
            <MyDevicesSettings />
        </DevicesContextProvider>
    );
};
