import isEqual from 'lodash/isEqual';

import { APIError, uploadFiles } from '@wbnr/frontend-shared/lib/api';
import {
    updateBranding,
    createBranding,
    updateAdditionalAgreements,
} from '@wbnr/frontend-shared/lib/api/branding';
import { ApiRequestBrandingFull } from '@wbnr/frontend-shared/lib/api/branding/types';
import { updateSMTP, createSMTP } from '@wbnr/frontend-shared/lib/api/smtp';
import { SMTP } from '@wbnr/frontend-shared/lib/types/Smtp';

import { BrandingFormValues, BrandingInitialValues, BrandingImage } from '../types';

export const saveBranding = async (
    organizationId: number,
    oldBranding: BrandingInitialValues,
    newBranding: BrandingFormValues,
) => {
    let brandingId = oldBranding.branding.id;

    const {
        branding: { additionalAgreements: oldAgreements },
        smtp: oldSmtp,
    } = oldBranding;

    const {
        branding: {
            id: newBrandingId,
            additionalAgreements: newAgreements,
            interfaceLogoImage,
            landingLogoImage,
            eventPauseImage,
            ...branding
        },
        smtp: newSmtp,
    } = newBranding;

    const images = [interfaceLogoImage, landingLogoImage, eventPauseImage];
    const [interfaceLogoImageId, landingLogoImageId, eventPauseImageId] = await uploadImages(
        images,
    );

    const brandingRequest: ApiRequestBrandingFull = {
        ...branding,
        interfaceLogoImage: interfaceLogoImageId,
        landingLogoImage: landingLogoImageId,
        eventPauseImage: eventPauseImageId,
    };

    if (!brandingRequest.isQuestions) {
        delete brandingRequest.questionsEmail;
    }

    const promises: Promise<void>[] = [];

    if (brandingId === -1) {
        const createdBranding = await createBranding({ ...brandingRequest, organizationId }).catch(
            handleBrandingErrors,
        );
        if (createdBranding) {
            brandingId = createdBranding.id;
        }
    } else {
        promises.push(
            updateBranding(organizationId, brandingId, brandingRequest).catch(handleBrandingErrors),
        );
    }

    promises.push(saveAdditionalAgreements(brandingId, oldAgreements, newAgreements));

    if (oldSmtp.id || newSmtp.isActive) {
        const port = newSmtp.port || oldSmtp.port;

        promises.push(
            saveCustomSMTP(brandingId, {
                ...oldSmtp,
                ...newSmtp,
                port: port!,
            }),
        );
    }

    await Promise.all(promises);

    return brandingId;
};

const saveAdditionalAgreements = async (
    brandingId: number,
    oldAgreements: BrandingInitialValues['branding']['additionalAgreements'],
    newAgreements: BrandingFormValues['branding']['additionalAgreements'] = [],
): Promise<void> => {
    if (
        isEqual(
            oldAgreements.map((agreement) => ({
                id: agreement.id,
                description: agreement.description,
                isRequired: agreement.isRequired,
                exportName: agreement.exportName,
            })),
            newAgreements.map((agreement) => ({
                id: agreement.id,
                description: agreement.description,
                isRequired: agreement.isRequired,
                exportName: agreement.exportName,
            })),
        )
    ) {
        return;
    }

    const deletedAdditionalAgreements = oldAgreements.filter(
        ({ id: oldId }) => !newAgreements.some(({ id }) => oldId === id),
    );

    return updateAdditionalAgreements(brandingId, [
        ...newAgreements.map((agreement) => ({
            ...agreement,
            type: 'text' as const,
            isDeleted: false,
        })),
        ...deletedAdditionalAgreements.map((agreement) => ({
            ...agreement,
            type: 'text' as const,
            isDeleted: true,
        })),
    ]);
};

const saveCustomSMTP = async (
    brandingId: number,
    smtp: Omit<SMTP, 'port'> & { port: number },
): Promise<void> => {
    if (smtp.id) {
        await updateSMTP({ ...smtp, id: smtp.id! });
    } else {
        await createSMTP({
            ...smtp,
            brandingId,
        });
    }
};

async function uploadImages(images: BrandingImage[]): Promise<(string | number)[]> {
    const promises = images.map((image) => {
        if (image instanceof File) {
            const uploadState = uploadFiles({}, [image], true);
            return uploadState.promise;
        } else if (image?.id) {
            return Promise.resolve(image.id);
        }
        return Promise.resolve(0);
    });

    const data = await Promise.all(promises);

    return data.map((item) => {
        if (Array.isArray(item)) {
            return item.length === 1 ? item[0]?.originFileId || 0 : 0;
        }

        return item || 0;
    });
}

function handleBrandingErrors(err: any) {
    if (err instanceof APIError && err.status === 409) {
        throw new Error('eventCustomLinkExist');
    }

    throw err;
}
