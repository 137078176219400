import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useMixpanelTracking } from '@wbnr/frontend-shared/lib/analytics/mixpanel/useMixpanelTracking';
import { injectTranslations } from '@wbnr/frontend-shared/lib/utils/i18n';
import {
    Button,
    Dropdown,
    MenuItem,
    Menu,
    ListSubheader,
    createTestIdProps,
    Typography,
} from '@wbnr/ui';

import { TARIFF_PAGE_NS } from '../constants';
import { CardOrder } from '../types';

import translations from './translations';

injectTranslations(TARIFF_PAGE_NS, translations);

type Props = {
    isCurrentTariff?: boolean;
    onPay?: (order: CardOrder) => void;
    goToTariff: () => void;
    order: CardOrder;
    onInvoiceFormOpen: () => void;
};

const BASE_TEST_ID = 'tariffBuyButton';

const TariffButton = ({ isCurrentTariff, onPay, onInvoiceFormOpen, goToTariff, order }: Props) => {
    const [anchor, setAnchor] = useState<HTMLElement | null>(null);
    const { track } = useMixpanelTracking();
    const { t } = useTranslation(TARIFF_PAGE_NS);

    const sendMixPanelDataOnToggle = () => {
        track('press_buybtn', { tariff_name: order.tariffName, period: order.duration });
    };

    const invoiceMethodHandler = () => {
        track('tariff_buy_payment_method_selected', {
            tariff_name: order.tariffName,
            period: order.duration,
            payment_method: 'invoice',
        });
        onInvoiceFormOpen();
    };

    return (
        <>
            {isCurrentTariff ? (
                <Button
                    variant={'outlined'}
                    color="primary"
                    onClick={goToTariff}
                    {...createTestIdProps(BASE_TEST_ID, 'goToTariff')}
                    fullWidth
                >
                    {t('currentTariff')}
                </Button>
            ) : (
                <Dropdown
                    renderAnchor={({ toggle, anchorRef }) => {
                        setAnchor(anchorRef.current);
                        return (
                            <Button
                                fullWidth
                                variant={'contained'}
                                color="primary"
                                buttonRef={anchorRef}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggle(true);
                                    sendMixPanelDataOnToggle();
                                }}
                                {...createTestIdProps(BASE_TEST_ID, 'openPayMethods')}
                            >
                                {t('buy')}
                            </Button>
                        );
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    PaperProps={{ style: { minWidth: anchor ? anchor.clientWidth : undefined } }}
                    component={Menu}
                    disableAutoFocusItem
                    disableRestoreFocus
                    closeOnClick
                >
                    <ListSubheader>
                        <Typography color="textSecondary" variant="overline">
                            {t('payMethod')}
                        </Typography>
                    </ListSubheader>
                    <MenuItem
                        onClick={onPay && (() => onPay(order))}
                        {...createTestIdProps(BASE_TEST_ID, 'openPayMethods', 'byCard')}
                    >
                        {t('byCard')}
                    </MenuItem>

                    <MenuItem
                        onClick={invoiceMethodHandler}
                        {...createTestIdProps(BASE_TEST_ID, 'openPayMethods', 'byInvoice')}
                    >
                        {t('byInvoice')}
                    </MenuItem>
                </Dropdown>
            )}
        </>
    );
};

export default TariffButton;
