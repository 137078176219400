import cn from 'classnames';
import { FC } from 'react';

import { DevicesItem } from '@wbnr/frontend-shared/lib/api/business-api/types';
import { ListItem, ListItemText, OverflowTooltip } from '@wbnr/ui';

import { useDevices } from '../../hooks/useDevices';
import { useModalMyDeviceDelete } from '../../hooks/useModalMyDeviceDelete';
import { useModalMyDeviceEdit } from '../../hooks/useModalMyDeviceEdit';

import styles from './MyDeviceTable.module.scss';
import { MyDeviceTableMenu } from './MyDeviceTableMenu';

interface MyDeviceTableRowProps {
    device: DevicesItem;
}

export const MyDeviceTableRow: FC<MyDeviceTableRowProps> = ({ device: { id, name, uri } }) => {
    const { deleteDevice } = useDevices();
    const { showModalMyDeviceEdit } = useModalMyDeviceEdit({
        deviceId: id,
        defaultValues: {
            name,
            uri,
        },
    });

    const { showModalMyDeviceDelete } = useModalMyDeviceDelete();

    const onClickDeleteDevice = () => {
        showModalMyDeviceDelete({
            name,
            onSubmit: async () => {
                await deleteDevice({
                    deviceId: id,
                });
            },
        });
    };

    return (
        <ListItem
            button
            classes={{
                root: cn(styles.row, styles.bottomBorder),
            }}
        >
            <OverflowTooltip title={name}>
                <ListItemText className={styles.cell}>{name}</ListItemText>
            </OverflowTooltip>
            <OverflowTooltip title={uri}>
                <ListItemText className={styles.cell}>{uri}</ListItemText>
            </OverflowTooltip>
            <ListItemText className={cn(styles.cell, styles.actionCell)}>
                <MyDeviceTableMenu
                    onClickEdit={showModalMyDeviceEdit}
                    onClickDelete={onClickDeleteDevice}
                />
            </ListItemText>
        </ListItem>
    );
};
