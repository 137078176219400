import { useTranslation, Trans } from 'react-i18next';

import { useFormContext, useField } from '@wbnr/frontend-shared/lib/forms';
import { AdditionalAgreement } from '@wbnr/frontend-shared/lib/types/Branding';
import { validateLength } from '@wbnr/frontend-shared/lib/utils/validators';
import { TextField, Button } from '@wbnr/ui';

import { BrandingFormValues } from '../../types';
import { getBrandingPropName } from '../../utils';
import BrandingSwitchField from '../BrandingSwitchField';

import styles from './AdditionalAgreementField.module.scss';

type Props = Partial<AdditionalAgreement> & {
    index: number;
    customizableAgreements: boolean;
    onDelete: () => void;
};

const MAX_LENGTH = 50000;
const i18nPath = 'business.brandingPage';

const AdditionalAgreementField = ({
    index,
    id,
    description,
    isRequired,
    exportName,
    customizableAgreements,
    onDelete,
}: Props) => {
    const { t } = useTranslation();
    const { control } = useFormContext<BrandingFormValues>();

    const namePrefix = getBrandingPropName('additionalAgreements') + `[${index}]`;

    const {
        error: idError,
        inputRef: idRef,
        ...idParams
    } = useField(control, `${namePrefix}.id`, {
        defaultValue: id,
    });
    const {
        error: exportNameError,
        inputRef: exportNameRef,
        ...exportNameParams
    } = useField(control, `${namePrefix}.exportName`, {
        defaultValue: exportName,
    });

    const descriptionField = useField(control, `${namePrefix}.description`, {
        defaultValue: description,
        rules: { required: true, validate: validateMaxLength },
    });

    return (
        <>
            <div className={styles.wrapper}>
                <input type="hidden" {...idParams} />
                <input type="hidden" {...exportNameParams} />

                <TextField
                    fullWidth
                    multiline
                    rowsMax={5}
                    label={t(`${i18nPath}.additionalAgreementLabel`)}
                    helperText={t(`${i18nPath}.additionalAgreementHint`)}
                    {...descriptionField}
                />
                <Button color="primary" onClick={onDelete} className={styles.deleteButton}>
                    <Trans i18nKey="delete" />
                </Button>
            </div>

            {customizableAgreements && (
                <div className={styles.switch}>
                    <AgreementRequiredField
                        name={`${namePrefix}.isRequired`}
                        defaultValue={isRequired}
                    />
                </div>
            )}
        </>
    );
};

export default AdditionalAgreementField;

const AgreementRequiredField = ({
    name,
    defaultValue,
}: {
    name: string;
    defaultValue?: boolean;
}) => {
    const { control } = useFormContext<BrandingFormValues>();
    const { t } = useTranslation();

    return (
        <BrandingSwitchField
            label={t(`${i18nPath}.agreementRequired`)}
            helperText={t(`${i18nPath}.agreementRequiredHint`)}
            {...useField(control, name, { defaultValue })}
        />
    );
};

const validateMaxLength = (value: string) => {
    return validateLength(MAX_LENGTH)(value) && `${i18nPath}.additionalAgreementMaxLength`;
};
