import { apiFormFetch, APIError } from '../apiFetch';
export function clearOnboardingv2Group(params) {
  return apiFormFetch('DELETE', '/onboarding-v2/group/{groupToken}', params).catch(function (error) {
    if (error instanceof APIError) {
      if (error.status === 404) {
        return undefined;
      }
    }
    throw error;
  });
}