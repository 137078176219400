import React, { FC, Suspense, useEffect, useCallback } from 'react';

import LoadingScreen from '@wbnr/frontend-shared/lib/components/LoadingScreen';

import { KnockoutBannerProps } from 'routes/KnockoutLanding/KnockoutLandingRoute';

const KnockoutAdapter = React.lazy(() => import('./KnockoutAdapter'));

type LazyKnockoutProps = {
    onLoad?: () => void;
    callbacks?: {
        setSignupOfferBannerProps: (params: KnockoutBannerProps) => void;
    };
    syncData?: {
        meetingsPromoDialogShown: boolean | undefined;
        meetingPromoDialogPromise: Promise<void>;
    };
};

export const LazyKnockout: FC<LazyKnockoutProps> = (props) => {
    const onLoad = props.onLoad;

    return (
        <Suspense fallback={<LoadingScreen />}>
            <ReportMount
                onMount={useCallback(() => {
                    if (onLoad) {
                        onLoad();
                    }
                }, [onLoad])}
            />
            <KnockoutAdapter {...props} />
        </Suspense>
    );
};

export const ReportMount = ({ onMount }: { onMount: () => void }) => {
    useEffect(() => {
        onMount && onMount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};
