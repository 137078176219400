import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { updateOrganization, IOrganization } from '@wbnr/frontend-shared/lib/api/business-api';
import { useForm, FormProvider } from '@wbnr/frontend-shared/lib/forms';
import { CloseIcon } from '@wbnr/icons';
import { IconButton, SnackbarContent } from '@wbnr/ui';

import { TRANS_NS } from '../constants';
import styles from '../Organization.module.scss';

import { NameField } from './NameField';
import { SubdomainField } from './SubdomainField';
import { IParams } from './types';

export function OrganizationForm({ organization }: { organization: IOrganization }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { t } = useTranslation(TRANS_NS);
    const defaultValues = { name: organization.name, subdomain: organization.subdomain };

    const form = useForm({ defaultValues });
    const { name, subdomain } = form.watch(['name', 'subdomain']);

    const handleUpdateOrganization = (params: IParams, translateKey: string) => {
        updateOrganization(organization.id, params).then(() => {
            const notificationCreateKey = enqueueSnackbar('', {
                content: (
                    <SnackbarContent
                        message={t(translateKey)}
                        action={
                            <IconButton
                                onClick={() => closeSnackbar(notificationCreateKey)}
                                className={styles.notification}
                            >
                                <CloseIcon className={styles.notificationIcon} />
                            </IconButton>
                        }
                    />
                ),
            });

            form.reset({ name, subdomain }, { dirtyFields: true, errors: true });
        });
    };

    return (
        <FormProvider {...form}>
            <form className={styles.form}>
                <NameField handleUpdateOrganization={handleUpdateOrganization} />
                <SubdomainField handleUpdateOrganization={handleUpdateOrganization} />
            </form>
        </FormProvider>
    );
}
