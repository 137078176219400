function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
export var createRegisterGroupAction = function createRegisterGroupAction(id, priority, stepIds) {
  return {
    type: 'registerGroup',
    data: {
      groupId: id,
      priority: priority,
      stepIds: stepIds
    }
  };
};
export var createUnregisterGroupAction = function createUnregisterGroupAction(id) {
  return {
    type: 'unregisterGroup',
    data: {
      groupId: id
    }
  };
};
export var createLoadGroupDoneAction = function createLoadGroupDoneAction(id, finished, steps) {
  return {
    type: 'loadGroupDone',
    data: {
      groupId: id,
      finished: finished,
      steps: steps
    }
  };
};
export var createToggleGroupRunAction = function createToggleGroupRunAction(id, shouldRun) {
  return {
    type: 'toggleGroupRun',
    data: {
      groupId: id,
      shouldRun: shouldRun
    }
  };
};
export var createFinishGroupAction = function createFinishGroupAction(id) {
  return {
    type: 'finishGroup',
    data: {
      groupId: id
    }
  };
};
export var createActivateGroupStepAction = function createActivateGroupStepAction(groupId, stepId) {
  return {
    type: 'activateGroupStep',
    data: {
      groupId: groupId,
      stepId: stepId
    }
  };
};
export var createSetGroupStepCanRunAction = function createSetGroupStepCanRunAction(groupId, stepId, canRun) {
  return {
    type: 'setGroupStepCanRun',
    data: {
      groupId: groupId,
      stepId: stepId,
      canRun: canRun
    }
  };
};
export var createFinishGroupStepAction = function createFinishGroupStepAction(groupId, stepId) {
  return {
    type: 'finishGroupStep',
    data: {
      groupId: groupId,
      stepId: stepId
    }
  };
};
export var createResetGroupAction = function createResetGroupAction(id) {
  return {
    type: 'resetGroup',
    data: {
      groupId: id
    }
  };
};
export var onboardingStoreReducer = function onboardingStoreReducer(state, action) {
  switch (action.type) {
    case 'registerGroup':
      {
        var oldGroup = state.groups[action.data.groupId];
        var newGroup = oldGroup;
        if (oldGroup && oldGroup.available === true) {
          newGroup = oldGroup;
        } else if (oldGroup && oldGroup.available === false) {
          newGroup = _objectSpread(_objectSpread({}, oldGroup), {}, {
            available: true
          });
        } else {
          newGroup = registerDefaultGroupState(action.data);
        }
        return {
          groups: _objectSpread(_objectSpread({}, state.groups), {}, _defineProperty({}, action.data.groupId, newGroup))
        };
      }
    case 'unregisterGroup':
      return updateGroup(state, {
        groupId: action.data.groupId
      }, unregisterGroup);
    case 'loadGroupDone':
      return updateGroup(state, {
        groupId: action.data.groupId
      }, function (oldGroup) {
        return _objectSpread(_objectSpread({}, oldGroup), {}, {
          finished: action.data.finished,
          loaded: true,
          steps: Object.keys(action.data.steps).reduce(function (memo, key) {
            if (memo[key]) {
              memo[key] = _objectSpread(_objectSpread({}, memo[key]), {}, {
                finished: action.data.steps[key].finished
              });
            }
            return memo;
          }, _objectSpread({}, oldGroup.steps))
        });
      });
    case 'toggleGroupRun':
      return updateGroup(state, {
        groupId: action.data.groupId
      }, function (oldGroup) {
        if (oldGroup.active === action.data.shouldRun) {
          return oldGroup;
        }
        return _objectSpread(_objectSpread({}, oldGroup), {}, {
          active: action.data.shouldRun
        });
      });
    case 'finishGroup':
      return updateGroup(state, {
        groupId: action.data.groupId
      }, function (oldGroup) {
        if (oldGroup.finished) {
          return oldGroup;
        }
        return _objectSpread(_objectSpread({}, oldGroup), {}, {
          finished: true
        });
      });
    case 'activateGroupStep':
      return updateGroup(state, {
        groupId: action.data.groupId
      }, function (oldGroup) {
        if (oldGroup.activeStepId === action.data.stepId) {
          return oldGroup;
        }
        return _objectSpread(_objectSpread({}, oldGroup), {}, {
          activeStepId: action.data.stepId
        });
      });
    case 'setGroupStepCanRun':
      return updateGroup(state, {
        groupId: action.data.groupId
      }, function (oldGroup) {
        if (!oldGroup.steps[action.data.stepId] || oldGroup.steps[action.data.stepId].canRun === action.data.canRun) {
          return oldGroup;
        }
        return _objectSpread(_objectSpread({}, oldGroup), {}, {
          steps: _objectSpread(_objectSpread({}, oldGroup.steps), {}, _defineProperty({}, action.data.stepId, _objectSpread(_objectSpread({}, oldGroup.steps[action.data.stepId]), {}, {
            canRun: action.data.canRun
          })))
        });
      });
    case 'finishGroupStep':
      return updateGroup(state, {
        groupId: action.data.groupId
      }, function (oldGroup) {
        if (!oldGroup.steps[action.data.stepId] || oldGroup.steps[action.data.stepId].finished) {
          return oldGroup;
        }
        return _objectSpread(_objectSpread({}, oldGroup), {}, {
          activeStepId: action.data.stepId === oldGroup.activeStepId ? undefined : oldGroup.activeStepId,
          steps: _objectSpread(_objectSpread({}, oldGroup.steps), {}, _defineProperty({}, action.data.stepId, _objectSpread(_objectSpread({}, oldGroup.steps[action.data.stepId]), {}, {
            finished: true
          })))
        });
      });
    case 'resetGroup':
      return updateGroup(state, {
        groupId: action.data.groupId
      }, function (oldGroup) {
        return _objectSpread(_objectSpread({}, oldGroup), {}, {
          finished: false,
          activeStepId: undefined,
          steps: Object.keys(oldGroup.steps).reduce(function (acc, stepId) {
            acc[stepId] = _objectSpread(_objectSpread({}, acc[stepId]), {}, {
              finished: false
            });
            return acc;
          }, _objectSpread({}, oldGroup.steps))
        });
      });
    default:
      return state;
  }
};
export var updateGroup = function updateGroup(state, _ref, updateFunc) {
  var groupId = _ref.groupId;
  var oldGroup = state.groups[groupId];
  if (oldGroup === undefined) {
    return state;
  }
  var newGroup = updateFunc(oldGroup);
  if (newGroup === oldGroup) {
    return state;
  }
  return {
    groups: _objectSpread(_objectSpread({}, state.groups), {}, _defineProperty({}, groupId, newGroup))
  };
};
var registerDefaultGroupState = function registerDefaultGroupState(data) {
  return {
    id: data.groupId,
    activeStepId: undefined,
    loaded: false,
    active: false,
    available: true,
    finished: false,
    priority: data.priority,
    steps: data.stepIds.reduce(function (memo, stepId) {
      memo[stepId] = {
        id: stepId,
        canRun: false,
        finished: false
      };
      return memo;
    }, {})
  };
};
var unregisterGroup = function unregisterGroup(group) {
  return _objectSpread(_objectSpread({}, group), {}, {
    available: false,
    active: false,
    activeStepId: undefined,
    steps: Object.keys(group.steps).reduce(function (memo, key) {
      memo[key] = _objectSpread(_objectSpread({}, memo[key]), {}, {
        canRun: false
      });
      return memo;
    }, _objectSpread({}, group.steps))
  });
};