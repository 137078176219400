"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PLACEHOLDER_TYPES = void 0;
var PLACEHOLDER_TYPES = {
  CODE: 'code',
  MIRO: 'miro'
};
exports.PLACEHOLDER_TYPES = PLACEHOLDER_TYPES;