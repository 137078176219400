import googleCalendarIcon from './assets/google-calendar.svg';
import outlookIcon from './assets/outlook.svg';
import { Extension, ExtensionType } from './types';

export const EXTENSIONS: { [key in ExtensionType]: Extension } = {
    googleCalendar: {
        nameKey: 'extensions.googleCalendar.title',
        descriptionKey: 'extensions.googleCalendar.description',
        imagePath: googleCalendarIcon,
        helpLink:
            'https://help.webinar.ru/ru/articles/5913012-плагин-для-создания-встреч-через-google-calendar',
    },
    outlook: {
        nameKey: 'extensions.outlook.title',
        descriptionKey: 'extensions.outlook.description',
        imagePath: outlookIcon,
        helpLink: 'https://help.webinar.ru/ru/articles/6169456-интеграция-с-календарем-outlook',
    },
};
