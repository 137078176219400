import classnames from 'classnames';
import { ReactNode } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import FeatureRequest from '@wbnr/frontend-shared/lib/components/FeatureRequest';
import LoadingScreen from '@wbnr/frontend-shared/lib/components/LoadingScreen';
import TextFieldClipboard from '@wbnr/frontend-shared/lib/components/TextFieldClipboard';
import { useFeatureSettings } from '@wbnr/frontend-shared/lib/data/featureSettings';
import { useCommonCancelableFeatureRequestDialogs } from '@wbnr/frontend-shared/lib/dialogs/commonCancelableFeatureRequestDialogs';
import { Typography, Button, A, createTestIdProps } from '@wbnr/ui';

import styles from './Api.module.scss';
import useConfirmRefreshApiKeyDialog from './dialogs/ConfirmRefreshApiKeyDialog/useConfirmRefreshApiKeyDialog';
import useBusinessApiLogic from './hooks/useBusinessApiLogic';

const baseTestId = 'BusinessApi';
const API_DESCRIPTION_URL = 'https://help.webinar.ru/ru/collections/1839571-интеграция-api';

export const Api = () => {
    const { t } = useTranslation();
    const { inLoad, access, apiKey, isKeyRefreshing, onApiKeyRefresh } = useBusinessApiLogic();
    const { data: features } = useFeatureSettings();
    const [openRefreshApiKeyDialog] = useConfirmRefreshApiKeyDialog({ onSubmit: onApiKeyRefresh });

    const { openApiCancelableFeatureRequestDialog } = useCommonCancelableFeatureRequestDialogs();

    if (inLoad) {
        return (
            <div className={styles.loader}>
                <LoadingScreen />
            </div>
        );
    }

    return (
        <div className={classnames(styles.root, { [styles.rootAccess]: access })}>
            <Typography variant="h6">{t('business.apiPage.title')}</Typography>
            {!access ? (
                features?.newPlg ? (
                    <>
                        <Typography className={styles.featureRequestText}>
                            <Trans
                                i18nKey={'business.apiPage.text'}
                                components={[<ReferenceLink key="ReferenceLink" />]}
                            />
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => openApiCancelableFeatureRequestDialog()}
                            className={styles.featureRequestButton}
                            {...createTestIdProps(baseTestId, 'openFeatureRequestModal')}
                        >
                            {t('business.apiPage.action')}
                        </Button>
                    </>
                ) : (
                    <FeatureRequest
                        featureType="api"
                        description={
                            <Trans
                                i18nKey={'business.apiPage.text'}
                                components={[<ReferenceLink key="ReferenceLink" />]}
                            />
                        }
                        requestedText={t('business.apiPage.requested')}
                        buttonText={t('business.apiPage.action')}
                        {...createTestIdProps(baseTestId)}
                    />
                )
            ) : (
                <div>
                    <TextFieldClipboard
                        label={t('business.apiPage.key')}
                        fullWidth
                        value={apiKey}
                        InputProps={{
                            readOnly: true,
                        }}
                        className={styles.apiKey}
                        tooltip={t('business.apiPage.copyTooltip')}
                        {...createTestIdProps(baseTestId, 'clipboard')}
                    />
                    <div className={styles.actions}>
                        <Button
                            variant="text"
                            color="primary"
                            onClick={openRefreshApiKeyDialog}
                            disabled={isKeyRefreshing}
                            {...createTestIdProps(baseTestId, 'refresh')}
                        >
                            {t('business.apiPage.keyRefresh')}
                        </Button>

                        <ReferenceLink>
                            <Button variant="text" color="primary">
                                {t('business.apiPage.toApiDescription')}
                            </Button>
                        </ReferenceLink>
                    </div>
                </div>
            )}
        </div>
    );
};

function ReferenceLink({ children }: { children?: ReactNode }) {
    return (
        <A
            href={API_DESCRIPTION_URL}
            color="primary"
            target="_blank"
            rel="noreferrer noopener"
            {...createTestIdProps(baseTestId, 'description', 'link')}
        >
            {children}
        </A>
    );
}
