import { useState, useMemo } from 'react';

export const useMeetingLimitBanner = () => {
    const [params, setParams] = useState(undefined);

    return useMemo(
        () => ({
            show: Boolean(params),
            params,
            onClose: () => setParams(undefined),
            setParams,
        }),
        [params, setParams],
    );
};
