import cn from 'classnames';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import LoadingScreen from '@wbnr/frontend-shared/lib/components/LoadingScreen';
import { ChevronLeftIcon } from '@wbnr/icons';
import { A, createTestIdProps, IconButton, Typography } from '@wbnr/ui';

import BusinessLayout from '../../BusinessLayout';
import { SSOStep } from '../constants';
import { DomainSettings } from '../DomainSettings';
import { SAMLSettingsEdit } from '../SAMLSettingsEdit';
import { SAMLSettingsView } from '../SAMLSettingsView';
import { useSSO } from '../SSOContext';
import { SSOSettingsEdit } from '../SSOSettingsEdit';

import styles from './SSOSettings.module.scss';

const HELP_LINK = 'https://help.webinar.ru/ru/articles/4472120-настройка-google-sso';

const BASE_TEST_ID = 'SSOSettings';

export const SSOSettings = () => {
    const { t } = useTranslation();
    const { step, hasApprovedDomain } = useSSO();
    const [show, setShow] = useState(true);
    const stepView = step === SSOStep.VIEW;
    const key = show ? 'hide' : 'show';

    return (
        <BusinessLayout>
            {step === SSOStep.LOADING ? (
                <LoadingScreen />
            ) : (
                <div className={styles.root}>
                    <div className={styles.content}>
                        <Typography variant="h2" className={styles.title}>
                            <Trans i18nKey={'business.sso.title'} />
                        </Typography>
                        <Typography variant="body1">
                            <Trans i18nKey={'business.sso.subtitle'} />{' '}
                            <A
                                color="primary"
                                href={HELP_LINK}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <Trans i18nKey={'business.sso.help'} />
                            </A>
                        </Typography>
                        <div className={styles.card}>
                            <div className={cn(styles.samlTitle, { [styles.hidden]: !show })}>
                                <Trans i18nKey={'business.sso.saml.title'} />
                                {stepView && (
                                    <IconButton
                                        onClick={() => setShow((prevValue) => !prevValue)}
                                        {...createTestIdProps(BASE_TEST_ID, key)}
                                        title={t(`business.sso.saml.${key}`)}
                                    >
                                        <ChevronLeftIcon
                                            className={show ? styles.show : styles.hide}
                                        />
                                    </IconButton>
                                )}
                            </div>
                            <Typography variant="body1" className={styles.subtitle}>
                                <Trans
                                    i18nKey={`business.sso.${
                                        step !== SSOStep.IDP
                                            ? 'subtitleProvider'
                                            : 'subtitleProtocol'
                                    }`}
                                />
                            </Typography>
                            {stepView ? show && <SAMLSettingsView /> : <SAMLSettingsEdit />}
                        </div>
                        {stepView && (
                            <div className={styles.card}>
                                <DomainSettings />
                            </div>
                        )}
                    </div>
                    {stepView && hasApprovedDomain && (
                        <div className={styles.sso}>
                            <SSOSettingsEdit />
                        </div>
                    )}
                </div>
            )}
        </BusinessLayout>
    );
};
