import React, { useState, ReactNode, useMemo } from 'react';

import { CommonDialogsControlsContext } from '@wbnr/frontend-shared/lib/dialogs/commonDialogsContext';
import { useWebinarTrialDialog } from '@wbnr/frontend-shared/lib/dialogs/webinarTrialDialog';

interface CommonDialogsContextType {
    webinarTrialDialog: ReturnType<typeof useWebinarTrialDialog>;
}

export const CommonDialogsContext = React.createContext<CommonDialogsContextType | undefined>(
    undefined,
);

const CommonDialogsProvider = ({ children }: { children: ReactNode }) => {
    const [disabled, setCommonDialogsDisabled] = useState(false);
    const webinarTrialDialog = useWebinarTrialDialog({ canAutoOpen: !disabled });

    return (
        <CommonDialogsContext.Provider
            value={useMemo(() => ({ webinarTrialDialog }), [webinarTrialDialog])}
        >
            <CommonDialogsControlsContext.Provider
                value={useMemo(() => ({ setCommonDialogsDisabled }), [])}
            >
                {children}
            </CommonDialogsControlsContext.Provider>
        </CommonDialogsContext.Provider>
    );
};

export default CommonDialogsProvider;
