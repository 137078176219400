import get from 'lodash/get';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { APIError } from '@wbnr/frontend-shared/lib/api';
import { useForm, useField, makeFormErrors } from '@wbnr/frontend-shared/lib/forms';
import { validateLength, validateLink } from '@wbnr/frontend-shared/lib/utils/validators';
import {
    DialogActions,
    Button,
    LoadableButton,
    createTestIdProps,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
} from '@wbnr/ui';

import styles from './AddWebhookDialog.module.scss';

const BASE_TEST_ID = 'AddWebhookDialog';

type AddWebhookDialogProps = {
    opened: boolean;
    webhooksLimit: number;
    onSubmit: (name: string, endpointUrl: string) => Promise<void>;
    onClose: () => void;
};

type FieldName = 'name' | 'endpointUrl';

type FormErrors = Partial<Record<FieldName, string>>;

interface ValidationErrors {
    code: string;
    field: FieldName;
    message: string;
}

const MAX_NAME_LENGTH = 255;

export const AddWebhookDialog = ({
    opened,
    webhooksLimit,
    onSubmit,
    onClose,
}: AddWebhookDialogProps) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const validateNameMaxLength = (value: string) => {
        return (
            validateLength(MAX_NAME_LENGTH)(value) &&
            t('business.webhooksPage.addWebhookDialog.maxLength')
        );
    };

    const COMMON_ERROR_MESSAGES = useMemo(
        () => ({
            WEBHOOK_LIMIT_EXCEEDED: t('business.webhooksPage.addWebhookDialog.limitExceeded', {
                limit: webhooksLimit,
            }),
        }),
        [t, webhooksLimit],
    );

    const FIELDS_ERROR_MESSAGES: { [key in FieldName]: Record<string, string> } = useMemo(
        () => ({
            name: {
                WEBHOOK_NAME_ALREADY_EXISTS: t(
                    'business.webhooksPage.addWebhookDialog.nameAlreadyExists',
                ),
            },
            endpointUrl: {
                WEBHOOK_URL_ALREADY_EXISTS: t(
                    'business.webhooksPage.addWebhookDialog.urlAlreadyExists',
                ),
                WRONG_VALUE: t('business.webhooksPage.addWebhookDialog.urlWrongValue', {
                    keySeparator: '>',
                    nsSeparator: '|',
                }),
            },
        }),
        [t],
    );

    const form = useForm(
        {
            defaultValues: {
                name: '',
                endpointUrl: '',
            },
        },
        {
            onSubmit: async ({ name, endpointUrl }) => {
                try {
                    await onSubmit(name, endpointUrl);
                } catch (error) {
                    if (error instanceof APIError) {
                        handleFormError(error);

                        return;
                    }

                    throw error;
                }
            },
        },
    );

    const handleFormError = (error: APIError) => {
        const errorMessages: FormErrors = {};

        if (error.body.error.validationErrors instanceof Array) {
            (error.body.error.validationErrors as ValidationErrors[]).forEach(({ field, code }) => {
                const errorMessage = get(FIELDS_ERROR_MESSAGES, `${field}.${code}`, '');
                if (errorMessage) {
                    errorMessages[field] = errorMessage;
                }
            });
        }

        Object.entries(FIELDS_ERROR_MESSAGES).forEach(([field, fieldErrors]) => {
            const errorMessage = fieldErrors[error.body.error.code];
            if (errorMessage) {
                errorMessages[field as FieldName] = errorMessage;
            }
        });

        if (Object.keys(errorMessages).length) {
            throw makeFormErrors(errorMessages);
        }

        const errorMessage = get(COMMON_ERROR_MESSAGES, error.body.error.code, t('unknownError'));
        enqueueSnackbar(errorMessage, {
            variant: 'error',
        });

        throw error;
    };

    const { submit, control } = form;

    const nameField = useField(control, 'name', {
        rules: { required: true, validate: validateNameMaxLength },
    });
    const endpointUrlField = useField(control, 'endpointUrl', {
        rules: { required: true, validate: validateLink },
    });

    return (
        <Dialog maxWidth="md" open={opened} onClose={onClose}>
            <DialogTitle>{t('business.webhooksPage.addWebhookDialog.title')}</DialogTitle>
            <form onSubmit={submit}>
                <DialogContent
                    classes={{
                        root: styles.dialogContent,
                    }}
                    className={styles.dialogContentWrapper}
                >
                    <TextField
                        fullWidth
                        label={t('business.webhooksPage.addWebhookDialog.nameField')}
                        {...nameField}
                    />
                    <TextField
                        fullWidth
                        label={t('business.webhooksPage.addWebhookDialog.endpointUrlField')}
                        {...endpointUrlField}
                    />
                </DialogContent>

                <DialogActions>
                    <Button
                        variant="text"
                        color="primary"
                        onClick={onClose}
                        {...createTestIdProps(BASE_TEST_ID, 'close')}
                    >
                        {t('business.webhooksPage.addWebhookDialog.cancelButton')}
                    </Button>
                    <LoadableButton
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={submit}
                        {...createTestIdProps(BASE_TEST_ID, 'create')}
                    >
                        {t('business.webhooksPage.addWebhookDialog.addButton')}
                    </LoadableButton>
                </DialogActions>
            </form>
        </Dialog>
    );
};
