import { useCallback } from 'react';
import { useCometListeners } from '../../../comet';
import { useContactsRemoveCometHandler } from './useContactsRemoveCometHandler';
import { useContactsImportCometHandlers } from './useContactsImportCometHandlers';
export var useCommonCometListeners = function useCommonCometListeners(disableCometListeners) {
  var contactsRemoveCometHandler = useContactsRemoveCometHandler();
  var _useContactsImportCom = useContactsImportCometHandlers(),
    importSuccesHandler = _useContactsImportCom.successHandler,
    importFailHandler = _useContactsImportCom.failHandler;
  var listenersCallback = useCallback(function () {
    if (disableCometListeners) {
      return {};
    }
    return {
      'contacts.remove': contactsRemoveCometHandler,
      'contacts.import.complete': importSuccesHandler,
      'contacts.import.failure': importFailHandler
    };
  }, [disableCometListeners, contactsRemoveCometHandler, importSuccesHandler, importFailHandler]);
  useCometListeners(listenersCallback, []);
};