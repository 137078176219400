import { differenceInMilliseconds, isPast } from 'date-fns';
import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';
import {
    FC,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
    fetchPoP,
    enablePop,
    disablePop,
    refreshPop,
    createPoP,
} from '@wbnr/frontend-shared/lib/api/pop/pop';
import type { PointOfPresenceData } from '@wbnr/frontend-shared/lib/api/pop/types';
import { useCometListeners } from '@wbnr/frontend-shared/lib/comet';
import { CometTypes } from '@wbnr/frontend-shared/lib/comet/types';
import { ResetStateAction, useResource } from '@wbnr/frontend-shared/lib/data/useResource';
import { useBinded } from '@wbnr/frontend-shared/lib/hooks/useBinded';
import { useUnmountedRef } from '@wbnr/frontend-shared/lib/hooks/useUnmountedRef';
import { ReducerAction } from '@wbnr/frontend-shared/lib/types/ReducerAction';

export type PointOfPresenceContextData = {
    popData: PointOfPresenceData | null | undefined;
    switcherValue: boolean;
    initialLoading: boolean;
    initialFulfilled: boolean;
    onChangeSwitcher: (value: boolean) => void;
    refreshPoP: () => void;
    loading: boolean;
    error: any;
    isPopStatusEnabled: boolean;
};

export const PointOfPresenceContext = createContext<PointOfPresenceContextData>({
    popData: null,
    switcherValue: false,
    initialLoading: false,
    initialFulfilled: false,
    onChangeSwitcher: () => {},
    loading: false,
    refreshPoP: () => {},
    error: null,
    isPopStatusEnabled: false,
});

export const usePointOfPresence = () => useContext(PointOfPresenceContext);

export const PointOfPresenceContextProvider: FC = ({ children }) => {
    const { t } = useTranslation();

    const {
        data,
        loading: initialLoading,
        dispatch,
        error,
        fulfilled: initialFulfilled,
    } = useResource(
        {
            request: useBinded(fetchPoP),
        },
        reducer,
    );

    const [loading, setLoading] = useState(false);
    const [switcherValue, setSwitcherValue] = useState(false);
    const [isPopStatusEnabled, setIsPopStatusEnabled] = useState(false);

    const timeoutIdRef = useRef<NodeJS.Timeout>();
    const unmountedRef = useUnmountedRef();
    const { enqueueSnackbar } = useSnackbar();

    useCometListeners(
        () => ({
            'PointOfPresence.healthChecked': ({
                id,
                healthCheckExpiredAt,
            }: CometTypes['PointOfPresence.healthChecked']) => {
                if (data?.id === id) {
                    dispatch({
                        type: 'update',
                        payload: { ...data, healthCheckExpiredAt },
                    });
                }
            },
        }),
        [data],
    );

    const switchPoP = useMemo(() => {
        return debounce(
            async (actualData: PointOfPresenceData | null | undefined, value: boolean) => {
                if (Boolean(actualData?.enabled) === value) {
                    return;
                }

                setLoading(true);

                try {
                    let newData;

                    if (actualData) {
                        if (value) {
                            await enablePop(actualData.id);
                        } else {
                            await disablePop(actualData.id);
                        }

                        newData = { ...actualData, enabled: value };
                    } else {
                        // if no data and pop was enabled
                        newData = await createPoP();
                    }

                    if (!unmountedRef.current) {
                        dispatch({ type: 'update', payload: newData });
                    }
                } catch (e) {
                    enqueueSnackbar(t('business.pointOfPresencePage.errorSwitching'));
                } finally {
                    if (!unmountedRef.current) {
                        setLoading(false);
                    }
                }
            },
            200,
        );
    }, [dispatch, enqueueSnackbar, t, unmountedRef]);

    const refreshPoP = useCallback(async () => {
        if (!data) {
            return;
        }

        setLoading(true);

        try {
            const { data: newData } = await refreshPop(data?.id);

            if (!unmountedRef.current) {
                dispatch({ type: 'update', payload: newData });
            }
        } catch (e) {
            enqueueSnackbar(t('business.pointOfPresencePage.errorRefresh'));
        } finally {
            if (!unmountedRef.current) {
                setLoading(false);
            }
        }
    }, [data, unmountedRef, dispatch, enqueueSnackbar, t]);

    useEffect(() => {
        if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current);
        }

        if (!data || !data.healthCheckExpiredAt) {
            return setIsPopStatusEnabled(false);
        }

        const healthCheckExpiredAtDate = new Date(data.healthCheckExpiredAt);

        if (isPast(healthCheckExpiredAtDate)) {
            return setIsPopStatusEnabled(false);
        }

        setIsPopStatusEnabled(true);
        timeoutIdRef.current = setTimeout(() => {
            if (!unmountedRef.current) {
                setIsPopStatusEnabled(false);
            }
        }, differenceInMilliseconds(healthCheckExpiredAtDate, new Date()));
    }, [data, unmountedRef]);

    useEffect(() => {
        return () => {
            switchPoP.cancel();
        };
    }, [switchPoP]);

    useEffect(() => {
        if (!loading && initialFulfilled) {
            setSwitcherValue(Boolean(data?.enabled));
        }
    }, [data, initialFulfilled, loading]);

    const onChangeSwitcher = useCallback(
        (value: boolean) => {
            setSwitcherValue(value);
            switchPoP(data, value);
        },
        [data, setSwitcherValue, switchPoP],
    );

    return (
        <PointOfPresenceContext.Provider
            value={{
                popData: data,
                switcherValue,
                initialLoading,
                initialFulfilled,
                onChangeSwitcher,
                loading,
                refreshPoP,
                error,
                isPopStatusEnabled,
            }}
        >
            {children}
        </PointOfPresenceContext.Provider>
    );
};

interface UpdateAction extends ReducerAction<'update'> {
    payload: PointOfPresenceData;
}

const reducer = (
    _state: PointOfPresenceData | null = null,
    action: UpdateAction | ResetStateAction<PointOfPresenceData, any>,
) => {
    switch (action.type) {
        case 'update':
            return action.payload;

        default:
            throw new Error(`Unsupported action type ${action.type}`);
    }
};
