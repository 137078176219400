function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { useCallback, useEffect, useMemo } from 'react';
import { useCache } from './cacheContext';
import { apiFetch } from '../../api';
import { createCustomizableItemReducer, createItemDispatch } from './useCustomizableItemsReducer';
var loadingResources = {};
var defaultCustomReducer = function defaultCustomReducer(state) {
  return state;
};
export function useCachedResource(route) {
  var customReducer = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : defaultCustomReducer;
  var _useCache = useCache(route),
    cachedData = _useCache.data,
    dispatch = _useCache.dispatch;
  var _ref = cachedData || {},
    data = _ref.data,
    loading = _ref.loading,
    error = _ref.error,
    fulfilled = _ref.fulfilled;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  var cacheDispatch = useCallback(createItemDispatch(dispatch, cachedResourcesReducer, route), [route, dispatch]);
  var updateResource = useCallback(function () {
    loadingResources[route] = true;
    cacheDispatch({
      type: 'loadRecord_start',
      payload: {
        key: route
      }
    });
    apiFetch(route, {
      method: 'GET'
    }).then(function (data) {
      return cacheDispatch({
        type: 'loadRecord_success',
        payload: {
          key: route,
          data: data
        }
      });
    }, function (resError) {
      return cacheDispatch({
        type: 'loadRecord_failure',
        payload: {
          key: route,
          error: resError
        }
      });
    }).then(function () {
      loadingResources[route] = false;
    });
  }, [route, cacheDispatch]);
  useEffect(function () {
    if (route && !error && !fulfilled && !loading && !loadingResources[route]) {
      updateResource();
    }
  }, [route, error, fulfilled, loading, updateResource]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  var resourceDataDispatch = useCallback(createItemDispatch(cacheDispatch, customReducer, 'data'), [customReducer, cacheDispatch]);
  return useMemo(function () {
    return {
      data: data,
      fulfilled: fulfilled,
      loading: loading || !fulfilled && !!route && !error,
      error: error,
      updateResource: updateResource,
      dispatch: resourceDataDispatch
    };
  }, [data, loading, fulfilled, error, updateResource, resourceDataDispatch, route]);
}
var cachedResourcesReducer = createCustomizableItemReducer(function (state, action) {
  switch (action.type) {
    case 'loadRecord_start':
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true
      });
    case 'loadRecord_success':
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: false,
        fulfilled: true,
        data: action.payload.data
      });
    case 'loadRecord_failure':
      return {
        loading: false,
        fulfilled: false,
        data: undefined,
        error: action.payload.error
      };
    default:
      {
        throw new Error("Unsupported action type ".concat(action.type));
      }
  }
});