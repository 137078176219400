import { useCallback } from 'react';
import { ColorResult } from 'react-color';
import { useTranslation } from 'react-i18next';

import { useField, useFormContext } from '@wbnr/frontend-shared/lib/forms';
import { ArrowDropDown } from '@wbnr/icons';
import { IconButton, Button, TextField, Dropdown, DEFAULT_BRANDING_COLOR } from '@wbnr/ui';

import { getBrandingPropName } from '../../utils';
import BrandingHeading from '../BrandingHeading';

import styles from './BrandingBrandColor.module.scss';
import BrandingColorPicker from './BrandingColorPicker';

const i18nPath = 'business.brandingPage';

const BrandingBrandColor = () => {
    const { t } = useTranslation();

    const { control } = useFormContext();
    const eventButtonAndLinkColorField = useField(
        control,
        getBrandingPropName('eventButtonAndLinkColor'),
    );

    const onColorChange = useCallback(
        (color: ColorResult) => {
            eventButtonAndLinkColorField.onChange(processColorValue(color.hex));
        },
        [eventButtonAndLinkColorField],
    );

    return (
        <>
            <BrandingHeading>{t(`${i18nPath}.brandColor`)}</BrandingHeading>

            <div className={styles.content}>
                <Dropdown
                    renderAnchor={({ toggle, anchorRef }) => (
                        <IconButton
                            buttonRef={anchorRef}
                            size="small"
                            onClick={() => toggle(true)}
                            className={styles.brand}
                            style={{
                                backgroundColor: `#${eventButtonAndLinkColorField.value}`,
                            }}
                        >
                            <ArrowDropDown />
                        </IconButton>
                    )}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    width={null}
                >
                    <BrandingColorPicker
                        color={eventButtonAndLinkColorField.value}
                        onChange={onColorChange}
                    />
                </Dropdown>

                <TextField
                    {...eventButtonAndLinkColorField}
                    inputProps={{ maxLength: 6 }}
                    onChange={(e) =>
                        eventButtonAndLinkColorField.onChange(processColorValue(e.target.value))
                    }
                    label={t(`${i18nPath}.colorNumber`)}
                />

                {processColorValue(DEFAULT_BRANDING_COLOR) !==
                    eventButtonAndLinkColorField.value && (
                    <Button
                        color="primary"
                        onClick={() =>
                            eventButtonAndLinkColorField.onChange(
                                processColorValue(DEFAULT_BRANDING_COLOR),
                            )
                        }
                        className={styles.default}
                    >
                        {t(`${i18nPath}.reset`)}
                    </Button>
                )}
            </div>
        </>
    );
};

function processColorValue(color: string) {
    return color.replace(/[^a-f0-9]/g, '');
}

export default BrandingBrandColor;
