import { ComponentType } from 'react';

import LoadingScreen from '@wbnr/frontend-shared/lib/components/LoadingScreen';
import {
    useFeatureSettings,
    FeatureSettings,
} from '@wbnr/frontend-shared/lib/data/featureSettings';

import { LazyKnockout } from './KnockoutAdapter/LazyKnockout';

const koPageSwitch = (key: keyof FeatureSettings) => (Component: ComponentType) => {
    const KoPageSwitch = () => {
        const { fulfilled, data } = useFeatureSettings();

        if (!fulfilled) {
            return <LoadingScreen />;
        }

        if (!data[key]) {
            return <LazyKnockout />;
        }

        return <Component />;
    };

    return KoPageSwitch;
};

export default koPageSwitch;
