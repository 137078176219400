import { WebhooksListItem } from '@wbnr/frontend-shared/lib/api/business-api/types';
import { SortOrderType } from '@wbnr/frontend-shared/lib/types/CollectionRequestParams';

import { SortingColumn } from '../types';

export function getWebhooksComparator<T extends SortingColumn>(order: SortOrderType, orderBy: T) {
    const comparatorMap: {
        [key in SortingColumn]: (a: WebhooksListItem, b: WebhooksListItem) => number;
    } = {
        createdAt: (a, b) =>
            order === 'desc'
                ? descendingComparator(a, b, orderBy)
                : -descendingComparator(a, b, orderBy),
    };

    return comparatorMap[orderBy];
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
