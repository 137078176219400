import { useSnackbar } from 'notistack';
import { FC } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { APIError } from '@wbnr/frontend-shared/lib/api';
import { Domain } from '@wbnr/frontend-shared/lib/api/business-api/types';
import { FormProvider, makeFormErrors, useForm } from '@wbnr/frontend-shared/lib/forms';
import { createTestIdProps, LoadableButton } from '@wbnr/ui';

import { useSSO } from '../../SSOContext';

import styles from './AddDomain.module.scss';
import { AddDomainField } from './AddDomainField';

const ERROR_MESSAGES = {
    ALREADY_EXISTS: 'duplicate',
    INVALID_INPUT_DATA: 'error',
};

const BASE_TEST_ID = 'AddDomain';

export const AddDomain: FC<{ onAdd: (domain: Domain) => void }> = ({ onAdd }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { addDomain } = useSSO();
    const form = useForm(
        {
            defaultValues: {
                domain: '',
            },
        },
        {
            onSubmit: async ({ domain }) => {
                try {
                    if (addDomain) {
                        const newDomain = await addDomain(domain);
                        onAdd(newDomain);
                        form.reset();
                        form.clearErrors();
                    }
                } catch (error) {
                    if (error instanceof APIError) {
                        if (
                            error.status === 400 &&
                            Object.keys(ERROR_MESSAGES).includes(error.body.error.code)
                        ) {
                            throw makeFormErrors({
                                domain: t(
                                    `business.sso.domain.${
                                        (ERROR_MESSAGES as any)[error.body.error.code]
                                    }`,
                                ),
                            });
                        }
                    } else {
                        enqueueSnackbar(t('unknownError'));
                    }
                    throw error;
                }
            },
        },
    );
    const { submit, watch } = form;
    const domain = watch('domain');
    return (
        <FormProvider {...form}>
            <div className={styles.add}>
                <AddDomainField />
                <LoadableButton
                    color={'primary'}
                    variant={'contained'}
                    onClick={submit}
                    className={styles.addBtn}
                    disabled={!domain}
                    {...createTestIdProps(BASE_TEST_ID, 'AddDomain')}
                >
                    <Trans i18nKey={'business.sso.domain.add'} />
                </LoadableButton>
            </div>
        </FormProvider>
    );
};
