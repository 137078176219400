"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateBlock = exports.replaceBlockKeyInState = exports.replaceBlockKey = exports.removeBlock = exports.isEmptyUnstyledBlock = exports.insertNewBlock = exports.insertFilesAsBlocks = exports.insertBlockToPosition = exports.getSelectedBlocksMap = exports.getNodeByBlockKey = exports.getKeysFromBlocksMap = exports.getEntityType = exports.getBlockKeysWithinSelection = exports.getBlockChanges = exports.addAtomicBlock = exports.INTERNAL_CHANGE_TYPE = exports.ATOMIC_BLOCK_TYPES = void 0;
var _draftJs = _interopRequireWildcard(require("draft-js"));
var _DraftOffsetKey = _interopRequireDefault(require("draft-js/lib/DraftOffsetKey"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); } // @ts-ignore
var AtomicBlockUtils = _draftJs.default.AtomicBlockUtils,
  SelectionState = _draftJs.default.SelectionState,
  genKey = _draftJs.default.genKey;
var ATOMIC_BLOCK_TYPES = {
  IFRAME: 'iframe',
  IMAGE: 'slide',
  FILE: 'file',
  PRESENTATION: 'presentation',
  MEDIA: 'video'
};
exports.ATOMIC_BLOCK_TYPES = ATOMIC_BLOCK_TYPES;
var INTERNAL_CHANGE_TYPE = 'internal-change';
exports.INTERNAL_CHANGE_TYPE = INTERNAL_CHANGE_TYPE;
var insertFilesAsBlocks = function insertFilesAsBlocks(files, currentEditorState) {
  var newEditorState = files.reduce(function (editorState, file) {
    var _ref = file || {},
      typeFile = _ref.typeFile;
    var entityData = makeEntityDataFromFile(file);
    return addAtomicBlock(editorState, typeFile, entityData);
  }, currentEditorState);
  return newEditorState;
};
exports.insertFilesAsBlocks = insertFilesAsBlocks;
var makeEntityDataFromFile = function makeEntityDataFromFile(_ref2) {
  var id = _ref2.id,
    name = _ref2.name,
    url = _ref2.url;
  return {
    id: id,
    name: name,
    src: url,
    isFile: true
  };
};
var addAtomicBlock = function addAtomicBlock(editorState, type, data) {
  var contentState = editorState.getCurrentContent();
  var contentStateWithEntity = contentState.createEntity(type, 'IMMUTABLE', _objectSpread({}, data));
  var entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  var newEditorState = _draftJs.EditorState.set(editorState, {
    currentContent: contentStateWithEntity
  }
  // 'create-entity',
  );

  return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
};
exports.addAtomicBlock = addAtomicBlock;
var getEntityType = function getEntityType(contentState, entityKey) {
  if (!entityKey) {
    return null;
  }
  var entity = contentState.getEntity(entityKey);
  return entity && entity.getType();
};
exports.getEntityType = getEntityType;
var getBlocksBefore = function getBlocksBefore(blockMap, currentBlock) {
  return blockMap.toSeq().takeUntil(function (block) {
    return block === currentBlock;
  });
};
var getBlocksAfter = function getBlocksAfter(blockMap, currentBlock) {
  return blockMap.toSeq().skipUntil(function (block) {
    return block === currentBlock;
  }).rest();
};
var removeBlockFromMap = function removeBlockFromMap(blockMap, blockToRemove) {
  var blocksBefore = getBlocksBefore(blockMap, blockToRemove);
  var blocksAfter = getBlocksAfter(blockMap, blockToRemove);
  return blocksBefore.concat(blocksAfter).toOrderedMap();
};
var insertBlockAfter = function insertBlockAfter(blockMap, blockToInsert, blockToInsertAfter) {
  var blocksBefore = getBlocksBefore(blockMap, blockToInsertAfter);
  var blocksAfter = getBlocksAfter(blockMap, blockToInsertAfter);
  var newBlocks = [[blockToInsertAfter.getKey(), blockToInsertAfter], [blockToInsert.getKey(), blockToInsert]];
  return blocksBefore.concat(newBlocks, blocksAfter).toOrderedMap();
};
var insertBlockBefore = function insertBlockBefore(blockMap, blockToInsert, blockToInsertBefore) {
  if (!blockToInsertBefore) {
    return blockMap.concat([[blockToInsert.getKey(), blockToInsert]]).toOrderedMap();
  }
  var blocksBefore = getBlocksBefore(blockMap, blockToInsertBefore);
  var blocksAfter = getBlocksAfter(blockMap, blockToInsertBefore);
  var newBlocks = [[blockToInsert.getKey(), blockToInsert], [blockToInsertBefore.getKey(), blockToInsertBefore]];
  return blocksBefore.concat(newBlocks, blocksAfter).toOrderedMap();
};
var replaceBlockFromBlockMap = function replaceBlockFromBlockMap(blockMap, oldBlock, newBlock) {
  var blocksBefore = getBlocksBefore(blockMap, oldBlock);
  var blocksAfter = getBlocksAfter(blockMap, oldBlock);
  var newBlocks = [[newBlock.getKey(), newBlock]];
  return blocksBefore.concat(newBlocks, blocksAfter).toOrderedMap();
};
var insertBlockToPosition = function insertBlockToPosition(editorState, block, position) {
  var contentState = editorState.getCurrentContent();
  var blockMap = contentState.getBlockMap();
  var indexedBlockMap = blockMap.toIndexedSeq();
  var selection = editorState.getSelection();
  if (indexedBlockMap.count() < position) {
    position = indexedBlockMap.count();
  }
  var blockToInsertBefore = indexedBlockMap.get(position, undefined);
  var newBlockMap = insertBlockBefore(blockMap, block, blockToInsertBefore);
  var newContentState = contentState.merge({
    blockMap: newBlockMap,
    selectionBefore: selection,
    selectionAfter: selection
  });
  return _draftJs.EditorState.push(editorState, newContentState, INTERNAL_CHANGE_TYPE);
};
exports.insertBlockToPosition = insertBlockToPosition;
var replaceBlockKey = function replaceBlockKey(block, newKey) {
  return new _draftJs.ContentBlock({
    key: newKey,
    text: block.getText(),
    type: block.getType(),
    data: block.getData().merge({})
  });
};
exports.replaceBlockKey = replaceBlockKey;
var insertNewBlock = function insertNewBlock(editorState) {
  var blockParams = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var withFocus = arguments.length > 2 ? arguments[2] : undefined;
  var _blockParams$key = blockParams.key,
    key = _blockParams$key === void 0 ? genKey() : _blockParams$key,
    _blockParams$type = blockParams.type,
    type = _blockParams$type === void 0 ? 'unstyled' : _blockParams$type;
  var contentState = editorState.getCurrentContent();
  var selection = editorState.getSelection();
  var blockMap = contentState.getBlockMap();
  var currentBlock = contentState.getBlockForKey(selection.getEndKey());
  var newBlock = new _draftJs.ContentBlock({
    key: key,
    text: '',
    type: type,
    data: currentBlock.getData().merge({})
  });
  var newBlockMap = insertBlockAfter(blockMap, newBlock, currentBlock);
  var newContentState = contentState.merge({
    blockMap: newBlockMap,
    selectionBefore: selection,
    selectionAfter: selection
  });
  var newEditorState = _draftJs.EditorState.push(editorState, newContentState, INTERNAL_CHANGE_TYPE);
  return withFocus ? _draftJs.EditorState.forceSelection(newEditorState, SelectionState.createEmpty(key)) : newEditorState;
};
exports.insertNewBlock = insertNewBlock;
var replaceBlockKeyInState = function replaceBlockKeyInState(editorState, oldKey, newKey) {
  var contentState = editorState.getCurrentContent();
  var selection = editorState.getSelection();
  var oldBlock = contentState.getBlockForKey(oldKey);
  if (!oldBlock) {
    return editorState;
  }
  var blockMap = contentState.getBlockMap();
  var newBlock = new _draftJs.ContentBlock({
    key: newKey,
    text: oldBlock.getText(),
    data: oldBlock.getData(),
    characterList: oldBlock.getCharacterList()
  });
  var newSelectionState = selection;
  if (selection.getAnchorKey() === oldKey) {
    newSelectionState = SelectionState.createEmpty(newKey);
    newSelectionState = newSelectionState.merge({
      focusKey: selection.getFocusKey() === oldKey ? newKey : selection.getFocusKey(),
      focusOffset: selection.getFocusOffset(),
      anchorOffset: selection.getAnchorOffset()
    });
  }
  var newBlockMap = replaceBlockFromBlockMap(blockMap, oldBlock, newBlock);
  var newContentState = contentState.merge({
    blockMap: newBlockMap,
    selectionBefore: selection,
    selectionAfter: selection
  });
  var newEditorState = _draftJs.EditorState.push(editorState, newContentState, INTERNAL_CHANGE_TYPE);
  return _draftJs.EditorState.forceSelection(newEditorState, newSelectionState);
};
exports.replaceBlockKeyInState = replaceBlockKeyInState;
var updateBlock = function updateBlock(editorState, updatedBlock) {
  var contentState = editorState.getCurrentContent();
  var selection = editorState.getSelection();
  var oldBlock = contentState.getBlockForKey(updatedBlock.getKey());
  var blockMap = contentState.getBlockMap();
  var newBlockMap = replaceBlockFromBlockMap(blockMap, oldBlock, updatedBlock);
  var newContentState = contentState.merge({
    blockMap: newBlockMap,
    selectionBefore: selection,
    selectionAfter: selection
  });
  return _draftJs.EditorState.push(editorState, newContentState, INTERNAL_CHANGE_TYPE);
};
exports.updateBlock = updateBlock;
var removeBlock = function removeBlock(editorState, blockKey) {
  var contentState = editorState.getCurrentContent();
  var selection = editorState.getSelection();
  var oldBlock = contentState.getBlockForKey(blockKey);
  var blockMap = contentState.getBlockMap();
  var newBlockMap = removeBlockFromMap(blockMap, oldBlock);
  var newContentState = contentState.merge({
    blockMap: newBlockMap,
    selectionBefore: selection,
    selectionAfter: selection
  });
  return _draftJs.EditorState.push(editorState, newContentState, INTERNAL_CHANGE_TYPE);
};
exports.removeBlock = removeBlock;
var getNodeByBlockKey = function getNodeByBlockKey(blockKey) {
  if (!blockKey) {
    return null;
  }
  var offsetKey = _DraftOffsetKey.default.encode(blockKey, 0, 0);
  return document.querySelector("[data-offset-key=\"".concat(offsetKey, "\"]"));
};
exports.getNodeByBlockKey = getNodeByBlockKey;
var isEmptyUnstyledBlock = function isEmptyUnstyledBlock(editorState, blockKey) {
  var contentState = editorState.getCurrentContent();
  var currentBlock = contentState.getBlockForKey(blockKey);
  return currentBlock.getType() === 'unstyled' && currentBlock.getText() === '';
};
exports.isEmptyUnstyledBlock = isEmptyUnstyledBlock;
var getSelectedBlocksMap = function getSelectedBlocksMap(editorState) {
  var selection = editorState.getSelection();
  var content = editorState.getCurrentContent();
  var startKey = selection.getStartKey();
  var endKey = selection.getEndKey();
  var blockMap = content.getBlockMap();
  return blockMap.toSeq().skipUntil(function (_, k) {
    return k === startKey;
  }).takeUntil(function (_, k) {
    return k === endKey;
  }).concat([[endKey, blockMap.get(endKey)]]);
};
exports.getSelectedBlocksMap = getSelectedBlocksMap;
var getKeysFromBlocksMap = function getKeysFromBlocksMap(blocksMap) {
  var keysIterator = blocksMap.keys();
  var keys = [];
  var next = keysIterator.next();
  var i = 0;
  while (!next.done && i < 4) {
    keys.push(next.value);
    next = keysIterator.next();
  }
  return keys;
};
exports.getKeysFromBlocksMap = getKeysFromBlocksMap;
var getBlockKeysWithinSelection = function getBlockKeysWithinSelection(editorState) {
  var contentState = editorState.getCurrentContent();
  var selectionState = editorState.getSelection();
  var startBlockKey = selectionState.getStartKey();
  var endBlockKey = selectionState.getEndKey();
  var isSameBlock = startBlockKey === endBlockKey;
  var selectedBlockKeys = [startBlockKey];
  if (!isSameBlock) {
    var blockKey = startBlockKey;
    while (blockKey !== endBlockKey) {
      var nextBlock = contentState.getBlockAfter(blockKey);
      if (nextBlock) {
        selectedBlockKeys.push(nextBlock.getKey());
        blockKey = nextBlock.getKey();
      } else {
        break;
      }
    }
  }
  return selectedBlockKeys;
};
exports.getBlockKeysWithinSelection = getBlockKeysWithinSelection;
var getBlockChanges = function getBlockChanges(oldEditorState, newEditorState) {
  var newBlockMap = newEditorState.getCurrentContent().getBlockMap();
  var oldBlockMap = oldEditorState.getCurrentContent().getBlockMap();
  var newBlockKeys = getKeysFromBlocksMap(newEditorState.getCurrentContent().getBlockMap());
  var oldBlockKeys = getKeysFromBlocksMap(oldEditorState.getCurrentContent().getBlockMap());
  var addedBlocks = [];
  var deletedBlocks = [];
  var changedBlocks = [];
  newBlockKeys.forEach(function (key) {
    var oldBlock = oldBlockMap.get(key);
    var newBlock = newBlockMap.get(key);
    if (!oldBlock) {
      addedBlocks.push(key);
    } else if (oldBlock !== newBlock && !(oldBlock.getText() === '' && newBlock.getText() === '')) {
      changedBlocks.push(key);
    }
  });
  oldBlockKeys.forEach(function (key) {
    var newBlock = newBlockMap.get(key);
    if (!newBlock) {
      deletedBlocks.push(key);
    }
  });
  return {
    addedBlocks: addedBlocks,
    deletedBlocks: deletedBlocks,
    changedBlocks: changedBlocks
  };
};
exports.getBlockChanges = getBlockChanges;