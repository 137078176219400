import { useSnackbar } from 'notistack';
import { useRef, useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useMixpanelTracking } from '@wbnr/frontend-shared/lib/analytics/mixpanel/useMixpanelTracking';
import { getUsedSpacePercent, useUser } from '@wbnr/frontend-shared/lib/data/user';
import { appLocalStorage } from '@wbnr/frontend-shared/lib/utils/browserStorage';

export const useFileSizeBanner = ({ pathname }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [user] = useUser();
    const prevUsedSpacePercent = useRef(null);
    const [forceClose, setForceClose] = useState(false);
    const { track } = useMixpanelTracking();

    const usedSpacePercent = getUsedSpacePercent(user && user.fileSize);
    const canShowBasedOnPercent = usedSpacePercent && usedSpacePercent > 90;
    const close = useCallback(() => {
        if (user) {
            saveLastUsedSpace(user.id, usedSpacePercent);
            setForceClose(true);
            appLocalStorage.setItem(FM_ALERT_SHOWN_KEY, false);
        }
    }, [setForceClose, user, usedSpacePercent]);

    useEffect(() => {
        if (user && prevUsedSpacePercent.current === null) {
            prevUsedSpacePercent.current = getLastUsedSpace(user.id);
            setForceClose(Boolean(prevUsedSpacePercent.current));
        }

        if (user && usedSpacePercent < 90) {
            setForceClose(false);
            removeLastUsedSpace(user.id);
        } else if (user && prevUsedSpacePercent.current !== null) {
            if (prevUsedSpacePercent.current < 100 && usedSpacePercent >= 100) {
                setForceClose(false);
                removeLastUsedSpace(user.id);
            }

            if (usedSpacePercent < 100 && prevUsedSpacePercent.current >= 100) {
                enqueueSnackbar(t('banners.newFileSizeAvailable'));
            }
        }

        if (user) {
            prevUsedSpacePercent.current = usedSpacePercent;
        }
    }, [usedSpacePercent, setForceClose, user, t, enqueueSnackbar]);
    const show =
        Boolean(user) && canShowBasedOnPercent && !forceClose && !/^\/files/.test(pathname);

    useEffect(() => {
        if (user && usedSpacePercent < 90) {
            appLocalStorage.setItem(FM_ALERT_SHOWN_KEY, false);
        }

        const bannerWasShown = appLocalStorage.getItem(FM_ALERT_SHOWN_KEY) === 'true';

        if (show && !bannerWasShown) {
            track(FM_ALERT_SHOWN_KEY, {
                filling_rate: usedSpacePercent >= 90 && usedSpacePercent < 100 ? 90 : 100,
            });
            appLocalStorage.setItem(FM_ALERT_SHOWN_KEY, true);
        }
    }, [show, user, track, usedSpacePercent]);

    return useMemo(
        () => ({
            show,
            onClose: close,
        }),
        [show, close],
    );
};

const LAST_USED_SPACE_KEY = 'fs_size_warning_closed_';
const FM_ALERT_SHOWN_KEY = 'fm_full_alert_shown';

const saveLastUsedSpace = (id, usedSpace) => {
    appLocalStorage.setItem(LAST_USED_SPACE_KEY + id, String(usedSpace / 100));
};

const getLastUsedSpace = (id) => {
    let value = appLocalStorage.getItem(LAST_USED_SPACE_KEY + id);

    if (value) {
        value = parseFloat(value) * 100;
    }

    return value;
};

const removeLastUsedSpace = (id) => {
    appLocalStorage.removeItem(id);
};
