var TRANSLATIONS = {
  RU: {
    b: 'Б',
    Kb: 'КБ',
    Mb: 'МБ',
    Gb: 'ГБ'
  },
  EN: {
    b: 'B',
    Kb: 'KB',
    Mb: 'MB',
    Gb: 'GB'
  }
};
var fileSize = function fileSize(_ref) {
  var size = _ref.size,
    _ref$locale = _ref.locale,
    locale = _ref$locale === void 0 ? 'RU' : _ref$locale,
    zeroAsInf = _ref.zeroAsInf;
  var translations = TRANSLATIONS[locale];
  if (size) {
    var unit = 'b';
    var units = ['Kb', 'Mb', 'Gb'];
    for (var i = 0; i < units.length && size >= 1000; i++) {
      size /= 1000;
      unit = units[i];
    }
    var resultSize = (unit === 'Gb' ? Math.ceil(size * 10) / 10 : Math.ceil(size)).toString();
    if (locale === 'RU') {
      resultSize = resultSize.replace('.', ',');
    }
    return "".concat(resultSize, "\u2009").concat(translations[unit]);
  } else if (zeroAsInf) {
    return "\u221E\u2009\u0413\u0431";
  }
  return "\xA0";
};
export default fileSize;