import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import LoadingScreen from '@wbnr/frontend-shared/lib/components/LoadingScreen';
import { useFeatureSettings } from '@wbnr/frontend-shared/lib/data/featureSettings';
import { isOrganizationAdmin, useAccountUser } from '@wbnr/frontend-shared/lib/data/user';
import { Typography, Link } from '@wbnr/ui';

import NotFoundPage from 'routes/notFound';

import BusinessLayout from '../BusinessLayout';

import { PointOfPresence } from './PointOfPresence';
import styles from './PointOfPresence.module.scss';
import { PointOfPresenceContextProvider } from './PointOfPresenceContext';

const PAGE_TITLE = 'Медиавыносы - Webinar';
const POP_ARTICLE = 'https://help.webinar.ru/ru';

const PointOfPresencePage = () => {
    const { t } = useTranslation();
    const [user] = useAccountUser();
    const isAdmin = useMemo(() => isOrganizationAdmin(user), [user]);

    const { data: features, fulfilled: featuresFulfilled } = useFeatureSettings();

    if (!featuresFulfilled) {
        return <LoadingScreen />;
    }

    if (!features.useMediaPop || !isAdmin) {
        return <NotFoundPage />;
    }

    return (
        <PointOfPresenceContextProvider>
            <Helmet>
                <title>{PAGE_TITLE}</title>
            </Helmet>

            <BusinessLayout>
                <div className={styles.root}>
                    <header className={styles.header}>
                        <Typography className={styles.title} variant="h6">
                            {t('business.pointOfPresencePage.title')}
                        </Typography>
                    </header>

                    <section className={styles.contextPart}>
                        <div className={styles.contextSection}>
                            <Typography className={styles.description} variant="body1">
                                {t('business.pointOfPresencePage.description')}
                            </Typography>

                            <Link
                                href={POP_ARTICLE}
                                className={styles.aboutLink}
                                variant="body1"
                                underline="hover"
                            >
                                {t('business.pointOfPresencePage.aboutLink')}
                            </Link>
                        </div>

                        <PointOfPresence />
                    </section>
                </div>
            </BusinessLayout>
        </PointOfPresenceContextProvider>
    );
};

export { PointOfPresencePage };
