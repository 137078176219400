import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { APIError } from '@wbnr/frontend-shared/lib/api';
import { confirmSMTP } from '@wbnr/frontend-shared/lib/api/smtp';
import { AuthLayout } from '@wbnr/frontend-shared/lib/components/auth';
import LoadingScreen from '@wbnr/frontend-shared/lib/components/LoadingScreen';
import { Typography, Button } from '@wbnr/ui';

import styles from './SMTPSettingsConfirmation.module.scss';

const i18nPath = 'admin.smtp';

const SMTPSettingsConfirmation = () => {
    const { token } = useParams<{ token: string }>();
    const { t } = useTranslation();
    const [tokenIsValid, setTokenIsValid] = useState<boolean | null>(null);
    const [error, setError] = useState<null | APIError>(null);

    useEffect(() => {
        confirmSMTP(token)
            .then(() => {
                setTokenIsValid(true);
            })
            .catch((confirmSMTPError) => {
                setTokenIsValid(false);
                setError(confirmSMTPError);
            });
    }, [token]);

    if (tokenIsValid === null) {
        return <LoadingScreen />;
    }

    let content;

    if (error) {
        switch (error.status) {
            case 400:
                content = (
                    <Typography paragraph>{t(`${i18nPath}.errors.notFound.text`)}</Typography>
                );
                break;

            default:
                content = (
                    <>
                        <Typography paragraph>{t(`${i18nPath}.errors.network.text`)}</Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => window.location.reload()}
                        >
                            {t(`${i18nPath}.errors.network.button`)}
                        </Button>
                    </>
                );
                break;
        }
    }

    if (tokenIsValid) {
        content = (
            <>
                <Typography paragraph>{t(`${i18nPath}.confirmation`)}</Typography>
                <Button color="inherit" href={`${window.location.origin}/admin/smtp`}>
                    {t(`${i18nPath}.adminLink`)}
                </Button>
            </>
        );
    }
    return (
        <AuthLayout>
            <div className={styles.wrapper}>{content}</div>
        </AuthLayout>
    );
};

export default SMTPSettingsConfirmation;
