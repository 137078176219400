import { useState } from 'react';
import { Trans } from 'react-i18next';

import { Domain } from '@wbnr/frontend-shared/lib/api/business-api/types';
import { Typography } from '@wbnr/ui';

import { AddDomain } from './AddDomain';
import { DomainApproveModal } from './DomainApproveModal';
import { DomainList } from './DomainList';
import styles from './DomainSettings.module.scss';

export const DomainSettings = () => {
    const [approveDomainModal, setApproveDomainModal] = useState<Domain | null>(null);

    return (
        <div className={styles.root}>
            <div className={styles.title}>
                <Trans i18nKey={'business.sso.domain.title'} />
            </div>
            <Typography variant="body1" className={styles.subtitle}>
                <Trans i18nKey={'business.sso.domain.subtitle'} />
            </Typography>
            <DomainList onApprove={(domain) => setApproveDomainModal(domain)} />
            <AddDomain onAdd={(domain) => setApproveDomainModal(domain)} />
            {approveDomainModal && (
                <DomainApproveModal
                    domain={approveDomainModal}
                    onClose={() => setApproveDomainModal(null)}
                />
            )}
        </div>
    );
};
