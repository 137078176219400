import { useTranslation } from 'react-i18next';

import { useCommonCancelableFeatureRequestDialogs } from '@wbnr/frontend-shared/lib/dialogs/commonCancelableFeatureRequestDialogs';
import { useFeatureRequestDialog } from '@wbnr/frontend-shared/lib/dialogs/featureRequestDialog';
import { createTestIdProps } from '@wbnr/ui';

import brandingFeatureRequestImage from '../../assets/branding-feature-request.svg';

const i18nPath = 'business.brandingPage';

export const useFeatureSuggestionDialogs = () => {
    const { t } = useTranslation();

    const { openFeatureRequestDialog: openBrandingSuggestion } = useFeatureRequestDialog(
        'branding',
        {
            imageSrc: brandingFeatureRequestImage,
            title: t(`${i18nPath}.brandingFeatureRequest.title`),
            description: t(`${i18nPath}.brandingFeatureRequest.description`),
            submitText: t(`${i18nPath}.brandingFeatureRequest.submit`),
            ...createTestIdProps('BrandingSuggestion'),
        },
    );

    const { openFeatureRequestDialog: openMultibrandingSuggestion } = useFeatureRequestDialog(
        'multibranding',
        {
            type: 'simple',
            mixpanelEventOnShow: 'plg_multibranding',
            mixpanelEventOnRequest: 'plg_multibranding_click',
            title: t(`${i18nPath}.multibrandingFeatureRequest.title`),
            description: t(`${i18nPath}.multibrandingFeatureRequest.description`),
            submitText: t(`${i18nPath}.multibrandingFeatureRequest.submit`),
            ...createTestIdProps('MultibrandingSuggestion'),
        },
    );

    const { openBrandingCancelableFeatureRequestDialog: openNewBrandingSuggestion } =
        useCommonCancelableFeatureRequestDialogs();

    return { openBrandingSuggestion, openMultibrandingSuggestion, openNewBrandingSuggestion };
};
