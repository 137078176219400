import { SAMLSettings } from '@wbnr/frontend-shared/lib/api/business-api/types';

export enum SSOStep {
    LOADING,
    VIEW,
    IDP,
    SP,
}

export enum IdpProviders {
    GSuite = 'gsuite',
    ActiveDirectory = 'ad',
    VMWare = 'workspaceOneAccess',
}

export enum IdpProtocols {
    SAML = 'SAML',
}

export const IDP_PROTOCOLS = [
    {
        id: IdpProtocols.SAML,
        label: IdpProtocols.SAML,
    },
];

export const IDP_OPTIONS = [
    {
        id: IdpProviders.GSuite,
        label: 'Google Workspace',
    },
    {
        id: IdpProviders.ActiveDirectory,
        label: 'Active Directory',
    },
    {
        id: IdpProviders.VMWare,
        label: 'VMware',
    },
];

export const SP_SETTING_LIST: (keyof Omit<SAMLSettings, 'id'>)[] = [
    'spEntityId',
    'spAssertionConsumerService',
];

export const SETTINGS_LIST: (keyof Omit<SAMLSettings, 'id'>)[] = [
    'idpEntityId',
    'idpSingleSignOnServiceUrl',
    'idpSingleLogoutServiceUrl',
    'idpX509certBase64',
    ...SP_SETTING_LIST,
];
