import { useTranslation } from 'react-i18next';

import Banner from '@wbnr/frontend-shared/lib/components/Banner';
import { Button } from '@wbnr/ui';

import { useBannerParams } from 'shared/banners';

const MeetingLimitBanner = () => {
    const { params, ...bannerParams } = useBannerParams('meetingLimit');
    const { t } = useTranslation();

    if (!params) {
        return null;
    }

    return (
        <Banner
            onClose={() => {
                params.onBannerClose();
            }}
            show={bannerParams.show}
            title={t('banners.meetingParticipantsLimit', { limit: params.limit })}
            type={'danger'}
            action={
                <Button
                    color="inherit"
                    onClick={() => {
                        params.onSwitchEvent();
                        bannerParams.onClose();
                    }}
                >
                    {t('banners.switchEventType')}
                </Button>
            }
        />
    );
};

export default MeetingLimitBanner;
