import { useResource } from '../useResource';
import { useBinded } from '../../hooks/useBinded';
import { getCourseStatistics } from '../../api/statistics';
export var useCourseStatistics = function useCourseStatistics(startAt, endAt, courseIds, reducer, options) {
  var ids = courseIds && (courseIds === null || courseIds === void 0 ? void 0 : courseIds.map(function (courseId) {
    return Number(courseId);
  }));
  return useResource({
    request: useBinded(getCourseStatistics, {
      startAt: startAt,
      endAt: endAt,
      ids: ids
    })
  }, reducer, options);
};