"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useHover = void 0;
var _react = require("react");
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var useHover = function useHover() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    delayBeforeDisable = _ref.delayBeforeDisable,
    delayBeforeIdle = _ref.delayBeforeIdle;
  var _useState = (0, _react.useState)(false),
    _useState2 = _slicedToArray(_useState, 2),
    hovered = _useState2[0],
    setHovered = _useState2[1];
  var _useState3 = (0, _react.useState)(false),
    _useState4 = _slicedToArray(_useState3, 2),
    idle = _useState4[0],
    setIdle = _useState4[1];
  var hoverRef = (0, _react.useRef)(null);
  var disableTimeout = (0, _react.useRef)(null);
  var idleTimeout = (0, _react.useRef)(null);
  (0, _react.useEffect)(function () {
    var removeHover = function removeHover() {
      if (!delayBeforeDisable) {
        setHovered(false);
      } else {
        disableTimeout.current = setTimeout(function () {
          return setHovered(false);
        }, delayBeforeDisable);
      }
      window.removeEventListener('mousemove', documentMouseMoveHandler);
    };
    var documentMouseMoveHandler = function documentMouseMoveHandler(e) {
      if (hoverRef.current && e.target && !hoverRef.current.contains(e.target)) {
        removeHover();
      }
    };
    var mouseEnterHandler = function mouseEnterHandler() {
      clearTimeout(disableTimeout.current);
      window.addEventListener('mousemove', documentMouseMoveHandler);
      setHovered(true);
    };
    var mouseLeaveHandler = function mouseLeaveHandler() {
      removeHover();
    };
    var mouseMoveHandler = function mouseMoveHandler() {
      clearTimeout(idleTimeout.current);
      idleTimeout.current = setTimeout(function () {
        return setIdle(true);
      }, delayBeforeIdle);
      setIdle(false);
    };
    var currentHoverRef = hoverRef.current;
    if (currentHoverRef) {
      currentHoverRef.addEventListener('mouseenter', mouseEnterHandler);
      currentHoverRef.addEventListener('mouseleave', mouseLeaveHandler);
    }
    if (delayBeforeIdle) {
      idleTimeout.current = setTimeout(function () {
        return setIdle(true);
      }, delayBeforeIdle);
      if (currentHoverRef) {
        currentHoverRef.addEventListener('mousemove', mouseMoveHandler);
      }
    }
    return function () {
      clearTimeout(disableTimeout.current);
      clearTimeout(idleTimeout.current);
      window.removeEventListener('mousemove', documentMouseMoveHandler);
      if (currentHoverRef) {
        currentHoverRef.removeEventListener('mouseenter', mouseEnterHandler);
        currentHoverRef.removeEventListener('mouseleave', mouseLeaveHandler);
        currentHoverRef.removeEventListener('mousemove', mouseMoveHandler);
      }
    };
  }, [delayBeforeDisable, delayBeforeIdle]);
  return [hovered && !idle, hoverRef];
};
exports.useHover = useHover;