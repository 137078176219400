export var pickActiveGroupAndStep = function pickActiveGroupAndStep(state) {
  var sortedByPriority = Object.keys(state.groups).map(function (key) {
    return state.groups[key];
  }).filter(function (group) {
    return !group || group.available && group.active && !group.finished;
  }).sort(function (group1, group2) {
    return !group1 || !group2 ? 0 : group2.priority - group1.priority;
  });
  var topGroup = sortedByPriority[0];
  if (!topGroup) {
    return;
  }
  return {
    groupId: topGroup.id,
    stepId: topGroup.loaded ? topGroup.activeStepId : undefined
  };
};