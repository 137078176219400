import React, { Suspense, ComponentProps } from 'react';

import LoadingScreen from '@wbnr/frontend-shared/lib/components/LoadingScreen';

const MeetingsLazy = React.lazy(() => import('@wbnr/meetings-control-panel'));

const MeetingsRoutes = (props: ComponentProps<typeof MeetingsLazy>) => {
    return (
        <Suspense fallback={<LoadingScreen />}>
            <MeetingsLazy {...props} />
        </Suspense>
    );
};

export default MeetingsRoutes;
