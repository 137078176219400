var translations = {
  ru: {
    branding: {
      title: 'Брендирование',
      description: 'Персонализируйте весь контент, который вы показываете участникам: от писем до кнопок в записи. <0>Узнать больше<0>'
    },
    api: {
      title: 'API',
      description: 'Бесшовная интеграция платформы Webinar со сторонними системами, такими как CRM, LMS и другие. <0>Узнать больше<0>'
    },
    analytics: {
      title: 'Яндекс. Метрика и Google Analytics',
      description: 'Контролируйте эффективность рекламных кампаний, отслеживайте достижение маркетинговых целей. <0>Узнать больше<0>'
    },
    utm: {
      title: 'UTM-метки',
      description: 'Чтобы вы точно знали, откуда пришли на вебинар участники, в статистке будут данные о UTM-метках. <0>Узнать больше<0>'
    },
    autowebinars: {
      title: 'Автовебинар',
      description: 'Автовебинар - это трансляция записи, которую не отличить от прямого эфира. Участники не увидят разницу, а вы сэкономите. <0>Узнать больше<0>'
    }
  },
  en: {
    branding: {
      title: 'Branding',
      description: 'Personalize all the content you show to attendees, from emails to buttons in the record. <0>Learn more<0>'
    },
    api: {
      title: 'API',
      description: 'Seamless integration of the Webinar platform with third-party systems such as CRM, LMS and others. <0>Learn more<0>'
    },
    analytics: {
      title: 'Yandex. Metric and Google Analytics',
      description: 'Track the effectiveness of your advertising campaigns and monitor the achievement of your marketing goals. <0>Learn more<0>'
    },
    utm: {
      title: 'UTM tags',
      description: 'To let you know exactly where the participants came from in the webinar, the stats will include data on UTM tags. <0>Learn more<0>'
    },
    autowebinars: {
      title: 'Autowebinar',
      description: "Autowebinar is a broadcast recording, which is indistinguishable from the live broadcast. Participants won't see the difference, and you'll save money. <0>Learn more<0>"
    }
  }
};
export default translations;