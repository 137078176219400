import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { logout } from '@wbnr/frontend-shared/lib/api/user';
import LoadingScreen from '@wbnr/frontend-shared/lib/components/LoadingScreen';
import { FormProvider } from '@wbnr/frontend-shared/lib/forms';
import { ComponentTestIdProps, createTestIdProps, dataTestIdKey } from '@wbnr/ui';

import { useCheckSSOTransferOrganization } from '../../hooks/useCheckSSOTransferOrganization';
import { FormButtons } from '../FormButtons';
import { SSOChooseOrganization } from '../SSOChooseOrganization';

type Props = {
    isSelectedSSOOrganization: boolean;
    onSubmitClick: () => void;
} & ComponentTestIdProps;

export const AuthFormTransferOrganization = ({
    isSelectedSSOOrganization,
    onSubmitClick,
    [dataTestIdKey]: baseTestId,
}: Props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { formMethods, transferOrganizations } = useCheckSSOTransferOrganization({
        isSelectedSSOOrganization,
        onSubmitClick,
    });

    const handleBack = useCallback(() => {
        logout().then(() => history.push('/signin', history.location.pathname));
    }, [history]);

    if (!transferOrganizations?.length) {
        return <LoadingScreen />;
    }

    return (
        <>
            <FormProvider {...formMethods}>
                <SSOChooseOrganization organizationsList={transferOrganizations} />
            </FormProvider>
            <FormButtons
                {...createTestIdProps(baseTestId, 'action')}
                submitButtonText={t('save')}
                backButtonText={t('auth.sso.backLogin')}
                onBackClick={handleBack}
                onSubmitClick={formMethods.submit}
            />
        </>
    );
};
