import { useTranslation } from 'react-i18next';

import { useFormContext, useField } from '@wbnr/frontend-shared/lib/forms';
import {
    validateEmail,
    validateHost,
    validatePort,
    validateNoSpaces,
} from '@wbnr/frontend-shared/lib/utils/validators';
import { TextField, CheckboxField } from '@wbnr/ui';

import { BrandingFormValues } from '../../types';
import { getSmtpPropName } from '../../utils';

import styles from './SmtpForm.module.scss';

const SmtpForm = () => {
    const { t } = useTranslation();
    const { control } = useFormContext<BrandingFormValues>();

    const defaultPortValue = control.defaultValuesRef.current.smtp?.port;

    return (
        <>
            <div className={styles.inline}>
                <TextField
                    fullWidth
                    label={t('business.brandingPage.smtpLogin')}
                    className={styles.field}
                    {...useField(control, getSmtpPropName('login'), {
                        rules: { required: true, validate: validateNoSpaces },
                    })}
                />
                <TextField
                    fullWidth
                    label={t('business.brandingPage.smtpPassword')}
                    className={styles.field}
                    {...useField(control, getSmtpPropName('password'), {
                        rules: { required: true },
                    })}
                />
            </div>
            <TextField
                fullWidth
                label={t('business.brandingPage.smtpEmail')}
                {...useField(control, getSmtpPropName('email'), {
                    rules: { required: true, validate: validateEmail },
                })}
            />
            <TextField
                fullWidth
                label={t('business.brandingPage.smtpName')}
                {...useField(control, getSmtpPropName('name'), { rules: { required: true } })}
            />
            <TextField
                fullWidth
                label={t('business.brandingPage.smtpHost')}
                {...useField(control, getSmtpPropName('host'), {
                    rules: { required: true, validate: validateHost },
                })}
            />
            <TextField
                fullWidth
                label={t('business.brandingPage.smtpPort')}
                {...useField(control, getSmtpPropName('port'), {
                    defaultValue: defaultPortValue || '',
                    rules: { required: true, validate: validatePort },
                })}
            />
            <CheckboxField
                label={t('business.brandingPage.smtpSSL')}
                edge
                {...useField(control, getSmtpPropName('ssl'))}
            />
        </>
    );
};

export default SmtpForm;
