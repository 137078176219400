/* eslint-disable */

function vercmp(a, b) {
  if (a == b) return 0;
  a = a.split('.');
  b = b.split('.');
  for (var i = 0; i < 4; i++) {
    var x = parseInt(a[i]) || 0,
      y = parseInt(b[i]) || 0;
    if (x < y) {
      return -1;
    } else if (x > y) {
      return 1;
    }
  }
  return 0;
}
var win7Regex = /(Windows 7|Windows NT 6.1)/;
export function checkHost(host) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    allowYaBrowser = _ref.allowYaBrowser;
  return new Promise(function (resolve, reject) {
    var devices = navigator.mediaDevices || {};
    var RTCSessionDescription = window.RTCSessionDescription || window.mozRTCSessionDescription || window.webkitRTCSessionDescription || window.msRTCSessionDescription;
    var RTCPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection || window.msRTCPeerConnection;
    var RTCIceCandidate = window.RTCIceCandidate || window.mozRTCIceCandidate || window.webkitRTCIceCandidate || window.msRTCIceCandidate;
    var getUserMedia = devices.getUserMedia || devices.mozGetUserMedia || devices.webkitGetUserMedia || devices.msGetUserMedia;
    var ua = navigator.userAgent;
    var edge = ua.match(/edge\/([0-9]+)\./i);
    var yabrowser = ua.match(/\bYaBrowser\/([\d.]+)/);
    var chrome = edge || yabrowser ? null : ua.match(/chrom(?:e|ium)\/([0-9]+)\./i);
    var firefox = ua.match(/firefox\/(([0-9]\.?)+)/i);
    var safari = edge || chrome || yabrowser ? null : ua.match(/version\/((?:[0-9]\.?)+) safari\/([0-9]+)\./i);
    var opera = ua.match(/\bOPR\/([\d.]+)/);
    if (!RTCSessionDescription || !RTCPeerConnection || !RTCIceCandidate || !getUserMedia || !URL) {
      reject('NotSupportedError');
    }
    if (opera) {
      reject('ProductNotSupportedError');
    } else if (yabrowser) {
      if (!allowYaBrowser) {
        if (ua.match(win7Regex)) {
          reject('ProductVersionNotSupportedError');
        }
        var yaVersion = ua.match(/YaBrowser\/(\d+)\.(\d+)\./i);
        if (yaVersion[1] >= 23 && yaVersion[2] >= 3) {
          reject('ProductVersionNotSupportedError');
        }
        var chromeVersion = ua.match(/chrom(?:e|ium)\/([0-9]+)\./i);
        if (chromeVersion[1] < 60) {
          reject('ProductVersionNotSupportedError');
        }
      }
    } else if (firefox) {
      reject('ProductNotSupportedError');
    } else if (chrome) {
      if (chrome[1] < 60) {
        reject('ProductVersionNotSupportedError');
      }
    } else if (edge) {
      if (edge[1] < 17) {
        reject('ProductVersionNotSupportedError');
      }
    } else if (safari) {
      if (vercmp('13.0.5', safari[1]) > 0) {
        reject('ProductVersionNotSupportedError');
      }
    } else {
      reject('ProductNotSupportedError');
    }
    try {
      var peer;
      try {
        peer = new RTCPeerConnection({
          sdpSemantics: 'unified-plan'
        }, {
          optional: [{
            DtlsSrtpKeyAgreement: false
          }]
        });
      } catch (err) {
        peer = new RTCPeerConnection(null);
      }
      if (peer.addTransceiver) {
        peer.addTransceiver('audio');
        peer.addTransceiver('video');
      }
      peer.oniceconnectionstatechange = function () {
        if (!peer) return;
        switch (peer.iceConnectionState) {
          case 'completed':
          case 'connected':
            resolve();
            peer.close();
            break;
          case 'failed':
          case 'disconnected':
            reject('PeerNetworkError');
            peer.close();
            break;
        }
      };
      peer.createOffer({
        offerToReceiveAudio: true,
        offerToReceiveVideo: true
      }).then(function (offer) {
        if (offer.sdp.match(/^a=rtpmap:\d+\s+opus/gim) == null) {
          reject('AudioNotSupportedError');
        }
        if (offer.sdp.match(/^a=rtpmap:\d+\s+h264/gim) == null) {
          reject('VideoNotSupportedError');
        }
        return peer.setLocalDescription(offer).then(function () {
          var url = "https://".concat(host, "/rtc/conncheck/connect");
          return fetch(url, {
            method: 'post',
            body: offer.sdp
          }).then(function (r) {
            return r.text();
          }).then(function (answer) {
            return peer.setRemoteDescription(new RTCSessionDescription({
              type: 'answer',
              sdp: answer
            }));
          }).catch(function (err) {
            console.log(err);
            reject('SignalingNetworkError');
            peer.close();
          });
        });
      }).catch(function (err) {
        console.log(err);
        reject('UnhandledCreateOfferError');
        peer.close();
      });
      setTimeout(function () {
        reject('TimeoutError');
      }, 15000);
    } catch (err) {
      console.log(err);
      reject('UnhandledError');
    }
  });
}