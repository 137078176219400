"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createDate = createDate;
var _parseISO = _interopRequireDefault(require("date-fns/parseISO"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var ISOTIMEZONE_REGEX = /([+-][0-9]{2}:?([0-9]{2})?|Z)$/;
function createDate(toConvert) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (!toConvert) {
    return new Date();
  } else if (toConvert instanceof Date || typeof toConvert === 'number') {
    return new Date(toConvert);
  }
  if (options.ignoreTimezone) {
    toConvert = toConvert.replace(ISOTIMEZONE_REGEX, '');
  }
  return (0, _parseISO.default)(toConvert);
}