"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useFullscreen = void 0;
var _react = require("react");
var _utils = require("./utils");
var useFullscreen = function useFullscreen(fullscreen, onFullscreenChange) {
  var fullscreenElementRef = (0, _react.useRef)(null);
  (0, _react.useEffect)(function () {
    if (fullscreen) {
      (0, _utils.requestFullscreen)(fullscreenElementRef.current);
    }
    if ((0, _utils.getFullscreenElement)() === fullscreenElementRef.current) {
      (0, _utils.cancelFullScreen)();
    }
  }, [fullscreen]);
  (0, _react.useEffect)(function () {
    var listener = function listener(event) {
      onFullscreenChange && onFullscreenChange(event.target === (0, _utils.getFullscreenElement)());
    };
    (0, _utils.listenFullscreenChange)(listener);
    return function () {
      (0, _utils.stopListenFullscreenChange)(listener);
    };
  }, [onFullscreenChange]);
  return [fullscreenElementRef];
};
exports.useFullscreen = useFullscreen;