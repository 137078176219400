import { serverNow } from './serverTime';
import { createDate } from '@wbnr/ui';
export var defineButtonVariant = function defineButtonVariant(paidBefore, isOrganizationAdmin, isFreeTariff, isGotoBuyTariff, isGotoTariffAnchor, newTariffsPage) {
  var daysToEndTariff = Math.ceil((createDate(paidBefore).getTime() - serverNow()) / (24 * 60 * 60 * 1000));
  if (!isOrganizationAdmin || !isFreeTariff && daysToEndTariff > 7) {
    return 'myTariff';
  } else if (daysToEndTariff < 7 || isFreeTariff) {
    if (isGotoBuyTariff || newTariffsPage) {
      return newTariffsPage ? 'gotoNewTariffsPage' : 'gotoTariff';
    } else if (isGotoTariffAnchor) {
      return newTariffsPage ? 'gotoNewTariffsPage' : 'buyTariff';
    }
  }
};