import { useSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import LoadingScreen from '@wbnr/frontend-shared/lib/components/LoadingScreen';
import { useFeatureSettings } from '@wbnr/frontend-shared/lib/data/featureSettings';
import { useAccountUser, getUserOrganizationId } from '@wbnr/frontend-shared/lib/data/user';
import { BrandingFull } from '@wbnr/frontend-shared/lib/types/Branding';

import BusinessLayout from '../BusinessLayout';

import Branding from './branding';
import BrandingList from './brandingList';
import { useBrandingsFullResource } from './hooks/useBrandingsFullResource';

const Multibranding = () => {
    const params = useParams<{ id?: string }>();
    const history = useHistory();
    const location = useLocation<{ branding?: BrandingFull }>();

    const id = Number(params.id);
    const localBranding = location.state?.branding;

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [user] = useAccountUser();
    const organizationId = getUserOrganizationId(user);

    const { data: features, fulfilled: featuresFulfilled } = useFeatureSettings();

    const {
        data: brandings,
        loading: brandingsLoading,
        fulfilled: brandingsFulfilled,
        onBrandingUpdate,
        ...methods
    } = useBrandingsFullResource(organizationId, Boolean(features.isBrandingEnabled));

    const fulfilled = featuresFulfilled && (brandingsFulfilled || !features.isBrandingEnabled);

    const branding = useMemo(() => {
        if (id === -1 && localBranding) {
            return localBranding;
        } else if (id && brandings) {
            return brandings.find((item) => item.id === id);
        }
    }, [id, localBranding, brandings]);

    useEffect(() => {
        if (fulfilled && features.isBrandingEnabled && id && !branding) {
            history.replace('/business/branding');
            enqueueSnackbar(t('business.brandingPage.notFound'));
        }
    }, [id, branding, fulfilled, features.isBrandingEnabled, history, t, enqueueSnackbar]);

    if (!fulfilled) {
        return <LoadingScreen />;
    }

    return branding ? (
        <Branding
            organizationId={organizationId}
            branding={branding}
            brandingLoading={brandingsLoading}
            customizableAgreements={Boolean(features.registrationCheckbox)}
            onBrandingUpdate={onBrandingUpdate}
        />
    ) : (
        <BusinessLayout>
            <BrandingList
                list={brandings || []}
                isBrandingEnabled={Boolean(features.isBrandingEnabled)}
                isMultibranding={Boolean(features.multibrand)}
                {...methods}
            />
        </BusinessLayout>
    );
};

export default Multibranding;
