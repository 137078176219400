export var commonTranslations = {
  ru: {
    close: 'Закрыть',
    save: 'Сохранить',
    cancel: 'Отмена',
    undo: 'Отменить'
  },
  en: {
    close: 'Close',
    save: 'Save',
    cancel: 'Cancel',
    undo: 'Undo'
  }
};