import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchIcon } from '@wbnr/icons';
import { createTestIdProps, InputAdornment, TextField } from '@wbnr/ui';

import styles from './SearchField.module.scss';

type Props = {
    query: string;
    onChange: (value: string) => void;
};

const BASE_TEST_ID = 'SearchField';

export const SearchField = ({ query, onChange }: Props) => {
    const { t } = useTranslation();
    const [isFocused, setIsFocused] = useState(false);
    return (
        <div className={styles.root}>
            <TextField
                InputProps={{
                    startAdornment: (
                        <InputAdornment className={styles.addoneStart} position="start">
                            <SearchIcon
                                className={cn(styles.icon, { [styles.focus]: isFocused })}
                            />
                        </InputAdornment>
                    ),
                }}
                placeholder={t('business.sso.memberships.search')}
                value={query}
                onChange={(event) => onChange(event.currentTarget.value)}
                autoFocus
                onBlur={() => setIsFocused(false)}
                onFocus={() => setIsFocused(true)}
                fullWidth
                className={styles.searchField}
                {...createTestIdProps(BASE_TEST_ID, 'searchInput')}
            />
        </div>
    );
};
