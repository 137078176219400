"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createBlockFromData = void 0;
var _immutable = require("immutable");
var _draftJs = require("draft-js");
var createBlockFromData = function createBlockFromData(_ref) {
  var key = _ref.key,
    type = _ref.type,
    text = _ref.text;
  return new _draftJs.ContentBlock({
    key: key,
    type: type,
    text: text,
    inlineStyleRanges: [],
    entityRanges: [],
    data: (0, _immutable.Map)()
  });
};
exports.createBlockFromData = createBlockFromData;