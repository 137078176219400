var shouldLog = function shouldLog() {
  return process.env.NODE_ENV !== 'production' || typeof window !== 'undefined' && window.debug;
};
if (typeof Storage !== 'undefined' && typeof window !== 'undefined') {
  try {
    if (localStorage.getItem('debug')) {
      window.debug = true;
    }
  } catch (_unused) {}
}
var logger = function logger() {
  if (!shouldLog()) {
    return;
  }
  for (var _len = arguments.length, argv = new Array(_len), _key = 0; _key < _len; _key++) {
    argv[_key] = arguments[_key];
  }
  if (argv.reduce(function (prev, curr) {
    return prev || curr instanceof Error;
  }, false)) {
    logger.error.apply(logger, argv);
  } else {
    logger.log.apply(logger, argv);
  }
};
logger.info = function () {
  var _console;
  return shouldLog() && (_console = console).info.apply(_console, arguments);
};
logger.log = function () {
  var _console2;
  return shouldLog() && (_console2 = console).log.apply(_console2, arguments);
};
logger.warn = function () {
  var _console3;
  return shouldLog() && (_console3 = console).warn.apply(_console3, arguments);
};
logger.error = function () {
  var _console4;
  return shouldLog() && (_console4 = console).error.apply(_console4, arguments);
};
export default logger;