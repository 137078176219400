import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { A, Button, Typography, createTestIdProps } from '@wbnr/ui';

import { useAddWebhookDialog } from './dialogs/AddWebhookDialog';
import { useWebhooks } from './hooks/useWebhooks';
import { ShowMoreToggle } from './ShowMoreToggle';
import styles from './Webhooks.module.scss';
import { WebhooksList } from './WebhooksList';

const BASE_TEST_ID = 'Webhooks';
const WEBHOOKS_DESCRIPTION_URL = 'https://help.webinar.ru/ru/articles/7831256-webhook';
const createExampleCode = (
    eventComment: string,
    occurredAtComment: string,
    eventSessionIdComment: string,
) => {
    return `{
        "event": "eventSession.ended",  // ${eventComment}
        "occurredAt": "2023-04-21T16:00+03:00",  // ${occurredAtComment}
        "data": {
                "eventSessionId": 1969088081  // ${eventSessionIdComment}
        }
}`;
};

export const Webhooks = () => {
    const { t } = useTranslation();
    const {
        webhooks,
        loading,
        webhooksLimit,
        deleteWebhook,
        enableWebhook,
        disableWebhook,
        createWebhook,
    } = useWebhooks();
    const [openAddWebhookDialog] = useAddWebhookDialog({
        createWebhook,
        webhooksLimit,
    });

    const webhooksLimitReached = webhooks?.length === webhooksLimit;

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <Typography variant="h6" className={styles.title}>
                    {t('business.webhooksPage.title')}
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={openAddWebhookDialog}
                    className={styles.addWebhook}
                    disabled={webhooksLimitReached}
                >
                    {t('business.webhooksPage.addWebhook')}
                </Button>
            </div>
            <Typography variant="body1" className={styles.description}>
                <span>{t('business.webhooksPage.description')}</span>{' '}
                <ReferenceLink>{t('business.webhooksPage.learnMore')}</ReferenceLink>
            </Typography>

            <ShowMoreToggle title={t('business.webhooksPage.example.title')}>
                <Typography variant="body1" component="div">
                    <p className={styles.exampleDescription}>
                        {t('business.webhooksPage.example.description')}
                    </p>
                    <code className={styles.exampleCode}>
                        {createExampleCode(
                            t('business.webhooksPage.example.eventComment'),
                            t('business.webhooksPage.example.occurredAtComment'),
                            t('business.webhooksPage.example.eventSessionIdComment'),
                        )}
                    </code>
                </Typography>
            </ShowMoreToggle>

            {Boolean(webhooks?.length) && (
                <div className={styles.list}>
                    <WebhooksList
                        webhooks={webhooks}
                        loading={loading}
                        webhooksLimit={webhooksLimit}
                        webhooksLimitReached={webhooksLimitReached}
                        deleteWebhook={deleteWebhook}
                        enableWebhook={enableWebhook}
                        disableWebhook={disableWebhook}
                    />
                </div>
            )}
        </div>
    );
};

function ReferenceLink({ children }: { children?: ReactNode }) {
    return (
        <A
            href={WEBHOOKS_DESCRIPTION_URL}
            color="primary"
            target="_blank"
            rel="noreferrer noopener"
            {...createTestIdProps(BASE_TEST_ID, 'description', 'link')}
        >
            {children}
        </A>
    );
}
