import { isSameDay, isBefore, set, sub } from 'date-fns';
import React, { useState, useCallback } from 'react';

import { CalendarTodayIcon } from '@wbnr/icons';
import { IconButton, Dropdown, DayPicker } from '@wbnr/ui';

import styles from './StatisticsPeriodPicker.module.scss';
import { DateRange } from './types';

type ChangeDateRangeHandler = (range: DateRange) => void;

interface StatisticsPeriodPickerProps {
    value: DateRange;
    onChange: ChangeDateRangeHandler;
}

const StatisticsPeriodPicker = ({ value, onChange }: StatisticsPeriodPickerProps) => {
    const [dateRange, onChangeDate] = useDaysRange(onChange, value);

    return (
        <Dropdown
            renderAnchor={({ toggle, anchorRef }) => (
                <div ref={anchorRef as React.RefObject<HTMLDivElement>}>
                    <IconButton
                        className={styles.dayPickerAnchor}
                        onClick={(e) => {
                            e.preventDefault();
                            toggle(true);

                            return false;
                        }}
                    >
                        <CalendarTodayIcon />
                    </IconButton>
                </div>
            )}
            width="lg"
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <DayPicker
                numberOfMonths={2}
                initialMonth={sub(new Date(), { months: 1 })}
                selectedDays={dateRange}
                disabledDays={{ after: new Date() }}
                className={styles.dayPickerRoot}
                classNames={{
                    day: styles.day,
                    selected: styles.selected,
                }}
                modifiers={{
                    selected: dateRange.from || dateRange.to || undefined,
                    [styles.firstSelected]: dateRange.from || undefined,
                    [styles.lastSelected]: dateRange.to || undefined,
                }}
                onDayClick={onChangeDate}
            />
        </Dropdown>
    );
};

function useDaysRange(
    onChange: ChangeDateRangeHandler,
    defaultState?: DateRange,
): [DateRange, (day: Date) => void] {
    const [range, setRange] = useState(defaultState || { from: null, to: null });

    const onChangeDate = useCallback(
        (day: Date) => {
            let { from, to } = range;

            if (!from) {
                from = day;
            } else if (from && to && isSameDay(from, to) && isSameDay(day, from)) {
                from = null;
                to = null;
            } else if (to && isDayBefore(day, from)) {
                from = day;
            } else if (to && isSameDay(day, to)) {
                from = day;
                to = day;
            } else {
                to = day;
                if (isDayBefore(to, from)) {
                    to = from;
                    from = day;
                }
            }

            const result = { from, to };

            setRange(result);
            onChange(result);
        },
        [range, setRange, onChange],
    );

    return [range, onChangeDate];
}

function isDayBefore(dayA: number | Date, dayB: number | Date): boolean {
    const nullTimeData = { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 };
    const formattedA = set(dayA, nullTimeData);
    const formattedB = set(dayB, nullTimeData);

    return isBefore(formattedA, formattedB);
}

export default StatisticsPeriodPicker;
