import { createDate } from './dates';
var serverTimeOffset;
export var convertToServerTime = function convertToServerTime(localTime) {
  var offset = serverTimeOffset;
  if (offset === undefined) {
    offset = 0;
    console.error('convertToServerTime: Server time offset is not defined, using 0 as fallback');
  }
  return createDate(localTime).getTime() + offset;
};
export var convertToLocalTime = function convertToLocalTime(serverTime) {
  var offset = serverTimeOffset;
  if (offset === undefined) {
    offset = 0;
    console.error('convertToLocalTime: Server time offset is not defined, using 0 as fallback');
  }
  return createDate(serverTime).getTime() - offset;
};
export var serverNow = function serverNow() {
  return convertToServerTime(Date.now());
};
export var setServerTime = function setServerTime(serverTime) {
  var localTime = Date.now();
  var offset = serverTime - localTime;
  if (!isNaN(offset)) {
    serverTimeOffset = offset;
  }
};