"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _draftJs = _interopRequireDefault(require("draft-js"));
var _block = require("../utils/block");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var EditorState = _draftJs.default.EditorState;
var createDragAndDropPlugin = function createDragAndDropPlugin() {
  return {
    handleDroppedFiles: function handleDroppedFiles(selection, files, _ref) {
      var getEditorState = _ref.getEditorState,
        getProps = _ref.getProps,
        setEditorState = _ref.setEditorState;
      var _ref2 = getProps() || {},
        onFilesDrop = _ref2.onFilesDrop;
      var editorStateWithSelection = EditorState.acceptSelection(getEditorState(), selection);
      if (!onFilesDrop) {
        return;
      }
      onFilesDrop(files).then(function (all) {
        setEditorState((0, _block.insertFilesAsBlocks)(all, editorStateWithSelection));
      });
    }
  };
};
var _default = createDragAndDropPlugin;
exports.default = _default;