import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SignupOfferBanner } from '@wbnr/frontend-shared/lib/components/SignupOfferBanner';
import { BrandingThemeProvider } from '@wbnr/frontend-shared/lib/theming';
import { createTestIdProps } from '@wbnr/ui';

import { LazyKnockout } from 'containers/KnockoutAdapter/LazyKnockout';

const BASE_TEST_ID = 'SignupOfferBanner';

export type KnockoutBannerProps = {
    pglBtnTxt: boolean;
    link: string;
};

const I18N_PATH = 'banners.signupOffer';

export const KnockoutLandingRoute = () => {
    const { t } = useTranslation();
    const [knockoutBannerProps, setKnockoutBannerProps] = useState<
        KnockoutBannerProps | undefined
    >();

    return (
        <BrandingThemeProvider>
            {knockoutBannerProps && (
                <SignupOfferBanner
                    infoText={t(`${I18N_PATH}.text`)}
                    cancelText={t(`${I18N_PATH}.cancel`)}
                    confirmText={t(
                        `${
                            knockoutBannerProps?.pglBtnTxt
                                ? `${I18N_PATH}.action2`
                                : `${I18N_PATH}.action1`
                        }`,
                    )}
                    link={knockoutBannerProps?.link}
                    {...createTestIdProps(BASE_TEST_ID)}
                />
            )}
            <LazyKnockout
                callbacks={{
                    setSignupOfferBannerProps: (params: KnockoutBannerProps) =>
                        setKnockoutBannerProps(params),
                }}
            />
        </BrandingThemeProvider>
    );
};
