import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useFormContext } from '@wbnr/frontend-shared/lib/forms';
import { SSOTransferOrganization } from '@wbnr/frontend-shared/lib/types/User';
import { Typography, RadioGroup, Radio, MuiFormControlLabel } from '@wbnr/ui';

import styles from './SSOChooseOrganization.module.scss';

type ChooseOrganizationsProps = {
    organizationsList: SSOTransferOrganization[];
};

export const SSOChooseOrganization = ({ organizationsList }: ChooseOrganizationsProps) => {
    const { t } = useTranslation();
    const { control } = useFormContext();
    return (
        <div className={styles.root}>
            <Typography variant="body1" className={styles.email}>
                {t('auth.signin.infoTitle')}
            </Typography>

            <Controller
                name="transferOrganization"
                control={control}
                defaultValue={null}
                render={(field) => (
                    <RadioGroup {...field}>
                        {organizationsList.map(({ id, name }) => (
                            <MuiFormControlLabel
                                key={id}
                                control={<Radio color="primary" />}
                                value={String(id)}
                                label={name}
                                labelPlacement={'end'}
                            />
                        ))}
                    </RadioGroup>
                )}
            />
        </div>
    );
};
