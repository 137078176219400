import { useEffect } from 'react';
import { useOnboardingContext } from './useOnboardingContext';
import { createActivateGroupStepAction, createFinishGroupAction } from './onboardingReducer';
export var useSequentialOnboardingStepsOrder = function useSequentialOnboardingStepsOrder(_ref) {
  var _state$groups$groupId;
  var groupId = _ref.groupId,
    stepIds = _ref.stepIds;
  var _useOnboardingContext = useOnboardingContext(),
    activeStep = _useOnboardingContext.activeStep,
    state = _useOnboardingContext.state,
    dispatch = _useOnboardingContext.dispatch;
  var groupSteps = (_state$groups$groupId = state.groups[groupId]) === null || _state$groups$groupId === void 0 ? void 0 : _state$groups$groupId.steps;
  var group = state.groups[groupId];
  useEffect(function () {
    if (group !== null && group !== void 0 && group.loaded && group !== null && group !== void 0 && group.available && group !== null && group !== void 0 && group.active && (activeStep === null || activeStep === void 0 ? void 0 : activeStep.groupId) === groupId && activeStep.stepId === undefined) {
      if (!groupSteps) {
        return;
      }
      var nextStepId = stepIds.find(function (stepId) {
        return !groupSteps[stepId].finished && groupSteps[stepId].canRun;
      });
      if (nextStepId) {
        dispatch(createActivateGroupStepAction(groupId, nextStepId));
      } else {
        if (stepIds.every(function (stepId) {
          return groupSteps[stepId].finished;
        })) {
          dispatch(createFinishGroupAction(groupId));
        }
      }
    }
  }, [activeStep, groupId, stepIds, groupSteps, dispatch, group]);
};