import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { APIError } from '@wbnr/frontend-shared/lib/api';
import { register } from '@wbnr/frontend-shared/lib/api/user';
import {
    AuthLayout,
    AuthContent,
    AuthForm,
    AuthButtons,
    AuthTextField,
    AuthPasswordField,
} from '@wbnr/frontend-shared/lib/components/auth';
import { useInfoDialog } from '@wbnr/frontend-shared/lib/dialogs';
import { makeFormErrors, useField, useForm } from '@wbnr/frontend-shared/lib/forms';
import {
    validateEmail,
    validatePassword,
    validatePhone,
} from '@wbnr/frontend-shared/lib/utils/validators';
import { createTestIdProps, Typography } from '@wbnr/ui';

import { ORGANIZATION_INVITATION_LINK_ERRORS } from 'routes/organization/OrganizationInvitationLink';

interface Props {
    organizationInvitation?: {
        token: string;
        password: string;
        handleErrors: (err: APIError) => void;
    };
    // Prop to use in Route component from react-router
    children?: React.ReactNode;
}

const baseTestId = 'Signup';

const i18nPath = 'auth.signup';

const Signup = ({ organizationInvitation }: Props) => {
    const history = useHistory();
    const { t, i18n } = useTranslation();

    const isCourses = window.location.pathname.includes('/courses');

    const [isSuccess, setSuccess] = useState(false);

    const [openCompanyInformation] = useInfoDialog({
        title: t(`${i18nPath}.companyInformation.title`),
        cancelText: t('close'),
        content: (
            <Typography>
                <Trans i18nKey={`${i18nPath}.companyInformation.content`} />
            </Typography>
        ),
    });

    const { control, submit } = useForm(
        {
            defaultValues: {
                name: '',
                secondName: '',
                phone: '',
                email: '',
                password: '',
                organization: '',
            },
        },
        {
            onSubmit: async (data) => {
                try {
                    const result = await register({
                        ...data,
                        locale: i18n.language,
                        project: isCourses ? 'course' : undefined,
                        organizationInvitationToken: organizationInvitation?.token,
                        organizationInvitationPassword: organizationInvitation?.password,
                    });

                    if (result.code === 'EMAIL_SENT' || result.data.userId) {
                        setSuccess(true);
                        setTimeout(function () {
                            history.push(isCourses ? '/courses/signin' : '/signin');
                        }, 10000);
                    }
                } catch (err) {
                    if (err instanceof APIError) {
                        if (err.status === 409) {
                            throw makeFormErrors({
                                email: t(`${i18nPath}.emailExist`),
                            });
                        } else if (
                            err.status === 400 &&
                            err.errorMessage === 'Registration only by sso'
                        ) {
                            history.push('/sso-signup', { email: data.email });
                        } else if (
                            organizationInvitation &&
                            Object.values(ORGANIZATION_INVITATION_LINK_ERRORS).includes(err.status)
                        ) {
                            organizationInvitation.handleErrors(err);
                        }
                    }
                }
            },
        },
    );

    const nameField = useField(control, 'name', {
        rules: { required: true },
    });
    const secondNameField = useField(control, 'secondName', {
        rules: { required: true },
    });
    const phoneField = useField(control, 'phone', {
        rules: { required: true, validate: validatePhone },
    });
    const emailField = useField(control, 'email', {
        rules: { required: true, validate: validateEmail },
    });
    const passwordField = useField(control, 'password', {
        rules: {
            required: true,
            validate: validatePassword,
        },
    });
    const organizationField = useField(control, 'organization');

    return (
        <>
            {!organizationInvitation && (
                <Helmet>
                    <title>{t('pageTitle.signup')}</title>
                </Helmet>
            )}

            <AuthLayout>
                <AuthContent
                    title={isSuccess ? t(`${i18nPath}.success.title`) : t(`${i18nPath}.title`)}
                >
                    {isSuccess ? (
                        <Typography>
                            <Trans
                                i18nKey={`${i18nPath}.success.content`}
                                values={{ email: emailField.value }}
                                components={[<b key="0" />]}
                            />
                        </Typography>
                    ) : (
                        <AuthForm {...createTestIdProps(baseTestId, 'form')}>
                            <AuthTextField
                                label={t(`${i18nPath}.name`)}
                                {...nameField}
                                {...createTestIdProps(baseTestId, 'field', 'name')}
                            />
                            <AuthTextField
                                label={t(`${i18nPath}.secondName`)}
                                {...secondNameField}
                                {...createTestIdProps(baseTestId, 'field', 'second-name')}
                            />
                            <AuthTextField
                                label={t(`${i18nPath}.phone`)}
                                {...phoneField}
                                {...createTestIdProps(baseTestId, 'field', 'phone')}
                            />
                            <AuthTextField
                                type="email"
                                label={t(`${i18nPath}.email`)}
                                {...emailField}
                                {...createTestIdProps(baseTestId, 'field', 'email')}
                            />
                            <AuthPasswordField
                                label={t(`${i18nPath}.password`)}
                                {...passwordField}
                                {...createTestIdProps(baseTestId, 'field', 'password')}
                            />
                            <AuthTextField
                                label={t(`${i18nPath}.organization`)}
                                {...organizationField}
                                {...createTestIdProps(baseTestId, 'field', 'organization')}
                            />

                            <Typography variant="caption" paragraph>
                                <Trans
                                    i18nKey={`${i18nPath}.terms`}
                                    components={[
                                        <a
                                            key="license-agreement.pdf"
                                            href="https://webinar.ru/legal/license-agreement.pdf"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            {...createTestIdProps(
                                                baseTestId,
                                                'action',
                                                'go-to-license-agreement',
                                            )}
                                        >
                                            text
                                        </a>,
                                        <a
                                            key="privacy-policy-soft.pdf"
                                            href="https://webinar.ru/legal/privacy-policy-soft.pdf"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            {...createTestIdProps(
                                                baseTestId,
                                                'action',
                                                'go-to-privacy-policy-soft',
                                            )}
                                        >
                                            text
                                        </a>,
                                        <a
                                            key="private-agreement-soft.pdf"
                                            href="https://webinar.ru/legal/private-agreement-soft.pdf"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            {...createTestIdProps(
                                                baseTestId,
                                                'action',
                                                'go-to-private-agreement-soft',
                                            )}
                                        >
                                            text
                                        </a>,
                                        <a
                                            href="#"
                                            key="companyInformation"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                openCompanyInformation();
                                            }}
                                            {...createTestIdProps(
                                                baseTestId,
                                                'action',
                                                'open-company-information',
                                            )}
                                        >
                                            text
                                        </a>,
                                    ]}
                                />
                            </Typography>

                            <AuthButtons
                                main={{
                                    text: t(`${i18nPath}.submit`),
                                    onClick: submit,
                                    ...createTestIdProps(baseTestId, 'action', 'submit'),
                                }}
                                buttons={[
                                    {
                                        text: t(`${i18nPath}.login`),
                                        onClick: () =>
                                            history.push(isCourses ? '/courses/signin' : '/signin'),
                                        ...createTestIdProps(baseTestId, 'action', 'go-to-signin'),
                                    },
                                ]}
                            />
                        </AuthForm>
                    )}
                </AuthContent>
            </AuthLayout>
        </>
    );
};

export default Signup;
