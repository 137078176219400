"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createTestId = createTestId;
exports.createTestIdProps = createTestIdProps;
exports.dataTestIdKey = void 0;
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var dataTestIdKey = 'data-testid';
exports.dataTestIdKey = dataTestIdKey;
function createTestIdProps() {
  if ((arguments.length <= 0 ? undefined : arguments[0]) === undefined) {
    return undefined;
  }
  return _defineProperty({}, dataTestIdKey, createTestId.apply(void 0, arguments));
}
function createTestId() {
  for (var _len = arguments.length, idParts = new Array(_len), _key = 0; _key < _len; _key++) {
    idParts[_key] = arguments[_key];
  }
  if (idParts[0] === undefined) {
    return undefined;
  }
  return idParts.filter(Boolean).join('.');
}