import { useTranslation } from 'react-i18next';

import { useField, useFormContext } from '@wbnr/frontend-shared/lib/forms';
import { TextField } from '@wbnr/ui';

import { getBrandingPropName } from '../../utils';

const i18nPath = 'business.brandingPage';

const BrandingTitle = () => {
    const { t } = useTranslation();

    const { control } = useFormContext();

    const titleField = useField(control, getBrandingPropName('title'), {
        rules: { required: true },
    });

    return <TextField {...titleField} label={t(`${i18nPath}.title`)} fullWidth />;
};

export default BrandingTitle;
