import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router';

import LoadingScreen from '@wbnr/frontend-shared/lib/components/LoadingScreen';
import { useFeatureSettings } from '@wbnr/frontend-shared/lib/data/featureSettings';

import { SIEMContextProvider } from './context/SIEMContext';
import { SIEMSettings } from './SIEMSettings';

export const SIEMSettingsPage: FC = () => {
    const { t } = useTranslation();

    const {
        fulfilled,
        data: { siemLogs },
    } = useFeatureSettings();

    if (!fulfilled) {
        return <LoadingScreen />;
    }

    if (!siemLogs) {
        return <Redirect to="/business/tariffs" />;
    }

    return (
        <SIEMContextProvider>
            <Helmet>
                <title>{t('nav.siemSettings')} - Webinar</title>
            </Helmet>
            <SIEMSettings />
        </SIEMContextProvider>
    );
};
