function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
var CONFIG_KEY = 'WEBINAR_CONFIG';
var DEV = ['dev', 'development'].includes((process.env.NODE_ENV || '').toLowerCase());
function getConfigOption(name, defaultValue) {
  var configObject = window[CONFIG_KEY];
  var optionValue = _typeof(configObject) === 'object' ? configObject[name] : undefined;
  return optionValue !== undefined ? optionValue : defaultValue;
}

// MAIN_HOST and API_URL are used in non-web contexts (chrome extension and electron app)
// so they are treated specially and NODE_ENV is used to set their value
export var MAIN_HOST = getConfigOption('MAIN_HOST', DEV ? 'dev-3.webinar.ru' : 'events.webinar.ru');
export var API_URL = getConfigOption('API_URL', DEV ? 'https://dev-3.webinar.ru/api' : 'https://events.webinar.ru/api');
export var IS_BOX_MODE = getConfigOption('IS_BOX_MODE', false);
export var STREAM_URL_HOST = getConfigOption('STREAM_URL_HOST', DEV ? 'https://dev-3.webinar.ru/' : '');
export var TEST_EDITOR_HOST = getConfigOption('TEST_EDITOR_HOST', DEV ? 'dev-3.webinar.ru' : '');
export var DEFAULT_HOSTS = getConfigOption('DEFAULT_HOSTS', []);
export var API_LIGHT_URL = getConfigOption('API_LIGHT_URL', DEV ? 'https://dev-3.webinar.ru/api/light' : '');
export var UPLOAD_URL = getConfigOption('UPLOAD_URL', '');
export var MAX_UPLOAD_SIZE = getConfigOption('MAX_UPLOAD_SIZE', 104857600);
export var MAX_UPLOAD_SIZE_VIDEO = getConfigOption('MAX_UPLOAD_SIZE_VIDEO', 2147483648);
export var USER_CONTENT_HOST = getConfigOption('USER_CONTENT_HOST', '');
export var HLS_FILES_URL = getConfigOption('HLS_FILES_URL', DEV ? 'dev-wowza.webinar.ru/streamer' : '');
export var HLS_RECORDS_URL = getConfigOption('HLS_RECORDS_URL', DEV ? 'dev-wowza.webinar.ru/streamer' : '');
export var ACTIVE_SPEAKER_MEDIA_SERVER = getConfigOption('ACTIVE_SPEAKER_MEDIA_SERVER', '');
export var WEBRTC_TEST_HOSTS = getConfigOption('WEBRTC_TEST_HOSTS', []);
export var ORGANIZATIONS_MAIN_DOMAIN = getConfigOption('ORGANIZATIONS_MAIN_DOMAIN', '');
export var MOBILE_API_HOST = getConfigOption('MOBILE_API_HOST', '');
export var MOBILE_SCHEMA = getConfigOption('MOBILE_SCHEMA', 'webinarru');
export var MOBILE_APP_URL_IOS = getConfigOption('MOBILE_APP_URL_IOS', 'https://itunes.apple.com/us/app/webinar/id1050809427');
export var MOBILE_APP_URL_ANDROID = getConfigOption('MOBILE_APP_URL_ANDROID', 'https://play.google.com/store/apps/details?id=ru.webinar.mobile');
export var ANDROID_APK_URL = getConfigOption('ANDROID_APK_URL', 'https://apps.webinar.ru/android/webinar.apk');
export var SCREENSHARING_SCHEMA = getConfigOption('SCREENSHARING_SCHEMA', 'webinarru-screenshare2');
export var SCREENSHARING_APP_URL_MAC = getConfigOption('SCREENSHARING_APP_URL_MAC', 'https://apps.webinar.ru/screensharing/webinar-screensharing-mac-4.2.15.dmg');
export var SCREENSHARING_APP_URL_WIN = getConfigOption('SCREENSHARING_APP_URL_WIN', 'https://apps.webinar.ru/screensharing/webinar-screensharing-win-4.2.15.msi');
export var SCREENSHARING_ALLOW_DEV_ELECTRON = getConfigOption('SCREENSHARING_ALLOW_DEV_ELECTRON', false);
export var MIXPANEL_TOKEN = getConfigOption('MIXPANEL_TOKEN', '');
export var INTERCOM_TOKEN = getConfigOption('INTERCOM_TOKEN', '');
export var WOOTRIC_TOKEN = getConfigOption('WOOTRIC_TOKEN', '');
export var BPM_TRACKING_TOKEN = getConfigOption('BPM_TRACKING_TOKEN', '');
export var HOTJAR_COURSES_TOKEN = getConfigOption('HOTJAR_COURSES_TOKEN', '');
export var HOTJAR_RECORD_TOKEN = getConfigOption('HOTJAR_RECORD_TOKEN', '');
export var HOTJAR_FIRST_MEETING_TOKEN = getConfigOption('HOTJAR_FIRST_MEETING_TOKEN', '');
export var FULLSTORY_ORG = getConfigOption('FULLSTORY_ORG', '');
export var MIRO_CLIENT_ID = getConfigOption('MIRO_CLIENT_ID', '');
export var DADATA_TOKEN = getConfigOption('DADATA_TOKEN', '');
export var GOOGLE_MAPS_TOKEN = getConfigOption('GOOGLE_MAPS_TOKEN', '');
export var GOOGLE_COUNTER_ID = getConfigOption('GOOGLE_COUNTER_ID', '');
export var YANDEX_COUNTER_ID = getConfigOption('YANDEX_COUNTER_ID', '');
export var OAUTH_VK_APP_ID = getConfigOption('OAUTH_VK_APP_ID', '');
export var OAUTH_VK_APP_VERSION = getConfigOption('OAUTH_VK_APP_VERSION', '');
export var OAUTH_FACEBOOK_APP_ID = getConfigOption('OAUTH_FACEBOOK_APP_ID', '');
export var OAUTH_GOOGLE_APP_ID = getConfigOption('OAUTH_GOOGLE_APP_ID', '');
export var ONBOARDING_LEARNING_VIDEO_NAME = getConfigOption('ONBOARDING_LEARNING_VIDEO_NAME', 'Видеоурок "Подготовка к проведению вебинара"');
export var ONBOARDING_PRESENTATION_URI = getConfigOption('ONBOARDING_PRESENTATION_URI', '');
export var CHROME_EXTENSION_ID = getConfigOption('CHROME_EXTENSION_ID', 'hopiakbbpmgkihbkehgdabadnikcgjlg');
export var PROCTOR_EDU_SERVER_URL = getConfigOption('PROCTOR_EDU_SERVER_URL', '');
export var DISABLE_SUPPORT_MESSAGE = getConfigOption('DISABLE_SUPPORT_MESSAGE', false);
export var MAX_ENDLESS_MEETINGS_COUNT_DEFAULT = getConfigOption('MAX_ENDLESS_MEETINGS_COUNT_DEFAULT', 2);
export var DESKTOP_APP_HOST = getConfigOption('DESKTOP_APP_HOST', 'https://apps.webinar.ru/desktop/latest');
export var DESKTOP_APP_FILE_NAME = getConfigOption('DESKTOP_APP_FILE_NAME', 'webinar-desktop');
export var DESKTOP_SNAP_URL = getConfigOption('DESKTOP_SNAP_URL', 'https://snapcraft.io/webinar');
export var MEDIA_TELEMETRY_KEY = getConfigOption('MEDIA_TELEMETRY_KEY', '');