import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useCustomDialog } from '@wbnr/frontend-shared/lib/dialogs';
import { DialogRenderFunc } from '@wbnr/frontend-shared/lib/dialogs/context';
import { ActionDialog } from '@wbnr/ui';

import EmailTemplatePreview from '../branding/EmailTemplatePreview';

export const useTemplateDialog = () => {
    const { t } = useTranslation();

    const render: DialogRenderFunc<{ template: string }> = useCallback(
        ({ id, opened, closeDialog, props }) => (
            <ActionDialog
                key={id}
                opened={opened}
                onClose={closeDialog}
                maxWidth="lg"
                scroll="body"
                cancelText={t('close')}
            >
                <EmailTemplatePreview template={props.template} />
            </ActionDialog>
        ),
        [t],
    );

    return useCustomDialog(render, { template: '' });
};
