import { getApiKey, refreshApiKey } from '@wbnr/frontend-shared/lib/api/business-api';
import {
    useAccountUser,
    getUserOrganizationId,
    useProlongationData,
    isApiAvailable,
} from '@wbnr/frontend-shared/lib/data/user';
import { useApiKey } from '@wbnr/frontend-shared/lib/hooks/useApiKey';
import { useBinded } from '@wbnr/frontend-shared/lib/hooks/useBinded';

const useBusinessApiLogic = (): BusinessLogicReturnedValue => {
    const [user] = useAccountUser();
    const organizationId = getUserOrganizationId(user);
    const prolongationData = useProlongationData();
    const access = isApiAvailable(prolongationData);

    const onGetApiKey = useBinded(access ? getApiKey : undefined, { organizationId });
    const onRenewApiKey = useBinded(access ? refreshApiKey : undefined, { organizationId });

    const { apiKey, onApiKeyRefresh, apiKeyFulfilled, isKeyRefreshing } = useApiKey({
        getApiKey: onGetApiKey,
        renewApiKey: onRenewApiKey,
    });

    const inLoad = Boolean(access && !(apiKeyFulfilled && apiKey));

    return {
        inLoad,
        access,
        apiKey,
        isKeyRefreshing,
        onApiKeyRefresh,
    };
};

type BusinessLogicReturnedValue = {
    inLoad: boolean;
    access: boolean;
    apiKey: string;
    isKeyRefreshing: boolean;
    onApiKeyRefresh: () => void;
};

export default useBusinessApiLogic;
