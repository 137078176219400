import { useContext } from 'react';

import { DevicesContext } from '../context/DevciesContext';

export type {
    GetDevicesParams,
    GetDevices,
    GetDevicesSearchParams,
    GetDevicesSearch,
    CreateDeviceParams,
    CreateDevice,
    UpdateDeviceParams,
    UpdateDevice,
    DeleteDeviceParams,
    DeleteDevice,
    DevicesContextData,
    DevicesAction,
    DevicesSearchAction,
    DeviceAddAction,
    DeviceUpdateAction,
    DeviceDeleteActionPayload,
    DeviceDeleteAction,
} from '../context/interfaces';

export const useDevices = () => useContext(DevicesContext);
