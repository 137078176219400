import { useCustomDialog } from '@wbnr/frontend-shared/lib/dialogs';

import { InvoiceForm } from './InvoiceForm';

export type FormProps = {
    price: string;
    tariffName: string;
    productIds: number[];
    duration?: number;
    email: string;
};

export const useInvoiceFormDialog = () => {
    return useCustomDialog<FormProps>(({ props, opened, closeDialog }) => {
        return <InvoiceForm {...props} opened={opened} onClose={closeDialog} />;
    });
};
