import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { APIError } from '@wbnr/frontend-shared/lib/api';
import { SAMLSettings } from '@wbnr/frontend-shared/lib/api/business-api/types';
import { makeFormErrors, useField, useForm } from '@wbnr/frontend-shared/lib/forms';
import { Button, createTestIdProps, MenuItem, SelectField, TextField } from '@wbnr/ui';

import { IdpProtocols, IDP_OPTIONS, SP_SETTING_LIST, SETTINGS_LIST, SSOStep } from '../constants';
import { SettingViewField } from '../SettingViewFIeld';
import { useSSO } from '../SSOContext';

import styles from './SAMLSettingsEdit.module.scss';

const FIELD_CONFIG = { rules: { required: true } };
const DEFAULT_VALUE: Partial<SAMLSettings> = {
    idpProvider: undefined,
    idpEntityId: '',
    idpSingleSignOnServiceUrl: '',
    idpSingleLogoutServiceUrl: '',
    idpX509certBase64: '',
};

const FIELD_NAMES = [...SETTINGS_LIST, 'idpProvider'];

const BASE_TEST_ID = 'SAMLSettingsEdit';

export const SAMLSettingsEdit = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { samlSettings, step, setStep, updateSAML } = useSSO();
    const [loading, setLoading] = useState(false);
    const { control, submit, getValues } = useForm(
        {
            defaultValues: {
                ...DEFAULT_VALUE,
                ...samlSettings,
            },
        },
        {
            onSubmit: async (settings: SAMLSettings) => {
                try {
                    setLoading(true);
                    await updateSAML?.(settings);
                } catch (error) {
                    if (
                        error instanceof APIError &&
                        error.body.error.code === 400 &&
                        error.body.error.formErrors
                    ) {
                        const errors = Object.keys(error.body.error.formErrors).reduce(
                            (result: Record<string, string>, formError) => {
                                const fieldName = FIELD_NAMES.find((field) =>
                                    formError.endsWith(`.${String(field)}`),
                                );
                                if (fieldName) {
                                    result[String(fieldName)] = t('business.sso.saml.wrongValue');
                                }
                                return result;
                            },
                            {},
                        );
                        if (Object.keys(errors).length > 0) {
                            throw makeFormErrors(errors);
                        } else {
                            enqueueSnackbar(t('unknownError'));
                        }
                    } else {
                        enqueueSnackbar(t('unknownError'));
                    }
                } finally {
                    setLoading(false);
                }
            },
        },
    );
    const idpProviderField = useField(control, 'idpProvider', FIELD_CONFIG);
    const idpEntityIdField = useField(control, 'idpEntityId', FIELD_CONFIG);
    const idpSingleSignOnServiceUrlField = useField(
        control,
        'idpSingleSignOnServiceUrl',
        FIELD_CONFIG,
    );
    const idpSingleSignOutServiceUrlField = useField(
        control,
        'idpSingleLogoutServiceUrl',
        FIELD_CONFIG,
    );
    const idpX509certBase64Field = useField(control, 'idpX509certBase64', FIELD_CONFIG);
    const formData = getValues();
    const isFormDataCompleted = useMemo(() => Object.values(formData).every(Boolean), [formData]);

    return step === SSOStep.IDP ? (
        <form onSubmit={submit} className={styles.root}>
            <SelectField
                value={IdpProtocols.SAML}
                fullWidth
                disabled
                label={t('business.sso.saml.idpProtocol')}
                {...createTestIdProps(BASE_TEST_ID, 'idpProtocol')}
            >
                <MenuItem value={IdpProtocols.SAML}>{IdpProtocols.SAML}</MenuItem>
            </SelectField>
            <SelectField
                {...idpProviderField}
                fullWidth
                label={t('business.sso.saml.idpProvider')}
                {...createTestIdProps(BASE_TEST_ID, 'idpProvider')}
            >
                {IDP_OPTIONS.map(({ id, label }) => (
                    <MenuItem key={id} value={id}>
                        {label}
                    </MenuItem>
                ))}
            </SelectField>
            <TextField
                {...idpEntityIdField}
                fullWidth
                label={t('business.sso.saml.idpEntityId')}
                {...createTestIdProps(BASE_TEST_ID, 'idpEntityId')}
            />
            <TextField
                {...idpSingleSignOnServiceUrlField}
                fullWidth
                label={t('business.sso.saml.idpSingleSignOnServiceUrl')}
                {...createTestIdProps(BASE_TEST_ID, 'idpSingleSignOnServiceUrl')}
            />
            <TextField
                {...idpSingleSignOutServiceUrlField}
                fullWidth
                label={t('business.sso.saml.idpSingleLogoutServiceUrl')}
                {...createTestIdProps(BASE_TEST_ID, 'idpSingleLogoutServiceUrl')}
            />
            <TextField
                {...idpX509certBase64Field}
                fullWidth
                label={t('business.sso.saml.idpX509certBase64')}
                multiline
                {...createTestIdProps(BASE_TEST_ID, 'idpX509certBase64')}
            />
            <div className={styles.buttons}>
                {samlSettings?.id && (
                    <Button
                        onClick={() => setStep?.(SSOStep.VIEW)}
                        className={styles.button}
                        disabled={loading}
                        {...createTestIdProps(BASE_TEST_ID, 'cancel')}
                    >
                        <Trans i18nKey={'business.sso.saml.cancel'} />
                    </Button>
                )}
                <Button
                    disabled={loading || !isFormDataCompleted}
                    type={'submit'}
                    variant={'contained'}
                    color={'primary'}
                    className={styles.button}
                    {...createTestIdProps(BASE_TEST_ID, 'next')}
                >
                    <Trans i18nKey={'business.sso.saml.next'} />
                </Button>
            </div>
        </form>
    ) : (
        <div className={styles.root}>
            {SP_SETTING_LIST.map((settingKey) => (
                <SettingViewField
                    key={String(settingKey)}
                    text={t(`business.sso.saml.${String(settingKey)}`)}
                    value={samlSettings?.[settingKey]}
                />
            ))}
            <div className={styles.buttons}>
                <Button
                    color={'primary'}
                    onClick={() => setStep?.(SSOStep.IDP)}
                    className={styles.button}
                    disabled={loading}
                    {...createTestIdProps(BASE_TEST_ID, 'back')}
                >
                    <Trans i18nKey={'business.sso.saml.back'} />
                </Button>
                <Button
                    variant={'contained'}
                    color={'primary'}
                    className={styles.button}
                    onClick={() => setStep?.(SSOStep.VIEW)}
                    {...createTestIdProps(BASE_TEST_ID, 'next')}
                >
                    <Trans i18nKey={'business.sso.saml.next'} />
                </Button>
            </div>
        </div>
    );
};
