import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { AuthContent, AuthForm, AuthLayout } from '@wbnr/frontend-shared/lib/components/auth';
import LoadingScreen from '@wbnr/frontend-shared/lib/components/LoadingScreen';
import { createTestIdProps } from '@wbnr/ui';

import { useSignIn } from '../hooks/useSignIn';

import { AuthFormOrganization } from './AuthFormOrganization';
import { AuthFormTransferOrganization } from './AuthFormTransferOrganization';

const BASE_TEST_ID = 'SSOSignIn';

type Props = {
    isSignUp?: boolean;
    children?: React.ReactNode;
};

export const SSOSignIn = ({ isSignUp }: Props) => {
    const { userInited, isAuthTypeSSO, isSelectedSSOOrganization, handleLoadedUser } = useSignIn();
    const { t } = useTranslation();

    if (isAuthTypeSSO ? !userInited && isSelectedSSOOrganization : !userInited) {
        return <LoadingScreen />;
    }

    return (
        <AuthLayout>
            <Helmet>
                <title>{t(isSignUp ? 'pageTitle.signup' : 'pageTitle.signin')}</title>
            </Helmet>
            <AuthContent title={t(isSignUp ? 'auth.ssoSignup.title' : 'auth.sso.title')}>
                <AuthForm {...createTestIdProps(BASE_TEST_ID, 'form')}>
                    {isAuthTypeSSO && !isSignUp ? (
                        <AuthFormTransferOrganization
                            isSelectedSSOOrganization={false}
                            onSubmitClick={handleLoadedUser}
                            {...createTestIdProps(BASE_TEST_ID)}
                        />
                    ) : (
                        <AuthFormOrganization
                            {...createTestIdProps(BASE_TEST_ID)}
                            isSignUp={isSignUp}
                        />
                    )}
                </AuthForm>
            </AuthContent>
        </AuthLayout>
    );
};
