import debounce from 'lodash/debounce';
import { FC, useCallback, useMemo, useRef, ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import { assignValueToRef } from '@wbnr/frontend-shared/lib/utils/react';
import { SearchIcon } from '@wbnr/icons';
import { createTestIdProps, IconButton, TextField } from '@wbnr/ui';

import { useDevices } from '../../hooks/useDevices';

import styles from './SearchField.module.scss';

const BASE_TEST_ID = 'SearchField';

type TextFieldOnChange = ComponentProps<typeof TextField>['onChange'];

interface FetchDevicesSearch {
    (values: string): Promise<void>;
}

interface SearchFieldProps {
    value: string;
    setValue: (value: string) => void;
}

export const SearchField: FC<SearchFieldProps> = ({
    value: textFieldValue,
    setValue: setTextFieldValue,
}) => {
    const { t } = useTranslation();
    const { getDevicesSearch, clearDevicesSearch } = useDevices();

    const textFieldRef = useRef<HTMLInputElement>();
    const prevTextFieldValueRef = useRef<string>('');

    const isChangeValue = useCallback((value) => {
        if (prevTextFieldValueRef.current !== value) {
            assignValueToRef(prevTextFieldValueRef, value);
            return true;
        }

        return false;
    }, []);

    const fetchDevicesSearch = useMemo(
        () =>
            debounce<FetchDevicesSearch>(async (value) => {
                if (!isChangeValue(value)) {
                    return;
                }

                if (!value) {
                    clearDevicesSearch();
                    return;
                }

                await getDevicesSearch({
                    query: value,
                });
            }, 300),
        [clearDevicesSearch, getDevicesSearch, isChangeValue],
    );

    const handleChange: TextFieldOnChange = (event) => {
        const value = event.currentTarget.value;
        setTextFieldValue(value);
        fetchDevicesSearch(value);
    };

    const handleClick = () => {
        if (!textFieldRef.current) {
            return;
        }

        textFieldRef.current.focus();
    };

    return (
        <div className={styles.root}>
            <IconButton onClick={handleClick} {...createTestIdProps(BASE_TEST_ID, 'focusSearch')}>
                <SearchIcon />
            </IconButton>
            <TextField
                inputProps={{
                    ref: (ref: HTMLInputElement) => {
                        assignValueToRef(textFieldRef, ref);
                    },
                }}
                placeholder={t('business.myDevicesPage.myDevicesList.search')}
                value={textFieldValue}
                onChange={handleChange}
                autoFocus
                fullWidth
                {...createTestIdProps(BASE_TEST_ID, 'input', 'search')}
            />
        </div>
    );
};
