import { useMemo } from 'react';
import { sortUsingComparators } from '../utils/sort';
export var useSortedData = function useSortedData(data) {
  var comparators = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var sortedData = useMemo(function () {
    if (comparators.length === 0) {
      return data;
    }
    return data && sortUsingComparators(data, comparators);
  }, [data, comparators]);
  return sortedData;
};