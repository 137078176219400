import { Route, Switch, Redirect } from 'react-router';

import requireAuthentication from '@wbnr/frontend-shared/lib/components/requireAuthentication';
import { webinarPage } from '@wbnr/frontend-shared/lib/components/webinarPage';
import { IS_BOX_MODE } from '@wbnr/frontend-shared/lib/config';

import { LazyKnockout } from 'containers/KnockoutAdapter/LazyKnockout';

import SMTPSettingsConfirmation from './admin/SMTPSettingsConfirmation';
import EmailSubmit from './auth/EmailSubmit';
import ExtendAccount from './auth/ExtendAccount';
import PasswordRecovery from './auth/PasswordRecovery';
import PasswordRecoveryToken from './auth/PasswordRecoveryToken';
import SignIn from './auth/SignIn';
import { SignInDesktopApp } from './auth/SignInDesktopApp';
import { SignInMobileApp } from './auth/SignInMobileApp';
import Signup from './auth/Signup';
import { SSOSignIn } from './auth/SSOSignIn';
import BusinessRoute from './business';
import CreateEventRoute from './createEvent';
import { KnockoutLandingRoute } from './KnockoutLanding';
import OrganizationInvitationEmail from './organization/OrganizationInvitationEmail';
import OrganizationInvitationLink from './organization/OrganizationInvitationLink';
import StatisticsRoute from './statistics';
import WebinarsRoute from './webinars';
import WebinarsInitCabinetRoute from './webinars/WebinarsInitCabinetRoute';

const publicPageOptions = {
    withIntercom: false,
    disableAccountBanners: true,
    disableCommonDialogs: true,
    disableCometListeners: true,
};

const Knockout = LazyKnockout;

const EventCreatePage = requireAuthentication(webinarPage()(CreateEventRoute));
const BusinessPage = requireAuthentication(webinarPage()(BusinessRoute));
const WebinarsPage = requireAuthentication(webinarPage()(WebinarsRoute));
const StatisticsPage = requireAuthentication(webinarPage()(StatisticsRoute));

const PrivateKnockoutPage = requireAuthentication(webinarPage()(Knockout));

const SignInPage = webinarPage(publicPageOptions)(SignIn);
const SsoSignInPage = webinarPage<typeof SSOSignIn>(publicPageOptions)(SSOSignIn);
const SignInDesktopAppPage = requireAuthentication(
    webinarPage(publicPageOptions)(SignInDesktopApp),
);
const SignInMobileAppPage = requireAuthentication(webinarPage(publicPageOptions)(SignInMobileApp));
const SignupPage = webinarPage(publicPageOptions)(Signup);
const PasswordRecoveryTokenPage = webinarPage(publicPageOptions)(PasswordRecoveryToken);
const PasswordRecoveryPage = webinarPage(publicPageOptions)(PasswordRecovery);
const EmailSubmitPage = webinarPage(publicPageOptions)(EmailSubmit);
const ExtendAccountPage = webinarPage(publicPageOptions)(ExtendAccount);
const OrganizationInvitationEmailPage = webinarPage(publicPageOptions)(OrganizationInvitationEmail);
const OrganizationInvitationLinkPage = webinarPage(publicPageOptions)(OrganizationInvitationLink);
const WebinarsInitCabinetPage = requireAuthentication(webinarPage()(WebinarsInitCabinetRoute));

const PublicKnockoutPage = webinarPage(publicPageOptions)(Knockout);
const SupportPage = webinarPage()(Knockout);
const SMTPSettingsConfirmationPage = webinarPage(publicPageOptions)(SMTPSettingsConfirmation);
const KnockoutLandingPage = webinarPage(publicPageOptions)(KnockoutLandingRoute);

export const routes = () => (
    <Switch>
        <Route path="/files/:id?" component={PrivateKnockoutPage} />
        <Route path="/editor/:id?" component={PrivateKnockoutPage} />
        <Route
            path="/event/:eventId/:eventSessionId?/edit/:sessionTime?"
            component={PrivateKnockoutPage}
        />
        <Route path="/event/create/:type?" component={EventCreatePage} />
        <Route path="/event/:eventId/:eventSessionId" component={KnockoutLandingPage} />
        <Route path={'/business'} component={BusinessPage} />
        <Route path={'/signin'} component={SignInPage} />
        <Route path={'/meetings/signin'} component={SignInPage} />
        <Route path={'/desktop/signin'} component={SignInDesktopAppPage} />
        <Route path={'/mobile/signin'} component={SignInMobileAppPage} />
        <Route path={'/sso-signin'} component={SsoSignInPage} />
        <Route path={'/courses/signin'} component={SignInPage} />
        <Route path={'/signup'} component={SignupPage} />
        <Route path={'/sso-signup'} render={(props) => <SsoSignInPage {...props} isSignUp />} />
        <Route path={'/courses/signup'} component={SignupPage} />
        <Route path={'/signin-reset/:token'} component={PasswordRecoveryTokenPage} />
        <Route path={'/courses/signin-reset/:token'} component={PasswordRecoveryTokenPage} />
        <Route path={'/mobile/signin-reset/:token'} component={PasswordRecoveryTokenPage} />
        <Route path={'/signin-reset'} component={PasswordRecoveryPage} />
        <Route path={'/courses/signin-reset'} component={PasswordRecoveryPage} />
        <Route path={'/email-submit/:submitToken'} component={EmailSubmitPage} />
        <Route path={'/courses/email-submit/:submitToken'} component={EmailSubmitPage} />
        <Route path={'/extend-account/:token'} component={ExtendAccountPage} />
        <Route
            path={'/organizations/invitations/:token'}
            component={OrganizationInvitationEmailPage}
        />
        <Route path={'/organization-invite/:token'} component={OrganizationInvitationLinkPage} />
        <Route path="/" exact component={WebinarsPage} />
        <Route path="/webinars" component={WebinarsInitCabinetPage} />
        <Route path={'/oauth/:netOAuth'} component={PublicKnockoutPage} />
        <Route path={'/subscriptions'} component={PublicKnockoutPage} />
        <Route path={'/subscriptions/:subscriptionToken'} component={PublicKnockoutPage} />
        <Route path="/testeditor" component={PublicKnockoutPage} />
        <Route path={'/people'} component={PrivateKnockoutPage} />
        <Route path={'/people/:peopleId'} component={PrivateKnockoutPage} />
        <Route path={'/support/:section'} component={SupportPage} />
        <Route path={'/search'} component={PrivateKnockoutPage} />
        <Route path={'/statistics'} component={StatisticsPage} />
        {IS_BOX_MODE && (
            <Route
                path={'/confirm-smtp-settings/:token'}
                component={SMTPSettingsConfirmationPage}
            />
        )}

        <Route path="/:userId/:eventId/session/:eventSessionId" component={KnockoutLandingPage} />
        <Route path="/:userId/:eventId/:token?" component={KnockoutLandingPage} />
        <Redirect to={'/'} />
    </Switch>
);
