const logToUnit = ['b', 'kb', 'mb', 'gb', 'tb'];

export function formatFileSize(size) {
    if (!size) {
        return [0, 'b'];
    } else if (size === Infinity) {
        return ['∞', 'gb'];
    }

    const [log, remaining] = findIntLogAndRemaining(size, 1000);

    return [Math.round(remaining), logToUnit[log]];
}

function findIntLogAndRemaining(value, base) {
    const intLog = Math.floor(Math.log(Math.abs(value)) / Math.log(base));
    const remaining = value / Math.pow(base, intLog);

    return [intLog, remaining];
}
