import { FC } from 'react';

import LoadingScreen from '@wbnr/frontend-shared/lib/components/LoadingScreen';

import BusinessLayout from '../../BusinessLayout';
import { useDevices } from '../hooks/useDevices';
import { MyDevicesList } from '../MyDevicesList';

import styles from './MyDevicesSettings.module.scss';

export const MyDevicesSettings: FC = () => {
    const { loadingDevices } = useDevices();

    return (
        <BusinessLayout className={styles.root}>
            {loadingDevices ? <LoadingScreen /> : <MyDevicesList />}
        </BusinessLayout>
    );
};
