import React, { useState, useMemo, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import ItemsListFilter from '@wbnr/frontend-shared/lib/components/ItemsListFilter';
import { CoursesList } from '@wbnr/frontend-shared/lib/data/statistics';
import { Select, MenuItem, Dropdown } from '@wbnr/ui';

const TOGGLE_KEY = 'toggle-courses-list';

const CoursesFilter = ({
    courseList,
    checkedCoursesList,
    allCoursesKey,
    onClose,
}: {
    courseList: CoursesList;
    checkedCoursesList: string[];
    allCoursesKey: string;
    onClose: (checked: string[]) => void;
}) => {
    const { localChecked, onToggle, onCloseFilter } = useLocalChecked({
        courseList,
        checkedList: checkedCoursesList,
        allCoursesKey,
        onUpdateChecked: onClose,
    });
    const { t } = useTranslation();

    const renderAnchor = ({ toggle, anchorRef }: any) => (
        <Select
            ref={anchorRef as React.RefObject<HTMLDivElement>}
            onClick={(e) => {
                e.preventDefault();
                toggle(true);

                return false;
            }}
            value={TOGGLE_KEY}
            readOnly
        >
            <MenuItem key={TOGGLE_KEY} value={TOGGLE_KEY}>
                {t('statistics.courseFilters.chooseCourses')}
            </MenuItem>
        </Select>
    );

    const allCoursesItem = useMemo(
        () => ({ id: allCoursesKey, name: t('statistics.courseFilters.allCourses') }),
        [t, allCoursesKey],
    );
    const courseItems = useMemo(() => {
        return [allCoursesItem, ...courseList].map(({ id, name }) => ({
            value: id.toString(),
            label: name,
        }));
    }, [allCoursesItem, courseList]);

    return (
        <Dropdown renderAnchor={renderAnchor} width="md" onClose={onCloseFilter}>
            <ItemsListFilter
                items={courseItems}
                selected={localChecked}
                onSelectItem={onToggle}
                searchPlaceholder={t('statistics.courseFilters.searchPlaceholder')}
                withSearch
            />
        </Dropdown>
    );
};

function useLocalChecked({
    courseList,
    checkedList,
    allCoursesKey,
    onUpdateChecked,
}: {
    courseList: CoursesList;
    checkedList: string[];
    allCoursesKey: string;
    onUpdateChecked: (checked: string[]) => void;
}) {
    const localCheckedRef = useRef(checkedList);
    const [displayedLocalChecked, setDisplayedLocalChecked] = useState(checkedList);

    const onToggle = useCallback(
        (valueKey, isChecked) => {
            const localChecked = localCheckedRef.current;
            const checkedCount = localChecked.length;
            let newLocalChecked = [] as string[];

            if (valueKey === allCoursesKey && isChecked) {
                newLocalChecked = [...courseList.map(({ id }) => id.toString()), allCoursesKey];
            } else if (valueKey === allCoursesKey) {
                newLocalChecked = [];
            } else if (isChecked && checkedCount + 1 === courseList.length) {
                newLocalChecked = [...localChecked, valueKey, allCoursesKey];
            } else if (isChecked) {
                newLocalChecked = [...localChecked, valueKey];
            } else {
                newLocalChecked = localChecked.filter(
                    (val) => val !== valueKey && val !== allCoursesKey,
                );
            }

            // Дублирование в рефе необходимо для корректной работы onClose коллбека в Dropdown - он не обновляется
            // при простом обновлении deps, и, соответственно, не передает актуальные данные
            localCheckedRef.current = newLocalChecked;
            setDisplayedLocalChecked(newLocalChecked);
        },
        [courseList, allCoursesKey],
    );

    const onCloseFilter = useCallback(() => {
        onUpdateChecked(localCheckedRef.current);
    }, [onUpdateChecked]);

    return { localChecked: displayedLocalChecked, onToggle, onCloseFilter };
}

export default CoursesFilter;
