import qs from 'qs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { useMixpanelTracking } from '@wbnr/frontend-shared/lib/analytics/mixpanel/useMixpanelTracking';
import { APIError } from '@wbnr/frontend-shared/lib/api';
import { registerOrganizationInvitationEmail } from '@wbnr/frontend-shared/lib/api/organization';
import { confirmEmail } from '@wbnr/frontend-shared/lib/api/user';
import {
    AuthLayout,
    AuthContent,
    AuthForm,
    AuthPasswordField,
    AuthButtons,
} from '@wbnr/frontend-shared/lib/components/auth';
import LoadingScreen from '@wbnr/frontend-shared/lib/components/LoadingScreen';
import { makeFormErrors, useField, useForm } from '@wbnr/frontend-shared/lib/forms';
import { isAccountUser } from '@wbnr/frontend-shared/lib/types/User';
import { createTestIdProps } from '@wbnr/ui';

import { useQualification } from 'shared/qualification/useQualification';

const STATUS = {
    joined: 1,
    notCompletedUser: 2,
    tariffRestriction: 3,
};

const baseTestId = 'OrganizationInvitationEmail';

const i18nPath = 'organization.invitation.email';

const OrganizationInvitationEmail = () => {
    const history = useHistory();
    const { token } = useParams<{ token: string }>();
    const { t } = useTranslation();

    const { track } = useMixpanelTracking();

    const isCourses = window.location.pathname.includes('/courses');

    const qualification = useQualification();

    const [status, setStatus] = useState(0);

    const redirectUrl = useMemo(() => {
        const { redirectUrl: redirectUrlParam } = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
        });
        return typeof redirectUrlParam === 'string' ? redirectUrlParam : undefined;
    }, []);

    const { control, submit } = useForm(
        {
            defaultValues: {
                password: '',
            },
        },
        {
            onSubmit: useCallback(
                async ({ password }: { password: string }) => {
                    try {
                        const user = await registerOrganizationInvitationEmail({ token, password });

                        setStatus(STATUS.joined);

                        setTimeout(() => {
                            track('enter');
                        }, 100);

                        if (isCourses) {
                            setTimeout(() => {
                                window.location.href = '/courses';
                            }, 3000);
                        } else if (isAccountUser(user)) {
                            setTimeout(() => {
                                if (redirectUrl) {
                                    history.push(redirectUrl);
                                } else {
                                    qualification.goToOnboardingOrQualification(user, true);
                                }
                            }, 3000);
                        }
                    } catch (err) {
                        if (err instanceof APIError && err.status === 409) {
                            throw makeFormErrors({
                                password: t(`${i18nPath}.existPaidTariff`),
                            });
                        }
                    }
                },
                [token, isCourses, qualification, track, t, history, redirectUrl],
            ),
        },
    );

    const passwordField = useField(control, 'password', {
        rules: {
            required: true,
        },
    });

    useEffect(() => {
        const { submitemail } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        if (typeof submitemail === 'string') {
            const confirm = async () => {
                return confirmEmail({ submitToken: submitemail });
            };

            confirm();
        }
    }, []);

    useEffect(() => {
        const register = async () => {
            try {
                const { source: sourceParam } = qs.parse(window.location.search, {
                    ignoreQueryPrefix: true,
                });
                const source = typeof sourceParam === 'string' ? { source: sourceParam } : {};
                await registerOrganizationInvitationEmail({ token, ...source });
                setStatus(STATUS.joined);
                setTimeout(() => {
                    history.push(redirectUrl || '/');
                }, 3000);
            } catch (err) {
                if (err instanceof APIError) {
                    if (err.status === 400) {
                        setStatus(STATUS.notCompletedUser);
                    } else if (err.status === 409) {
                        setStatus(STATUS.tariffRestriction);
                    } else {
                        history.push(redirectUrl || '/');
                    }
                }
            }
        };

        register();
    }, [token, history, redirectUrl]);

    if (!status) {
        return <LoadingScreen />;
    }

    return (
        <>
            <Helmet>
                <title>{t('pageTitle.organizationInvitation.email')}</title>
            </Helmet>

            <AuthLayout>
                {status === STATUS.joined && <AuthContent title={t(`${i18nPath}.joined`)} />}

                {status === STATUS.tariffRestriction && (
                    <AuthContent title={t(`${i18nPath}.tariffRestriction`)} />
                )}

                {status === STATUS.notCompletedUser && (
                    <AuthContent title={t(`${i18nPath}.setPassword`)}>
                        <AuthForm {...createTestIdProps(baseTestId, 'form')}>
                            <AuthPasswordField
                                {...passwordField}
                                label={t(`${i18nPath}.password`)}
                                {...createTestIdProps(baseTestId, 'field', 'password')}
                            />
                            <AuthButtons
                                main={{
                                    text: t('save'),
                                    onClick: submit,
                                    ...createTestIdProps(baseTestId, 'action', 'submit'),
                                }}
                            />
                        </AuthForm>
                    </AuthContent>
                )}
            </AuthLayout>
        </>
    );
};

export default OrganizationInvitationEmail;
