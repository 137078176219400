import { useTranslation } from 'react-i18next';

import Banner from '@wbnr/frontend-shared/lib/components/Banner';
import { Button, A } from '@wbnr/ui';

import { useBannerParams } from 'shared/banners';

import backgroundUrl from './assets/payusbackground.jpg';

const PayUsBanner = () => {
    const bannerParams = useBannerParams('payUs');
    const { t } = useTranslation();

    return (
        <Banner
            {...bannerParams}
            title={t('banners.payUsTitle')}
            variant="h5"
            backgroundUrl={backgroundUrl}
            action={
                <A href="/business/tariffs">
                    <Button
                        color="inherit"
                        onClick={() => {
                            bannerParams.onClose();
                        }}
                    >
                        {t('banners.buyRate')}
                    </Button>
                </A>
            }
        />
    );
};

export default PayUsBanner;
