import { useTranslation } from 'react-i18next';

import { ValidationError } from '@wbnr/frontend-shared/lib/api/sso';
import CopyToClipboard from '@wbnr/frontend-shared/lib/components/CopyToClipboard';
import { ActionDialogBase, DialogActions, Button, Typography, createTestIdProps } from '@wbnr/ui';

import styles from './ValidationFailedModal.module.scss';

const BASE_TEST_ID = 'ValidationFailedModal';

type Props = {
    opened: boolean;
    onClose: () => void;
    error: ValidationError;
};

export const ValidationFailedModal = ({ opened, onClose, error }: Props) => {
    const { t } = useTranslation();
    const { response, message } = error;
    return (
        <ActionDialogBase
            opened={opened}
            onClose={onClose}
            title={t('business.sso.saml.validationFailedModal.title')}
            maxWidth="lg"
            {...createTestIdProps(BASE_TEST_ID)}
        >
            <Typography variant="body1" className={styles.text}>
                {t('business.sso.saml.validationFailedModal.text')}
            </Typography>
            <div className={styles.container}>
                <Typography variant="body1">{message}</Typography>
                {response && <Typography variant="body1">{response}</Typography>}
            </div>
            <DialogActions disableGutters>
                <CopyToClipboard text={`${message}\n${response || ''}`}>
                    <Button color="primary" {...createTestIdProps(BASE_TEST_ID, 'copy-button')}>
                        {t('business.sso.saml.validationFailedModal.copyButton')}
                    </Button>
                </CopyToClipboard>

                <Button
                    onClick={onClose}
                    variant="contained"
                    color="primary"
                    {...createTestIdProps(BASE_TEST_ID, 'close-button')}
                >
                    {t('business.sso.saml.validationFailedModal.cancelButton')}
                </Button>
            </DialogActions>
        </ActionDialogBase>
    );
};
