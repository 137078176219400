const translations = {
    ru: {
        maxEventSessions_0: '{{count}} мероприятие',
        maxEventSessions_1: '{{count}} мероприятия',
        maxEventSessions_2: '{{count}} мероприятий',
        maxUsers: '{{count}} участников на встрече',
        maxStorage: 'для файлов',
        recommended: 'Рекомендованный',
        rub: 'руб.',
        buy: 'Купить',
        isConversion: 'Скачивание записей в MP4',
        isWebinar: '{{maxUsers}} зрителей на вебинаре, включая {{maxConferences}} ведущих',
        isMarketing: 'Аналитика по источникам и участникам',
        canConnectExtensions: 'Возможность подключить дополнительные опции',
        isBranding: 'Брендирование',
        isPersonalManager: 'Персональный менеджер',
        isTraining: 'Тесты для проверки знаний',
        isSupport: 'Поддержка 24/7',
        isMobileApp: 'iOS и Android приложения',
        isInfinitySession: 'Без ограничений по времени',
        notInfinitySession: 'Ограничение 60 мин',
        meetings: {
            title: 'Встречи',
            description: 'Встречи без ограничений, брендирование',
        },
        meetingsPro: {
            title: 'Встречи Pro',
        },
        training: {
            title: 'Обучение',
            description: 'Корпоративное обучение, вебинары и тренинги',
        },
        trainingPro: {
            title: 'Обучение Pro',
        },
        marketing: {
            title: 'Маркетинг',
            description: 'Привлечение внимания к своему продукту, аналитика',
        },
        marketingPro: {
            title: 'Маркетинг Pro',
        },
        trial: {
            title: 'Пробный период',
            description: 'Все платные возможности доступны до {{date, dd.MM.yyyy}}',
        },
        free: {
            title: 'Бесплатно',
            description: 'Встречи c ограничением по времени 60 мин',
        },
        currentTariff: 'Текущий тариф',
        additionally: 'Дополнительно',
        main: 'Основное',
        analytics: 'Яндекс. Метрика, Google Analitics',
        payMethod: 'Способ оплаты',
        byCard: 'Банковская карта',
        byInvoice: 'Счёт-оферта',
    },
    en: {
        maxEventSessions: '{{count}} event',
        maxEventSessions_plural: '{{count}} events',
        maxUsers: '{{count}} participants at the meeting',
        maxStorage: 'for files',
        recommended: 'Recommended',
        rub: 'rub.',
        buy: 'Buy',
        isConversion: 'Download recordings to MP4',
        isWebinar: '{{maxUsers}} webinar viewers including {{maxConferences}} hosts',
        isMarketing: 'Analytics by sources and participants',
        canConnectExtensions: 'Possibility to add additional options',
        isBranding: 'Branding',
        isApi: 'Access to Webinar API',
        isPersonalManager: 'Personal manager',
        isTraining: 'Knowledge tests',
        isEncoder: 'Encoder',
        isSupport: 'Support 24/7',
        isMobileApp: 'iOS and Android apps',
        isInfinitySession: 'Unlimited event duration',
        notInfinitySession: '60 min limit',
        currentTariff: 'Current tariff',
        meetings: {
            title: 'Meetings',
            description: 'Meetings without limits, branding',
        },
        meetingsPro: {
            title: 'Meetings Pro',
        },
        training: {
            title: 'Learning',
            description: 'Corporate trainings and webinars',
        },
        trainingPro: {
            title: 'Learning Pro',
        },
        marketing: {
            title: 'Marketing',
            description: 'Attracting attention to your product, analytics',
        },
        marketingPro: {
            title: 'Marketing Pro',
        },
        trial: {
            title: 'Trial period',
            description: 'All paid features are available until {{date, MM/d/yyyy}}',
        },
        free: {
            title: 'Free',
            description: 'Meetings with a 60 min time limit',
        },
        additionally: 'Additionally',
        main: 'Main',
        analytics: 'Yandex. Metrica, Google Analytics',
        payMethod: 'Payment method',
        byCard: 'Bank card',
        byInvoice: 'Offer invoice',
    },
};

export default translations;
