import qs from 'qs';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';

import { APIError } from '@wbnr/frontend-shared/lib/api';
import { extendAccount } from '@wbnr/frontend-shared/lib/api/user';
import {
    AuthLayout,
    AuthContent,
    AuthForm,
    AuthButtons,
    AuthPasswordField,
} from '@wbnr/frontend-shared/lib/components/auth';
import LoadingScreen from '@wbnr/frontend-shared/lib/components/LoadingScreen';
import { makeFormErrors, useField, useForm } from '@wbnr/frontend-shared/lib/forms';
import { validatePassword } from '@wbnr/frontend-shared/lib/utils/validators';
import { createTestIdProps } from '@wbnr/ui';

const baseTestId = 'ExtendAccount';
const i18nPath = 'auth.extendAccount';
const REDIRECT_TIMEOUT = 5000;

const ExtendAccount = () => {
    const { t } = useTranslation();
    const { push } = useHistory();
    const { token } = useParams<{ token: string }>();

    const addititonalParams = useMemo(() => {
        const queryParams = qs.parse(window.location.search.substr(1));

        return (['host', 'origin', 'project', 'participantFlow'] as const).reduce<{
            host?: string;
            origin?: string;
            project?: string;
            participantFlow?: string;
        }>((result, field) => {
            const value = queryParams[field];

            if (typeof value === 'string') {
                result[field] = value;
            }

            return result;
        }, {});
    }, []);
    const isCourses = addititonalParams.project === 'course';

    const [checked, setChecked] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const { control, submit, getValues } = useForm(
        {
            defaultValues: {
                password: '',
                passwordConfirmation: '',
            },
        },
        {
            onSubmit: useCallback(
                async ({ password }) => {
                    if (!token) {
                        return;
                    }

                    try {
                        await extendAccount({
                            token,
                            password,
                            ...addititonalParams,
                        });
                        setIsSuccess(true);
                    } catch (err) {
                        if (
                            err instanceof APIError &&
                            err.status === 400 &&
                            err.errorMessage === 'Registration only by sso'
                        ) {
                            push('/sso-signup');
                        }
                        throw makeFormErrors({
                            password: t(`${i18nPath}.error`),
                        });
                    }
                },
                [token, addititonalParams, t, push],
            ),
        },
    );

    useEffect(() => {
        if (isSuccess) {
            const timer = setTimeout(() => {
                push(isCourses ? '/courses/signin' : '/signin');
            }, REDIRECT_TIMEOUT);

            return () => {
                timer && clearTimeout(timer);
            };
        }
    }, [isSuccess, push, isCourses]);

    useEffect(() => {
        const check = async () => {
            try {
                if (token) {
                    await extendAccount({
                        token,
                        ...addititonalParams,
                    });
                    push(isCourses ? '/courses/signin' : '/signin');
                } else {
                    push('/');
                }
            } catch (err) {
                if (
                    err instanceof APIError &&
                    err.body?.error?.code === 'UNABLE_TO_PROCESS_PASSWORD'
                ) {
                    setChecked(true);
                } else if (
                    err instanceof APIError &&
                    err.status === 400 &&
                    err.errorMessage === 'Registration only by sso'
                ) {
                    push('/sso-signup');
                } else {
                    push('/');
                }
            }
        };

        check();
    }, [token, push, addititonalParams, isCourses]);

    const passwordField = useField(control, 'password', {
        rules: { required: true, validate: validatePassword },
    });
    const passwordConfirmationField = useField(control, 'passwordConfirmation', {
        rules: {
            required: true,
            validate: (value) => value === getValues('password') || `${i18nPath}.passwordNotMatch`,
        },
    });

    if (!checked) {
        return <LoadingScreen />;
    }

    return (
        <>
            <Helmet>
                <title>{t('pageTitle.extendAccount')}</title>
            </Helmet>
            <AuthLayout>
                {isSuccess ? (
                    <AuthContent title={t(`${i18nPath}.success`)} />
                ) : (
                    <AuthContent title={t(`${i18nPath}.title`)}>
                        <AuthForm {...createTestIdProps(baseTestId, 'form')}>
                            <AuthPasswordField
                                {...passwordField}
                                label={t(`${i18nPath}.password`)}
                                {...createTestIdProps(baseTestId, 'field', 'password')}
                            />
                            <AuthPasswordField
                                {...passwordConfirmationField}
                                label={t(`${i18nPath}.passwordConfirmation`)}
                                {...createTestIdProps(baseTestId, 'field', 'passwordConfirmation')}
                            />
                            <AuthButtons
                                main={{
                                    text: t(`${i18nPath}.submit`),
                                    onClick: submit,
                                    ...createTestIdProps(baseTestId, 'action', 'submit'),
                                }}
                            />
                        </AuthForm>
                    </AuthContent>
                )}
            </AuthLayout>
        </>
    );
};

export default ExtendAccount;
