import { useTranslation } from 'react-i18next';

import { Button, CircularProgress, SwitchField, Typography } from '@wbnr/ui';

import styles from './PointOfPresence.module.scss';
import { usePointOfPresence } from './PointOfPresenceContext';

const PointOfPresence = () => {
    const { t } = useTranslation();

    const {
        popData,
        switcherValue,
        initialLoading,
        error,
        onChangeSwitcher,
        loading,
        refreshPoP,
        isPopStatusEnabled,
    } = usePointOfPresence();

    if (error) {
        return <Typography>{t('business.pointOfPresencePage.errorLoading')}</Typography>;
    }

    if (initialLoading) {
        return <CircularProgress className={styles.progress} />;
    }

    return (
        <div className={styles.card}>
            <SwitchField
                value={switcherValue}
                onChange={onChangeSwitcher}
                className={styles.switcher}
                label={t('business.pointOfPresencePage.switcherLabel')}
            />

            {(loading && <CircularProgress className={styles.progress} />) ||
                (popData?.enabled && (
                    <div className={styles.info}>
                        <section className={styles.cardSection}>
                            <Typography className={styles.itemTitle}>
                                {t('business.pointOfPresencePage.token')}
                            </Typography>
                            <Typography>{popData.token}</Typography>
                        </section>

                        {popData.networks.length > 0 && (
                            <section className={styles.cardSection}>
                                <Typography className={styles.itemTitle}>
                                    {t('business.pointOfPresencePage.networks')}
                                </Typography>
                                <Typography>{popData.networks.join(', ')}</Typography>
                            </section>
                        )}

                        {popData.host && (
                            <section className={styles.cardSection}>
                                <Typography className={styles.itemTitle}>
                                    {t('business.pointOfPresencePage.host')}
                                </Typography>
                                <Typography>{popData.host}</Typography>
                            </section>
                        )}

                        <section className={styles.cardSection}>
                            <Typography className={styles.itemTitle}>
                                {t('business.pointOfPresencePage.state')}
                            </Typography>
                            <Typography
                                className={
                                    isPopStatusEnabled
                                        ? styles.statusEnabled
                                        : styles.statusDisabled
                                }
                            >
                                {isPopStatusEnabled
                                    ? t('business.pointOfPresencePage.enabled')
                                    : t('business.pointOfPresencePage.disabled')}
                            </Typography>
                        </section>

                        <div className={styles.buttons}>
                            <Button
                                variant={'text'}
                                color={'primary'}
                                className={styles.button}
                                onClick={refreshPoP}
                            >
                                {t('business.pointOfPresencePage.refresh')}
                            </Button>
                        </div>
                    </div>
                ))}
        </div>
    );
};

export { PointOfPresence };
