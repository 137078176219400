import { useTranslation } from 'react-i18next';

import { injectTranslations } from '@wbnr/frontend-shared/lib/utils/i18n';
import {
    Typography,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    createTestIdProps,
} from '@wbnr/ui';

import { TRANS_NS } from '../constants';
import integrationsStyles from '../Integrations.module.scss';
import translations from '../translations.json';

import { EXTENSIONS } from './constants';
import { useExtensionModal } from './dialogs/ExtensionModal';
import styles from './Extensions.module.scss';
import { ExtensionType } from './types';

const BASE_TEST_ID = 'IntegrationExtensions';

injectTranslations(TRANS_NS, translations);

export const Extensions = () => {
    const { t } = useTranslation(TRANS_NS);
    const [openGoogleCalendarExtension] = useExtensionModal({
        extensionType: 'googleCalendar',
        ...createTestIdProps(BASE_TEST_ID, 'modal', 'googleCalendar'),
    });
    const [openOutlookExtension] = useExtensionModal({
        extensionType: 'outlook',
        ...createTestIdProps(BASE_TEST_ID, 'modal', 'outlook'),
    });

    const handleExtensionClick = (extensionType: ExtensionType) => {
        const handleClickMap = {
            googleCalendar: openGoogleCalendarExtension,
            outlook: openOutlookExtension,
        };

        handleClickMap[extensionType]();
    };

    return (
        <div className={styles.root}>
            <Typography variant="body1" className={integrationsStyles.description}>
                {t('extensions.description')}
            </Typography>
            <div className={styles.cards}>
                {Object.entries(EXTENSIONS).map(([extensionType, extension]) => (
                    <Card key={extensionType}>
                        <CardActionArea
                            onClick={() => handleExtensionClick(extensionType as ExtensionType)}
                            {...createTestIdProps(BASE_TEST_ID, 'card', extensionType)}
                        >
                            <CardContent className={styles.card}>
                                <CardMedia
                                    className={styles.cardImage}
                                    image={extension.imagePath}
                                    title={t(extension.nameKey)}
                                />

                                <Typography variant="h6" className={styles.cardTitle}>
                                    {t(extension.nameKey)}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    component="p"
                                    className={styles.cardDescription}
                                >
                                    {t(extension.descriptionKey)}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                ))}
            </div>
        </div>
    );
};
