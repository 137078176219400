import { Typography } from '@wbnr/ui';

import styles from './SubmitResult.module.scss';

export type ResultProps = {
    title: string;
    description: string;
};

export const SubmitResult = ({ title, description }: ResultProps) => {
    return (
        <div className={styles.submited}>
            <Typography variant="h6">{title}</Typography>
            <Typography variant="body1">{description}</Typography>
        </div>
    );
};
