function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import Cookie from 'js-cookie';
import { apiFormFetch } from '../apiFetch';
import { sendFeatureRequest } from './sendFeatureRequest';
import { USER_AUTH_METHOD_KEY } from './constants';
import { getAuthPayload } from '../../utils/auth';
export var saveUser = function saveUser(params) {
  return apiFormFetch('PUT', "/user/".concat(params.id), _objectSpread(_objectSpread({}, params), {}, {
    oldpassword: params.oldpassword || undefined,
    password: params.password || undefined,
    photo: params.photo ? params.photo.id : '',
    locale: params.language
  })).then(function () {
    return _objectSpread({}, params);
  });
};
export var saveGuest = function saveGuest(params) {
  return apiFormFetch('PUT', "/events/participations/".concat(params.id), {
    nickname: params.nickname
  }).then(function () {
    return _objectSpread({}, params);
  });
};
export var saveUserEventSettings = function saveUserEventSettings(params) {
  return apiFormFetch('PUT', '/user/eventSettings', params).then(function () {
    return _objectSpread({}, params);
  });
};
export var login = function login(_ref) {
  var email = _ref.email,
    password = _ref.password,
    _ref$rememberMe = _ref.rememberMe,
    rememberMe = _ref$rememberMe === void 0 ? true : _ref$rememberMe;
  var data = {
    email: email,
    password: password,
    rememberMe: rememberMe
  };
  var payload = getAuthPayload(data);
  return apiFormFetch('POST', '/login', payload);
};
export var jwt = function jwt(_ref2) {
  var platform = _ref2.platform;
  return apiFormFetch('POST', '/user/jwt', {
    platform: platform
  });
};
export var register = function register(params) {
  return apiFormFetch('POST', '/user', params);
};
export var restorePassword = function restorePassword(params) {
  return apiFormFetch('POST', '/restorePassword', params);
};
export var sendNewPassword = function sendNewPassword(params) {
  return apiFormFetch('POST', '/restorePassword/{token}', params);
};
export var checkRestorePasswordToken = function checkRestorePasswordToken(params) {
  return apiFormFetch('GET', '/restorePassword/{token}', params);
};
export var restorePasswordCourses = function restorePasswordCourses(params) {
  return apiFormFetch('POST', '/courses/restorePassword', params);
};
export var logout = function logout() {
  return apiFormFetch('POST', '/logout').then(function () {
    return Cookie.remove(USER_AUTH_METHOD_KEY);
  });
};
export var sendVerifyEmail = function sendVerifyEmail() {
  return apiFormFetch('POST', '/user/submitEmails');
};
export var confirmEmail = function confirmEmail(params) {
  return apiFormFetch('PUT', '/submitemail/{submitToken}', params);
};
export var requestMeetingsTrial = function requestMeetingsTrial() {
  return sendFeatureRequest({
    type: 'MeetingsTrial'
  });
};
export var extendAccount = function extendAccount(params) {
  return apiFormFetch('PUT', '/user/init-cabinet/{token}', params);
};
export var ssoLogin = function ssoLogin(email) {
  return apiFormFetch('POST', '/login/sso', {
    email: email
  }).then(function (result) {
    var _result$data;
    return (result === null || result === void 0 ? void 0 : (_result$data = result.data) === null || _result$data === void 0 ? void 0 : _result$data.items) || [];
  });
};
export var getSSOTransferOrganizations = function getSSOTransferOrganizations() {
  return apiFormFetch('GET', '/user/sso-transfer-organizations').then(function (result) {
    var _result$data2;
    return (result === null || result === void 0 ? void 0 : (_result$data2 = result.data) === null || _result$data2 === void 0 ? void 0 : _result$data2.items) || [];
  });
};
export var ssoTransferOrganizationCheck = function ssoTransferOrganizationCheck(params) {
  return apiFormFetch('POST', '/user/organization/sso-transfer', params);
};