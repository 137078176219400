import { commonTranslations } from '@wbnr/frontend-shared/lib/utils/i18n';

export const ru = {
    'voting-components': {
        test: {
            numberOfAnswers_0: '{{count}} ответ',
            numberOfAnswers_1: '{{count}} ответа',
            numberOfAnswers_2: '{{count}} ответов',
            numberOfPoints_0: '{{count}} балл',
            numberOfPoints_1: '{{count}} балла',
            numberOfPoints_2: '{{count}} баллов',
            passed: 'пройден',
            notPassed: 'не пройден',
            passTime: '{{time, timeDuration}}',
            participantsHeader: 'Участники',
            resultHeader: 'Результат',
            correctAnswersNumberHeader: 'Правильно',
            pointsNumberHeader: 'Набрано',
            timeHeader: 'Время',
        },
    },
    ui: {
        second_0: 'секунда',
        second_1: 'секунды',
        second_2: 'секунд',
        minute_0: 'минута',
        minute_1: 'минуты',
        minute_2: 'минут',
        hour_0: 'час',
        hour_1: 'часа',
        hour_2: 'часов',
        day_0: 'день',
        day_1: 'дня',
        day_2: 'дней',
        week_0: 'неделя',
        week_1: 'недели',
        week_2: 'недель',
        month_0: 'месяц',
        month_1: 'месяца',
        month_2: 'месяцев',
        minutesSimple: 'минут',
        slideNumber: '{{slideIndex}} из {{maxSlideIndex}}',
        search: 'Поиск',
        timePickerError: 'Что-то не так со временем',
        cancel: 'Отмена',
        next: 'Далее',
        save: 'Сохранить',
        date: {
            dateStr: '{{date, d MMMM yyyy}}',
            timeStr: '{{time, HH:mm}}',
            timePickerTitle: 'Выберите время',
            weekDayShort: '{{day, EE}}',
            weekDay: '{{day, EEEE}}',
            weekDayShortLocal: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
            weekDayLocal: [
                'Воскресенье',
                'Понедельник',
                'Вторник',
                'Среда',
                'Четверг',
                'Пятница',
                'Суббота',
            ],
            month: '{{month, LLLL}}',
        },
        wysiwyg: {
            placeholder: {
                default: 'Пишите или скопируйте текст сюда',
                code: 'Вставьте ссылку или код сюда',
                miro: 'Вставьте сюда код вашей доски. <0>Что это?</0>',
            },
            lineAttachmentToolbar: {
                code: 'Код',
                image: 'Изображение',
                file: 'Файл',
                upload: 'Загрузить с компьютера',
                miro: 'Доска Miro (RealtimeBoard)',
            },
            styles: {
                default: 'Текст',
                heading: 'Заголовок',
                list: 'Список',
                blockquote: 'Цитата',
            },
        },
    },
    cp: {
        ...commonTranslations.ru,
        preventNavigation: 'Возможно, внесенные изменения не сохранятся.',
        gotIt: 'Понятно',
        next: 'Далее',
        notIntrested: 'пропустить',
        meetingsPromoDialog: {
            step1: {
                title: 'Календарь встреч',
                text: 'Отслеживайте ваши собственные встречи и мероприятия, на которые вас пригласили, в едином календаре',
            },
            step2: {
                title: 'Планируйте встречи заранее',
                text: 'Попробуйте запланировать встречу в новом редакторе. Он был упрощен и адаптирован для небольших собраний.',
            },
            step3: {
                title: 'Оставьте этот личный кабинет для вебинаров',
                text: 'А встречи создавайте из нового. Скоро они окончательно переедут в отдельный раздел, чтобы вам было удобнее работать с разными видами онлайн-мероприятий.',
            },
            notIntrested: 'Не интересно',
            goToMeetings: 'Перейти к встречам',
        },
        webinarTrialDialog: {
            title: {
                common: 'Пробный период закончится через {{days}}',
                warning: 'Ваш пробный период скоро заканчивается. Осталось {{days}}.',
                over: 'Пробный тариф закончился',
                overSelfService: 'Пробный тариф закончился,\nподключен бесплатный тариф',
            },
            text: {
                warning:
                    'Когда доступ закончится, вы не сможете пользоваться инструментами маркетинга, вовлечения, количество участников будет ограничено.',
                over: 'Активирован бесплатный тариф без возможности скачать запись мероприятия.',
            },
            comment: {
                common: 'Есть вопросы? Будем рады ответить.',
                contact: 'Свяжитесь с менеджером, чтобы подключить полноценный доступ.',
            },
            disabled: 'Отключены инструменты<br/>маркетинга и вовлечения.',
            requested: 'Заявка отправлена. Менеджер скоро с вами свяжется.',
            hasNow: 'Доступно сейчас',
            records: 'Конвертация\nи скачивание записи',
            afterTrialEnd: 'Через {{days}}',
            users: {
                title: '30 участников\nна встрече',
                inTrial: '150 участников на вебинаре\nи 100 на встречах',
            },
            storage: {
                title: '500 МБ\nдля файлов',
                inTrial: '30 ГБ\nдля файлов',
            },
            noTimeLimits: {
                title: 'Ограничение\nпо времени 1 час',
                inTrial: 'Без ограничений по времени',
            },
            multipleEvents: {
                title: 'Параллельные мероприятия',
                description:
                    'Сотрудники, добавленные в организацию, смогут проводить параллельные мероприятия',
            },
            banners: {
                title: 'Баннеры',
                description:
                    'Показывайте участникам вебинара свою рекламу и делитесь важными ссылками',
            },
            branding: {
                title: 'Брендирование',
                description: 'Персонализируйте контент, который видят участники мероприятий',
            },
            background: {
                title: 'Фоны',
                description:
                    'Используйте ваши фоновые изображения для мероприятий, например, прессволы',
            },
            utm: {
                title: 'UTM-метки',
                description: 'UTM-метка будет у каждого участника мероприятия',
            },
            groupwork: {
                title: 'Деление на группы',
                description:
                    'Проводите групповые тренинги и делитесь на команды для отработки кейсов',
            },
            miro: {
                title: 'Miro',
                description:
                    'Miro можно "встроить" прямо в мероприятие, что поможет быстрее понять друг друга',
            },
            attentionControl: {
                title: 'Контроль участников',
                description: 'Инструменты, которые помогут узнать, кто внимательно смотрит вебинар',
            },
            statistics: {
                title: 'Статистика',
                description: 'Подробная информация об активности участников в статистике',
            },
        },
        webinarTrialBanner: {
            title: 'Ваш пробный период закончится через <bold>{{days}}</bold>',
            continue: 'Подробнее',
            opportunities: 'Посмотреть возможности',
        },
        and: 'и',
        andSeparator: ' и ',
        copiedToClipboard: 'Скопировано в буфер обмена',
        unknownError: 'Неизвестная ошибка',

        westudyPromoDialog: {
            title: 'Новые возможности для решения задач обучения',
            submit: 'Активировать',
            beforelistText: 'С помощью We.Study вы сможете:',
            listitem1: 'Автоматизировать адаптацию и обучение сотрудников',
            listitem2: 'Упаковать записи мероприятий и контент в библиотеку файлов',
            listitem3: 'Видеть статистику по просмотру записи и глубине просмотра записей',
            listitem4: 'Получить дополнительный доход за счет продажи онлайн-записей',
            afterlistText:
                'Отправьте заявку на подключение бесплатного тестового периода и попробуйте все возможности We.Study в течение 14 дней',
        },
        profile: {
            name: 'Имя',
            secondName: 'Фамилия',
            nickname: 'Имя для мероприятий и чата',
            phone: 'Телефон',
            email: 'Электронная почта',
            changePassword: 'Изменить пароль',
            dontChangePassword: 'Не менять пароль',
            oldPassword: 'Старый пароль',
            password: 'Новый пароль',
            organization: 'Организация',
            position: 'Должность',
            description: 'Коротко о себе',
            language: 'Язык',
            russian: 'Русский',
            english: 'English',
            settings: 'Настройки',
            autoRecord: 'Отключить запись вебинаров',
            autoRecordHint: 'Настройка создания записи применится к новым запущенным мероприятиям',
            autoRecordFeature: 'Запускать запись при старте мероприятия',
            autoRecordFeatureHint: 'Настройка не применяется к постоянным встречам',
            streamMode: 'По умолчанию использовать WebRTC в режиме «вебинара»',
            eventSelfNotify: 'Добавлять созданные мероприятия в мой календарь',
            forceUseMeetingCabinet: 'Использовать по умолчанию кабинет встреч',
            desktopAppForMe:
                'Использовать <a>десктопное приложение</a> при подключении к мероприятиям по умолчанию',
            desktopAppForMyParticipation:
                'Использовать <a>десктопное приложение</a> для участников моих мероприятий по умолчанию',
        },
        create: 'Создать',
        contactSales: {
            title: 'Встречайтесь без ограничений!',
            viewTarrifsButton: 'Выбрать тариф',
            updateTarrif: 'Купить тариф',
            button: 'Связаться с менеджером',
        },
        pageTitle: {
            webinarTemplate: '%s - Webinar',
            files: 'Файлы',
            editor: 'Редактор',
            signin: 'Вход',
            signup: 'Регистрация',
            passwordRecovery: 'Восстановление пароля',
            emailConfirmation: 'Подтверждение электронной почты',
            extendAccount: 'Установить пароль',
            organizationInvitation: {
                email: 'Приглашение в организацию',
                link: 'Приглашение в организацию по ссылке',
            },
        },
        nav: {
            profile: 'Профиль',
            payment: 'Оплата',
            systemTest: 'Тест системы',
            help: 'Инструкции',
            logout: 'Выход',
            webinars: 'Вебинары',
            broadcasts: 'Трансляции',
            organization: 'Организация',
            people: 'Люди',
            files: 'Файлы',
            statistic: 'Статистика',
            business: 'Бизнес',
            ssoSettings: 'Настройки SSO',
            myDevices: 'Мои устройства',
            siemSettings: 'Настройки SIEM',
            tariffInfo: 'Информация о тарифе',
            education: 'Обучение',
            search: 'Поиск',
            notifications: 'Оповещения',
            applications: 'Приложения',
            addFile: 'Добавить файл',
            save: 'Сохранить',
            meetings: 'Встречи',
            courses: 'Курсы',
            buyTariff: 'Купить тариф',
            myTariff: 'Мой тариф',
            download_app: 'Скачать приложение',
            integrations: 'Интеграции и расширения',
            integrations_google_calendar: 'Интеграция с Google календарем',
            integrations_outlook_calendar: 'Интеграция с Microsoft Outlook',
            integrations_google_chrome: 'Расширение для Google Chrome',
            integrations_more: 'Больше интеграций и решений',
        },
        editor: {
            save: 'Сохранить',
            textQuestion: 'Вопрос с текстом',
            imageQuestion: 'Вопрос текст и картинка',
            textAnswer: 'Ответ с текстом',
            imageAnswer: 'Ответ с картинками',
            freeAnswer: 'Свободный ответ',
            minScoreLabel: 'Минимальное кол-во ответов',
            freeAnswerSwitchLabel: 'Со свободным ответом',
            testDurationLabel: 'Длительность прохождения',
            passScoreLabel: 'Минимальное кол-во баллов',
            maxScore: 'Максимальное количество баллов {{maxScore}}',
            useScoreLabel: 'Использовать баллы для оценки',
            pollTitleLabel: 'Придумайте название голосования',
            questionLabel: 'Вопрос',
            scoreLabel: 'Баллы',
            answerLabel: 'Ответ',
            freeAnswerLabel: 'Свободный ответ',
            numberOfAnswers: 'Количество ответов которое можно дать на вопрос',
            loadFromSystem: 'С компьютера',
            loadFromFiles: 'Из файлов',
            activityTasksTitle: 'Задания для тренинга',
            activitySettingsTitle: 'Разделение на группы',
            activityGroupsNumberLabel: 'Количество групп',
            activityGroupsNumberEqualityLabel:
                'Кол-во активных (отмеченных галкой) заданий должно быть равно кол-ву групп',
            activityAutoReturnToDefault: 'Вернуть автоматически',
            activityDurationLabel: 'Вернуть через',
            activityIsPublicLabel: 'Участник сам выбирает группу',
            activityPublicHelper: 'Участники не будут изначально распределены по группам',
            activityNotPublicHelper: 'Участники будут автоматически распределены по группам',
            activityDistributionTypeLabel: 'Разные задания группам',
            activityIndividualDistributionHelper: 'Каждой группе достаётся индивидуальное задание',
            activityCommonDistributionHelper: 'Одинаковое задание для всех групп',
            activityAllowRenameGroups: 'Участники могут менять название группы',
            activityTitleLabel: 'Название активности',
            newGroupTaskLabel: 'Новое задание',
            groupTaskIndexHelper: 'Задание №{{index}}',
            fileMustBeImageError: 'Можно загружать только изображения',
        },
        files: {
            title: 'Файлы',
            emptyFolder: 'В этой папке ничего нет',
            revertDeletedFiles: 'Отменить',
            fileRestoredNotification_0: '{{count}} файл восстановлен',
            fileRestoredNotification_1: '{{count}} файла восстановлено',
            fileRestoredNotification_2: '{{count}} файлов восстановлено',
            fileDeletedNotification_0: '{{count}} файл удален',
            fileDeletedNotification_1: '{{count}} файла удалены',
            fileDeletedNotification_2: '{{count}} файлов удалены',
            fileNotCompletelyDeletedNotification:
                'Часть файлов не была удалена. Файлы находятся в обработке или относятся к не удаленным тестам. Попробуйте повторить действие позже.',
            fileDeleteErrorNotification: 'Ошибка при удалении файлов',
            fileMovedNotification_0: '{{count}} файл перемещен',
            fileMovedNotification_1: '{{count}} файла перемещены',
            fileMovedNotification_2: '{{count}} файлов перемещены',
            fileMoveErrorNotification: 'Ошибка перемещения файлов',
            fileRenamedNotification: 'Файл переименован',
            fileRenameErrorNotification: 'Ошибка переименовывания файла',
            deleteFileAction: 'Удалить',
            renameFileAction: 'Переименовать',
            openFileAction: 'Открыть',
            searchLabel: 'Поиск',
            selectedLabel: 'Выбрано {{selectedNumber}}',
            newBannerAction: 'Создать баннер',
            newFolderAction: 'Создать папку',
            newVideoAction: 'Добавить видео Youtube/Vimeo',
            newPollAction: 'Добавить голосование',
            newTestAction: 'Добавить тест',
            newActivityAction: 'Добавить активность',
            newFileAction: 'Добавить с компьютера',
            newScormAction: 'Доабвить SCORM-пакет',
            newVideoDialogTitle: 'Добавление видео Youtube/Vimeo',
            newVideoDialogLabel: 'Ссылка на видео',
            newVideoDialogSubmut: 'Сохранить',
            videoAdded: 'Видео добавлено',
            videoAddError: 'Ошибка добавления видео',
            maxStorageSize: 'Доступно из {{size, filesize}}',
            cancelUpload: 'Отменить',
            uploadedNFromM: 'Загружено {{N}} из {{M}}',
            uploadInProgress: 'Загрузка файлов',
            uploadDone: 'Файлы загружены',
            uploadError: 'Ошибка загрузки файлов',
            uploadCanceled: 'Загрузка файлов отменена',
            ownerLabel: 'Владелец',
            createDateLabel: 'Дата создания',
            toggleViewTypeTooltip: {
                table: 'Отобразить в виде плитки',
                grid: 'Отобразить в виде таблицы',
            },
            editPollLabel: 'Редактировать голосование',
            pollHistoryTitle: 'История результатов',
            chooseAllTooltip: 'Выбрать все',
            downloadFile: 'Скачать',
            name: 'Имя',
            createDate: 'Дата создания',
            size: 'Размер',
            owner: 'Владелец',
            sharedFolder: 'Общие файлы',
            allFilesFolder: 'Все файлы',
            folderRoot: 'Корень',
            myFiles: 'Мои файлы',
            newFolder: 'Новая папка',
            presetationFailure:
                'Возможно ваш файл содержит слишком много страниц или у него неверный формат. Пожалуйста, ознакомьтесь с <0>подробной информацией</0> и попробуйте еще раз.',
            fileStatusFailure: 'Ошибка! <0>Подробнее</0>',
            convertationCanceled: 'Остановлено',
            videoConvertCanceled: 'Конвертация отменена',
            presentationErrorTitle: 'Ошибка обработки файла',
            presentationErrorSubmit: 'Удалить',
            testResultDate: 'Время проведения',
            testMaxDuration: 'Время прохождения',
            testMaxPoints: 'Сумма баллов',
            testMinPoints: 'Минимальное количество баллов',
            testMaxAnswers: 'Количество правильных ответов',
            testMinAnswers: 'Минимальное количество ответов',
            personalTestPassedLabel: 'Результат',
            personalTestPassed: 'Пройден',
            personalTestFailed: 'Провален',
            personalTestResult: 'Правильно',
            personalTestNumberOfRightAnswers_0: '{{count}} ответ',
            personalTestNumberOfRightAnswers_1: '{{count}} ответа',
            personalTestNumberOfRightAnswers_2: '{{count}} ответов',
            personalTestNumberOfPoints_0: '{{count}} балл',
            personalTestNumberOfPoints_1: '{{count}} балла',
            personalTestNumberOfPoints_2: '{{count}} баллов',
            personalTestTimeTaken: 'Время',
            bannerCreateTitle: 'Создание баннера',
            bannerEditTitle: 'Редактирование баннера',
            bannerCreated: 'Баннер создан',
            bannerEdited: 'Баннер отредактирован',
            bannerCreateError: 'Не удалось создать баннер',
            bannerEditError: 'Не удалось отредактировать баннер',
            bannerDescription:
                'Используйте баннер для показа рекламы и призыва участников к действию',
            bannerFollow: 'Перейти',
            bannerName: 'Название',
            bannerText: 'Придумайте короткое описание',
            bannerTextNote: '80 символов',
            bannerLink: 'Ссылка, куда перейдет участник',
            bannerUseImage: 'Использовать изображение',
            bannerUploadImage:
                'Чтобы прикрепить файл, перетяните его сюда или <0>выберите файл</0> с компьютера.<br />SVG, PNG. TIFF, JPG. 44x44 px.',
            openMiro: 'Открыть MIRO',
            addMiroBoard: 'Добавить доску MIRO',
            miroAdded: 'Доска MIRO добавлена',
            miroAddedError: 'Не удалось добавить доску MIRO',
            scormAdded: 'SCORM-пакет добавлен',
            scormAddedError: 'Не удалось добавить SCORM-пакет',
        },
        business: {
            title: 'Бизнес',
            tariffs: 'Тарифы',
            myTariff: 'Мой тариф',
            organization: 'Организация',
            branding: 'Брендирование',
            certificate: 'Сертификат',
            analytics: 'Аналитика',
            paidEvents: 'Платные мероприятия',
            proctoring: 'Прокторинг',
            pointOfPresence: 'Медиавыносы',
            api: 'API',
            apiAndWebhooks: 'API / Webhooks',
            integrations: 'Интеграции',
            ssoSettings: 'Настройки SSO',
            myDevices: 'Мои устройства',
            siemSettings: 'Настройки SIEM',
            proctoringPage: {
                title: 'Прокторинг',
                description:
                    'Для подключения, введите данные, полученные от ProctorEdu, после сохранения настройка прокторинга будет доступна в настройках теста в We.Study. Сессия прокторинга автоматически начнется у участника, начавшего тест, данные по сессиям прокторинга ваших участников будут доступны в вашем админском кабинете в ProctorEdu.',
                save: 'Сохранить',
                change: 'Изменить',
                saveChanges: 'Сохранить изменения',
                fields: {
                    url: 'Введите URL сервера',
                    secretOrKey: 'Введите secretOrKey',
                    apiKey: 'Введите x-api-key',
                },
                proctoringSettingsGetFail: 'Ошибка при получении данных прокторинга',
                proctoringSettingsSaveSuccess: 'Данные прокторинга сохранены',
                proctoringSettingsSaveFail: 'Ошибка при сохранении данных прокторинга',
            },
            brandingPage: {
                create: 'Создать шаблон брендирования',
                created: 'Создан',
                updated: 'Изменено',
                default: 'По умолчанию',
                setDefault: 'Сделать по умолчанию',
                duplicate: 'Дублировать',
                title: 'Название шаблона',
                untitled: 'Без названия',
                newsletters: 'Рассылки',
                replyTo: 'Отправлять ответы на адрес',
                replyToComment: 'На этот Email будут приходить ответы участников на письма',
                emailFrom: 'От кого?',
                emailFromComment:
                    'Это имя будет отображаться во всех письмах, отправляемых участникам вебинара',
                useSignature: 'Использовать подпись в рассылке',
                signature: 'Введите текст подписи',
                useTemplateInvitation:
                    'Использовать собственный HTML-шаблон для письма приглашения',
                useTemplate: 'Использовать собственный HTML-шаблон для письма напоминания',
                template: 'Вставьте ваш код сюда',
                showTemplateResult: 'Посмотреть результат',
                macros: {
                    title: 'Набор макросов',
                    template: {
                        title: 'заголовок письма',
                        name: 'имя и фамилия участника-получателя письма (при наличии)',
                        webinarName: 'название мероприятия',
                        webinarDate:
                            'дата и время начала мероприятия в формате чч:мм (UTC +n) гггг-мм-дд',
                        imageUrl: 'ссылка на фоновое изображение мероприятия',
                        text: 'текст из настроек напоминания в редакторе мероприятия',
                        link: 'персональная ссылка участника (с токеном)',
                    },
                },
                useCustomSmtp: 'Использовать внешний SMTP-сервер',
                smtpLogin: 'Логин',
                smtpPassword: 'Пароль',
                smtpEmail: 'Электронная почта',
                smtpName: 'Имя отправителя',
                smtpHost: 'Сервер',
                smtpPort: 'Порт',
                smtpSSL: 'SSL шифрование',
                additionalAgreements: 'Дополнительные соглашения',
                additionalAgreementsDescription:
                    'Тексты доп. соглашений будут видны участнику в форме регистрации на вебинар',
                addAdditionalAgreement: 'Добавить соглашение',
                agreementsDefaultChecked: 'Установить чек-боксы по умолчанию',
                agreementsDefaultCheckedDescription:
                    'Чек-боксы в форме регистрации будут проставлены автоматически, пользователь сможет их снять.',
                additionalAgreementLabel: 'Текст дополнительного соглашения',
                additionalAgreementHint: 'Вы можете задавать ссылки в виде: [текст](ссылка)',
                agreementRequired: 'Обязательное соглашение',
                agreementRequiredHint:
                    'Участник не сможет зарегистрироваться на вебинар, если не примет это соглашение.',
                additionalAgreementMaxLength: 'Превышена максимальная длина',
                webinar: 'Вебинар',
                eventOrganizationName: 'Название организации во время трансляции',
                linksOrganizationName: 'Название компании в ссылках',
                linksOrganizationNameExist: 'Название компании уже занято.',
                linkPageAfterWebinar: 'Ссылка на страницу после вебинара',
                linkPageAfterWebinarComment:
                    'Эта ссылка будет использоваться для переадресации участников',
                showSocialNetworkButtons:
                    'Показывать кнопки социальных сетей на странице мероприятия',
                showQuestionButton: 'Показывать кнопку "задать вопрос" на странице мероприятия',
                questionsReplyTo: 'Отправлять вопросы на адрес',
                questionsReplyToComment:
                    'На этот Email будут приходить вопросы участников со страницы мероприятия',
                pauseBackgroundImage: 'Фон паузы',
                pauseBackgroundImageDescription:
                    'Используйте фирменный фон в вебинарной комнате для паузы. Формат SVG, PNG, TIFF, JPG.',
                brandColor: 'Фирменный цвет кнопок и ссылок',
                colorNumber: 'Номер цвета',
                reset: 'Вернуть по умолчанию',
                uploadFile:
                    'Чтобы прикрепить файл, перетяните его сюда или <0>выберите файл</0> с компьютера.',
                notFound: 'Не найден брендинг с таким Id',
                failed: {
                    delete: 'Не удалось удалить брендинг',
                    clone: 'Не удалось продублировать брендинг',
                    default: 'Не удалось задать брендинг по-умолчанию',
                },
                brandingFeatureRequest: {
                    title: 'Персонализируйте контент',
                    description:
                        'Настройте внешний вид контента, который вы показываете участникам: от писем до кнопок в записи. Это поможет придать уникальности вебинару и обеспечить узнаваемость бренда.',
                    submit: 'Запросить',
                },
                multibrandingFeatureRequest: {
                    title: 'Подключите шаблоны брендирования',
                    description:
                        'Чтоб использовать шаблоны брендирования, позволяющие применять разные стили оформления к мероприятиям, свяжитесь с менеджером',
                    submit: 'Отправить запрос',
                },
            },
            organizationPage: {
                invitationLimitReachedRequest: {
                    title: 'Достигнут лимит сотрудников в команде',
                    description:
                        'Вы не можете добавлять или приглашать по ссылке сотрудников в команду. Удалите лишних сотрудников или свяжитесь с менеджером, чтобы увеличить лимит.',
                },
            },
            apiPage: {
                title: 'API',
                text: 'API позволяет бесшовно интегрировать платформы Webinar со сторонними системами, такими как: CRM, системы автоматизации рассылок, LMS и другие. Для использования отправьте запрос на подключение. <0>Подробнее об API</0>',
                requested: 'Заявка отправлена. Менеджер скоро с вами свяжется.',
                action: 'Отправить запрос',
                key: 'Ключ',
                toApiDescription: 'Перейти к описанию API',
                keyRefresh: 'Обновить ключ',
                copy: 'Копировать',
                copyTooltip: 'Скопировать ключ',
                updateDialog: {
                    title: 'Обновление ключа API',
                    description:
                        'Внимание! Текущий ключ API будет аннулирован и заменен на новый. Перестанут работать интеграции с системами, которые были настроены ранее.',
                    cancel: 'Отмена',
                    renew: 'Обновить',
                },
            },
            webhooksPage: {
                title: 'Webhooks',
                addWebhook: 'Добавить',
                description:
                    'Вебхуки позволяют получить информацию о завершении события или о том, что все участники вышли.',
                learnMore: 'Узнать больше',
                example: {
                    title: 'Пример содержания уведомления',
                    description:
                        'Уведомление отправится на указанный URL-адрес в виде POST-запроса.\nТело запроса будет содержать данные в формате JSON.',
                    eventComment: 'Тип события',
                    occurredAtComment: 'Дата и время, когда произошло событие',
                    eventSessionIdComment: 'id мероприятия',
                },
                counter: 'Добавлено {{count}} из {{limit}} вебхуков.',
                counterWarning:
                    'Достигнут лимит на количество вебхуков. Пожалуйста, удалите ненужные адреса, чтобы добавить новые.',
                list: {
                    title: 'Название',
                    endpoint: 'Вебхук-адрес',
                    dateCreated: 'Дата добавления',
                    dateCreatedValue: '{{date, dd.MM.yyyy}} в {{date, HH:mm}}',
                    webhookAdded: 'Вебхук успешно добавлен',
                    webhookDeleted: 'Вебхук успешно удален',
                    undoDeletion: 'Отменить',
                },
                addWebhookDialog: {
                    title: 'Добавление вебхука',
                    nameField: 'Название',
                    endpointUrlField: 'Вебхук-адрес',
                    addButton: 'Добавить',
                    cancelButton: 'Отмена',
                    limitExceeded:
                        'Превышено ограничение на количество вебхуков. Организация может иметь только {{limit}} вебхуков.',
                    nameAlreadyExists: 'Вебхук с таким названием уже существует',
                    urlAlreadyExists: 'Вебхук с таким адресом уже существует',
                    urlWrongValue: `Некорректный вебхук-адрес. Вебхук-адрес должен начинаться с https://`,
                    maxLength: 'Превышена максимальная длина',
                },
            },
            sso: {
                title: 'Настройки SSO',
                subtitle:
                    'После настройки SSO сотрудники компании смогут авторизовываться под корпоративной учетной записью для доступа к мероприятиям или личному кабинету.',
                subtitleProtocol:
                    'Выберите протокол и настройте провайдер. Вы можете настроить только один протокол.',
                subtitleProvider: 'Введите эти данные в провайдер SSO',
                help: 'Узнать больше',
                saml: {
                    title: 'Выбор протокола и настройки провайдера идентификации',
                    idpProtocol: 'Протокол',
                    idpProvider: 'SSO провайдер',
                    idpEntityId: 'Идентификатор объекта (Identity Provider (idP) Entity ID)',
                    idpSingleSignOnServiceUrl: 'URL Системы единого входа (Sign-in page URL)',
                    idpSingleLogoutServiceUrl: 'URL Системы единого выхода (Sign-out page URL)',
                    idpX509certBase64: 'Сертификат (Identity provider certificate)',
                    spEntityId: 'Идентификатор объекта (Entity ID)',
                    spAssertionConsumerService: 'URL ACS',
                    edit: 'Изменить',
                    next: 'Далее',
                    cancel: 'Отмена',
                    back: 'Назад',
                    show: 'Показать',
                    hide: 'Скрыть',
                    wrongValue: 'Неверное значение',
                    checkSSO: 'Проверить работу',
                    loading: 'Выполняется проверка.<br/> Подождите...',
                    commonError: 'Что-то пошло не так. Обновите страницу и попробуйте еще раз.',
                    validationSuccessfulModal: {
                        title: 'Проверка прошла успешно!',
                        button: 'Отлично',
                    },
                    validationFailedModal: {
                        title: 'При проверке обнаружены ошибки',
                        text: 'В процессе проверки работы IDP были найдены ошибки. Ниже вы можете ознакомиться с логом проверки. Попробуйте изменить настройки и заново запустить проверку.',
                        cancelButton: 'Закрыть',
                        copyButton: 'Скопировать лог',
                    },
                },
                edit: {
                    course: {
                        label: 'Использовать SSO для ограничения доступа к курсам и вебинарам для всех аккаунтов "Организации"',
                        hint: 'Если включено - вход на курсы и вебинары, созданные любым аккаунтом, принадлежащим к вашей "Организации", будет возможен только с использованием SSO. Если вы хотите включить опцию только для определённых сотрудников, выберите их в списке ниже.',
                    },
                    login: {
                        label: 'Использовать SSO для входа в личный кабинет',
                        hint: 'Сотрудники вашей компании смогут войти в личный кабинет с помощью SSO без дополнительной регистрации на платформе. При первом входе будет произведена автоматическая регистрация, добавление в "Организацию" и подключение к тарифному плану вашей компании.',
                    },
                    signup: 'Регистрировать пользователей с подтверждёнными доменами моей организации только через SSO',
                },
                domain: {
                    title: 'Укажите домен электронной почты',
                    subtitle:
                        'Пользователи с указанным доменом электронной почты будут направлены на страницу вашего провайдера идентификации.',
                    label: 'Введите домен',
                    hint: 'Введите домен вашей электронной почты, например webinar.ru',
                    add: 'Добавить',
                    error: 'Неверно указан домен',
                    duplicate: 'Указанный домен уже добавлен',
                    approved: 'Подтвержден',
                    unapproved: 'Не подтвержден',
                    approve: 'Подтвердить',
                    delete: 'Удалить',
                    confirm: {
                        title: 'Подтверждение удаления',
                        text: 'Вы действительно хотите удалить домен {{domain}}?',
                    },
                    approveModal: {
                        title: 'Подтверждение домена',
                        text:
                            'Право собственности на доменное имя можно подтвердить через регистратора домена (как правило, это компания, у которой вы приобрели домен). У регистратора хранятся специальные настройки – записи DNS, которые направляют интернет-трафик на ваше доменное имя.<br /><br />' +
                            'Ниже указана проверочная запись TXT, которую нужно добавить в записи DNS регистратора домена. Наличие этой записи подтверждает, что домен действительно принадлежит вам. Проверочная запись не влияет на работу электронной почты или сайта. Если вы не можете подтвердить принадлежность домена через txt-запись, пожалуйста, обратитесь в техническую поддержку.',
                        confirm: 'Проверить txt-запись',
                        copyLink: 'Скопировать txt-запись',
                        success: 'TXT-запись успешно найдена.',
                        fail: 'TXT-запись не найдена. Обновление записей домена обычно происходит с задержкой со стороны провайдера, повторите попытку позднее. Максимальное время ожидания 72 часа.',
                        ok: 'Отлично',
                    },
                },
                memberships: {
                    title: 'Сотрудники',
                    search: 'Поиск',
                    name: 'Имя',
                    email: 'Электронная почта',
                },
            },
            myDevicesPage: {
                title: 'Мои устройства',
                subtitle:
                    'Раздел "Мои устройства" позволяет добавлять и сохранять список поддерживаемых устройств, которые работают по протоколу SIP.',
                myDevicesList: {
                    search: 'Поиск',
                    name: 'Название устройства',
                    address: 'Адрес SIP URI',
                    add: 'Добавить',
                    add_device: 'Добавить устройство',
                    add_device_success: 'Устройство успешно добавлено',
                    edit_device: 'Редактировать устройство',
                    edit_device_success: 'Устройство успешно сохранено',
                    edit: 'Редактировать',
                    delete: 'Удалить',
                    delete_success: 'Устройство удалено',
                    sipDeviceAlreadyExists: 'Устройство с таким адресом уже добавлено',
                    sipDeviceLimit: 'Достигнут лимит устройств',
                    sipDeviceOtherOrganization: 'Невозможно добавить данное устройство',
                    sipDeviceNotFound: 'Устройство не найдено',
                    searchNotFound: 'Ничего не найдено',
                    emptyDevices: 'Пока нет добавленных устройств',
                    maxLength: 'Превышена максимальная длина',
                },
                approve: 'Подтвердить',
                delete: 'Удалить',
                confirmRemove: {
                    title: 'Подтверждение удаления',
                    text: 'Вы действительно хотите удалить устройство {{device}}?',
                },
            },
            siem: {
                title: 'Настройки SIEM',
                subtitle: 'Для отправки логов организации в вашу SIEM-систему',
                SIEMSettingsForm: {
                    host: 'Адрес',
                    port: 'Порт',
                },
                success: 'Настройки SIEM были успешно обновлены',
            },
            pointOfPresencePage: {
                title: 'Медиавыносы',
                description:
                    'Активация медиавыноса позволяет снизить нагрузку на офисную сеть при проведении крупных мероприятий. ',
                switcherLabel: 'Активировать медиавынос',
                token: 'Токен',
                networks: 'Сети',
                host: 'Хост',
                refresh: 'Сгенерировать новый токен',
                errorLoading: 'Ошибка загрузки медиавыносов',
                errorSwitching: 'Ошибка переключения состояния',
                errorRefresh: 'Ошибка обновления токена',
                aboutLink: 'Узнать больше',
                state: 'Состояние',
                enabled: 'Активен',
                disabled: 'Неактивен',
            },
            apiKey: {
                keyRefreshing: 'Ключ обновляется',
                keyRefreshingDone: 'Ключ обновлён',
                keyRefreshingReject: 'Не удалось обновить ключ. Повторите попытку.',
            },
        },
        statistics: {
            title: 'Статистика',
            visits: 'Посещения',
            channels: 'Источники',
            participants: 'Участники',
            webinarSection: 'Вебинары',
            coursesSection: 'Курсы',
            coursesCountLabel: 'Кол-во курсов',
            activeStudentsCountLabel: 'Кол-во активных участников',
            averagePassingLabel: 'Средний прогресс прохождения',
            averagePassingHelper: '% курса, который в среднем проходит учащийся',
            finishedStudentsPartLabel: 'Конверсия в завершивших',
            finishedStudentsPartHelper: 'Доля учащихся, которые завершают обучение до конца',
            downloadXLS: 'Скачать в XLS',
            courseFilters: {
                chooseCourses: 'Выбрать курсы',
                allCourses: 'Все курсы',
                chosenCourses: 'Выбрано {count} курсов',
                searchPlaceholder: 'Поиск',
            },
            intervals: {
                allTime: 'За все время',
                byWeek: 'За неделю',
                byMonth: 'За месяц',
                byYear: 'За год',
                byPeriod: 'За период',
            },
            chooseDateRange: 'Выбрать период',
        },
        validate: {
            length: 'Недопустимое количество символов',
        },
        validation: {
            wrongPassword: 'Неверный пароль',
            required: 'Это поле не может быть пустым',
            phone: 'Кажется, вы ввели некорректный номер телефона',
            passwordTooShort: 'Пароль должен содержать не менее 8-ми символов',
            email: 'Неверный адрес электронной почты',
            nameFormat: 'Неверный формат. Можно использовать буквы, цифры, пробел, дефис и точку.',
            host: 'Адрес сервера содержит ошибки',
            port: 'Неверный порт',
            link: 'Неправильная ссылка',
            sipLink: 'Некорректный SIP URI',
            latin: 'Неверный формат. Можно использовать только латинские буквы.',
            noSpaces: 'Это поле не может содержать пробелы.',
            notDigitStart: 'Это поле не может начинаться с цифры.',
            onlyDigits: 'Поле может содержать только цифры',
            specificValue: 'Поле может содержать только {{count}} цифр',
            specificEnumValues: 'Поле может содержать {{firstCount}} или {{secondCount}} цифр',
        },
        core: {
            rateName: 'Тариф «{{name}}»',
            buyNewRate: 'ПОДКЛЮЧИТЬ НОВЫЙ ТАРИФ',
            updateRate: 'ОБНОВИТЬ',
            dueAt: 'Истекает {{date, date}}',
            dueAtWarning: 'Истекает {{date, date}} — через {{daysRemaining}} дней',
            dueAtInfinity: 'Истекает ∞',
            files: 'Файлы',
            fileSizeUsed: '{{size, filesize}} используется',
            fileSizeMax: 'из {{size, filesize}}',
            loadNewPhoto: 'Загрузить новую фотографию',
            delete: 'Удалить',
            dropHere: 'Перетащите сюда',
            rateOutOfSpace: 'Недостаточно места для новых файлов',
            spaceLimitClose: 'Мало места для новых файлов',
            hours: 'Часы',
            hoursUsed: '{{count}} использовано',
            hoursMax: 'из {{count}}',
        },
        onboarding: {
            signup: 'Вы зарегистрированы',
            trainingWebinar: 'Обучающий вебинар',
            repeatTrainingWebinar: 'Повторить',
            onProfileFill: 'Заполнение профиля',
            onProfileFill_info:
                'Для перехода в профиль, нажмите на изображение аватара в правом верхнем углу и в выпадающем списке выберите «Профиль»',
            onEventCreate: 'Создание вебинара',
            onEventCreate_info:
                'Нажмите на "Запланировать" и следуя инструкциям создайте свой первый вебинар: добавьте ведущих и информацию о вебинаре, пригласите участников и загрузите файлы',
            onRecord: 'Запись вебинара',
            onRecord_info:
                'Перейдите во вкладку завершенного вебинара, посмотрите его запись, разрешите просмотр для других участников вебинара. При необходимости запись можно отредактировать',
            onStat: 'Статистика вебинара',
            onStat_info:
                'Нажмите кнопку меню на верхней панели, перейдите в раздел «Статистика» и посмотрите информацию о проведенных вебинарах и участниках',
            NStepsFromM: '{{n}} из {{m}}',
            taskDescription: 'Выполните все задания и получите 2 Гб дополнительного места',
            trainingWebinarCreateFail: 'Что-то пошло не так',
        },
        checklist: {
            offers: {
                involvementAndMarketing3Months:
                    'Получите пакет "Маркетинг" и "Вовлечение" бесплатно на 3 месяца',
            },
            tasks: {
                inviteEmployee: 'Добавьте коллегу в организацию',
                scheduleEventSession: 'Запланируйте мероприятие',
                holdEventSessionWith2Guests: 'Проведите встречу с двумя и более коллегами',
                uploadFile: 'Добавьте какие-либо файлы в файловое хранилище',
                addContact: 'Добавьте контакты в адресную книгу',
            },
            completeTasksLeft: {
                commonLabel_0: 'На выполнение заданий остался',
                commonLabel_1: 'На выполнение заданий осталось',
                commonLabel_2: 'На выполнение заданий осталось',
                days_0: '{{ count }} день',
                days_1: '{{ count }} дня',
                days_2: '{{ count }} дней',
            },
            congratsDialog: {
                title: 'Поздравляем!',
                defaultText:
                    'Вы выполнили все задания и вам достался лутбокс «Пакет «Вовлечение» и «Маркетинг» бесплатно на полгода». Теперь в течение 6 месяцев вам доступны фича один и фича два.',
                actionButton: 'Отлично!',
            },
        },
        notifications: {
            CHAT_EXPORT_body: `Сообщения чата мероприятия «{{data.abstractEvent.name}}» успешно экспортированы в файл`,
            CHAT_EXPORT_title: `Чат готов к скачиванию`,
            CONTACTS_EXPORT_body: `Контакты экспортированы и доступны для скачивания.`,
            CONTACTS_EXPORT_title: `Контакты экспортированы`,
            DEFAULT_REMINDER_body: `{name}, напоминаем, что Вы зарегистрированы на вебинар.`,
            DEFAULT_REMINDER_title: `Напоминание о вебинаре "{{ webinar_name }}"`,
            EMAIL_QUESTION_body1:
                'Пользователь <0>{{data.name}} {{data.secondName}}</0> задал вопрос:',
            EMAIL_QUESTION_body2: '{{data.question}}',
            EMAIL_QUESTION_body3: '<0>Email:</0> {{data.email}}',
            EMAIL_QUESTION_title: `Вопрос к вебинару "{{data.title}}" `,
            EMAIL_QUESTION_COURSE_title: `Вопрос к курсу "{{data.title}}" `,
            EMAIL_QUESTION_LESSON_title: `Вопрос по уроку "{{data.title}}" `,
            EVENT_PARTICIPANTS_LIMIT_EXCEEDED_body: `Достигнуто максимальное число {{data.limit}} участников {{data.type}} {{data.name}}`,
            EVENT_PARTICIPANTS_LIMIT_EXCEEDED_title: `Достигнут лимит участников`,
            EVENTSESSION_PARTICIPANTS_LIMIT_EXCEEDED_body: `Достигнуто максимальное число {{data.limit}} участников {{data.type}} {{data.name}}`,
            EVENTSESSION_PARTICIPANTS_LIMIT_EXCEEDED_title: `Достигнут лимит участников`,
            JOURNAL_SELECTION_body: `Выгрузка из журнала готова и доступна для скачивания`,
            JOURNAL_SELECTION_title: `Выгрузка из журнала готова`,
            OLOLO_body: `Ololo, {{user.name}}`,
            OLOLO_title: `Ололо`,
            ORGANIZATION_INVITATION_body: `Вас пригласили к участию в организации «{{data.organizationName}}» в роли ведущего.<br/>После подтверждения участия вы можете создать свои онлайн-мероприятия и редактировать существующие. Читайте подробнее о ваших возможностях  в личном кабинете.<br/>Обращаем внимание, что после присоединения к организации вы теряете права на создаваемые вебинары и файлы, а так же не сможете управлять своим аккаунтом.`,
            ORGANIZATION_INVITATION_title: `Приглашение в организацию`,
            PAID_OPTION_body: `{{data.body}}`,
            PAID_OPTION_title: `{{data.title}}`,
            PAID_PRODUCT_body: `Пакет {{data.name}}, будет активирован {{data.date}}`,
            PAID_PRODUCT_title: `Подключен пакет`,
            PARTICIPANTS_LIMIT_EXCEEDED_body: `Достигнут лимит участников вебинара {{data.name}}`,
            PARTICIPANTS_LIMIT_EXCEEDED_title: `Превышен лимит одновременных участников`,
            PARTICIPATIONS_LIMIT_EXCEEDED_UNKNOWN_LIMIT_body: `Достигнуто максимальное число участников {{data.type}} {{data.name}}`,
            PARTICIPATIONS_LIMIT_EXCEEDED_UNKNOWN_LIMIT_title: `Достигнут лимит участников`,
            QUESTIONS_EXPORT_body: `Вопросы мероприятия «{{data.abstractEvent.name}}» успешно экспортированы в файл.`,
            QUESTIONS_EXPORT_title: `Вопросы готовы к скачиванию`,
            RECORD_CONVERTED_body: `Сконвертирована запись вебинара {{data.eventSession.name}}`,
            RECORD_CONVERTED_title: `Сконвертирована запись вебинара`,
            STATISTICS_CONVERTED_body: `Статистика сконвертирована и доступна для скачивания.`,
            STATISTICS_CONVERTED_title: `Статистика сконвертирована`,
            STATS_EXPORT_body: `Статистика выгружена и доступна для скачивания.`,
            STATS_EXPORT_title: `Статистика выгружена`,
            STATS_PREEXPORT_body: `Список участников выгружен и доступен для скачивания.`,
            STATS_PREEXPORT_title: `Список участников выгружен`,
            STORAGE_MAX_SIZE_INCREASED_body: `Размер файлового пространства увеличен до {{data.maxStorage}}Gb`,
            STORAGE_MAX_SIZE_INCREASED_title: `Файловое пространство увеличено`,
            STORAGE_SIZE_LOW_body: `У пользователя {{data.name}} осталось мало места`,
            STORAGE_SIZE_LOW_title: `Осталось мало места`,
            TARIFF_ADDED_body: `Подключена новая подписка {{data.tariffName}}`,
            TARIFF_ADDED_title: `Добавлена подписка`,
            TARIFF_ADDED_USER_body: `Подключена новая подписка {{data.tariffName}}`,
            TARIFF_ADDED_USER_title: `Добавлена подписка`,
            TARIFF_EXPIRED_body: `Тариф истёк у следующих пользователей: {{data.users}}`,
            TARIFF_EXPIRED_title: `Истекли тарифы`,
            TARIFF_EXPIRED_USER_body: `Уважаемый {{user.name}}, ваш тариф  {{data.tariffName}}  истёк`,
            TARIFF_EXPIRED_USER_title: `Истёк тариф`,
            TARIFF_EXPIRES_body: `Тариф истекает у следующих пользователей: {{data.users}}`,
            TARIFF_EXPIRES_title: `Истекают тарифы`,
            TARIFF_EXPIRES_USER_body: `Уважаемый {{user.name}}, ваш тариф истекает {{data.expires}}`,
            TARIFF_EXPIRES_USER_title: `Истекает тариф`,
            TARIFF_EXTENDED_body: `Cрок действия подписки {{data.tariffName}} продлен`,
            TARIFF_EXTENDED_title: `Продлен срок действия подписки`,
            TARIFF_EXTENDED_USER_body: `Срок действия подписки {{data.tariffName}} продлен`,
            TARIFF_EXTENDED_USER_title: `Продлен срок действия подписки`,
            TARIFF_OPTION_ADDED_body: `Услуга {{data.optionName}} успешно подключена`,
            TARIFF_OPTION_ADDED_title: `Услуга подключена`,
            TARIFF_OPTION_ADDED_USER_body: `Услуга {{data.optionName}} успешно подключена`,
            TARIFF_OPTION_ADDED_USER_title: `Услуга подключена`,
            TARIFF_OPTION_REMOVED_USER_body: `Услуга {{data.optionName}} отключена`,
            TARIFF_OPTION_REMOVED_USER_title: `Услуга отключена`,
            TARIFF_REMOVED_body: `Подписка {{data.tariffName}} отключена`,
            TARIFF_REMOVED_title: `Подписка отключена`,
            TARIFF_REMOVED_USER_body: `Подписка  {{data.tariffName}} отключена`,
            TARIFF_REMOVED_USER_title: `Подписка отключена`,
            TRANSFER_DATA_OFFER_body: `Продолжайте работу с уже созданными вебинарами, файлами и контактами, работайте с тестами и опросами, просматривайте и редактируйте онлайн и сконвертированные записи.`,
            TRANSFER_DATA_OFFER_title: `Перенесите свои данные`,
            WEBINAR_NEAR_TO_STOP_body: `Вебинар {{data.eventSession.name}} автоматически завершиться через 5 минут.`,
            WEBINAR_NEAR_TO_STOP_title: `Вебинар скоро завершиться`,
            WEBINAR_AUTOMATICALLY_STOPPED_body: `Вебинар {{data.eventSession.name}} автоматически завершен по причине отсутствия активности более 15 минут.`,
            WEBINAR_AUTOMATICALLY_STOPPED_title: `Вебинар завершен.`,
            STATS_COURSES_EXPORT_title: `Статистика по курсам выгружена`,
            STATS_COURSES_EXPORT_body: `Статистика по курсам выгружена и доступна для скачивания.`,
            ATTEMPT_TO_JOIN_ORGANIZATION_FOR_ADMIN_title:
                'Ваши коллеги пытались присоединиться к команде',
            USER_JOINED_ORGANIZATION_title:
                'Вы успешно добавлены в команду {{data.organization_name}}',
            continue: 'Продолжить',
            answerSubmit: 'Ответить',
            answerLabel: 'Ответ',
            emptyContent: 'Нет уведомлений',
            today: 'Сегодня',
            yesterday: 'Вчера',
            new: 'Новое',
            earlier: 'Ранее',
            dateSettingValue: '{{date, dd.MM.yyyy}}',
        },
        banners: {
            switchEventType: 'Переключить на «вебинар»',
            meetingParticipantsLimit:
                'Достигнуто предельное число({{limit}}) участников совещания.',
            fileSize90AutoRecord:
                'Место заканчивается. Функция записи мероприятий будет недоступна.',
            fileSize90: 'Заканчивается свободное место. Удалите лишние файлы или увеличьте объем.',
            fileSize100AutoRecord:
                'Закончилось место для записей и файлов. Освободите или увеличьте объем хранилища.',
            fileSize100: 'Нет свободного места. Удалите лишние файлы или увеличьте объем.',
            buyRate: 'Подключить тариф',
            buyFileSize: 'Увеличить объем',
            newFileSizeAvailable: 'Доступно свободное место',
            payUsTitle: 'Используй вебинары на все 100%',
            webrtcHelp: 'Помощь',
            webrtcTestUnsupportedBrowserBanner:
                'Ваш браузер не поддерживает технологии, необходимые для проведения  мероприятий.',
            webrtcTestUnsupportedBrowserSummary: 'Пожалуйста, смените браузер',
            webrtcTestUnsupportedBrowserDesc:
                'Ваш браузер не поддерживает технологии, необходимые для проведения мероприятий. Для выхода в эфир рекомендуется использовать последнюю версию Google Chrome.',
            webrtcTestNetworkBlockedBanner:
                'В вашей сети обнаружены неполадки, мешающие проведению мероприятий.',
            webrtcTestNetworkBlockedSummary: 'Нет связи',
            webrtcTestNetworkBlockedDesc:
                'Убедитесь, что в вашей сети разблокирован 443 порт (обязательно по протоколу TCP, а не только HTTPS) для подсетей Webinar 37.130.192.0/22, 185.45.80.0/22 и ознакомьтесь с техническими требованиями.',
            webrtcTestNetworkBlockedDescBox:
                'Убедитесь, что в вашей сети разблокированы порты 443:TCP/UDP, 1030:TCP/UDP к {{hosts}} и ознакомьтесь с техническими требованиями.',
            webrtcTestNetworkBanner:
                'Обнаружены неполадки, которые могут помешать проведению мероприятий.',
            webrtcTestNetworkSummary: 'Не удалось подключиться к серверу',
            webrtcTestNetworkDesc:
                'Автоматический анализ сети показал наличие ошибки. Пожалуйста обратитесь в поддержку. Убедитесь, что в вашей сети разблокирован 443 порт (обязательно по протоколу TCP, а не только HTTPS) для подсетей Webinar 37.130.192.0/22, 185.45.80.0/22 и ознакомьтесь с техническими требованиями.',
            webrtcTestNetworkDescBox:
                'Автоматический анализ сети показал наличие ошибки. Убедитесь, что в вашей сети разблокирован порты 443:TCP/UDP, 1030:TCP/UDP к {{hosts}} и ознакомьтесь с техническими требованиями.',
            webrtcTestUnknownSummary: 'Неизвестная ошибка',
            webrtcTestUnknownDesc:
                'Автоматический анализ подключения показал наличие неизвестной ошибки. Она может помешать проведению мероприятий. Пожалуйста, обратитесь в поддержку, чтобы сотрудники могли детально исследовать причину ошибки.',
            webrtcTestContactSupport: 'Обратиться в поддержку',
            webrtcTestHelp: 'Требования',
            webrtcTestDownloadChrome: 'Скачать Chrome',
            webrtcTestHideWarningTitle: 'Можно скрыть предупреждение, но ошибка останется',
            webrtcTestHideWarningText1:
                'Обнаружена ошибка, мы беспокоимся, что она может повлиять на успешное проведение ваших мероприятий.',
            webrtcTestHideWarningText2: 'Рекомендуем обратиться в поддержку.',
            webrtcTestFixWarning: 'Устранить неполадки',
            webrtcTestHideWarning: 'Скрыть предупреждение',
            webrtcTestSupportContacts:
                'Поддержка Webinar: +7 495 118-44-44, <0>support@webinar.ru</0>',
            endlessMeetingsPromo:
                'Новинка!  Постоянные встречи — встречи, доступные всегда по ссылке.',
            tryAction: 'Попробовать',
            signupOffer: {
                text: 'Проводите свои мероприятия. Зарегистрируйтесь, чтобы получить все премиум опции и проводить встречи и вебинары',
                cancel: 'Не интересно',
                action1: 'Зарегистрироваться',
                action2: 'Проводить свои мероприятия',
            },
        },
        eventEditor: {
            errors: {
                validation: {
                    required: {
                        name: 'Название не может быть пустым',
                    },
                    rules: {
                        noMoreOneDash: 'Нельзя использовать 2 символа "_", "-" подряд',
                        onlyMidleDash: 'Нельзя использовать "_", "-" в начале и конце',
                        noOnlyNumbers: 'Ссылка должна содержать цифры и буквы',
                        noOtherSymbols: 'Нельзя использовать символы, кроме букв, цифр, "-", "_"',
                    },
                },
                urlAliasNotUnique: 'Ссылка "{{url}}" уже используется',
            },
            copyLink: 'Скопировать ссылку',
            copySipLink: 'Копировать URI',
            copySipLinkHint: 'SIP URI будет сгенерирован после нажатия "Сохранить"',
            editLink: 'Отредактировать ссылку',
            editLinkDescription:
                'Редактируемая ссылка должна обязательно содержать цифры и буквы. Например, MegaMeet-08-03',
            dateSettingValue: '{{date, dd.MM.yyyy}}',
            filesTooltip: 'Файлы',
            settingsTooltip: 'Настройки',
            streamSettingsTooltip: 'Дополнительные настройки',
            name: 'Название мероприятия',
            save: 'Сохранить',
            saved: 'Сохранено',
            delete: 'Удалить',
            goToEvent: 'Перейти к вебинару',
            loadNewBackImage: 'Загрузить новую',
            resetBackImage: 'Вернуть стандартную',
            description: 'Описание',
            announceFileHasBeenAdded: 'Файл {{data.fileName}} уже добавлен к описанию',
            participants: 'Участники',
            eventLink: 'Ссылка на мероприятие',
            timePickerTitle: 'Время проведения',
            find: 'Найти',
            selectAll: 'Выбрать все',
            deselectAll: 'Убрать выделение',
            inviteContact: 'Пригласить',
            invited: 'Приглашен',
            roleGuest: 'Гость',
            roleAdmin: 'Администратор',
            roleLecturer: 'Ведущий',
            preparedForInvite: 'Будет приглашен',
            onmoderation: 'На модерации',
            participantName: 'Имя',
            participantEmail: 'Почта',
            participantRole: 'Роль',
            participantStatus: 'Статус',
            noParticipants: 'Участников нет',
            noSearchedParticipants: 'Нет участников соответствующих условиям поиска',
            inviteByEmail: 'Пригласить по почте',
            makeParticipantGuest: 'Сделать гостем',
            makeParticipantLecturer: 'Сделать ведущим',
            makeParticipantAdmin: 'Сделать администратором',
            sendInviteAgain: 'Отправить ссылку повторно',
            blockParticipant: 'Заблокировать',
            deleteParticipant: 'Удалить',
            cancelInvite: 'Отменить приглашение',
            makeGuestDone: 'Успешно сделали гостем',
            makeLecturerDone: 'Успешно сделали ведущим',
            makingAdmin: 'Делаем администратором',
            makeAdminDone: 'Успешно сделали администратором',
            inviteSent: 'Ссылка отправлена',
            participantDeleted: 'Участник удален',
            participantBlocked: 'Участник заблокирован',
            filterParticipantsAll: 'Все',
            filterParticipantsOnModeration: 'На модерации',
            filterParticipantsInvited: 'Приглашенные',
            filterParticipantsPreparedForInvite: 'Подготовленные к приглашению',
            saveDialogTitle: 'Сохранение изменений',
            saveDialogText: 'Вы изменили настройки мероприятия. Сохранить изменения?',
            saveDialogActions: {
                event: 'Для всей серии',
                eventSession: 'Для этого мероприятия',
            },
            deleteDialog: {
                title: 'Удаление',
                text: 'Подтвердите удаление мероприятия',
                actionSessionWebinar: 'Удалить вебинар',
                actionSessionMeeting: 'Удалить встречу',
                actionEvent: 'Удалить серию',
            },
            changeRuleDialogTitleRule: 'Изменение графика',
            changeRuleDialogTitleDate: 'Изменение даты начала серии',
            changeRuleDialogTextRule:
                'Вы изменили график проведения мероприятия.<br/>В соответствии с новым правилом это мероприятие будет перенесено на {{date, d MMMM yyyy}}, а последующие согласно правилу «{{rule}}»',
            changeRuleDialogTextRuleSingle:
                'Вы изменили график проведения мероприятия.<br/>В соответствии с новым правилом это мероприятие будет перенесено на {{date, d MMMM yyyy}}, а последующие мероприятия будут удалены',
            changeRuleDialogTextDate:
                'Вы изменили дату начала серии мероприятий.<br/>Первое мероприятие в серии будет перенесено на {{date, d MMMM yyyy}}',
            settings: {
                durationPlaceholder: 'Укажите длительность встречи',
                registrationFieldPlaceholder: 'Название поля',
                createNewField: 'Добавить поле',

                title: 'Настройки',
                date: 'Дата проведения',
                duration: 'Продолжительность',
                durationOhter: 'Другая',
                serial: 'Серия',
                repeat: {
                    'FREQ=DAILY;COUNT=1': 'Не повторять',
                    'FREQ=DAILY': 'Каждый день',
                    'FREQ=WEEKLY': 'Каждую неделю',
                    'FREQ=WEEKLY;INTERVAL=2': 'Каждые две недели',
                    'FREQ=MONTHLY': 'Каждый месяц',
                    CUSTOM: 'Свой график',
                },
                repeatWeekly_0: 'Каждую неделю',
                repeatWeekly_1: 'Каждые {{num}} недели',
                repeatWeekly_2: 'Каждые {{num}} недель',
                repeatWeekend: 'По выходным',
                repeatWorkDays: 'По будням',
                repaetRestriction: {
                    date: 'Ограничение по дате',
                    count: 'Ограничение по кол-ву вебинаров',
                },
                repaetBefore: 'до',
                repeatTimes: 'Количество вебинаров',
                totalEvents_0: '{{num}} мероприятие',
                totalEvents_1: '{{num}} мероприятия',
                totalEvents_2: '{{num}} мероприятий',
                eventRegistration: 'Приглашать на всю серию',
                enterType: 'Вход на мероприятие',
                freeAccess: 'Свободный вход',
                freeAccessComment:
                    'Участникам не требуется регистрация, достаточно указать имя в чате',
                registration: 'Регистрация',
                registrationComment:
                    'Участникам потребуется заполнить форму регистрации при входе на мероприятие. По завершении можно сделать выгрузку с данными участников в личном кабинете.',
                moderationComment: 'Необходимо одобрить вход участника после его регистрации',
                password: 'Пароль для входа',
                passwordComment: 'Пароль участникам необходимо сообщить отдельно',
                tags: 'Теги',
                cost: 'Стоимость, ₽',
                costSerial: 'Стоимость серии, ₽',
                owner: 'Владелец мероприятия',
                language: 'Язык интерфейса мероприятия',
                languageHelperText:
                    'Влияет только на интерфейс вашего мероприятия, не затрагивая личный кабинет пользователя. <0>Подробнее</0>',
                reminders: 'Напоминания',
                remindersTitle: 'Напоминания для участников',
                remindersTimeOffsetField: 'Выслать за',
                remindersTimeOffsetFieldAfter: '',
                remindersTitleLabel: 'Заголовок',
                remindersTemplateLabel: 'Шаблон сообщения',
                remindersActiveLabel: 'Включить рассылку',
                reminderDisabled: 'Отключено',
                reminderLength: 'За {{number}} {{unit}}',
                reminderTitle: 'Напоминание {{index}}',
                reminderSent: 'Отправлено',
                remindersSet: 'Установить напоминания',
                remindersTemplateRules:
                    'Набор макросов:\n{{webinar_name}} — вставляет название мероприятия\n{{name}} — вставляет имя и фамилия участника-получателя письма (при наличии)\n{{status}} — вставляет статус регистрации участника "приглашены" или "зарегистрированы"',
                remindersminute_0: 'минуту',
                remindersminute_1: 'минуты',
                remindersminute_2: 'минут',
                remindershour_0: 'час',
                remindershour_1: 'часа',
                remindershour_2: 'часов',
                remindersday_0: 'день',
                remindersday_1: 'дня',
                remindersday_2: 'дней',
                remindersweek_0: 'неделю',
                remindersweek_1: 'недели',
                remindersweek_2: 'недель',
                remindersmonth_0: 'месяц',
                remindersmonth_1: 'месяца',
                remindersmonth_2: 'месяцев',
                defaultReminderTitle: 'Напоминание о вебинаре "{{webinar_name}}"',
                defaultReminderContent: `{{name}},\n напоминаем, что Вы {{status}} на вебинар.`,
                defaultMeetingReminderTitle: 'Напоминание о встрече "{{webinar_name}}"',
                defaultMeetingReminderContent: `{{name}},\n напоминаем, что Вы {{status}} на встречу.`,
                ru: 'Русский',
                en: 'English',
                encoder: 'Вещание через энкодер',
                encoderPopup: {
                    title: 'Настройки вещания через энкодер',
                    server: 'Адрес сервера (FMS URL)',
                    name: 'Имя потока',
                    warning: 'Требуется использовать кодеки ACC для звука и H.264 для видео',
                    help: 'Как настроить энкодер?',
                },
                additionalFieldsName: 'Имя',
                additionalFieldsSecondName: 'Фамилия',
                additionalFieldsEmail: 'Email',
                additionalFieldsPhone: 'Телефон',
                additionalFieldsCity: 'Город',
                additionalFieldsCompany: 'Компания',
                additionalFieldsPosition: 'Должность',
                additionalFieldsSource: 'Откуда вы о нас узнали?',
                pickAdditionalFields: 'Выберите поля для добавления в форму регистрации',
                additionalFieldsCustom: 'Свои поля',
                newAdditionalFieldAnswer: 'Новый вариант ответа',
                newAdditionalTextField: 'Простое поле',
                newAdditionalRadioField: 'Поле с заданными ответами',
                fieldsDialogTitle: 'Параметры регистрации',
                openFieldsDialog: 'Настроить',
                registrationWithModeration: 'Регистрация с модерацией',
                moderationSettingLabel:
                    'Модерация самостоятельно зарегистрировавшихся пользователей',
                branding: 'Брендирование',
                brandingCreateNote: 'Создайте новый шаблон в разделе <0>Брендирование</0>',
                autostart: {
                    title: 'Запуск встречи',
                    switchFieldTitle: 'Автозапуск',
                    helperText:
                        'При активации мероприятие само запустится в указанное время старта. Возможно отклонение на +/- 2 мин.',
                },
                waitingRoom: {
                    switchFieldTitle: 'Зал ожидания',
                    helperText:
                        'Участники смогут присоединиться только после подтверждения организатором',
                },
            },
            streamsettings: {
                background: 'Фоновое изображение',
                general: 'Общие',
                guestAllowed: 'Разрешить участникам:',
                title: 'Дополнительно',
                chatmoderation: 'Модерация чата и вопросов',
                chatmoderationHelperText:
                    'Сообщения от участников будут видны в чате после проверки модератором или администратором',
                allowGuestOnAir: 'Выходить в эфир',
                allowGuestOnAirHelperText: 'У участника появится кнопка выхода в эфир',
                joinWithEnabledMicrophone: 'Подключаться с включенным микрофоном',
                joinWithEnabledMicrophoneHelperText:
                    'Чтобы не отвлекать участников от встречи посторонними звуками отключите эту опцию',
                viewModeLabel: 'Отображение видео спикера и участников',
                viewModeClassicComment:
                    'Мозаика — миниатюры видео участников и спикера одинакового размера.',
                viewModeActiveSpeakerComment:
                    'В центре внимания — видео спикера занимает всю зону презентера. При включённой презентации видео спикера находится рядом с ней.',
                manageFiles: 'Управлять файлами',
                manageFilesHelperText:
                    'Участники смогут добавлять файлы и управлять ими в презентере',
                uploadFiles: 'Загружать файлы',
                uploadFilesHelperText: 'Участники могут добавлять, удалять и скачивать файлы',
                demonstrateFiles: 'Демонстрировать файлы',
                demonstrateFilesHelperText:
                    'Участники могут демонстрировать файлы и управлять чужими презентациями',
                allowScreensharing: 'Показывать экран',
                allowScreensharingHelperText: 'У участника появится кнопка показа экрана',
                mediaControls: 'Управлять медиа контентом',
                mediaControlsHelperText: 'Участник сможет управлять видео плеером',
                allowUseChat: 'Писать сообщения в чат',
                showMinichat: 'Показывать мини-чат',
                allowUseQuestions: 'Задавать вопросы',
                canSeeParticipnants: 'Видеть список участников',
                onAirWithConfirmation: 'С подтверждением',
                onAirFree: 'Автоматически',
                standartVideoSize: 'Стандартный размер видео',
                largeVideoSize: 'Крупный размер видео',
                useActiveSpeaker: '100 участников во встрече',
            },
            files: {
                title: 'Файлы',
                addFiles: 'Добавить',
                openFile: 'Открыть',
                downloadFile: 'Скачать',
                unshareFile: 'Запретить скачивание',
                shareFile: 'Разрешить просмотр',
                removeFile: 'Открепить от вебинара',
            },
            importInProgress: 'Импортируем',
            importDone: 'Импорт завершен',
            import: 'Импортировать',
            step1Import: 'Скачайте шаблон для импорта',
            step2Import: 'Заполните шаблон информацией о людях',
            step3Import: 'Импортируйте готовый шаблон на сайт',
            downloadImportTemplate: 'Скачать шаблон',
            importError: 'При импорте контактов произошла ошибка',
            importErrorBadFile: 'Файл поврежден',
            importErrorNoContacts: 'В файле не найдены контакты',
            importErrorTooManyContacts: 'Нельзя загрузить больше 30 тысяч контактов за раз',
            importSidebarText:
                'Пригласите людей из адресной книги, по почте или <0>импортируйте</0> свои контакты',
            contactsSidebarHeader: 'Пригласите участников',
            cantInviteLectorWithEmail: 'Участник с такой электронной почтой уже приглашен',
            addNewLectorCandidate: 'Добавить нового ведущего',
            noLectorCandidatesWithQuery: 'Не найдено ведущих соответствующих условию поиска',
            noLectorCandidates: 'Нет ведущих',
            lectorsSendInviteLabel: 'Отправить приглашение',
            lectorsEmailLabel: 'Email',
            lectorsShowOnLandingLabel: 'Показывать на лендинге',
            lectorsMakeAdminLabel: 'Сделать администратором',
            lectorSecondNameLabel: 'Фамилия',
            lectorNameLabel: 'Имя',
            lectorPositionLabel: 'Должность',
            lectorDescriptionLabel: 'Описание',
            lectorsSearchPlaceholder: 'Введите имя или электронную почту',
            lectorsListHeader: 'Ведущие',
            lectorsListEmpty: 'Ведущие не будут отображаться на лендинге',
            lectorsWithInviteHelper: 'Ведущий получит письмо со ссылкой для входа',
            lectorsWithoutInviteHelper: 'Ведущий будет добавлен к описанию мероприятия',
            lectorsMakeAdminHelper:
                'Администратор может завершить мероприятие.<0></0> <1>Подробнее о правах</1>',
        },
        events: {
            title: 'Вебинары',
            schedule: 'Запланировать',
        },
        selectedLabel: 'Выбрано {{selectedNumber}}',
        fs_folder_record: 'Записи',
        fs_folder_record_converted: 'Сконвертированные записи',
        fs_folder_test: 'Тесты и голосования',
        fs_folder_homework: 'Домашние задания',
        fs_folder_banner: 'Баннеры',
        fs_folder_background: 'Фоны',
        fs_folder_endless_record: 'Постоянные встречи',
        fs_folder_agenda: 'Заметки',
        fs_folder_course_test: 'Тесты для курсов',
        filesize: '{{size, filesize}}',
        date: '{{date, date}}',
        timeDuration: '{{time, timeDuration}}',
        filesizeUnit_b: 'Б',
        filesizeUnit_kb: 'КБ',
        filesizeUnit_mb: 'МБ',
        filesizeUnit_gb: 'ГБ',
        filesizeUnit_tb: 'ТБ',
        abort: 'Отменить',
        apply: 'Применить',
        back: 'Назад',
        continue: 'Продолжить',
        goto: 'Перейти',
        ready: 'Готово',
        send: 'Отправить',
        delete: 'Удалить',
        change: 'Изменить',
        or: 'или',
        wrongFileType: 'Неверный тип файла',
        invitesInProgress: 'Приглашение участников...',
        webinarNearEnd:
            'Вебинар «{{name}}» будет остановлен меньше чем через {{seconds, timeDuration}} минут',
        webinarAutoEnd: 'Вебинар «{{name}}» был автоматически остановлен',
        goToWebinar: 'Перейти',
        pageNotFound: {
            title: 'К сожалению, страница не найдена',
            comment: 'Организатор удалил мероприятие или запись, либо вы ввели неверный адрес.',
            goToInstructions: 'Смотреть инструкции',
            goToSite: 'Хотите организовать вебинар?',
        },
        download: 'Скачать',
        statsPrepareReady: 'Файл подготовлен',
        statsPrepare: 'Идёт подготовка файла..',
        statsPrepareExplain: 'Вы можете уйти со страницы. Ссылка появится в уведомлениях.',
        shareRecord: 'Поделиться записью',
        landing: {
            askQuestion: 'Задать вопрос',
            askQuestionTitle: 'Задать вопрос ведущему',
            eventWiilStartAt: 'Мероприятие начнется: <0/>',
            eventStarted: 'Мероприятие началось',
            eventEnded: 'Мероприятие завершено',
            joinEvent: 'Подключиться',
            register: 'Зарегистрироваться',
            eventEnterDialogTitle: 'Вход на мероприятие "{{name}}"',
            eventEnterDialogLabel: 'Пожалуйста, представьтесь',
            eventEnterDialogSubmitText: 'Подключиться',
            alreadyEnteredWarningDialog: {
                title: 'Внимание!',
                content:
                    'Вы входите под учетными данными или по персональной ссылке пользователя, который уже в мероприятии. Вход возможен только под одним аккаунтом. Если вы продолжите, то другой пользователь будет удален с мероприятия.',
                submit: 'Продолжить',
            },
            eventRegisterDialog: {
                title: 'Регистрация на вебинар "{{name}}"',
                submit: 'Зарегистрироваться',
                email: 'Электронная почта',
                name: 'Имя',
                secondName: 'Фамилия',
                acceptConditionsComment:
                    'Нажимая на кнопку «Зарегистрироваться», вы акцептуете условия <0>оферты</0>, соглашаетесь <1>на обработку персональных данных</1> и связь с вами способами, указанными в оферте, в целях исполнения условий оферты.',
                validation: {
                    nameMaxLength: 'Имя должно содержать не более {{max}} символов',
                    secondNameMaxLength: 'Фамилия должна содержать не более {{max}} символов',
                },
            },
            requireParticipantPasswordDialog: {
                title: 'Необходима авторизация',
                submit: 'Войти',
                cancel: 'Войти по другими данными',
                description: 'Для входа в вебинар, введите пароль от учетной записи {{email}}',
                passwordPlaceholder: 'Введите пароль',
                wrongPassword: 'Неверный пароль',
                recoverPassword: 'Восстановить пароль',
            },
            passwordRecoveryDialog: {
                title: 'Забыли пароль?',
                submit: 'Восстановить пароль',
                label: 'Электронная почта',
                cancel: 'Вернуться к авторизации',
                noSuchUser: 'Мы не нашли такого адреса почты',
                successTitle: 'Пароль восстановлен!',
                successContent:
                    'Письмо с инструкциями по восстановлению пароля отправлено на почту {{email}}',
            },
            eventRegisterCreatorDialog: {
                title: 'Регистрация не требуется',
                text: 'Вы можете перейти в вебинар из своего личного кабинета',
            },
            eventRegisterAlreadyRegisteredDialog: {
                webinarTitle: 'Вы уже зарегистрированы на вебинар «{{name}}»',
                meetingTitle: 'Вы уже зарегистрированы на мероприятие «{{name}}»',
                webinarText:
                    'Вы уже зарегистрированы на вебинар «{{name}}», который пройдет <0>{{date, d MMMM yyyy}} в {{date, HH:mm}}</0>',
                meetingText:
                    'Вы уже зарегистрированы на мероприятие «{{name}}», которое пройдет <0>{{date, d MMMM yyyy}} в {{date, HH:mm}}</0>',
                noLink: 'Ссылка доступа так и не пришла?',
                sendAgain: 'Отправить еще раз',
            },
            eventRegisterSuccessDialog: {
                title: 'Спасибо!',
                text: 'Вы зарегистрированы на вебинар «{{name}}», который пройдет <0>{{date, d MMMM yyyy}} в {{date, HH:mm}} (часовой пояс {{date, timezoneName}}, {{date, xxx}})</0>.<br/>Ссылка с приглашением отправлена на почту <0>{{email}}</0>.',
            },
            eventRegisterResendSuccessDialog: {
                text: 'Ссылка с приглашением повторно отправлена на почту <0>{{email}}</0>',
            },
            maxUsersDialog: {
                title: 'Закончились свободные места',
                text: 'К сожалению, на данном вебинаре достигнуто максимальное количество участников согласно выбранному тарифу',
                meetingTitle: 'Достигнуто предельное число участников',
                meetingText:
                    'Все места на этом мероприятии заняты. Вы можете обратиться к организатору',
                action: 'Связаться с организатором',
            },
            contactAdminDialog: {
                title: 'Связаться с организатором',
                name: 'Имя',
                secondName: 'Фамилия',
                email: 'Электронная почта',
                question: 'Ваш вопрос',
            },
            beforeEventStartDialog: {
                meetingTitle: 'Мероприятие «{{name}}» еще не началось.',
                webinarTitle: 'Вебинар «{{name}}» еще не начался.',
                thanksTitle: 'Спасибо!',
                accessAfterStart:
                    'Начало мероприятия <0>{{date, d MMMM yyyy}} в {{date, HH:mm}} (часовой пояс {{date, timezoneName}}, {{date, xxx}})</0>.<br/>Вход станет доступен после начала мероприятия.',
                meetingRegistarionSuccess:
                    '<0>{{nickname}}</0>, Вы зарегистрированы на мероприятие «{{name}}», которое пройдет <0>{{date, d MMMM yyyy}} в {{date, HH:mm}} (часовой пояс {{date, timezoneName}}, {{date, xxx}})</0>.',
                webinarRegistrationSuccess:
                    '<0>{{nickname}}</0>, Вы зарегистрированы на вебинар «{{name}}», который пройдет <0>{{date, d MMMM yyyy}} в {{date, HH:mm}} (часовой пояс {{date, timezoneName}}, {{date, xxx}})</0>.',
            },
            logoutDialog: {
                title: 'Владелец аккаунта принудительно завершил сеансы',
                text: 'На адрес {{email}} отправлена новая ссылка для входа. Если у вас нет доступа к этой почте, используйте другую или обратитесь к владельцу аккаунта.',
            },
            notAcceptedDialog: {
                title: 'Доступ запрещен',
                text: 'Ведущий пока не одобрил вход на мероприятие',
            },
        },
        modalFeatureRequest: {
            submit: 'Отправить запрос',
            requested: 'Спасибо, запрос уже отправлен',
            contactManager: 'Связаться с менеджером',
            requestedFeedback: 'Заявка отправлена. Менеджер скоро с вами свяжется.',
        },
        eventSelfNotifyOfferDialog: {
            title: 'Хотите, чтобы мероприятия добавлялись в рабочий календарь?',
            text: 'На почту будет приходить письмо о запланированном мероприятии, которое автоматически создаст событие в календаре.',
            comment:
                'Эта настройка в личном кабинете, нажмите на свой аватар и откройте раздел Профиль',
            submit: 'Да, хочу',
            cancel: 'Нет, спасибо',
        },
        integrationInfoDialog: {
            title: 'Расширения и интеграции',
            text: 'Создавайте и проводите встречи через привычные инструменты и календари',
            submit: 'Узнать больше',
            cancel: 'Закрыть',
        },
        auth: {
            ru: 'RU',
            en: 'ENG',
            signin: {
                title: 'Вход',
                infoTitle:
                    'Для доступа к системе используйте свои учетные данные организации и присоединитесь к ней.',
                email: 'Почта',
                ldapDnOrEmail: 'LDAP логин',
                password: 'Пароль',
                show: 'Показать',
                rememberMe: 'Запомнить меня',
                submit: 'Войти',
                restorePassword: 'Восстановить пароль',
                register: 'Создать аккаунт',
                ssoLogin: 'Войти через SSO',
                errors: {
                    wrongLogin: 'Логин или пароль содержит ошибки',
                    noProlongation: 'Не найден тариф, <0>обратитесь в поддержку</0>',
                    participant:
                        'Ищете вебинар? <0>Вам сюда</0>. Хотите проводить свои мероприятия? <1>Зарегистрируйтесь</1>',
                    ssoOnly:
                        'Данная учетная запись управляется организацией.\nВход возможен только через SSO.',
                    passwordAttemptsLimit:
                        'Слишком много попыток ввода пароля.<br>Попробуйте снова через 15 минут.',
                },
            },
            sso: {
                title: 'Вход через SSO',
                signupTitle: 'Регистрация через SSO',
                email: 'Корпоративная почта',
                signInByEmail: 'Войти по  email',
                back: 'Вернуться назад',
                backLogin: 'Вернуться к странице входа',
                organization: 'Выберите организацию',
                organizationError: 'Email не принадлежит ни к одной из организаций',
                invalidEmail: 'Неверное значение',
                notVerifiedEmail: 'Пользователь не подтвердил свою почту',
                invalidParams:
                    'Неверные входные параметры / Организация не подходит для перехода / Организация не найдена',
                noTokenPassed: 'Не передан токен авторизации / не аутентифицирован',
                notRightsToGo: 'Пользователь не подтвердил свою почту / нет прав для перехода',
                defaultOrganization: 'Остаться в текущей организации',
            },
            ssoSignup: {
                title: 'Регистрация через SSO',
                subtitle:
                    'Администратор вашей организации установил ограничение на регистрацию с помощью email. Что бы продолжить регистрацию с помощью SSO (Single Sing On) нажмите кнопку "Продолжить"',
            },
            signup: {
                title: 'Регистрация',
                name: 'Имя',
                secondName: 'Фамилия',
                phone: 'Телефон',
                email: 'Электронная почта',
                emailExist: 'Такой адрес уже зарегистрирован',
                password: 'Пароль',
                organization: 'Компания, в которой вы работаете',
                submit: 'Зарегистрироваться',
                login: 'Войти',
                terms: 'Нажимая на кнопку «Зарегистрироваться», я акцептую условия <0>лицензионного договора</0>, подтверждаю факт ознакомления с <1>"Политикой обработки персональных данных"</1>, соглашаюсь <2>на обработку персональных данных</2> и связь со мною способами, указанными в договоре, в целях исполнения условий договора. <3>Информация о компании.</3>',
                companyInformation: {
                    title: 'ООО «ВЕБИНАР ТЕХНОЛОГИИ»',
                    content:
                        'ОГРН 1157746417575<br />ИНН 7707340808<br />КПП 770701001<br /><br />Адрес местонахождения: 127055 Москва, Приютский пер., д. 3, стр. 1<br /><br />Адрес для направления корреспонденции: 129515, г.Москва, ул. Прасковьина, д.21<br /><br />Банковские реквизиты:<br />АО «Альфа-Банк» (г.Москва)<br />Р/с 40702810701100005539<br />К/с 30101810200000000593<br />БИК 044525593<br /><br />Генеральный директор Альперн А.В.',
                },
                success: {
                    title: 'Вы зарегистрированы!',
                    content:
                        'Требуется подтвердить email. Письмо отправлено на электронную почту <0>{{email}}</0>',
                },
            },
            passwordRecovery: {
                title: 'Забыли пароль?',
                description:
                    'Пожалуйста, введите адрес электронной почты, который вы использовали при регистрации.',
                email: 'Почта',
                submit: 'Восстановить пароль',
                back: 'Вернуться к странице входа',
                restored: 'Пароль восстановлен!',
                sent: 'Письмо с инструкциями по восстановлению пароля отправлено на почту <0>{{email}}</0>',
                newPassword: 'Новый пароль',
                password: 'Пароль',
                passwordChanged: 'Пароль успешно изменен',
                emailNotFound: 'Мы не нашли такого адреса почты',
                weakPassword: 'Плохой пароль',
                errors: {
                    ssoRecovery:
                        'Данная учетная запись управляется организацией.\nДля изменения пароля обратитесь к администратору.',
                    passwordAttemptsLimit:
                        'Слишком много попыток ввода пароля. Попробуйте снова через 15 минут.',
                },
            },
            emailSubmit: {
                title: 'Ваш email успешно подтверждён.',
            },
            extendAccount: {
                title: 'Установите пароль',
                success: 'Пароль успешно установлен',
                error: 'Не удалось установить пароль. Попробуйте позже',
                submit: 'Установить',
                password: 'Пароль',
                passwordConfirmation: 'Повторите пароль',
                passwordNotMatch: 'Пароли не совпадают',
            },
            desktopApp: {
                text: 'Мы перенаправили вас в личный кабинет десктопного приложения. Если автоматический переход не произошел, нажмите <bold>“Открыть приложение”</bold> ниже',
                button: 'Открыть приложение',
                troubles: 'Возникли проблемы с запуском приложения?',
                browserLink: 'Войти в личный кабинет в браузере',
            },
            mobileApp: {
                text: 'Для перехода в личный кабинет мобильного приложения нажмите <bold>“Открыть приложение”</bold> ниже',
                button: 'Открыть приложение',
                error: 'Ой! Что-то пошло не так. Попробуйте позднее',
                reload: 'Перезагрузить',
            },
        },
        organization: {
            invitation: {
                email: {
                    setPassword: 'Задайте пароль для вашего аккаунта',
                    password: 'Пароль',
                    joined: 'Ваш профиль был успешно добавлен в организацию!',
                    tariffRestriction:
                        'Нельзя принять приглашение в организацию, когда у вас есть платный тариф. Свяжитесь с администратором команды.',
                    existPaidTariff: 'Нельзя пригласить пользователя с платным тарифом',
                },
                link: {
                    join: 'Присоединиться к команде <0>"{{name}}"</0>',
                    enterPassword:
                        'Пожалуйста, введите пароль, который вы получили вместе с ссылкой приглашением',
                    password: 'Защитный пароль',
                    passwordHelperText:
                        'Если вы не знаете пароль, обратитесь к пригласившему вас человеку',
                    wrongPassword: 'Неверный пароль',
                    selectAuth:
                        'Чтобы присоединиться к организации, необходимо зарегистрироваться или войти',
                    signup: 'Зарегистрироваться',
                    signin: 'Войти',
                    invalidToken: '<0>Упс!</0> Ссылка больше не действительна',
                    invalidPassword: '<0>Упс!</0> Пароль больше не действителен',
                    limit: '<0>Упс!</0> В организации, куда вы хотите добавиться, больше нет свободных мест',
                    contact: 'Пожалуйста, обратитесь к пригласившему вас человеку',
                    member: 'Вы уже присоединены к этой команде',
                    memberDescription:
                        'Вы пытались добавиться в организацию, в которой уже состоите',
                    anotherMember: 'Вы состоите в другой команде и не можете добавиться в еще одну',
                    anotherMemberDescription:
                        'Чтобы присоединиться к другой организации, вам надо выйти из существующей команды',
                    paidTariff: 'У вас платный тариф',
                    paidTariffDescription:
                        'Пользователь с платным тарифом не может присоединиться к другой команде',
                    studentNeedRegister: 'Пожалуйста, зарегистрируйтесь',
                    studentNeedRegisterDescription:
                        'Чтобы присоединиться к организации, пожалуйста, пройдите регистрацию',
                },
            },
        },
        contacts: {
            itemDeleted: 'Контакт удален',
            itemsDeleted: 'Контакты удалены',
            importSuccess: 'Импортировано контактов: {{contactsNum}}',
            importFail: 'Ошибка импорта',
        },
        confirmEmailDialog: {
            title: 'Подтвердите почту',
            sendButton: 'Отправить ссылку повторно',
            beforeSentText:
                'Перед началом работы подтвердите email адрес своего аккаунта. Действующая почта нужна для получения важных системных оповещений, например: вопросов от участников, заканчивающемся месте в файловом хранилище и др.',
            afterSentText:
                'На вашу почту {{email}} отправлено письмо со ссылкой для подтверждения аккаунта. Нажмите на кнопку в письме, аккаунт будет подтвержден автоматически.',
        },
        timezone: {
            'Pacific/Samoa': 'Самоа',
            'Pacific/Honolulu': 'Гавайи',
            'America/Anchorage': 'Аляска',
            'America/Los_Angeles': 'Тихоокеанское время, США',
            'America/Tijuana': 'Нижняя Калифорния',
            'America/Chihuahua': 'Ла-Пас',
            'America/Denver': 'Горное время, США',
            'America/Phoenix': 'Аризона',
            'America/Chicago': 'Центральное время, США',
            'America/Tegucigalpa': 'Центральная Америка',
            'America/Regina': 'Саскачеван',
            'America/Mexico_City': 'Мехико',
            'America/Bogota': 'Богота',
            'America/New_York': 'Восточное время, США',
            'America/Caracas': 'Каракас',
            'America/Manaus': 'Джорджтаун',
            'America/Halifax': 'Атлантическое время, Канада',
            'America/St_Johns': 'Ньюфаундленд',
            'America/Buenos_Aires': 'Буэнос-Айрес',
            'America/Fortaleza': 'Кайенна',
            'America/Godthab': 'Гренландия',
            'America/Santiago': 'Сантьяго',
            'America/Cuiaba': 'Куяба',
            'America/Asuncion': 'Асунсьон',
            'America/Sao_Paulo': 'Бразилия',
            'America/Montevideo': 'Монтевидео',
            'Atlantic/Cape_Verde': 'О-ва Зелёного Мыса',
            'Atlantic/Azores': 'Азорские о-ва',
            'Africa/Casablanca': 'Касабланка',
            'Atlantic/Reykjavik': 'Рейкьявик',
            'Europe/London': 'Лондон',
            'Africa/Ndjamena': 'Западная Центральная Африка',
            'Europe/Brussels': 'Париж',
            'Europe/Warsaw': 'Варшава',
            'Europe/Belgrade': 'Прага',
            'Europe/Berlin': 'Берлин',
            'Africa/Tripoli': 'Триполи',
            'Africa/Cairo': 'Каир',
            'Africa/Harare': 'Хараре',
            'Asia/Jerusalem': 'Иерусалим',
            'Europe/Kaliningrad': 'Калининград',
            'Asia/Amman': 'Амман',
            'Asia/Istanbul': 'Стамбул',
            'Africa/Windhoek': 'Виндхук',
            'Europe/Athens': 'Афины',
            'Asia/Beirut': 'Бейрут',
            'Asia/Damascus': 'Дамаск',
            'Europe/Kiev': 'Киев',
            'Europe/Ulyanovsk': 'Ульяновск',
            'Europe/Astrakhan': 'Астрахань',
            'Europe/Samara': 'Самара',
            'Asia/Kuwait': 'Кувейт',
            'Africa/Nairobi': 'Найроби',
            'Asia/Baghdad': 'Багдад',
            'Europe/Moscow': 'Москва',
            'Asia/Tehran': 'Тегеран',
            'Asia/Tbilisi': 'Тбилиси',
            'Asia/Yerevan': 'Ереван',
            'Asia/Muscat': 'Абу-Даби',
            'Asia/Baku': 'Баку',
            'Asia/Kabul': 'Кабул',
            'Asia/Karachi': 'Исламабад',
            'Asia/Tashkent': 'Ташкент',
            'Asia/Yekaterinburg': 'Екатеринбург, Пермь',
            'Asia/Kolkata': 'Колката',
            'Asia/Katmandu': 'Катманду',
            'Asia/Kamchatka': 'Камчатка',
            'Asia/Dacca': 'Дакка',
            'Asia/Almaty': 'Астана',
            'Asia/Novosibirsk': 'Новосибирск',
            'Asia/Rangoon': 'Янгон',
            'Asia/Krasnoyarsk': 'Красноярск',
            'Asia/Bangkok': 'Бангкок',
            'Asia/Ulan_Bator': 'Улан-Батор',
            'Asia/Hong_Kong': 'Гонконг',
            'Asia/Taipei': 'Тайбэй',
            'Asia/Irkutsk': 'Иркутск',
            'Asia/Kuala_Lumpur': 'Куала-Лумпур',
            'Australia/Perth': 'Перт',
            'Asia/Seoul': 'Сеул',
            'Asia/Yakutsk': 'Якутск',
            'Asia/Tokyo': 'Осака',
            'Australia/Darwin': 'Дарвин',
            'Asia/Vladivostok': 'Владивосток',
            'Asia/Magadan': 'Магадан',
            'Australia/Brisbane': 'Брисбен',
            'Pacific/Port_Moresby': 'Гуам',
            'Australia/Adelaide': 'Аделаида',
            'Australia/Hobart': 'Хобарт',
            'Pacific/Noumea': 'Нов. Каледония',
            'Australia/Sydney': 'Канберра',
            'Pacific/Fiji': 'Фиджи',
            'Pacific/Tongatapu': 'Нукуалофа',
            'Pacific/Auckland': 'Веллингтон',
        },
        admin: {
            smtp: {
                confirmation: 'Настройки SMTP подтверждены',
                adminLink: 'Перейти в панель администрирования',
                errors: {
                    notFound: {
                        text: 'Кажется, с этой ссылкой что-то не так! Она не работает. Попробуйте скопировать ее из письма заново.',
                    },
                    network: {
                        text: 'Что-то пошло не так! Попробуйте отправить ваш запрос повторно, либо обратитесь к администратору вашей сети.',
                        button: 'Попробовать еще раз',
                    },
                },
            },
        },
        addEmployee: {
            inviteEmployee: 'Пригласите сотрудников, чтобы использовать один тариф',
            manageEmployee:
                'Управлять сотрудниками и настройками организации вы можете в разделе <a>Организация</a>',
            inviteLink: 'Ссылка-приглашение',
            inviteDescription: 'По ссылке сотрудник присоединиться к вашей организации',
            employee: 'сотрудник',
        },
    },
};
