import { useTranslation } from 'react-i18next';

import Banner from '@wbnr/frontend-shared/lib/components/Banner';
import { useUser } from '@wbnr/frontend-shared/lib/data/user';
import { Button, A } from '@wbnr/ui';

import { useBannerParams } from 'shared/banners';

import backgroundUrl from './assets/endless-meetings-background.png';
import styles from './EndlessMeetingsBanner.module.scss';

const EndlessMeetingsBanner = () => {
    const [user, loading, error] = useUser();
    const params = useBannerParams('endlessMeetings');
    const { t } = useTranslation();

    if (!user || loading || error) {
        return null;
    }

    return (
        <Banner
            {...params}
            backgroundUrl={backgroundUrl}
            title={t('banners.endlessMeetingsPromo')}
            action={
                <A href="/meetings/create/endless">
                    <Button className={styles.button} variant="outlined" color="inherit">
                        {t('banners.tryAction')}
                    </Button>
                </A>
            }
        />
    );
};

export default EndlessMeetingsBanner;
