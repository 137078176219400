import classnames from 'classnames';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router';

import { FullFilteredProduct } from '@wbnr/frontend-shared/lib/types/Tariffs';
import { injectTranslations } from '@wbnr/frontend-shared/lib/utils/i18n';
import thinSpaceFormatter from '@wbnr/frontend-shared/lib/utils/thinSpaceFormatter';
import { Tooltip, Button, createTestIdProps, Typography, Badge } from '@wbnr/ui';

import { TARIFF_PAGE_NS } from '../constants';
import { useInvoiceFormDialog } from '../InvoiceForm/useInvoiceFormDialog';
import { CardOrder } from '../types';

import TariffButton from './TariffButton';
import styles from './TariffsCard.module.scss';
import translations from './translations';

injectTranslations(TARIFF_PAGE_NS, translations);

type CardProps = {
    tariff: FullFilteredProduct;
    duration?: number;
    isAutoAS?: boolean;
    isInvoicing?: boolean;
    isRecommended?: boolean;
    isVisible?: boolean;
    email: string;
    onPay?: (order: CardOrder) => Promise<void>;
};

const MEETINGS_BASIC_NAME = 'Meetings Basic';
const MEETINGS_FREE_KEY = 'PRODUCT_WEBINAR_MEETINGS_FREE';
const MEETING = 'meetings';
const MARKETING = 'marketing';
const TRAINING = 'training';
const TRIAL_KEY = 'PRODUCT_WEBINAR_FREE_TRIAL';
const BASE_TEST_ID = 'tariffCard';

const TariffCard = ({
    tariff,
    duration,
    onPay,
    isInvoicing,
    isRecommended,
    isVisible,
    isAutoAS,
    email,
}: CardProps) => {
    const { t } = useTranslation(TARIFF_PAGE_NS);
    const history = useHistory();
    const { options, name, prices, id, displayName, key } = tariff;
    const { price, id: productId } =
        prices.find((priceEl) => priceEl.duration === duration) || prices[0] || {};
    const { price: monthPrice } =
        prices.find((priceEl) => priceEl.duration === 1) || prices[0] || {};

    const currentDurationParams = prices.find((priceEl) => priceEl.duration === duration);
    const canConnectExtensions = currentDurationParams?.canConnectExtensions;
    const maxStorage = (options?.maxStorage?.value as number) * 1e6;

    const isCurrentTariff = Boolean(tariff?.isCurrentTariff);
    const isTrainingOrMarketing = id === TRAINING || id === MARKETING;
    const isFreeOrTrial = id || key === TRIAL_KEY;
    const isNotMeeting = id !== MEETING;
    const isTrial = key === TRIAL_KEY;
    const isMeetingFree = key === MEETINGS_FREE_KEY;

    const goToTariff = () => {
        history.push('/business/tariffs');
    };

    const oldPrice = thinSpaceFormatter(
        isNaN(monthPrice) ? 0 : duration && monthPrice && monthPrice * duration,
    );

    const currentDiscountPercent =
        duration === 1 ||
        (name === MEETINGS_BASIC_NAME && duration === 6) ||
        key === TRIAL_KEY ||
        key === MEETINGS_FREE_KEY ||
        duration === 3
            ? null
            : duration === 6
            ? '-10%'
            : '-20%';

    const currentPrice = thinSpaceFormatter(isNaN(price) ? 0 : price);

    const order = {
        productIds: [productId],
        duration: duration || 1,
        type: 'card',
        tariffName: name,
    };

    const [openInvoiceForm] = useInvoiceFormDialog();

    const openInvoiceFormDialog = () => {
        openInvoiceForm({
            price: currentPrice,
            tariffName: name,
            duration,
            email,
            productIds: [productId],
        });
    };

    return (
        <>
            <div
                className={classnames(styles.cardContainer, {
                    [styles.recommendedCard]: isRecommended,
                })}
            >
                {isRecommended && !isCurrentTariff && (
                    <div
                        className={classnames(styles.recommended, { [styles.hidden]: !isVisible })}
                    >
                        <span>{t('recommended')}</span>
                    </div>
                )}
                <div className={styles.head}>
                    <Typography variant="h6" className={styles.tariffName}>
                        {t(`${displayName}.title`)}
                    </Typography>
                    <div className={styles.badge}>
                        <Badge badgeContent={currentDiscountPercent} color="error" />
                    </div>
                </div>

                <Typography color="textSecondary" variant="body2">
                    {isTrial && tariff?.paidUntil
                        ? t('trial.description', {
                              date: tariff.paidUntil,
                          })
                        : t(`${id || 'free'}.description`)}
                </Typography>

                <div className={styles.price}>
                    <div className={styles.current}>
                        <Typography variant="h4">{currentPrice}</Typography>
                        <Typography variant="caption" className={styles.currency}>
                            {t('rub')}
                        </Typography>
                    </div>
                    <div className={styles.old}>
                        <Typography color="textSecondary" variant="caption">
                            {oldPrice !== currentPrice && `${oldPrice}${t('rub')}`}
                        </Typography>
                    </div>
                </div>

                {isInvoicing ? (
                    <TariffButton
                        isCurrentTariff={isCurrentTariff}
                        goToTariff={goToTariff}
                        onInvoiceFormOpen={openInvoiceFormDialog}
                        onPay={onPay}
                        order={order}
                        {...createTestIdProps(BASE_TEST_ID, 'invoicingFeatureButton')}
                    />
                ) : (
                    <Button
                        fullWidth
                        variant={isCurrentTariff ? 'outlined' : 'contained'}
                        color="primary"
                        onClick={isCurrentTariff ? goToTariff : () => onPay && onPay(order)}
                        {...createTestIdProps(BASE_TEST_ID, 'defaultTariffButton')}
                    >
                        {t(`${isCurrentTariff ? 'currentTariff' : 'buy'}`)}
                    </Button>
                )}

                <div className={styles.optionsType}>
                    <Typography variant="overline" color="textSecondary">
                        {t('main')}
                    </Typography>
                </div>

                <ul className={styles.optionsList}>
                    {(isTrainingOrMarketing || isTrial) && (
                        <li>
                            {' '}
                            <Typography variant="body2">
                                {t('isWebinar', {
                                    maxConferences: options?.maxConferences?.value,
                                    maxUsers: options?.maxUsers?.value,
                                })}
                            </Typography>
                        </li>
                    )}

                    {(!isNotMeeting || isMeetingFree) && (
                        <li>
                            {' '}
                            <Typography variant="body2">
                                {t(`${options?.maxUsers?.name}`, {
                                    count: isAutoAS && id && !isMeetingFree ? 100 : 30,
                                })}
                            </Typography>
                        </li>
                    )}

                    <li>
                        {' '}
                        <Typography variant="body2">
                            <Trans i18nKey="filesize" values={{ size: maxStorage }} />
                            {`${' '}${t(`${options?.maxStorage?.name}`)}`}
                        </Typography>
                    </li>

                    <li>
                        {' '}
                        <Typography variant="body2">
                            {t(
                                `${
                                    isFreeOrTrial
                                        ? options?.isInfinitySession?.name
                                        : 'notInfinitySession'
                                }`,
                            )}
                        </Typography>
                    </li>

                    {isNotMeeting && !isMeetingFree && (
                        <li>
                            {' '}
                            <Typography variant="body2">
                                {t(`${options?.maxUsers?.name}`, {
                                    count: isAutoAS ? 100 : 30,
                                })}
                            </Typography>
                        </li>
                    )}
                </ul>

                <div className={styles.optionsType}>
                    <Typography variant="overline" color="textSecondary">
                        {t('additionally')}
                    </Typography>
                </div>

                {options && (
                    <ul className={styles.optionsList}>
                        {id && (
                            <li>
                                {' '}
                                <Typography variant="body2">
                                    {t(`${options.isSupport?.name}`)}
                                </Typography>
                            </li>
                        )}

                        {options?.isConversion && isFreeOrTrial && (
                            <li>
                                {' '}
                                <Typography variant="body2">
                                    {t(`${options.isConversion?.name}`)}
                                </Typography>
                            </li>
                        )}

                        {options?.isBranding && isFreeOrTrial && (
                            <li>
                                {' '}
                                <Typography variant="body2">
                                    {t(`${options.isBranding?.name}`)}
                                </Typography>
                            </li>
                        )}

                        {isNotMeeting && id && (
                            <li>
                                {' '}
                                <Typography variant="body2">
                                    {t(`${options.isPersonalManager?.name}`)}
                                </Typography>
                            </li>
                        )}

                        {options?.isMarketing && (
                            <Tooltip arrow placement="top" title={`${t('analytics')}`}>
                                <li className={styles.analytics}>
                                    <Typography variant="body2">
                                        {t(`${options.isMarketing?.name}`)}
                                    </Typography>
                                </li>
                            </Tooltip>
                        )}

                        {options?.isTraining && (
                            <li>
                                {' '}
                                <Typography variant="body2">
                                    {t(`${options.isTraining?.name}`)}
                                </Typography>
                            </li>
                        )}

                        {canConnectExtensions && isNotMeeting && (
                            <li>
                                {' '}
                                <Typography variant="body2">
                                    {' '}
                                    {t('canConnectExtensions')}
                                </Typography>
                            </li>
                        )}

                        <li>
                            {' '}
                            <Typography variant="body2">
                                {t(`${options.isMobileApp?.name}`)}
                            </Typography>
                        </li>
                    </ul>
                )}
            </div>
        </>
    );
};

export default TariffCard;
