"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("react");
var _block = require("../utils/block");
var _placeholder = require("../utils/placeholder");
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var useCustomPlaceholder = function useCustomPlaceholder(editorState) {
  var _useState = (0, _react.useState)(false),
    _useState2 = _slicedToArray(_useState, 2),
    show = _useState2[0],
    setShow = _useState2[1];
  var _useState3 = (0, _react.useState)(null),
    _useState4 = _slicedToArray(_useState3, 2),
    blockKey = _useState4[0],
    setBlockKey = _useState4[1];
  var _useState5 = (0, _react.useState)(_placeholder.PLACEHOLDER_TYPES.CODE),
    _useState6 = _slicedToArray(_useState5, 2),
    type = _useState6[0],
    setType = _useState6[1];
  var selection = editorState.getSelection();
  var currentBlockKey = selection.getStartKey();
  var isEmptyBlock = (0, _block.isEmptyUnstyledBlock)(editorState, currentBlockKey);
  var focusNode = (0, _block.getNodeByBlockKey)(currentBlockKey);
  var position = getNodePosition(focusNode);
  (0, _react.useEffect)(function () {
    if (show && (!isEmptyBlock || blockKey !== currentBlockKey)) {
      setShow(false);
      setBlockKey(null);
    }
  }, [show, isEmptyBlock, blockKey, setBlockKey, currentBlockKey]);
  var onPlaceholderClick = (0, _react.useCallback)(function () {
    setShow(false);
    setBlockKey(null);
  }, [setShow, setBlockKey]);
  var onShowPlaceholder = (0, _react.useCallback)(function (placeholderType) {
    if (isEmptyBlock) {
      setType(placeholderType);
      setShow(true);
      setBlockKey(currentBlockKey);
    }
  }, [isEmptyBlock, setType, setShow, setBlockKey, currentBlockKey]);
  return {
    placeholderParams: {
      show: show,
      type: type,
      position: position,
      onClick: onPlaceholderClick
    },
    onShowPlaceholder: onShowPlaceholder
  };
};
var getNodePosition = function getNodePosition(node) {
  return node ? {
    top: node.offsetTop,
    left: node.offsetLeft
  } : {};
};
var _default = useCustomPlaceholder;
exports.default = _default;