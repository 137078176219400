import { FC, useState, ComponentProps, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DevicesItem } from '@wbnr/frontend-shared/lib/api/business-api/types';
import DataList from '@wbnr/frontend-shared/lib/components/DataList';
import InfiniteLoader from '@wbnr/frontend-shared/lib/components/InfiniteLoader';
import { useSortedData } from '@wbnr/frontend-shared/lib/hooks/useSortedData';
import { createComparatorBy, SortDescription } from '@wbnr/frontend-shared/lib/utils/sort';
import { Typography } from '@wbnr/ui';

import { useDevices } from '../../hooks/useDevices';

import styles from './MyDeviceTable.module.scss';
import { MyDeviceTableHeader } from './MyDeviceTableHeader';
import { MyDeviceTableRow } from './MyDeviceTableRow';

const PAGE_ITEMS_COUNT_LIMIT = 250;

type DataListRef = ComponentProps<typeof DataList>['listRef'];

const DEFAULT_SORT: SortDescription<string> = {
    by: 'name',
    asc: true,
};

interface MyDeviceTableProps {
    isSearchingDevices: boolean;
    showTableHeader: boolean;
}

export const MyDeviceTable: FC<MyDeviceTableProps> = ({ isSearchingDevices, showTableHeader }) => {
    const { t } = useTranslation();
    const [sort, setSort] = useState<SortDescription<string>>(DEFAULT_SORT);

    const { devices, searchDevices, loadingDevices, fetchDevices } = useDevices();

    const { nextPageCursor, items: devicesItems = [] } =
        (isSearchingDevices ? searchDevices : devices) || {};

    const sortedDevices = useSortedData<DevicesItem>(
        devicesItems,
        useMemo(() => [createComparatorBy(sort)], [sort]),
    );

    const devicesLength = sortedDevices?.length ?? 0;

    const itemCount = nextPageCursor ? devicesLength + PAGE_ITEMS_COUNT_LIMIT : devicesLength;

    return (
        <div className={styles.root}>
            <div className={styles.table}>
                <InfiniteLoader
                    threshold={1}
                    isItemLoaded={(index) => {
                        return !nextPageCursor || index < devicesLength;
                    }}
                    itemCount={itemCount}
                    loadMoreItems={async (_startIndex, stopIndex) => {
                        if (!nextPageCursor || stopIndex <= devicesLength) {
                            return;
                        }

                        await fetchDevices({
                            cursor: nextPageCursor,
                        });
                    }}
                >
                    {({ onItemsRendered, ref }) => {
                        return (
                            <DataList
                                loading={loadingDevices}
                                data={sortedDevices || undefined}
                                onItemsRendered={onItemsRendered}
                                listRef={ref as unknown as DataListRef}
                                header={
                                    showTableHeader && (
                                        <MyDeviceTableHeader sort={sort} setSort={setSort} />
                                    )
                                }
                                emptyElement={
                                    <Typography
                                        variant="body1"
                                        component="div"
                                        className={styles.emptyContainer}
                                        color="textSecondary"
                                    >
                                        {t(
                                            showTableHeader
                                                ? 'business.myDevicesPage.myDevicesList.searchNotFound'
                                                : 'business.myDevicesPage.myDevicesList.emptyDevices',
                                        )}
                                    </Typography>
                                }
                                renderRow={({ data, style, key }) => (
                                    <div key={key} style={style}>
                                        <MyDeviceTableRow device={data} />
                                    </div>
                                )}
                            />
                        );
                    }}
                </InfiniteLoader>
            </div>
        </div>
    );
};
