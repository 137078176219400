import { useMixpanelTracking } from '../../analytics/mixpanel/useMixpanelTracking';
import { useCallback } from 'react';
export var useOnboardingMixpanelTrack = function useOnboardingMixpanelTrack(_ref) {
  var stepPassedEventKey = _ref.stepPassedEventKey,
    groupSkippedEventKey = _ref.groupSkippedEventKey;
  var _useMixpanelTracking = useMixpanelTracking(),
    track = _useMixpanelTracking.track;
  return {
    trackGroupSkip: useCallback(function (groupId) {
      track(groupSkippedEventKey, {
        type: groupId
      });
    }, [track, groupSkippedEventKey]),
    trackStepPass: useCallback(function (groupId, stepId) {
      track(stepPassedEventKey, {
        type: groupId,
        step: stepId
      });
    }, [track, stepPassedEventKey])
  };
};