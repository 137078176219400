import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { BannersContext, BannersControlsContext } from '@wbnr/frontend-shared/lib/banners/context';

import { useEndlessMeetingsBanner } from './useEndlessMeetingsBanner';
import { useFileSizeBanner } from './useFileSizeBanner';
import { useMeetingLimitBanner } from './useMeetingLimitBanner';
import { usePayUsBanner } from './usePayUsBanner';
import { useWebrtcWarningBanner } from './useWebrtcWarningBanner';

const BannersProvider: FC = ({ children }) => {
    const location = useLocation();
    const [bannersDisabled, setBannersDisabled] = useState(false);
    const fileSizeBannerParams = useFileSizeBanner(location);
    const payUsBannerParams = usePayUsBanner();
    const webrtcWarningParams = useWebrtcWarningBanner();
    const meetingLimitParams = useMeetingLimitBanner();
    const endlessMeetingsParams = useEndlessMeetingsBanner(location);
    const hasOpened =
        !bannersDisabled &&
        [
            fileSizeBannerParams,
            payUsBannerParams,
            webrtcWarningParams,
            meetingLimitParams,
            endlessMeetingsParams,
        ].some(({ show }) => Boolean(show));

    useEffect(() => {
        if (hasOpened) {
            document.body.classList.add('banner-active');
        }
        return () => {
            document.body.classList.remove('banner-active');
        };
    }, [hasOpened]);

    return (
        <BannersContext.Provider
            value={useMemo(
                () => ({
                    fileSize: maybeDisableBanner(fileSizeBannerParams, bannersDisabled),
                    payUs: maybeDisableBanner(payUsBannerParams, bannersDisabled),
                    webrtcWarning: maybeDisableBanner(webrtcWarningParams, bannersDisabled),
                    meetingLimit: maybeDisableBanner(meetingLimitParams, bannersDisabled),
                    endlessMeetings: maybeDisableBanner(endlessMeetingsParams, bannersDisabled),
                    bannerHeight: hasOpened ? '64px' : undefined,
                    hasOpened,
                }),
                [
                    bannersDisabled,
                    fileSizeBannerParams,
                    payUsBannerParams,
                    webrtcWarningParams,
                    meetingLimitParams,
                    endlessMeetingsParams,
                    hasOpened,
                ],
            )}
        >
            <BannersControlsContext.Provider
                value={useMemo(() => ({ setBannersDisabled }), [setBannersDisabled])}
            >
                {children}
            </BannersControlsContext.Provider>
        </BannersContext.Provider>
    );
};

const maybeDisableBanner = (banner: { [key: string]: any }, bannersDisable: boolean) => ({
    ...banner,
    show: bannersDisable ? false : banner.show,
});

export default BannersProvider;
