import { useUserActivity } from '@wbnr/frontend-shared/lib/data/userActivity/useUserActivity';
var ACTIVITY = 'meetingsVisited';
export var useMeetingsVisitedActivity = function useMeetingsVisitedActivity() {
  var _useUserActivity = useUserActivity({
      token: ACTIVITY
    }),
    activityData = _useUserActivity.activityData,
    fulfilled = _useUserActivity.fulfilled,
    setUserActivity = _useUserActivity.setUserActivity;
  return {
    visited: ((activityData === null || activityData === void 0 ? void 0 : activityData.activityCounter) || 0) > 0,
    fulfilled: fulfilled,
    setVisited: setUserActivity
  };
};