import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { sendNewPassword, checkRestorePasswordToken } from '@wbnr/frontend-shared/lib/api/user';
import {
    AuthLayout,
    AuthContent,
    AuthForm,
    AuthPasswordField,
    AuthButtons,
} from '@wbnr/frontend-shared/lib/components/auth';
import LoadingScreen from '@wbnr/frontend-shared/lib/components/LoadingScreen';
import { makeFormErrors, useField, useForm } from '@wbnr/frontend-shared/lib/forms';
import { createTestIdProps } from '@wbnr/ui';

const baseTestId = 'PasswordRecoveryToken';

const i18nPath = 'auth.passwordRecovery';

const PasswordRecoveryToken = () => {
    const history = useHistory();
    const { token } = useParams<{ token: string }>();
    const { t } = useTranslation();

    const isCourses = window.location.pathname.includes('/courses');
    const isMobile = window.location.pathname.includes('/mobile');

    const { control, submit } = useForm(
        {
            defaultValues: {
                password: '',
            },
        },
        {
            onSubmit: useCallback(
                async ({ password }) => {
                    if (!token) {
                        return;
                    }

                    try {
                        await sendNewPassword({ token, password });
                        setSuccess(true);

                        if (!isMobile) {
                            setTimeout(() => {
                                if (isCourses) {
                                    window.location.href = '/courses/signin';
                                } else {
                                    history.push('/signin');
                                }
                            }, 3000);
                        }
                    } catch {
                        throw makeFormErrors({
                            password: t(`${i18nPath}.weakPassword`),
                        });
                    }
                },
                [token, isCourses, isMobile, history, t],
            ),
        },
    );

    const passwordField = useField(control, 'password', {
        rules: {
            required: true,
        },
    });

    const [checked, setChecked] = useState(false);
    const [isSuccess, setSuccess] = useState(false);

    useEffect(() => {
        const check = async () => {
            try {
                if (token) {
                    const data = await checkRestorePasswordToken({ token });
                    if (!data.valid) {
                        return history.push('/');
                    }
                }
            } finally {
                setChecked(true);
            }
        };

        check();
    }, [token, history]);

    if (!checked) {
        return <LoadingScreen />;
    }

    return (
        <>
            <Helmet>
                <title>{t('pageTitle.passwordRecovery')}</title>
            </Helmet>

            <AuthLayout>
                {isSuccess ? (
                    <AuthContent title={t(`${i18nPath}.passwordChanged`)} />
                ) : (
                    <AuthContent title={t(`${i18nPath}.newPassword`)}>
                        <AuthForm {...createTestIdProps(baseTestId, 'form')}>
                            <AuthPasswordField
                                {...passwordField}
                                label={t(`${i18nPath}.password`)}
                                {...createTestIdProps(baseTestId, 'field', 'password')}
                            />
                            <AuthButtons
                                main={{
                                    text: t(`${i18nPath}.submit`),
                                    onClick: submit,
                                    ...createTestIdProps(baseTestId, 'action', 'submit'),
                                }}
                            />
                        </AuthForm>
                    </AuthContent>
                )}
            </AuthLayout>
        </>
    );
};

export default PasswordRecoveryToken;
