import { getUniqueDialogId } from './getUniqueDialogId';
import { useDialogContext } from './useDialogContext';
import { useMemo, useEffect, useCallback } from 'react';
export var useCustomDialog = function useCustomDialog(render, defaultProps) {
  var _useDialogContext = useDialogContext(),
    openDialog = _useDialogContext.openDialog,
    closeDialog = _useDialogContext.closeDialog,
    registerDialog = _useDialogContext.registerDialog,
    unregisterDialog = _useDialogContext.unregisterDialog;
  var id = useMemo(getUniqueDialogId, []);
  useEffect(function () {
    registerDialog({
      id: id,
      defaultProps: defaultProps || {},
      render: render
    });
  }, [id, defaultProps, registerDialog, render]);
  useEffect(function () {
    return function () {
      return unregisterDialog(id);
    };
  }, [unregisterDialog, id]);
  var openCustomDialog = useCallback(function (props, options) {
    openDialog(id, props, options);
  }, [openDialog, id]);
  var closeCustomDialog = useCallback(function () {
    closeDialog(id);
  }, [closeDialog, id]);
  return [openCustomDialog, closeCustomDialog];
};