import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';

import { apiFetch, apiFormFetch, constructUrl } from '@wbnr/frontend-shared/lib/api';
import LoadingScreen from '@wbnr/frontend-shared/lib/components/LoadingScreen';

const CreateEvent = () => {
    const { replace } = useHistory();
    const { type = 'webinar' } = useParams();

    useEffect(() => {
        let canceled = false;

        createEvent(type).then((id) => {
            if (!canceled) {
                replace(`/event/${id}/edit`);
            }
        });

        return () => (canceled = true);
    }, [type, replace]);

    return <LoadingScreen />;
};

const createEvent = async (type) => {
    const { id } = await apiFetch(constructUrl('/reserved/event', { type })).catch(() => {
        return apiFormFetch('POST', constructUrl('/reserved/event'), { type });
    });

    await apiFormFetch('PUT', `/event/${id}`, {
        description: '',
        accessSettings: {
            isRegistrationRequired: false,
            isPasswordRequired: false,
            isModerationRequired: false,
        },
        name: 'Новое мероприятие сегодня',
    });

    return id;
};

export default CreateEvent;
