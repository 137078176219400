import formatISO from 'date-fns/formatISO';
import { useMemo, useCallback, useState, ComponentProps } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import LoadingScreen from '@wbnr/frontend-shared/lib/components/LoadingScreen';
import {
    useCourseStatistics,
    useCoursesForFilter,
    useExportCourseStatistics,
    AggregateCourseStatistics,
} from '@wbnr/frontend-shared/lib/data/statistics';
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
    Select,
    MenuItem,
    Button,
} from '@wbnr/ui';

import StatisticsCoursesFilter from '../StatisticsCoursesFilter';
import StatisticsPeriodPicker, { DateRange } from '../StatisticsPeriodPicker';

import styles from './Statistics.module.scss';
import StatisticsLayout from './StatisticsLayout';
import { usePeriodFilter, PERIOD_TYPES, DEFAULT_PERIOD_TYPE } from './usePeriodFilter';

const ALL_COURSES_KEY = 'all-courses';
const PEDIOD_LABEL_KEYS_MAP = {
    [PERIOD_TYPES.ALL_TIME]: 'statistics.intervals.allTime',
    [PERIOD_TYPES.BY_WEEK]: 'statistics.intervals.byWeek',
    [PERIOD_TYPES.BY_MONTH]: 'statistics.intervals.byMonth',
    [PERIOD_TYPES.BY_YEAR]: 'statistics.intervals.byYear',
    [PERIOD_TYPES.CUSTOM]: 'statistics.intervals.byPeriod',
};

const Statistics = () => {
    const { data: coursesListData } = useCoursesForFilter();
    const [checkedCourses, setCheckedCourses] = useState([]);
    const { startAt, endAt, periodType, customPeriod, onChangePeriodType, onChangeCustomPeriod } =
        usePeriodFilter(DEFAULT_PERIOD_TYPE);

    const checkedCoursesForApi = useMemo(
        () => checkedCourses.filter((val) => val !== ALL_COURSES_KEY),
        [checkedCourses],
    );
    const startAtFormatted = startAt && formatISO(startAt);
    const endAtFormatted = endAt && formatISO(endAt);
    const { data, fulfilled } = useCourseStatistics(
        startAtFormatted,
        endAtFormatted,
        checkedCoursesForApi,
    );
    const [onDownload] = useExportCourseStatistics(startAtFormatted, checkedCoursesForApi);

    const handleUpdateCheckedCourses = useCallback(
        (newCheckedCourses) => {
            setCheckedCourses(newCheckedCourses);
        },
        [setCheckedCourses],
    );

    if (!fulfilled) {
        return (
            <StatisticsLayout>
                <LoadingScreen />
            </StatisticsLayout>
        );
    }

    return (
        <StatisticsLayout>
            <div className={styles.root}>
                <div className={styles.header}>
                    <StatisticsTitle periodType={periodType} customPeriod={customPeriod} />
                    <div className={styles.filters}>
                        <div className={styles.filterAnchor}>
                            <StatisticsCoursesFilter
                                courseList={coursesListData || []}
                                checkedCoursesList={checkedCourses}
                                allCoursesKey={ALL_COURSES_KEY}
                                onClose={handleUpdateCheckedCourses}
                            />
                        </div>
                        <div className={styles.filterAnchor}>
                            <PeriodFilter value={periodType} onChange={onChangePeriodType} />
                        </div>
                        {periodType === PERIOD_TYPES.CUSTOM && (
                            <div className={styles.filterAnchor}>
                                <StatisticsPeriodPicker
                                    value={customPeriod as DateRange}
                                    onChange={onChangeCustomPeriod}
                                />
                            </div>
                        )}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onDownload}
                            className={styles.downloadButton}
                        >
                            <Trans i18nKey="statistics.downloadXLS" />
                        </Button>
                    </div>
                </div>
                <div className={styles.dataTableWrapper}>
                    <StatisticsSummaryTable data={data} />
                </div>
            </div>
        </StatisticsLayout>
    );
};

function StatisticsTitle({
    periodType,
    customPeriod,
}: {
    periodType: string;
    customPeriod: DateRange;
}) {
    const { t } = useTranslation();
    let displayedTitle = '';

    if (periodType !== PERIOD_TYPES.CUSTOM) {
        displayedTitle = t(PEDIOD_LABEL_KEYS_MAP[periodType]);
    } else {
        const dateFrom = t('ui:date.dateStr', {
            date: customPeriod.from || new Date(),
        });
        const dateTo = t('ui:date.dateStr', {
            date: customPeriod.to || new Date(),
        });

        displayedTitle = `${dateFrom} - ${dateTo}`;
    }

    return (
        <Typography variant="h4" classes={{ root: styles.title }}>
            {displayedTitle}
        </Typography>
    );
}

function PeriodFilter({ value, onChange }: { value: string; onChange: (value: string) => void }) {
    const { t } = useTranslation();
    const handleChange: ComponentProps<typeof Select>['onChange'] = (event) => {
        onChange(event.target.value as string);
    };

    const items = [
        PERIOD_TYPES.ALL_TIME,
        PERIOD_TYPES.BY_WEEK,
        PERIOD_TYPES.BY_MONTH,
        PERIOD_TYPES.BY_YEAR,
        PERIOD_TYPES.CUSTOM,
    ].map((periodType) => ({ value: periodType, label: t(PEDIOD_LABEL_KEYS_MAP[periodType]) }));

    return (
        <Select value={value} className={styles.select} onChange={handleChange}>
            {items.map(({ label, value: periodType }) => (
                <MenuItem key={value} value={periodType}>
                    {label}
                </MenuItem>
            ))}
        </Select>
    );
}

function StatisticsSummaryTable({ data }: { data: AggregateCourseStatistics | null | undefined }) {
    const { t } = useTranslation();
    const rowsDataMap = useMemo(() => {
        return [
            {
                label: t('statistics.coursesCountLabel'),
                value: data?.coursesCount ?? 0,
            },
            {
                label: t('statistics.activeStudentsCountLabel'),
                value: data?.activeStudentsCount ?? 0,
            },
            {
                label: t('statistics.averagePassingLabel'),
                helperText: t('statistics.averagePassingHelper'),
                value: formatToPercents(data?.averagePassing ?? 0),
            },
            {
                label: t('statistics.finishedStudentsPartLabel'),
                helperText: t('statistics.finishedStudentsPartHelper'),
                value: formatToPercents(data?.finishedStudentsPart ?? 0),
            },
        ];
    }, [t, data]);

    const textClasses = { root: styles.dataText };

    return (
        <Table classes={{ root: styles.dataTable }}>
            <TableBody>
                {rowsDataMap.map((row, id) => (
                    <TableRow key={id} classes={{ root: styles.dataTableRow }}>
                        <TableCell>
                            <Typography classes={textClasses}>{row.label}</Typography>
                            {row.helperText && (
                                <Typography classes={textClasses} color="textSecondary">
                                    {row.helperText}
                                </Typography>
                            )}
                        </TableCell>
                        <TableCell classes={{ root: styles.dataValueCell }}>{row.value}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}

function formatToPercents(value: number | string): string {
    return Number(Number(value) * 100).toFixed(2) + '%';
}

export default Statistics;
