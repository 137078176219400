function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import { useReducer, useEffect, useCallback, useRef } from 'react';
import { apiFetch, APIError } from './apiFetch';
import { useUnmountedRef } from '../hooks/useUnmountedRef';
var getInitialState = function getInitialState(_ref) {
  var preventAutoLoad = _ref.preventAutoLoad;
  return {
    loading: !preventAutoLoad,
    error: null,
    data: null,
    fulfilled: false
  };
};
function apiReducer(state, action) {
  switch (action.type) {
    case 'startFetching':
      return {
        loading: true,
        error: null,
        data: state.data,
        fulfilled: state.fulfilled
      };
    case 'success':
      return {
        loading: false,
        error: null,
        data: action.data,
        fulfilled: true
      };
    case 'error':
      return {
        loading: false,
        error: action.error,
        data: null,
        fulfilled: true
      };
    default:
      var unknownType = action.type;
      throw new Error("Unsupported action type ".concat(unknownType));
  }
}
export function useApi(routeOrRequest) {
  var _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    preventAutoLoad = _ref2.preventAutoLoad;
  var _useReducer = useReducer(apiReducer, getInitialState({
      preventAutoLoad: !!preventAutoLoad
    })),
    _useReducer2 = _slicedToArray(_useReducer, 2),
    _useReducer2$ = _useReducer2[0],
    data = _useReducer2$.data,
    loading = _useReducer2$.loading,
    error = _useReducer2$.error,
    fulfilled = _useReducer2$.fulfilled,
    rawDispatch = _useReducer2[1];
  var unmountedRef = useUnmountedRef();
  var request = useCallback(function () {
    if (isRequest(routeOrRequest)) {
      return routeOrRequest();
    }
    return apiFetch(routeOrRequest);
  }, [routeOrRequest]);
  var dispatch = useCallback(function (action) {
    if (!unmountedRef.current) {
      rawDispatch(action);
    }
  }, [rawDispatch, unmountedRef]);
  var currentRequest = useRef(null);
  var makeRequest = useCallback(function () {
    if (currentRequest.current) {
      currentRequest.current.cancel();
      currentRequest.current = null;
    }
    var canceled = false;
    var resolve = function resolve() {};
    var reject;
    var promise = new Promise(function (newResolve, newReject) {
      resolve = newResolve;
      reject = newReject;
    });
    currentRequest.current = {
      promise: promise,
      cancel: function cancel() {
        canceled = true;
        resolve({
          canceled: true
        });
        currentRequest.current = null;
      }
    };
    dispatch({
      type: 'startFetching'
    });
    if (!routeOrRequest) {
      dispatch({
        type: 'success',
        data: null
      });
      currentRequest.current = null;
      resolve(null);
    } else {
      request().then(function (data) {
        if (!canceled) {
          dispatch({
            type: 'success',
            data: data
          });
          currentRequest.current = null;
          resolve(data);
        }
        return data;
      }).catch(function (error) {
        if (!canceled) {
          if (error instanceof APIError) {
            dispatch({
              type: 'error',
              error: error
            });
          }
          currentRequest.current = null;
          reject(error);
        }
      });
    }
    return promise;
  }, [dispatch, routeOrRequest, request]);
  useEffect(function () {
    if (!preventAutoLoad) {
      makeRequest().catch(function (err) {
        if (!(err instanceof APIError)) {
          throw err;
        }
      });
    }
  }, [makeRequest, preventAutoLoad]);
  return [data, loading, error, makeRequest, fulfilled];
}
function isRequest(routeOrRequest) {
  return typeof routeOrRequest === 'function';
}