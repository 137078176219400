import { FC, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import {
    approveDomainTxt,
    createDomain,
    getDomains,
    getSAMLSettings,
    getSSOSettings,
    removeDomain,
    updateSAMLSettings,
    updateSSOSettings,
} from '@wbnr/frontend-shared/lib/api/business-api';
import { Domain, SAMLSettings, SSOSetting } from '@wbnr/frontend-shared/lib/api/business-api/types';
import { getMemberships, updateMembership } from '@wbnr/frontend-shared/lib/api/organization';
import { Membership } from '@wbnr/frontend-shared/lib/api/organization/types';
import { getUserOrganizationId, useAccountUser } from '@wbnr/frontend-shared/lib/data/user';
import { ResetStateAction, useResource } from '@wbnr/frontend-shared/lib/data/useResource';
import { useBinded } from '@wbnr/frontend-shared/lib/hooks/useBinded';
import { ReducerAction } from '@wbnr/frontend-shared/lib/types/ReducerAction';

import { SSOStep } from './constants';

export type SSOContextData = {
    step?: SSOStep;
    setStep?: (value: SSOStep) => void;
    organizationId?: number;
    samlSettings?: SAMLSettings | null;
    updateSAML?: (value: SAMLSettings) => Promise<void>;
    ssoSettings: SSOSetting;
    updateSSO?: (value: SSOSetting) => Promise<SSOSetting>;
    loadingSSO?: boolean;
    domains?: Domain[] | null;
    addDomain?: (newDomain: string) => Promise<Domain>;
    approveDomain?: (domain: Domain) => Promise<void>;
    deleteDomain?: (removedDomain: Domain) => Promise<void>;
    loadingDomain?: boolean;
    memberships?: Membership[] | null;
    membershipsLoading?: boolean;
    updateMembership?: (value: Membership) => Promise<void>;
    hasApprovedDomain?: boolean;
};

const DEFAULT_SSO = {
    loginToPersonalAccount: false,
    isNeedSsoForAll: false,
    prohibitionOfRegistrationWithoutSso: false,
};

export const SSOContext = createContext<SSOContextData>({
    step: SSOStep.LOADING,
    ssoSettings: DEFAULT_SSO,
});

export const useSSO = () => useContext(SSOContext);

interface DomainAddAction extends ReducerAction<'add'> {
    payload: Domain;
}

interface DomainDelAction extends ReducerAction<'delete'> {
    payload: Domain;
}

interface DomainUpdateAction extends ReducerAction<'update'> {
    payload: Domain;
}

interface SAMLUpdateAction extends ReducerAction<'update'> {
    payload: SAMLSettings;
}

interface SSOUpdateAction extends ReducerAction<'update'> {
    payload: SSOSetting;
}

interface MembershipUpdateAction extends ReducerAction<'update'> {
    payload: Membership;
}

const domainReducer = (
    state: Domain[] | null = [],
    action:
        | DomainAddAction
        | DomainDelAction
        | DomainUpdateAction
        | ResetStateAction<Partial<Domain>[], any>,
) => {
    switch (action.type) {
        case 'add':
            return [...(state || []), action.payload];
        case 'delete':
            return (state || []).filter((domain) => domain.id !== action.payload.id);
        case 'update':
            return (state || []).map((domain) =>
                domain.id === action.payload.id ? { ...domain, ...action.payload } : domain,
            );
        default:
            throw new Error(`Unsupported action type ${(action as any).type}`);
    }
};

const SAMLReducer = (
    state: SAMLSettings | undefined | null,
    action: SAMLUpdateAction | ResetStateAction<Partial<SAMLSettings>, any>,
) => {
    switch (action.type) {
        case 'update':
            return { ...action.payload };
        default:
            throw new Error(`Unsupported action type ${(action as any).type}`);
    }
};

const SSOReducer = (
    state: SSOSetting | undefined | null,
    action: SSOUpdateAction | ResetStateAction<Partial<SSOSetting>, any>,
) => {
    switch (action.type) {
        case 'update':
            return { ...action.payload };
        default:
            throw new Error(`Unsupported action type ${(action as any).type}`);
    }
};

const membershipsReducer = (
    state: Membership[] | undefined | null,
    action: MembershipUpdateAction | ResetStateAction<Partial<Membership>[], any>,
) => {
    switch (action.type) {
        case 'update':
            return (state || []).map((membership) =>
                membership.id === action.payload.id
                    ? { ...membership, ...action.payload }
                    : membership,
            );
        default:
            throw new Error(`Unsupported action type ${(action as any).type}`);
    }
};

export const SSOContextProvider: FC = ({ children }) => {
    const [user] = useAccountUser();
    const [step, setStep] = useState(SSOStep.LOADING);
    const organizationId = getUserOrganizationId(user);
    const {
        data: samlSettings,
        loading,
        dispatch: dispatchSAML,
        error,
    } = useResource(
        {
            request: useBinded(organizationId ? getSAMLSettings : undefined, {
                organizationId,
            }),
        },
        SAMLReducer,
    );

    const {
        data: ssoSettings,
        loading: loadingSSO,
        dispatch: dispatchSSO,
    } = useResource(
        {
            request: useBinded(organizationId ? getSSOSettings : undefined, { organizationId }),
        },
        SSOReducer,
    );
    const {
        data: domains,
        loading: loadingDomain,
        dispatch: dispatchDomain,
    } = useResource(
        {
            request: useBinded(getDomains),
        },
        domainReducer,
    );

    const {
        data: memberships,
        loading: membershipsLoading,
        dispatch: dispatchMemberships,
    } = useResource(
        {
            request: useBinded(organizationId ? getMemberships : undefined, { organizationId }),
        },
        membershipsReducer,
    );

    const addDomain = useCallback(
        (domain: string) => {
            return createDomain(domain).then((newDomain) => {
                dispatchDomain({ type: 'add', payload: newDomain });
                return newDomain;
            });
        },
        [dispatchDomain],
    );

    const deleteDomain = useCallback(
        (removedDomain: Domain) => {
            return removeDomain(removedDomain.id).then(() => {
                dispatchDomain({ type: 'delete', payload: removedDomain });
            });
        },
        [dispatchDomain],
    );

    const approveDomain = useCallback(
        (domain: Domain) =>
            approveDomainTxt(domain.id).then(() => {
                dispatchDomain({ type: 'update', payload: { ...domain, isApproved: true } });
            }),
        [dispatchDomain],
    );

    const updateSAML = useCallback(
        (newSettings: SAMLSettings) => {
            return updateSAMLSettings(organizationId, samlSettings?.id, newSettings).then(
                (result) => {
                    dispatchSAML({ type: 'update', payload: result });
                    setStep(SSOStep.SP);
                },
            );
        },
        [dispatchSAML, setStep, organizationId, samlSettings?.id],
    );

    const updateSSO = useCallback(
        (newSettings: SSOSetting) => {
            const oldSettings = ssoSettings;
            dispatchSSO({ type: 'update', payload: newSettings });
            return updateSSOSettings(organizationId, newSettings).catch(
                (updateSSOSettingsError) => {
                    dispatchSSO({ type: 'update', payload: oldSettings || DEFAULT_SSO });
                    throw updateSSOSettingsError;
                },
            );
        },
        [dispatchSSO, organizationId, ssoSettings],
    );

    const updateMembershipData = useCallback(
        (membership: Membership) => {
            return updateMembership(membership.id, { isSsoEnabled: !membership.isSsoEnabled }).then(
                (result) => {
                    dispatchMemberships({ type: 'update', payload: result });
                },
            );
        },
        [dispatchMemberships],
    );

    useEffect(() => {
        if (error) {
            setStep(SSOStep.IDP);
        } else if (!loading) {
            setStep(SSOStep.VIEW);
        }
    }, [loading, error]);

    const hasApprovedDomain = useMemo(
        () => domains?.some((domain) => domain.isApproved),
        [domains],
    );

    return (
        <SSOContext.Provider
            value={{
                step,
                setStep,
                samlSettings,
                ssoSettings: ssoSettings || DEFAULT_SSO,
                memberships,
                membershipsLoading,
                updateSAML,
                updateSSO,
                domains,
                addDomain,
                approveDomain,
                deleteDomain,
                organizationId,
                loadingSSO,
                loadingDomain,
                updateMembership: updateMembershipData,
                hasApprovedDomain,
            }}
        >
            {children}
        </SSOContext.Provider>
    );
};
