function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { isFreeProlongation, isParticipantFlow, isWebinarTrial, useUser } from '../../data/user';
import { isAccountUser } from '../../types/User';
import { createDate } from '../../utils/dates';
import { serverNow } from '../../utils/serverTime';
import { getUserActivity, setUserActivity } from '../../api/userActivity';
import { createTestIdProps } from '@wbnr/ui';
import TRIAL_PERIOD_CONFIG from './trialPeriodRangeConfig';
import { useFeatureSettings } from '../../data/featureSettings';
import { useTrialFeaturesDialog } from './useTrialFeaturesDialog';
import { useOnboardingContext } from '../../data/onboarding/useOnboardingContext';
import { useMixpanelTracking } from '../../analytics/mixpanel/useMixpanelTracking';
export var useWebinarTrialDialog = function useWebinarTrialDialog(_ref) {
  var canAutoOpen = _ref.canAutoOpen;
  var _useUser = useUser(),
    _useUser2 = _slicedToArray(_useUser, 1),
    user = _useUser2[0];
  var accountUser = isAccountUser(user) ? user : null;
  var _useOnboardingContext = useOnboardingContext(),
    activeStep = _useOnboardingContext.activeStep,
    groupFunctions = _useOnboardingContext.groupFunctions;
  var modalWasShown = useRef(false);
  var _useMixpanelTracking = useMixpanelTracking(),
    track = _useMixpanelTracking.track;
  var isTrial = accountUser && isWebinarTrial(accountUser);
  var trialEnded = !isTrial && accountUser && accountUser.hadWebinarTrialEver && !accountUser.hadPaidTariffEver && isFreeProlongation(accountUser.activeProlongation);
  var isSelfService = Boolean(accountUser === null || accountUser === void 0 ? void 0 : accountUser.isSelfService);
  var daysLeft = isTrial && accountUser ? Math.ceil((createDate(accountUser.activeProlongation.paidBefore).getTime() - serverNow()) / (24 * 60 * 60 * 1000)) : 0;
  var needInit = isTrial || trialEnded;
  var _useState = useState(),
    _useState2 = _slicedToArray(_useState, 2),
    needActivity = _useState2[0],
    setNeedActivity = _useState2[1];
  var _useFeatureSettings = useFeatureSettings(),
    features = _useFeatureSettings.data,
    fulfilled = _useFeatureSettings.fulfilled;
  var hasTrial30d = useMemo(function () {
    if (!fulfilled) {
      return undefined;
    }
    return Boolean(features.trial30d);
  }, [features, fulfilled]);
  var handleClose = useCallback(function () {
    if (needActivity && hasTrial30d !== undefined) {
      setUserActivity({
        token: needActivity
      }).then(function () {
        setNeedActivity(undefined);
      });
    }
  }, [hasTrial30d, needActivity]);
  var trialPeriodConfig = accountUser && hasTrial30d && !isParticipantFlow(accountUser) && TRIAL_PERIOD_CONFIG['30_DAYS'] || TRIAL_PERIOD_CONFIG['7_DAYS'];
  var _useTrialFeaturesDial = useTrialFeaturesDialog(_objectSpread(_objectSpread({
      ignorePreviousRequests: !needInit,
      daysLeft: daysLeft,
      onClose: handleClose
    }, createTestIdProps('WebinarTrial')), {}, {
      shouldDisableOnboarding: true
    })),
    openTrialFeaturesDialog = _useTrialFeaturesDial.openTrialFeaturesDialog,
    isRequested = _useTrialFeaturesDial.isRequested;
  var closeActiveOnboardingStep = useCallback(function () {
    if (activeStep !== null && activeStep !== void 0 && activeStep.groupId && activeStep !== null && activeStep !== void 0 && activeStep.stepId) {
      var _groupFunctions$activ;
      groupFunctions === null || groupFunctions === void 0 ? void 0 : (_groupFunctions$activ = groupFunctions[activeStep === null || activeStep === void 0 ? void 0 : activeStep.groupId]) === null || _groupFunctions$activ === void 0 ? void 0 : _groupFunctions$activ.finishStep(activeStep === null || activeStep === void 0 ? void 0 : activeStep.stepId);
    }
  }, [groupFunctions, activeStep]);
  var openWebinarTrialDialog = useCallback(function () {
    if (!accountUser) return;
    openTrialFeaturesDialog();
    closeActiveOnboardingStep();
    track('plg_trial_open', {
      isSelfService: isSelfService
    });
  }, [accountUser, closeActiveOnboardingStep, isSelfService, openTrialFeaturesDialog, track]);
  useEffect(function () {
    if (needInit && isRequested !== undefined && !needActivity) {
      var activity;
      if (daysLeft >= trialPeriodConfig.activity.normal.start && daysLeft <= trialPeriodConfig.activity.normal.end) {
        activity = 'webinarTrialFirstModalShown';
      } else if (daysLeft >= trialPeriodConfig.activity.warning.start && daysLeft <= trialPeriodConfig.activity.warning.end) {
        activity = 'webinarTrialSecondModalShown';
      } else if (trialEnded) {
        activity = 'webinarTrialFinishModalShown';
      }
      if (activity) {
        getUserActivity({
          token: activity
        }).then(function (response) {
          return setNeedActivity(!response ? activity : undefined);
        });
      }
    }
  }, [needInit, isRequested, daysLeft, trialEnded, needActivity, trialPeriodConfig]);
  useEffect(function () {
    if (canAutoOpen && needActivity && !modalWasShown.current) {
      modalWasShown.current = true;
      openTrialFeaturesDialog();
      track('plg_trial_open', {
        isSelfService: isSelfService
      });
    }
  }, [canAutoOpen, needActivity, modalWasShown, isSelfService, track, openTrialFeaturesDialog]);
  return {
    openWebinarTrialDialog: openWebinarTrialDialog
  };
};