"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var getFilesFromWysiwyg = function getFilesFromWysiwyg(description) {
  try {
    var _JSON$parse = JSON.parse(description),
      entityMap = _JSON$parse.entityMap;
    var files = Object.values(entityMap).filter(function (_ref) {
      var data = _ref.data;
      return !!data.isFile;
    }).map(function (_ref2) {
      var data = _ref2.data;
      return data.id;
    });
    return files;
  } catch (_unused) {
    return null;
  }
};
var _default = getFilesFromWysiwyg;
exports.default = _default;