import { useTranslation } from 'react-i18next';

import { useField, useFormContext } from '@wbnr/frontend-shared/lib/forms';
import { prependProtocolToLink } from '@wbnr/frontend-shared/lib/utils/links';
import { translit } from '@wbnr/frontend-shared/lib/utils/translit';
import {
    validateEmail,
    validateLink,
    validateNotDigitStart,
} from '@wbnr/frontend-shared/lib/utils/validators';
import { TextField } from '@wbnr/ui';

import { getBrandingPropName } from '../../utils';
import BrandingHeading from '../BrandingHeading';
import BrandingSwitchField from '../BrandingSwitchField';

const i18nPath = 'business.brandingPage';

const BrandingWebinar = () => {
    const { t } = useTranslation();

    const { control } = useFormContext();

    const eventOrganizationTitleField = useField(
        control,
        getBrandingPropName('eventOrganizationTitle'),
    );
    const eventCustomLinkField = useField(control, getBrandingPropName('eventCustomLink'), {
        rules: { validate: validateNotDigitStart },
    });
    const eventExitLinkField = useField(control, getBrandingPropName('eventExitLink'), {
        rules: { validate: validateLink },
    });
    const showSocialButtonsField = useField(control, getBrandingPropName('showSocialButtons'));
    const isQuestionsField = useField(control, getBrandingPropName('isQuestions'));
    const questionsEmailField = useField(control, getBrandingPropName('questionsEmail'), {
        rules: { required: Boolean(isQuestionsField.value), validate: validateEmail },
    });

    return (
        <>
            <BrandingHeading>{t(`${i18nPath}.webinar`)}</BrandingHeading>
            <TextField
                {...eventOrganizationTitleField}
                label={t(`${i18nPath}.eventOrganizationName`)}
                fullWidth
            />
            <TextField
                {...eventCustomLinkField}
                inputProps={{ maxLength: 20 }}
                onChange={(e) =>
                    eventCustomLinkField.onChange(
                        translit(e.target.value).replace(/[^a-z0-9]/gi, ''),
                    )
                }
                label={t(`${i18nPath}.linksOrganizationName`)}
                fullWidth
            />
            <TextField
                {...eventExitLinkField}
                label={t(`${i18nPath}.linkPageAfterWebinar`)}
                helperText={t(`${i18nPath}.linkPageAfterWebinarComment`)}
                onBlur={(e) => eventExitLinkField.onChange(prependProtocolToLink(e.target.value))}
                fullWidth
            />

            <BrandingSwitchField
                {...showSocialButtonsField}
                label={t(`${i18nPath}.showSocialNetworkButtons`)}
            />

            <BrandingSwitchField
                {...isQuestionsField}
                label={t(`${i18nPath}.showQuestionButton`)}
            />
            {isQuestionsField.value && (
                <TextField
                    {...questionsEmailField}
                    onChange={(e) =>
                        questionsEmailField.onChange(e.target.value.replace(/\s/g, ''))
                    }
                    label={t(`${i18nPath}.questionsReplyTo`)}
                    helperText={t(`${i18nPath}.questionsReplyToComment`)}
                    fullWidth
                />
            )}
        </>
    );
};

export default BrandingWebinar;
