import { useTranslation } from 'react-i18next';

import Banner from '@wbnr/frontend-shared/lib/components/Banner';
import { useCustomDialog } from '@wbnr/frontend-shared/lib/dialogs';
import { Button } from '@wbnr/ui';

import { useBannerParams } from 'shared/banners';

import { errorTypeTextKeys } from './errorTypeTextKeys';
import { WebrtcWarningConfirmIgnore } from './WebrtcWarningConfirmIgnore';
import { WebrtcWarningHelpDialog } from './WebrtcWarningHelpDialog';

const WebrtcWarningBanner = () => {
    const bannerParams = useBannerParams('webrtcWarning');
    const { t } = useTranslation();
    const { banner: bannerTextKey = '' } = errorTypeTextKeys[bannerParams.errorType] || {};
    const [openHelpDialog] = useCustomDialog(({ props, opened, closeDialog }) => (
        <WebrtcWarningHelpDialog {...props} opened={opened} closeDialog={closeDialog} />
    ));
    const [openConfirmIgnoreDialog] = useCustomDialog(({ props, opened, closeDialog }) => (
        <WebrtcWarningConfirmIgnore {...props} opened={opened} closeDialog={closeDialog} />
    ));

    return (
        <Banner
            show={bannerParams.show}
            title={t(bannerTextKey)}
            type={'danger'}
            onClose={() => {
                openConfirmIgnoreDialog({
                    onConfirm: bannerParams.onClose,
                    onGetHelp: () => openHelpDialog({ errorType: bannerParams.errorType }),
                });
            }}
            action={
                <Button
                    color="inherit"
                    onClick={() => {
                        openHelpDialog({ errorType: bannerParams.errorType });
                    }}
                >
                    {t('banners.webrtcHelp')}
                </Button>
            }
        />
    );
};

export default WebrtcWarningBanner;
