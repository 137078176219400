function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
export function formatProlongationData(data) {
  return {
    name: data.name,
    id: defineGroupKey(data === null || data === void 0 ? void 0 : data.group),
    key: data.key,
    prices: [],
    tariffId: data.tariffId,
    options: prolongationOptionsFilter(data.options),
    paidUntil: new Date(data.paidBefore),
    displayName: data.tariffDisplayName,
    isCurrentTariff: true
  };
}
export function formatTariffData(data) {
  return {
    name: data.name,
    prices: data.prices,
    tariffId: data.id,
    id: defineGroupKey(data.group),
    options: optionsFilter(data.options),
    displayName: data.displayName
  };
}
var defaultOptions = {
  isInfinitySession: {
    name: 'isInfinitySession',
    value: true
  },
  isMobileApp: {
    name: 'isMobileApp',
    value: true
  },
  isSupport: {
    name: 'isSupport',
    value: true
  },
  isPersonalManager: {
    name: 'isPersonalManager',
    value: true
  }
};
function setDefaultOptions(result) {
  var key;
  for (key in defaultOptions) {
    if (!Object.keys(result).includes(key)) {
      result[key] = defaultOptions[key];
    }
  }
  return result;
}
function prolongationOptionsFilter(optionsArray) {
  var result = {};
  var _iterator = _createForOfIteratorHelper(optionsArray),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var option = _step.value;
      var optionData = option.option.data;
      var dataKey = Object.keys(optionData)[0];
      var isIntOption = typeof optionData[dataKey] === 'number';
      var checkRepeatedOption = Object.keys(result).includes(dataKey);
      if (checkRepeatedOption && isIntOption) {
        var key = dataKey;
        result[key].value += option.option.data[dataKey];
      } else {
        result[dataKey] = {
          name: dataKey,
          value: Object.values(option.option.data)[0]
        };
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  return setDefaultOptions(result);
}
function optionsFilter(optionsArray) {
  var result = {};
  optionsArray.forEach(function (option) {
    var value = isNaN(Number(option.value)) ? option.value === 'true' : Number(option.value);
    result[option.name] = {
      name: option.name,
      value: value
    };
  });
  return setDefaultOptions(result);
}
export function defineGroupKey(group) {
  switch (group) {
    case 'Обучение':
      return 'training';
    case 'Маркетинг':
      return 'marketing';
    case 'Совещания':
      return 'meetings';
    default:
      return undefined;
  }
}