import cn from 'classnames';
import { useTranslation, Trans } from 'react-i18next';

import { useField, useFormContext } from '@wbnr/frontend-shared/lib/forms';
import { createTestIdProps, TextField } from '@wbnr/ui';

import styles from './AddDomainField.module.scss';

const BASE_TEST_ID = 'AddDomainField';

export const AddDomainField = () => {
    const { t } = useTranslation();
    const { control } = useFormContext();
    const domainField = useField(control, 'domain', { rules: { required: true } });
    return (
        <div className={styles.root}>
            <TextField
                {...domainField}
                fullWidth
                label={t('business.sso.domain.label')}
                {...createTestIdProps(BASE_TEST_ID, 'input')}
            />
            <div className={cn(styles.hint, { [styles.emptyError]: !domainField.error })}>
                <Trans i18nKey={'business.sso.domain.hint'} />
            </div>
        </div>
    );
};
