"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stopListenFullscreenChange = exports.requestFullscreen = exports.listenFullscreenChange = exports.getFullscreenElement = exports.cancelFullScreen = void 0;
var requestFullscreen = function requestFullscreen(element) {
  if (element.requestFullscreen) {
    return element.requestFullscreen();
  }
  if (element.mozRequestFullScreen) {
    return element.mozRequestFullScreen();
  }
  if (element.webkitRequestFullscreen) {
    return element.webkitRequestFullscreen();
  }
  if (element.msRequestFullscreen) {
    return element.msRequestFullscreen();
  }
};
exports.requestFullscreen = requestFullscreen;
var cancelFullScreen = function cancelFullScreen() {
  if (document.cancelFullScreen) {
    return document.cancelFullScreen();
  }
  if (document.mozCancelFullScreen) {
    return document.mozCancelFullScreen();
  }
  if (document.webkitCancelFullScreen) {
    return document.webkitCancelFullScreen();
  }
  if (document.msCancelFullScreen) {
    return document.msCancelFullScreen();
  }
};
exports.cancelFullScreen = cancelFullScreen;
var getFullscreenElement = function getFullscreenElement() {
  return document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement;
};
exports.getFullscreenElement = getFullscreenElement;
var listenFullscreenChange = function listenFullscreenChange(listener) {
  document.addEventListener('fullscreenchange', listener);
  document.addEventListener('mozfullscreenchange', listener);
  document.addEventListener('webkitfullscreenchange', listener);
  document.addEventListener('msfullscreenchange', listener);
};
exports.listenFullscreenChange = listenFullscreenChange;
var stopListenFullscreenChange = function stopListenFullscreenChange(listener) {
  document.removeEventListener('fullscreenchange', listener);
  document.removeEventListener('mozfullscreenchange', listener);
  document.removeEventListener('webkitfullscreenchange', listener);
  document.removeEventListener('msfullscreenchange', listener);
};
exports.stopListenFullscreenChange = stopListenFullscreenChange;