import { useSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { Domain } from '@wbnr/frontend-shared/lib/api/business-api/types';
import { DeleteOutlinedIcon } from '@wbnr/icons';
import { ActionDialog, createTestIdProps, DialogContentText, IconButton } from '@wbnr/ui';

import { useSSO } from '../../SSOContext';

const BASE_TEST_ID = 'DeleteDomain';

export const DeleteDomain: FC<{ domain: Domain }> = ({ domain }) => {
    const { t } = useTranslation();
    const [confirmOpened, setConfirmOpened] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { deleteDomain } = useSSO();
    const [loading, setLoading] = useState(false);
    const handleDeleteDomain = () => {
        setConfirmOpened(true);
    };

    const handleConfirmDelete = async () => {
        setLoading(true);
        setConfirmOpened(false);
        if (deleteDomain) {
            try {
                await deleteDomain(domain);
            } catch (error) {
                enqueueSnackbar(t('unknownError'), { variant: 'error' });
            } finally {
                setLoading(false);
            }
        }
    };
    return (
        <>
            <IconButton
                disabled={loading}
                title={t('business.sso.domain.delete')}
                onClick={handleDeleteDomain}
                {...createTestIdProps(BASE_TEST_ID, 'deleteBtn')}
            >
                <DeleteOutlinedIcon />
            </IconButton>
            <ActionDialog
                maxWidth="md"
                title={t('business.sso.domain.confirm.title')}
                opened={confirmOpened}
                onSubmit={handleConfirmDelete}
                onClose={() => setConfirmOpened(false)}
                containedSubmit
                submitText={t('business.sso.domain.delete')}
                {...createTestIdProps(BASE_TEST_ID, 'confirmDialog')}
            >
                <DialogContentText>
                    <Trans
                        i18nKey={'business.sso.domain.confirm.text'}
                        values={{ domain: domain.domain }}
                    />
                </DialogContentText>
            </ActionDialog>
        </>
    );
};
