"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styles = require("../../styles");
var usePrimaryColor = function usePrimaryColor() {
  var _useTheme = (0, _styles.useTheme)(),
    palette = _useTheme.palette;
  var _ref = palette || {},
    primary = _ref.primary;
  var _ref2 = primary || {},
    primaryColor = _ref2.main;
  return primaryColor;
};
var _default = usePrimaryColor;
exports.default = _default;