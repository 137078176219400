import React from 'react';
import { useTranslation } from 'react-i18next';

import LoadingScreen from '@wbnr/frontend-shared/lib/components/LoadingScreen';
import WebinarPageTopbar from '@wbnr/frontend-shared/lib/components/WebinarPageTopbar';
import { useFeatureSettings } from '@wbnr/frontend-shared/lib/data/featureSettings';
import { SidebarLayout, TopbarLayout } from '@wbnr/ui';

import NotFoundPage from 'routes/notFound';

import StatisticsNavigation from './StatisticsNavigation';

interface Props {
    children: React.ReactNode;
}

const StatisticsLayout = ({ children }: Props) => {
    const { t } = useTranslation();
    const { data: features, fulfilled: featuresFulfilled } = useFeatureSettings();

    if (!featuresFulfilled) {
        return <LoadingScreen />;
    }

    if (featuresFulfilled && !features.isCourseStatisticEnabled) {
        return <NotFoundPage />;
    }

    return (
        <TopbarLayout topbar={<WebinarPageTopbar title={t('statistics.title')} showControls />}>
            <SidebarLayout side="left" bar={<StatisticsNavigation />}>
                {children}
            </SidebarLayout>
        </TopbarLayout>
    );
};

export default StatisticsLayout;
