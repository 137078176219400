import { useEffect, useState, useMemo, useCallback } from 'react';

import { useFeatureSettings } from '@wbnr/frontend-shared/lib/data/featureSettings';
import { useUser } from '@wbnr/frontend-shared/lib/data/user';
import { isEdge } from '@wbnr/frontend-shared/lib/utils/browser';
import { appLocalStorage } from '@wbnr/frontend-shared/lib/utils/browserStorage';
import { checkWebrtc } from '@wbnr/frontend-shared/lib/utils/checkWebrtc';

const NEXT_CHECK_KEY = 'webrtc_next_check';

let checkData = {};

try {
    const rawCheckData = localStorage.getItem(NEXT_CHECK_KEY);

    if (rawCheckData) {
        checkData = JSON.parse(rawCheckData);
    }
} catch (e) {
    // TODO: catch is empty. check the reason.
}

export const useWebrtcWarningBanner = () => {
    const [user] = useUser();
    const { data: features, fulfilled: featuresFulfilled } = useFeatureSettings();
    const [errorType, setErrorType] = useState(undefined);
    const userId = (user && user.type === 'USER' && user.id) || undefined;
    const streamMode =
        user && user.type === 'USER' && user.eventSettings && user.eventSettings.streamMode;
    const shouldOpenConfirmModal = Boolean(checkData[errorType]);

    useEffect(() => {
        if (!userId || streamMode !== 'webrtc' || !featuresFulfilled) {
            return;
        }

        checkWebrtc({ allowYaBrowser: features.allowYaBrowser })
            .catch(function (err) {
                switch (err) {
                    case 'SignalingNetworkError':
                        return 'network';
                    case 'PeerNetworkError':
                    case 'TimeoutError':
                        return 'network_blocked';
                    case 'UnhandledCreateOfferError':
                    case 'UnhandledError':
                        return 'unknown';
                    default:
                        return 'browser';
                }
            })
            .then(function (result) {
                result = isEdge ? 'browser' : result;
                if (result) {
                    if (!checkData[result] || checkData[result] <= Date.now()) {
                        setErrorType(result);
                    }
                } else {
                    setErrorType(false);
                    checkData = {};
                    appLocalStorage.removeItem(NEXT_CHECK_KEY);
                }
            });
    }, [setErrorType, userId, streamMode, features, featuresFulfilled]);

    const onClose = useCallback(() => {
        if (shouldOpenConfirmModal) {
            checkData[errorType] = Number.MAX_SAFE_INTEGER;
        } else {
            checkData[errorType] = Date.now() + 14 * 24 * 3600;
        }

        saveCheckData();
        setErrorType(undefined);
    }, [errorType, setErrorType, shouldOpenConfirmModal]);

    return useMemo(
        () => ({
            show: Boolean(errorType),
            errorType,
            shouldOpenConfirmModal,
            onClose,
        }),
        [errorType, shouldOpenConfirmModal, onClose],
    );
};

const saveCheckData = () => {
    try {
        appLocalStorage.setItem(NEXT_CHECK_KEY, JSON.stringify(checkData));
    } catch (e) {
        // TODO: catch is empty. check the reason.
    }
};
