import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
var LOCALE_RU = 'RU';
var useTrialFeatures = function useTrialFeatures(_ref) {
  var mainFeatures = _ref.mainFeatures,
    otherFeatures = _ref.otherFeatures;
  var _useTranslation = useTranslation(),
    t = _useTranslation.t,
    locale = _useTranslation.i18n.language;
  var mainFeaturesMemoized = useMemo(function () {
    return mainFeatures.map(function (feature) {
      return {
        text: t(feature.text),
        trialText: feature.trialText && t(feature.trialText),
        Icon: feature.Icon,
        isInTrial: Boolean(feature.isInTrial)
      };
    });
  }, [mainFeatures, t]);
  var otherFeaturesMemoized = useMemo(function () {
    return otherFeatures.map(function (feature) {
      return {
        text: t(feature.text),
        testId: feature.testId,
        description: t(feature.description),
        image: locale.toUpperCase() === LOCALE_RU ? feature.ruImage : feature.enImage,
        isInTrial: Boolean(feature.isInTrial)
      };
    });
  }, [locale, otherFeatures, t]);
  return {
    mainFeaturesMemoized: mainFeaturesMemoized,
    otherFeaturesMemoized: otherFeaturesMemoized
  };
};
export default useTrialFeatures;