export var certificateEncode = function certificateEncode(certificate) {
  var encoded = encodeURIComponent(certificate);
  var unescaped = unescape(encoded);
  return btoa(unescaped);
};
export var certificateDecode = function certificateDecode(certificate) {
  try {
    var unescaped = atob(certificate);
    var encoded = escape(unescaped);
    return decodeURIComponent(encoded);
  } catch (_) {
    return '';
  }
};