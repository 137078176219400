import { useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './i18n';
import { FullstoryProvider } from '@wbnr/frontend-shared/lib/analytics/fullstory';
import IntercomProvider from '@wbnr/frontend-shared/lib/analytics/intercom/IntercomProvider';
import { MixpanelProvider } from '@wbnr/frontend-shared/lib/analytics/mixpanel/MixpanelProvider';
import { Wootric } from '@wbnr/frontend-shared/lib/analytics/Wootric';
import { CometProvider, CometClient } from '@wbnr/frontend-shared/lib/comet';
import { CacheProvider } from '@wbnr/frontend-shared/lib/data/cachedResource/cacheContext';
import { RootOnboardingProvider } from '@wbnr/frontend-shared/lib/data/onboarding';
import { SuperbrandingProvider } from '@wbnr/frontend-shared/lib/data/superbranding';
import { UserProvider } from '@wbnr/frontend-shared/lib/data/user';
import { DialogsProvider } from '@wbnr/frontend-shared/lib/dialogs';
import { BrandingThemeProvider } from '@wbnr/frontend-shared/lib/theming';

import { BannersProvider } from 'shared/banners';
import { CommonDialogsProvider } from 'shared/commonDialogs';

import { AppContextProvider } from './AppContext';
import Banners from './components/Banners';
import Root from './components/Root';
import Meetings from './containers/MeetingsRoutes';
import SnackbarProvider from './containers/SnackbarProvider';
import RootRoutes from './routes';

const cometClient = new CometClient();

function App() {
    return (
        <DndProvider backend={HTML5Backend}>
            <AppContextProvider>
                <AppContextContent />
            </AppContextProvider>
        </DndProvider>
    );
}

function AppContextContent() {
    const { t } = useTranslation();
    const getUserConfirmation = useCallback((message, callback) => {
        const ok = window.confirm(message);
        const event = new Event('reactRouterNavigateConfirmDenied');

        window.dispatchEvent(event);

        return callback(ok);
    }, []);

    return (
        <SuperbrandingProvider>
            <RootOnboardingProvider>
                <CometProvider client={cometClient}>
                    <CacheProvider>
                        <BrandingThemeProvider>
                            <UserProvider>
                                <FullstoryProvider>
                                    <IntercomProvider>
                                        <MixpanelProvider>
                                            <Router getUserConfirmation={getUserConfirmation}>
                                                <Root>
                                                    <Wootric />
                                                    <Helmet
                                                        titleTemplate={t(
                                                            'pageTitle.webinarTemplate',
                                                        )}
                                                    />
                                                    <SnackbarProvider>
                                                        <DialogsProvider>
                                                            <CommonDialogsProvider>
                                                                <BannersProvider>
                                                                    <Banners />
                                                                    <Switch>
                                                                        <Route
                                                                            path="/meetings"
                                                                            component={Meetings}
                                                                        />
                                                                        <Route
                                                                            path="/"
                                                                            component={RootRoutes}
                                                                        />
                                                                    </Switch>
                                                                </BannersProvider>
                                                            </CommonDialogsProvider>
                                                        </DialogsProvider>
                                                    </SnackbarProvider>
                                                </Root>
                                            </Router>
                                        </MixpanelProvider>
                                    </IntercomProvider>
                                </FullstoryProvider>
                            </UserProvider>
                        </BrandingThemeProvider>
                    </CacheProvider>
                </CometProvider>
            </RootOnboardingProvider>
        </SuperbrandingProvider>
    );
}

export default App;
