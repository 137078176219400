"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectFilesFromSystem = selectFilesFromSystem;
var fileInputElem = null;
function removeFileInputElem() {
  if (fileInputElem) {
    document.body.removeChild(fileInputElem);
    fileInputElem = null;
  }
}
function selectFilesFromSystem() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    accept = _ref.accept;
  removeFileInputElem();
  fileInputElem = document.createElement('input');
  fileInputElem.type = 'file';
  fileInputElem.setAttribute('multiple', 'true');
  fileInputElem.style.position = 'absolute';
  fileInputElem.style.top = '-10000px';
  fileInputElem.style.visibility = 'hidden';
  document.body.appendChild(fileInputElem);
  if (accept) {
    fileInputElem.setAttribute('accept', accept);
  } else {
    fileInputElem.removeAttribute('accept');
  }
  return new Promise(function (resolve) {
    if (fileInputElem) {
      fileInputElem.files = null;
      fileInputElem.onchange = function () {
        fileInputElem && resolve(fileInputElem.files);
        removeFileInputElem();
      };
      fileInputElem.click();
    }
  });
}