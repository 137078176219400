"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _draftJs = _interopRequireDefault(require("draft-js"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var EditorState = _draftJs.default.EditorState,
  Modifier = _draftJs.default.Modifier;
var textAlignPlugin = function textAlignPlugin() {
  var styles = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var pluginData = {};
  return {
    initialize: function initialize(_ref) {
      var setEditorState = _ref.setEditorState,
        getEditorState = _ref.getEditorState;
      pluginData.setEditorState = setEditorState;
      pluginData.getEditorState = getEditorState;
      pluginData.inited = true;
    },
    blockStyleFn: function blockStyleFn(block) {
      return styles[block.getData().get('textAlign') || 'left'];
    },
    setAlignment: function setAlignment(align) {
      if (pluginData.inited) {
        var editorState = pluginData.getEditorState();
        var content = Modifier.mergeBlockData(editorState.getCurrentContent(), editorState.getSelection(), {
          textAlign: align
        });
        pluginData.setEditorState(EditorState.push(editorState, content, 'change-block-data'));
      }
    },
    handlePastedText: function handlePastedText(text, html, editorState, _ref2) {
      var getEditorRef = _ref2.getEditorRef;
      var editor = getEditorRef();
      var blocksMap = editor.getClipboard();
      if (blocksMap) {
        var lastKey = blocksMap.keySeq().last();
        if (lastKey) {
          var selection = editorState.getSelection();
          var content = editorState.getCurrentContent();
          var endBlock = content.getBlockForKey(selection.getEndKey());
          var last = blocksMap.get(lastKey);
          var cleared = last.merge({
            data: last.getData().merge({
              textAlign: endBlock.getData().get('textAlign')
            })
          });
          var newBlocksMap = blocksMap.set(lastKey, cleared);
          editor.setClipboard(newBlocksMap);
        }
      }
      return 'not-handled';
    }
  };
};
var _default = textAlignPlugin;
exports.default = _default;