import { apiFormFetch } from '@wbnr/frontend-shared/lib/api';

export interface OrderData {
    duration: number;
    productIds: number[];
    type: string;
    userId: string | number;
}

interface Order {
    paymentUrl: string;
}

export async function getPaymentUrl(orderData: OrderData): Promise<Order> {
    const response = await apiFormFetch('POST', '/orders', { ...orderData, source: 'product' });

    return response;
}
