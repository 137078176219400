"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatTime = formatTime;
var MINUTE_S = 60;
var HOUR_S = 60 * MINUTE_S;
function formatTime() {
  var timeSeconds = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var numberOfHours = Math.floor(timeSeconds / HOUR_S);
  var numberOfMinutes = Math.floor((timeSeconds - numberOfHours * HOUR_S) / MINUTE_S);
  var numberOfSeconds = Math.floor(timeSeconds - numberOfHours * HOUR_S - numberOfMinutes * MINUTE_S);
  return "".concat(numberOfHours > 0 ? "".concat(zeroPad(String(numberOfHours)), ":") : '').concat(zeroPad(String(numberOfMinutes)), ":").concat(zeroPad(String(numberOfSeconds)));
}
function zeroPad(str) {
  var requiredLength = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  var paddedStr = str;
  while (paddedStr.length < requiredLength) {
    paddedStr = '0' + paddedStr;
  }
  return paddedStr;
}