import { useCallback, useState, useMemo, useEffect } from 'react';

import { apiFetch, constructUrl } from '@wbnr/frontend-shared/lib/api';
import { useSuperbranding } from '@wbnr/frontend-shared/lib/data/superbranding';
import {
    isEnterpriseUser,
    isOrganizationAdmin,
    useUser,
} from '@wbnr/frontend-shared/lib/data/user';
import { appLocalStorage } from '@wbnr/frontend-shared/lib/utils/browserStorage';
import { createDate } from '@wbnr/frontend-shared/lib/utils/dates';

export const usePayUsBanner = () => {
    const [user] = useUser();
    const [show, setShow] = useState(false);
    const superbranding = useSuperbranding();

    useEffect(() => {
        let canceled = false;
        const { data: { promoBar: allowed = true } = {} } = superbranding || {};

        if (!allowed || !user || !isOrganizationAdmin(user) || isEnterpriseUser(user)) {
            setShow(false);
            return;
        }
        shouldShowPayusBanner(user)
            .then((newShow) => {
                if (!canceled) {
                    setShow(newShow);
                }
            })
            .catch((e) => {
                console.error(e);
            });
    }, [user, superbranding]);

    const close = useCallback(() => {
        if (!user) {
            return;
        }

        const localStorageKey = getPayUsParamName(user);
        let params = appLocalStorage.getItem(localStorageKey);
        params = params ? JSON.parse(params) : {};

        appLocalStorage.setItem(
            localStorageKey,
            JSON.stringify({
                ...params,
                count: params.count ? params.count + 1 : 1,
                date: createDate().getTime(),
            }),
        );

        setShow(false);
    }, [user, setShow]);

    return useMemo(
        () => ({
            show,
            onClose: close,
        }),
        [show, close],
    );
};

const shouldShowPayusBanner = async (user) => {
    const localStorageKey = getPayUsParamName(user);
    let params = appLocalStorage.getItem(localStorageKey);
    params = params ? JSON.parse(params) : {};

    let alreadyPaid = false;

    if (params.never === undefined) {
        const prolongations = await apiFetch(constructUrl(`/users/${user.id}/prolongations`));

        alreadyPaid = prolongations.some((p) => p.product.key !== 'PRODUCT_FREE');
    } else if (!params.never) {
        alreadyPaid =
            user.activeProlongation && user.activeProlongation.product.key !== 'PRODUCT_FREE';
    } else {
        alreadyPaid = params.never;
    }

    if (params.never !== alreadyPaid) {
        params.never = alreadyPaid;
        appLocalStorage.setItem(localStorageKey, JSON.stringify(params));
    }

    if (!alreadyPaid) {
        const options = {};
        const nowTimestamp = createDate().getTime();

        if (params.date !== undefined) {
            options.from = toISOWithTimezone(createDate(params.date));
        }

        if (params.count === undefined) {
            const { count: numberOfFinishedEvents } = await getStoppedEventsCount(user, options);

            if (numberOfFinishedEvents >= 4) {
                return true;
            }
        } else if (params.count === 1 && params.date + 14 * DAY_MS < nowTimestamp) {
            const { count: numberOfFinishedEvents } = await getStoppedEventsCount(user, options);

            if (numberOfFinishedEvents) {
                return true;
            }
        } else if (params.count > 1) {
            const nextMonthAfterLastShow = createDate(params.date);
            nextMonthAfterLastShow.setMonth(nextMonthAfterLastShow.getMonth() + 1);

            if (nowTimestamp > nextMonthAfterLastShow.getTime()) {
                const { count: numberOfFinishedEvents } = await getStoppedEventsCount(
                    user,
                    options,
                );

                if (numberOfFinishedEvents) {
                    return true;
                }
            }
        }
    }
};
function toISOWithTimezone(date) {
    const format = function (p) {
        p = p.toString();
        return p.length < 2 ? '0' + p : p;
    };
    const offset = -date.getTimezoneOffset();
    const tzh = Math.abs(parseInt(offset / 60, 10)),
        tzm = offset % 60;
    const tz = (offset > 0 ? '+' : '-') + format(tzh) + format(tzm);

    return (
        date.getFullYear() +
        '-' +
        format(date.getMonth() + 1) +
        '-' +
        format(date.getDate()) +
        'T' +
        format(date.getHours()) +
        ':' +
        format(date.getMinutes()) +
        ':' +
        format(date.getSeconds()) +
        tz
    );
}

const DAY_MS = 864e5;

const getStoppedEventsCount = (user, options) => {
    if (!user) {
        Promise.reject(new Error('User is not defined'));
    }

    return apiFetch(
        constructUrl(
            `/organizations/${user.memberships[0].organization.id}/eventsessions/countStopped`,
            options,
        ),
    );
};

const getPayUsParamName = (user) => 'subscribePanelData_' + user.id;
