import i18n from 'i18next';
var delayedTranslations = [];
export function injectTranslations(namespace, resources) {
  if (i18n.isInitialized) {
    i18n.addResourceBundle('ru', namespace, resources.ru);
    i18n.addResourceBundle('en', namespace, resources.en);
  } else {
    delayedTranslations.push({
      namespace: namespace,
      resources: resources
    });
  }
}
i18n.on('initialized', function () {
  if (delayedTranslations.length > 0) {
    delayedTranslations.forEach(function (_ref) {
      var namespace = _ref.namespace,
        resources = _ref.resources;
      injectTranslations(namespace, resources);
    });
    delayedTranslations = [];
  }
});