import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router';

import { useFeatureSettings } from '@wbnr/frontend-shared/lib/data/featureSettings';
import { isOrganizationAdmin, useAccountUser } from '@wbnr/frontend-shared/lib/data/user';

import { ApiAndWebhooks } from './ApiAndWebhooks';

const PAGE_TITLE = 'API / Webhooks - Webinar';
const NO_WEBHOOKS_PAGE_TITLE = 'API - Webinar';

export const ApiAndWebhooksPage = () => {
    const [user] = useAccountUser();
    const isAdmin = useMemo(() => isOrganizationAdmin(user), [user]);
    const { data: features } = useFeatureSettings();
    const { webhooks: webhooksAvailable } = features;

    if (!isAdmin) {
        return <Redirect to="/business/tariffs" />;
    }

    return (
        <>
            <Helmet>
                <title>{webhooksAvailable ? PAGE_TITLE : NO_WEBHOOKS_PAGE_TITLE}</title>
            </Helmet>

            <ApiAndWebhooks />
        </>
    );
};
