"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _youtube = _interopRequireDefault(require("./youtube"));
var _mp = _interopRequireDefault(require("./mp4"));
var _vimeo = _interopRequireDefault(require("./vimeo"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var createMedia = function createMedia(element, callbacks, options) {
  if (isMp3OrMp4(options)) {
    return (0, _mp.default)(element, callbacks, options);
  } else if (options.type === 'vimeo') {
    return (0, _vimeo.default)(element, callbacks, options);
  }
  return (0, _youtube.default)(element, callbacks, options);
};
var isMp3OrMp4 = function isMp3OrMp4(options) {
  return options.type === 'mp4' || options.type === 'mp3';
};
var _default = createMedia;
exports.default = _default;