import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { APIError } from '@wbnr/frontend-shared/lib/api';
import { ssoTransferOrganizationCheck } from '@wbnr/frontend-shared/lib/api/user';
import { makeFormErrors, useForm } from '@wbnr/frontend-shared/lib/forms';
import { appLocalStorage } from '@wbnr/frontend-shared/lib/utils/browserStorage';

import {
    DEFAULT_SSO_ORGANIZATION_ID,
    SELECTED_SSO_ORGANIZATION_KEY,
    SELECTED_SSO_ORGANIZATION_VALUE,
} from '../constants';

import { useGetSSOTransferOrganizations } from './useGetSSOTransferOrganizations';

type Props = {
    isSelectedSSOOrganization: boolean;
    onSubmitClick: () => void;
};

export const useCheckSSOTransferOrganization = ({
    isSelectedSSOOrganization = false,
    onSubmitClick,
}: Props) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const { transferOrganizations, setTransferOrganizations } = useGetSSOTransferOrganizations({
        isSelectedSSOOrganization,
        onSubmitClick,
    });

    const handleShowError = useCallback(
        (error: unknown) => {
            if (!(error instanceof APIError)) {
                enqueueSnackbar(t('unknownError'), {
                    variant: 'error',
                });
                return;
            }

            if (error.status === 400) {
                throw makeFormErrors({
                    transferOrganization: 'auth.sso.invalidParams',
                });
            }
            if (error.status === 401 && error.body.error.code === 'UNAUTHORIZED') {
                throw makeFormErrors({
                    transferOrganization: 'auth.sso.noTokenPassed',
                });
            }
            if (error.status === 403) {
                throw makeFormErrors({
                    transferOrganization: 'auth.sso.notRightsToGo',
                });
            }
        },
        [enqueueSnackbar, t],
    );

    const handleSubmit = useCallback(
        async (result: { transferOrganization?: string }) => {
            const selectedOrganizationId = Number(result.transferOrganization);
            try {
                if (selectedOrganizationId !== DEFAULT_SSO_ORGANIZATION_ID) {
                    await ssoTransferOrganizationCheck({
                        organizationId: selectedOrganizationId,
                    });
                }
                appLocalStorage.setItem(
                    SELECTED_SSO_ORGANIZATION_KEY,
                    SELECTED_SSO_ORGANIZATION_VALUE,
                );
                onSubmitClick();
            } catch (error) {
                handleShowError(error);
            }
        },
        [handleShowError, onSubmitClick],
    );

    const methods = useForm(
        {
            defaultValues: {
                transferOrganization: undefined,
            },
        },
        { onSubmit: handleSubmit },
    );

    return {
        formMethods: methods,
        transferOrganizations,
        setTransferOrganizations,
    };
};
