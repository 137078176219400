"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TIME_MASK = void 0;
var TIME_MASK = {
  HMS: 'hh:mm:ss',
  HM: 'hh:mm',
  MS: 'mm:ss'
};
exports.TIME_MASK = TIME_MASK;