import { apiFormFetch, APIError } from '../apiFetch';
export function getOnboardingv2Group(params) {
  return apiFormFetch('GET', "/onboarding-v2/{groupToken}", params).catch(function (error) {
    if (error instanceof APIError) {
      if (error.status === 404) {
        return [];
      }
    }
    throw error;
  }).then(function (response) {
    return parseGroup(params.groupToken, response);
  });
}
function parseGroup(groupToken, response) {
  return {
    token: groupToken,
    steps: response.reduce(function (memo, step) {
      memo[step.token] = step;
      return memo;
    }, {})
  };
}