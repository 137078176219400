import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useMixpanelTracking } from '@wbnr/frontend-shared/lib/analytics/mixpanel/useMixpanelTracking';
import Banner from '@wbnr/frontend-shared/lib/components/Banner';
import { useFeatureSettings } from '@wbnr/frontend-shared/lib/data/featureSettings';
import { useUser, canBuyExtension, getUsedSpacePercent } from '@wbnr/frontend-shared/lib/data/user';
import { Button } from '@wbnr/ui';

import { useBannerParams } from 'shared/banners';

import styles from './FileSizeBanner.module.scss';

const FileSizeBanner = () => {
    const [user, loading, error] = useUser();
    const { onClose, ...bannerParams } = useBannerParams('fileSize');
    const { data } = useFeatureSettings();
    const { t } = useTranslation();
    const { track } = useMixpanelTracking();
    const history = useHistory();

    if (!user || loading || error) {
        return null;
    }

    const autoRecordEnabled = user.isAutoRecord;
    const showBuyButton = canBuyExtension(user);
    const percent = getUsedSpacePercent(user && user.fileSize);
    let titleKey;

    if (autoRecordEnabled) {
        titleKey = percent < 100 ? 'banners.fileSize90AutoRecord' : 'banners.fileSize100AutoRecord';
    } else {
        titleKey = percent < 100 ? 'banners.fileSize90' : 'banners.fileSize100';
    }

    const filling_rate = `${percent < 100 ? 90 : 100}%`;

    const onCloseHandler = () => {
        track('fm_full_alert_closed', {
            filling_rate,
        });
        onClose && onClose();
    };

    const onClickHandler = () => {
        track('fm_full_alert_gototariffs_clicked', {
            filling_rate,
        });

        if (data?.newTariffsPage && user?.isSelfService) {
            history.push('/business/buytariffs');
        } else if (user?.isSelfService) {
            sessionStorage.setItem('gotoTariff', 'true');
            history.push('/business/tariffs');
        } else {
            history.push('/business/tariffs');
        }
    };

    return (
        <Banner
            {...bannerParams}
            onClose={onCloseHandler}
            title={t(titleKey)}
            type={percent < 100 ? 'warning' : 'danger'}
            action={
                <>
                    {showBuyButton && (
                        <Button
                            className={styles.button}
                            onClick={onClickHandler}
                            type="default"
                            variant="contained"
                        >
                            {t('banners.buyFileSize')}
                        </Button>
                    )}
                </>
            }
        />
    );
};

export default FileSizeBanner;
