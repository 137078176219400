import { useMemo } from 'react';

import { AuthButtons } from '@wbnr/frontend-shared/lib/components/auth';
import { Submit } from '@wbnr/frontend-shared/lib/forms';
import { ComponentTestIdProps, createTestIdProps, dataTestIdKey } from '@wbnr/ui';

type Props = {
    onBackClick: () => void;
    onSubmitClick: Submit;
    submitButtonText: string;
    backButtonText: string;
} & ComponentTestIdProps;

export const FormButtons = ({
    onBackClick,
    onSubmitClick,
    submitButtonText,
    backButtonText,
    [dataTestIdKey]: baseTestId,
}: Props) => {
    const submitButton = useMemo(
        () => ({
            text: submitButtonText,
            onClick: onSubmitClick,
            ...createTestIdProps(baseTestId, 'next'),
        }),
        [onSubmitClick, baseTestId, submitButtonText],
    );

    const backButton = useMemo(
        () => [
            {
                text: backButtonText,
                onClick: onBackClick,
                ...createTestIdProps(baseTestId, 'back'),
            },
        ],
        [onBackClick, baseTestId, backButtonText],
    );

    return <AuthButtons main={submitButton} buttons={backButton} />;
};
