import { Trans } from 'react-i18next';

import { CircularProgress } from '@wbnr/ui';

import styles from './Preloader.module.scss';

export const Preloader = () => (
    <div className={styles.root}>
        <div className={styles.wrapper}>
            <CircularProgress className={styles.spinner} color="inherit" thickness={4} />
            <div className={styles.text}>
                <Trans i18nKey="business.sso.saml.loading" />
            </div>
        </div>
    </div>
);
