import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
    DEFAULT_EMAIL_TEMPLATE_INVITATION,
    DEFAULT_EMAIL_TEMPLATE,
} from '@wbnr/frontend-shared/lib/data/branding';
import { useField, useFormContext } from '@wbnr/frontend-shared/lib/forms';
import { validateEmail } from '@wbnr/frontend-shared/lib/utils/validators';
import { TextField } from '@wbnr/ui';

import { getBrandingPropName, getSmtpPropName } from '../../utils';
import BrandingHeading from '../BrandingHeading';
import BrandingSwitchField from '../BrandingSwitchField';

import EmailTemplate from './EmailTemplate';
import SmtpForm from './SmtpForm';

const i18nPath = 'business.brandingPage';

const BrandingNewsletters = () => {
    const { t } = useTranslation();

    const { control } = useFormContext();

    const emailReplyToField = useField(control, getBrandingPropName('emailReplyTo'), {
        rules: { validate: validateEmail },
    });
    const emailFromNameField = useField(control, getBrandingPropName('emailFromName'));
    const isSignatureField = useField(control, getBrandingPropName('isSignature'));
    const emailSignatureField = useField(control, getBrandingPropName('emailSignature'));
    const isTemplateInvitationField = useField(
        control,
        getBrandingPropName('isTemplateInvitation'),
    );
    const isTemplateField = useField(control, getBrandingPropName('isTemplate'));
    const isActiveField = useField(control, getSmtpPropName('isActive'));

    return (
        <>
            <BrandingHeading>{t(`${i18nPath}.newsletters`)}</BrandingHeading>

            <TextField
                {...emailReplyToField}
                onChange={(e) => emailReplyToField.onChange(e.target.value.replace(/\s/, ''))}
                label={t(`${i18nPath}.replyTo`)}
                helperText={t(`${i18nPath}.replyToComment`)}
                fullWidth
            />

            <TextField
                {...emailFromNameField}
                label={t(`${i18nPath}.emailFrom`)}
                helperText={t(`${i18nPath}.emailFromComment`)}
                fullWidth
            />

            <BrandingSwitchField {...isSignatureField} label={t(`${i18nPath}.useSignature`)} />
            {isSignatureField.value && (
                <TextField {...emailSignatureField} label={t(`${i18nPath}.signature`)} fullWidth />
            )}

            <BrandingSwitchField
                {...isTemplateInvitationField}
                label={t(`${i18nPath}.useTemplateInvitation`)}
            />
            <EmailTemplate
                fieldName="emailTemplateInvitation"
                show={Boolean(isTemplateInvitationField.value)}
                defaultTemplate={DEFAULT_EMAIL_TEMPLATE_INVITATION}
                macros={useMemo(() => getEmailTemplateInvitationMacros(t), [t])}
            />

            <BrandingSwitchField {...isTemplateField} label={t(`${i18nPath}.useTemplate`)} />
            <EmailTemplate
                fieldName="emailTemplate"
                show={Boolean(isTemplateField.value)}
                defaultTemplate={DEFAULT_EMAIL_TEMPLATE}
                macros={useMemo(() => getEmailTemplateMacros(t), [t])}
            />

            <BrandingSwitchField {...isActiveField} label={t(`${i18nPath}.useCustomSmtp`)} />
            {isActiveField.value && <SmtpForm />}
        </>
    );
};

export default BrandingNewsletters;

function getEmailTemplateInvitationMacros(t: TFunction) {
    return [
        {
            term: 'title',
            definition: t(`${i18nPath}.macros.template.title`),
        },
        {
            term: 'name',
            definition: t(`${i18nPath}.macros.template.name`),
        },
        {
            term: 'webinar_date',
            definition: t(`${i18nPath}.macros.template.webinarDate`),
        },
        {
            term: 'image_url',
            definition: t(`${i18nPath}.macros.template.imageUrl`),
        },
        {
            term: 'link',
            definition: t(`${i18nPath}.macros.template.link`),
        },
    ];
}

function getEmailTemplateMacros(t: TFunction) {
    return [
        {
            term: 'title',
            definition: t(`${i18nPath}.macros.template.title`),
        },
        {
            term: 'name',
            definition: t(`${i18nPath}.macros.template.name`),
        },
        {
            term: 'webinar_name',
            definition: t(`${i18nPath}.macros.template.webinarName`),
        },
        {
            term: 'webinar_date',
            definition: t(`${i18nPath}.macros.template.webinarDate`),
        },
        {
            term: 'image_url',
            definition: t(`${i18nPath}.macros.template.imageUrl`),
        },
        {
            term: 'text',
            definition: t(`${i18nPath}.macros.template.text`),
        },
        {
            term: 'link',
            definition: t(`${i18nPath}.macros.template.link`),
        },
    ];
}
