var translations = {
  ru: {
    maxParticipants: 'Макс. количество участников',
    maxBroadcasters: 'Из них в эфире макс.',
    maxParticipantsAutoAS: 'Общее количество<br/> в вебинарах',
    maxBroadcastersAutoAS: 'В эфире вебинара',
    maxInMeetings: 'Во встречах',
    participants: 'Участники',
    participantsCount: 'до {{ participants }}',
    participantsOnline: '{{count}} участников в сети',
    participantsMax: 'из {{count}}',
    participantLimitWarning: 'Мало места для новых участников'
  },
  en: {
    maxParticipants: 'Max. number of participants',
    maxBroadcasters: 'Max. number broadcasting',
    maxParticipantsAutoAS: 'Max. participants<br/> in webinars',
    maxBroadcastersAutoAS: 'In broadcasting',
    maxInMeetings: 'In meetings',
    participants: 'Participants',
    participantsCount: 'to {{ participants }}',
    participantsOnline: '{{count}} participants online',
    participantsMax: 'from {{count}}',
    participantLimitWarning: 'Not much room for new participants'
  }
};
export default translations;