import classNames from 'classnames';
import { useTranslation, Trans } from 'react-i18next';

import TextFieldClipboard from '@wbnr/frontend-shared/lib/components/TextFieldClipboard';
import { injectTranslations } from '@wbnr/frontend-shared/lib/utils/i18n';
import {
    DialogActions,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    A,
    makeStyles,
    createTestIdProps,
    ComponentTestIdProps,
    dataTestIdKey,
} from '@wbnr/ui';

import { TRANS_NS } from '../../../constants';
import translations from '../../../translations.json';

import styles from './ExtensionModal.module.scss';

interface ExtensionModalProps extends ComponentTestIdProps {
    opened: boolean;
    name: string;
    description: string;
    helpLink: string;
    apiKey: string;
    isKeyRefreshing: boolean;
    imagePath: string;
    onApiKeyRefresh: () => void;
    onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
    renewButton: {
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.dark,
        },
    },
}));

injectTranslations(TRANS_NS, translations);

export const ExtensionModal = ({
    opened,
    imagePath,
    name,
    description,
    helpLink,
    apiKey,
    isKeyRefreshing,
    [dataTestIdKey]: dataTestId,
    onApiKeyRefresh,
    onClose,
}: ExtensionModalProps) => {
    const { t } = useTranslation(TRANS_NS);
    const localTheme = useStyles();

    return (
        <Dialog maxWidth="md" open={opened} onClose={onClose} className={styles.root}>
            <img src={imagePath} alt={name} className={styles.image} />
            <DialogTitle className={styles.title}>{name}</DialogTitle>
            <DialogContent
                classes={{
                    root: styles.dialogContent,
                }}
                className={styles.dialogContentWrapper}
            >
                <Typography variant="body1" className={styles.description}>
                    {description}{' '}
                    <ReferenceLink
                        href={helpLink}
                        label={t('extensions.learnMore')}
                        {...createTestIdProps(dataTestId, 'link', 'learnMore')}
                    />
                </Typography>

                <TextFieldClipboard
                    label={t('extensions.key')}
                    fullWidth
                    value={apiKey}
                    InputProps={{
                        readOnly: true,
                    }}
                    FormHelperTextProps={{
                        hideEmpty: true,
                    }}
                    className={styles.apiKey}
                    tooltip={t('extensions.copyTooltip')}
                    {...createTestIdProps(dataTestId, 'textField', 'apiKeyCopy')}
                />

                <Typography variant="caption" color="textSecondary" className={styles.renewText}>
                    <Trans
                        t={t}
                        i18nKey={'extensions.renewText'}
                        components={[
                            <button
                                type="button"
                                key="apiKeyRefresh"
                                onClick={onApiKeyRefresh}
                                className={classNames(styles.renewButton, localTheme.renewButton)}
                                disabled={isKeyRefreshing}
                                {...createTestIdProps(dataTestId, 'action', 'renewApiKey')}
                            />,
                        ]}
                    />
                </Typography>
            </DialogContent>

            <DialogActions>
                <Button
                    variant="text"
                    color="primary"
                    onClick={onClose}
                    {...createTestIdProps(dataTestId, 'action', 'close')}
                >
                    {t('extensions.cancelButton')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

interface ReferenceLinkProps extends ComponentTestIdProps {
    label: string;
    href: string;
    className?: string;
}
function ReferenceLink({
    label,
    href,
    className,
    [dataTestIdKey]: dataTestId,
}: ReferenceLinkProps) {
    return (
        <A
            href={href}
            color="primary"
            target="_blank"
            rel="noreferrer noopener"
            className={className}
            {...createTestIdProps(dataTestId)}
        >
            {label}
        </A>
    );
}
