import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import LoadingScreen from '@wbnr/frontend-shared/lib/components/LoadingScreen';
import { Typography } from '@wbnr/ui';

import BusinessLayout from '../../BusinessLayout';
import { useSIEM } from '../hooks/useSIEM';
import { SIEMSettingsForm } from '../SIEMSettingsForm';

import styles from './SIEMSettings.module.scss';

export const SIEMSettings: FC = () => {
    const { t } = useTranslation();
    const { loadingSIEM } = useSIEM();

    return (
        <BusinessLayout>
            {loadingSIEM ? (
                <LoadingScreen />
            ) : (
                <div className={styles.root}>
                    <div>
                        <Typography variant="h2" className={styles.title}>
                            {t('business.siem.title')}
                        </Typography>
                    </div>
                    <div className={styles.card}>
                        <SIEMSettingsForm />
                    </div>
                </div>
            )}
        </BusinessLayout>
    );
};
