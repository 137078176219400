import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthButtons, AuthForm } from '@wbnr/frontend-shared/lib/components/auth';
import { ComponentTestIdProps, createTestIdProps, dataTestIdKey, Typography } from '@wbnr/ui';

type Props = {
    onSignin: () => void;
    onSignup: () => void;
} & ComponentTestIdProps;

const i18nPath = 'organization.invitation.link';

const AuthScreen = ({ [dataTestIdKey]: dataTestId, onSignin, onSignup }: Props) => {
    const { t } = useTranslation();

    return (
        <AuthForm {...createTestIdProps(dataTestId, 'form')}>
            <Typography paragraph>{t(`${i18nPath}.selectAuth`)}</Typography>

            <AuthButtons
                main={useMemo(
                    () => ({
                        text: t(`${i18nPath}.signup`),
                        onClick: onSignup,
                        ...createTestIdProps(dataTestId, 'action', 'signup'),
                    }),
                    [dataTestId, onSignup, t],
                )}
                buttons={useMemo(
                    () => [
                        {
                            text: t(`${i18nPath}.signin`),
                            onClick: onSignin,
                            ...createTestIdProps(dataTestId, 'action', 'signin'),
                        },
                    ],
                    [dataTestId, onSignin, t],
                )}
            />
        </AuthForm>
    );
};

export default AuthScreen;
