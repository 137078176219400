import { apiFormFetch } from '@wbnr/frontend-shared/lib/api';

import { OrderData } from './getPaymentUrl';

export interface InvoiceData {
    email: string;
    inn: string;
    kpp: string;
    companyName: string;
    address: string;
    ceoFullName: string;
}

interface RequestId {
    id: string;
}

export async function sendInvoiceData(
    billData: InvoiceData,
    orderData: OrderData,
): Promise<RequestId> {
    const { id: orderId } = await apiFormFetch('POST', '/orders', {
        ...orderData,
        source: 'product',
    });
    const response = await apiFormFetch('POST', '/invoices', { orderId, ...billData });
    return response;
}
