import classnames from 'classnames';
import { ReactNode, useState } from 'react';

import { KeyboardArrowDown } from '@wbnr/icons';
import { Collapse, Typography } from '@wbnr/ui';

import styles from './ShowMoreToggle.module.scss';

interface Props {
    title: string;
    children: ReactNode;
}

export const ShowMoreToggle = ({ title, children }: Props) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen((prev) => !prev);
    };

    return (
        <>
            <button
                type="button"
                className={classnames(styles.toggle, { [styles.toggleOpen]: isOpen })}
                onClick={handleToggle}
            >
                <Typography variant="body1" className={styles.title}>
                    {title}
                </Typography>
                <KeyboardArrowDown className={styles.arrow} />
            </button>

            <Collapse in={isOpen}>
                <div className={styles.content}>{children}</div>
            </Collapse>
        </>
    );
};
