var linkRules = {
  onlyMidleDash: {
    rule: /(^[-_]+)|([-_]+$)/,
    message: 'eventEditor.errors.validation.rules.onlyMidleDash'
  },
  noMoreOneDash: {
    rule: /[-_]{2,}/g,
    message: 'eventEditor.errors.validation.rules.noMoreOneDash'
  },
  noOnlyNumbers: {
    rule: /^\d+$/,
    message: 'eventEditor.errors.validation.rules.noOnlyNumbers'
  },
  noOtherSymbols: {
    rule: /(?!^(\d+|.*[_-]{2,}.*)$)^(?:[A-Za-z\d][A-Za-z\d_-]*[A-Za-z\d]|[A-Za-z]+)$/,
    message: 'eventEditor.errors.validation.rules.noOtherSymbols'
  }
};
var validateAlias = function validateAlias(value) {
  var errorMessage;
  if (value.search(linkRules.onlyMidleDash.rule) !== -1) {
    errorMessage = linkRules.onlyMidleDash.message;
  } else if (value.search(linkRules.noMoreOneDash.rule) !== -1) {
    errorMessage = linkRules.noMoreOneDash.message;
  } else if (value.search(linkRules.noOnlyNumbers.rule) !== -1) {
    errorMessage = linkRules.noOnlyNumbers.message;
  } else if (value.search(linkRules.noOtherSymbols.rule) === -1) {
    errorMessage = linkRules.noOtherSymbols.message;
  }
  return errorMessage;
};
export default validateAlias;