import { isOfType } from '../utils/isOfType';
export function isAccountUser(user) {
  if (!user || user.type !== 'USER') {
    return false;
  }
  if ('memberships' in user) {
    return isOfType(user) && user.memberships.length > 0;
  }
  return false;
}
var UserOrganizationRole;
(function (UserOrganizationRole) {
  UserOrganizationRole["Admin"] = "ADMIN";
  UserOrganizationRole["Lector"] = "LECTURER";
})(UserOrganizationRole || (UserOrganizationRole = {}));