export function applyTranslations(i18nInstance, translations) {
  Object.keys(translations || {}).forEach(function (lang) {
    var bundle = (translations === null || translations === void 0 ? void 0 : translations[lang]) || {};
    Object.keys(bundle).forEach(function (ns) {
      i18nInstance.addResourceBundle(lang, ns, bundle[ns], true, true);
    });
  });
}
export function applySuperbrandingIcons(superbranidng) {
  removeFromDOM('link[rel=icon]');
  removeFromDOM('link[rel=apple-touch-icon]');
  if (superbranidng.data['favicon.ico']) {
    addIcon({
      type: 'image/x-icon',
      rel: 'shortcut icon',
      href: superbranidng.data['favicon.ico']
    });
  }
  if (superbranidng.data.favicon) {
    addIcon({
      type: 'image/png',
      rel: 'shortcut icon',
      href: superbranidng.data.favicon
    });
    addIcon({
      type: 'image/png',
      rel: 'icon',
      href: superbranidng.data.favicon,
      size: '16x16'
    });
  }
}
export function applyDefaultIcons() {
  removeFromDOM('link[rel=icon]');
  removeFromDOM('link[rel=apple-touch-icon]');
  var sizes = ['16x16', '32x32', '96x96'];
  var appleSizes = ['57x57', '60x60', '72x72', '76x76', '114x114', '120x120', '144x144', '152x152', '180x180'];
  addIcon({
    type: 'image/x-icon',
    rel: 'shortcut icon',
    href: "".concat(process.env.PUBLIC_URL, "/images/favicon-32x32.ico")
  });
  addIcon({
    type: 'image/png',
    rel: 'shortcut icon',
    href: "".concat(process.env.PUBLIC_URL, "/images/favicon-16x16.png")
  });
  sizes.forEach(function (size) {
    addIcon({
      type: 'image/png',
      rel: 'icon',
      href: "".concat(process.env.PUBLIC_URL, "/images/favicon-").concat(size, ".png"),
      size: size
    });
  });
  appleSizes.forEach(function (size) {
    addIcon({
      type: 'image/png',
      rel: 'apple-touch-icon',
      href: "".concat(process.env.PUBLIC_URL, "/images/apple-touch-icon-").concat(size, ".png"),
      size: size
    });
  });
}
function removeFromDOM(selector) {
  var _element$parentNode;
  var element = document.querySelector(selector);
  element === null || element === void 0 ? void 0 : (_element$parentNode = element.parentNode) === null || _element$parentNode === void 0 ? void 0 : _element$parentNode.removeChild(element);
}
function addIcon(_ref) {
  var type = _ref.type,
    rel = _ref.rel,
    href = _ref.href,
    size = _ref.size;
  var link = document.createElement('link');
  link.rel = rel;
  link.href = href;
  if (type) {
    link.type = type;
  }

  // links sizes doesnot exist in ie and old edge
  if (size && link.sizes) {
    link.sizes.add(size);
  }
  document.head.appendChild(link);
}