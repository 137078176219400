"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Pulse", {
  enumerable: true,
  get: function get() {
    return _Pulse.Pulse;
  }
});
var _Pulse = require("./Pulse");