var lengthValidator = function lengthValidator(maxLength) {
  var minLength = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  return function () {
    var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    if (value.length > maxLength || value.length < minLength) {
      return 'validate.length';
    }
    return;
  };
};
export default lengthValidator;