import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
export var useSpecificLengthValidator = function useSpecificLengthValidator(count, errorKey) {
  var _useTranslation = useTranslation(),
    t = _useTranslation.t;
  var message = t(errorKey, {
    count: count
  });
  return useCallback(function (value) {
    if ((value === null || value === void 0 ? void 0 : value.length) !== count && Number(value === null || value === void 0 ? void 0 : value.length) > 0) {
      return message;
    }
  }, [message, count]);
};