import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
export var useContactsImportCometHandlers = function useContactsImportCometHandlers() {
  var _useTranslation = useTranslation(),
    t = _useTranslation.t;
  var _useSnackbar = useSnackbar(),
    enqueueSnackbar = _useSnackbar.enqueueSnackbar;
  var successHandler = useCallback(function (data) {
    enqueueSnackbar(t('contacts.importSuccess', {
      contactsNum: data.contactsNum
    }));
  }, [t, enqueueSnackbar]);
  var failHandler = useCallback(function (data) {
    enqueueSnackbar(t('contacts.importFail'));
  }, [t, enqueueSnackbar]);
  return {
    successHandler: successHandler,
    failHandler: failHandler
  };
};