import styles from '../Organization.module.scss';

export const textFieldCommonParams = {
    className: styles.textField,
    FormHelperTextProps: {
        hideErrorIcon: true,
        hideEmpty: true,
        className: styles.helperText,
    },
};
