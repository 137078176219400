import cn from 'classnames';
import { FC } from 'react';
import { Trans } from 'react-i18next';

import { Domain } from '@wbnr/frontend-shared/lib/api/business-api/types';
import {
    Button,
    createTestIdProps,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
} from '@wbnr/ui';

import { useSSO } from '../../SSOContext';

import { DeleteDomain } from './DeleteDomain';
import styles from './DomainList.module.scss';

const BASE_TEST_ID = 'DomainList';

export const DomainList: FC<{ onApprove: (domain: Domain) => void }> = ({ onApprove }) => {
    const { domains, loadingDomain } = useSSO();
    if (!domains || domains.length === 0 || loadingDomain) {
        return null;
    }
    return (
        <List className={styles.root}>
            {domains.map((domain) => (
                <ListItem key={domain.id} className={styles.domain}>
                    <ListItemText className={styles.name}>
                        <div>{domain.domain}</div>
                        <div
                            className={cn(styles.statusLabel, {
                                [styles.statusApproved]: domain.isApproved,
                            })}
                        >
                            <Trans
                                i18nKey={`business.sso.domain.${
                                    domain.isApproved ? 'approved' : 'unapproved'
                                }`}
                            />
                        </div>
                    </ListItemText>
                    <ListItemSecondaryAction className={styles.buttons}>
                        {!domain.isApproved && (
                            <Button
                                color={'primary'}
                                onClick={() => onApprove(domain)}
                                {...createTestIdProps(BASE_TEST_ID, 'ApproveDomain')}
                            >
                                <Trans i18nKey={'business.sso.domain.approve'} />
                            </Button>
                        )}
                        <DeleteDomain domain={domain} />
                    </ListItemSecondaryAction>
                </ListItem>
            ))}
        </List>
    );
};
