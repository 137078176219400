import { useTranslation } from 'react-i18next';

import { useField, useFormContext } from '@wbnr/frontend-shared/lib/forms';
import { createTestIdProps, TextField } from '@wbnr/ui';

import { BASE_TEST_ID, TRANS_NS } from '../../constants';
import { textFieldCommonParams } from '../textFieldCommonParams';
import { IPropsField } from '../types';

export function NameField({ handleUpdateOrganization }: IPropsField) {
    const { t } = useTranslation(TRANS_NS);

    const {
        control,
        formState: { dirtyFields },
        watch,
    } = useFormContext();
    const nameField = useField(control, 'name');
    const name = watch('name');

    const handleUpdateName = () => {
        if (!dirtyFields.name) {
            return;
        }

        handleUpdateOrganization({ name }, 'organizationNameChanged');
    };

    return (
        <TextField
            {...nameField}
            label={t('organizationName')}
            title={t('organizationName')}
            onBlur={handleUpdateName}
            onKeyDown={(e) => (e.keyCode === 13 ? handleUpdateName() : undefined)}
            {...textFieldCommonParams}
            {...createTestIdProps(BASE_TEST_ID, 'nameField')}
        />
    );
}
