import { CSSProperties, ComponentProps } from 'react';

import { TableCell } from '@wbnr/ui';

import { ColumnId } from '../types';
import styles from '../WebhooksList.module.scss';

export const getCellProps = (cellId: ColumnId): ComponentProps<typeof TableCell> => {
    const cellStyleMap: { [key in ColumnId]?: CSSProperties } = {
        isEnabled: { width: '62px' },
        name: { width: '151px' },
        endpointUrl: { width: '450px' },
        createdAt: { width: '200px' },
    };

    return {
        align: 'left',
        classes: { root: styles.cell },
        className: styles.cellWrapper,
        style: cellStyleMap[cellId],
    };
};
