import sub from 'date-fns/sub';
import { useState, useCallback, useMemo } from 'react';

import { DateRange } from '../StatisticsPeriodPicker/types';

export const PERIOD_TYPES = {
    ALL_TIME: 'all_time',
    BY_WEEK: 'by_week',
    BY_MONTH: 'by_month',
    BY_YEAR: 'by_year',
    CUSTOM: 'custom',
};
export const DEFAULT_PERIOD_TYPE = PERIOD_TYPES.ALL_TIME;

export const usePeriodFilter = (defaultPeriodType: string = DEFAULT_PERIOD_TYPE) => {
    const [periodType, setPeriodType] = useState(defaultPeriodType);
    const [customPeriod, setCustomPeriod] = useState<DateRange>({ from: null, to: null });

    const onChangePeriodType = useCallback(
        (value: string) => {
            setPeriodType(value);
        },
        [setPeriodType],
    );

    const onChangeCustomPeriod = useCallback(
        ({ from, to }: DateRange) => {
            const newPeriod = { from, to };

            setCustomPeriod(newPeriod);
        },
        [setCustomPeriod],
    );

    const startAt = useMemo(() => {
        const currentDate = new Date();

        if (periodType === PERIOD_TYPES.BY_WEEK) {
            return sub(currentDate, { weeks: 1 });
        } else if (periodType === PERIOD_TYPES.BY_MONTH) {
            return sub(currentDate, { months: 1 });
        } else if (periodType === PERIOD_TYPES.BY_YEAR) {
            return sub(currentDate, { years: 1 });
        } else if (periodType === PERIOD_TYPES.CUSTOM) {
            return customPeriod.from || currentDate;
        } else {
            return undefined;
        }
    }, [periodType, customPeriod]);

    const endAt = useMemo(() => {
        if (periodType !== PERIOD_TYPES.CUSTOM) {
            return undefined;
        }

        return customPeriod.to || new Date();
    }, [periodType, customPeriod]);

    return { startAt, endAt, periodType, customPeriod, onChangePeriodType, onChangeCustomPeriod };
};
