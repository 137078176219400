var validateEmail = function validateEmail(value) {
  if (!value || value.length === 0) {
    return;
  }
  var regex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/;
  if (regex.test(value)) {
    return;
  }
  return 'validation.email';
};
export default validateEmail;