import { CustomPicker, CustomPickerProps } from 'react-color';
import { Saturation, Hue } from 'react-color/lib/components/common';

import styles from './BrandingColorPicker.module.scss';

type Props = CustomPickerProps<any>;

const BrandingColorPicker = (props: Props) => {
    return (
        <div className={styles.root}>
            <div className={styles.saturation}>
                <Saturation {...props} />;
            </div>
            <div className={styles.hue}>
                <Hue {...props} direction="vertical" />;
            </div>
        </div>
    );
};

export default CustomPicker(BrandingColorPicker);
