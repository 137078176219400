var TRIAL_PERIOD_CONFIG = {
  '7_DAYS': {
    dialog: {
      warning: {
        end: 3
      }
    },
    activity: {
      normal: {
        start: 3,
        end: 4
      },
      warning: {
        start: 1,
        end: 2
      }
    }
  },
  '30_DAYS': {
    dialog: {
      warning: {
        end: 15
      }
    },
    activity: {
      normal: {
        start: 15,
        end: 29
      },
      warning: {
        start: 1,
        end: 14
      }
    }
  }
};
export default TRIAL_PERIOD_CONFIG;