import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { confirmEmail } from '@wbnr/frontend-shared/lib/api/user';
import { AuthLayout, AuthContent } from '@wbnr/frontend-shared/lib/components/auth';
import LoadingScreen from '@wbnr/frontend-shared/lib/components/LoadingScreen';
import { isAccountUser } from '@wbnr/frontend-shared/lib/types/User';

import { useQualification } from 'shared/qualification/useQualification';

const i18nPath = 'auth.emailSubmit';

const EmailSubmit = () => {
    const { submitToken } = useParams<{ submitToken: string }>();
    const { t } = useTranslation();

    const isCourses = window.location.pathname.includes('/courses');

    const qualification = useQualification();

    const [isSuccess, setSuccess] = useState(false);

    const confirm = useCallback(async () => {
        try {
            const user = await confirmEmail({ submitToken });
            setSuccess(true);

            if (isCourses) {
                setTimeout(() => {
                    window.location.href = '/courses';
                }, 3000);
            } else {
                if (isAccountUser(user)) {
                    setTimeout(() => {
                        qualification.goToOnboardingOrQualification(user, true);
                    }, 3000);
                }
            }
        } catch {
            if (isCourses) {
                window.location.href = '/courses/signin';
            } else {
                window.location.href = '/signin';
            }
        }
    }, [submitToken, isCourses, qualification]);

    useEffect(() => {
        if (!isSuccess) {
            confirm();
        }
    }, [confirm, isSuccess]);

    if (!isSuccess) {
        return <LoadingScreen />;
    }

    return (
        <>
            <Helmet>
                <title>{t('pageTitle.emailConfirmation')}</title>
            </Helmet>

            <AuthLayout>
                <AuthContent title={t(`${i18nPath}.title`)} />
            </AuthLayout>
        </>
    );
};

export default EmailSubmit;
