import { apiFormFetch, APIError } from '../apiFetch';
import { createDate } from '@wbnr/ui';
export var getUserActivity = function getUserActivity(_ref) {
  var token = _ref.token;
  return apiFormFetch('GET', '/user/activity/{activity}', {
    activity: token
  }).then(parseUserActivity).catch(function (e) {
    if (e instanceof APIError && e.status === 404) {
      return null;
    }
    throw e;
  });
};
var parseUserActivity = function parseUserActivity(raw) {
  return {
    activityCounter: raw.activityCounter || 1,
    lastActiveAt: createDate(raw.lastActiveAt)
  };
};