import { useContext } from 'react';

import { SIEMContextData } from '../context/interfaces';
import { SIEMContext } from '../context/SIEMContext';

export type {
    GetSIEMSettings,
    UpdateSIEMParams,
    UpdateSIEMSettings,
    SIEMContextData,
    SIEMAction,
    SIEMUpdateAction,
} from '../context/interfaces';

type UseSiem = () => SIEMContextData;

export const useSIEM: UseSiem = () => useContext<SIEMContextData>(SIEMContext);
