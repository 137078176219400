import classnames from 'classnames';
import { useState, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionDialogBase, DialogContentText, DialogActions, Button } from '@wbnr/ui';

import illustrationSrc1 from './assets/illustration1.png';
import illustrationSrc2en from './assets/illustration2-en.png';
import illustrationSrc2 from './assets/illustration2.png';
import illustrationSrc3 from './assets/illustration3.png';
import styles from './MeetingsPromoDialog.module.scss';

const MeetingsPromoDialog = ({
    opened,
    onPromoAgreeClick,
    onPromoDenyClick,
}: {
    opened: boolean;
    onPromoAgreeClick: () => void;
    onPromoDenyClick: () => void;
}) => {
    const { t, i18n } = useTranslation();
    const [stepIndex, setStepIndex] = useState(0);
    const currentStep = steps[stepIndex];
    const isLastStep = steps.length === stepIndex + 1;

    return (
        <ActionDialogBase
            style={{ zIndex: 32000 }}
            disableBackdropClick
            maxWidth="lg"
            opened={opened}
            title={t(currentStep.titleI18nkey)}
            image={
                <MeetingsPromoImageBlock numberOfSteps={steps.length} activeIndex={stepIndex}>
                    <img className={styles.image} src={illustrationSrc1} alt="Calendar" />
                    <img
                        className={styles.image}
                        src={
                            i18n.language.toLowerCase() === 'en'
                                ? illustrationSrc2en
                                : illustrationSrc2
                        }
                        alt="Schedule"
                    />
                    <img
                        className={styles.image}
                        src={illustrationSrc3}
                        alt="Keep current control panel to webinars"
                    />
                </MeetingsPromoImageBlock>
            }
            classes={{
                mainImage: styles.mainImage,
            }}
            actions={
                <DialogActions>
                    <Button
                        color="primary"
                        onClick={() => {
                            if (!isLastStep) {
                                setStepIndex((index) => index + 1);
                            } else {
                                onPromoDenyClick();
                            }
                        }}
                    >
                        {isLastStep ? t('meetingsPromoDialog.notIntrested') : t('next')}
                    </Button>
                    {isLastStep && (
                        <Button onClick={onPromoAgreeClick} color="primary">
                            {t('meetingsPromoDialog.goToMeetings')}
                        </Button>
                    )}
                </DialogActions>
            }
        >
            <DialogContentText>{t(currentStep.textI18nkey)}</DialogContentText>
        </ActionDialogBase>
    );
};

const steps: {
    titleI18nkey: string;
    textI18nkey: string;
}[] = [
    {
        titleI18nkey: 'meetingsPromoDialog.step1.title',
        textI18nkey: 'meetingsPromoDialog.step1.text',
    },
    {
        titleI18nkey: 'meetingsPromoDialog.step2.title',
        textI18nkey: 'meetingsPromoDialog.step2.text',
    },
    {
        titleI18nkey: 'meetingsPromoDialog.step3.title',
        textI18nkey: 'meetingsPromoDialog.step3.text',
    },
];

const MeetingsPromoImageBlock = ({
    numberOfSteps,
    activeIndex,
    children,
}: {
    activeIndex: number;
    numberOfSteps: number;
    children: ReactNode;
}) => {
    return (
        <div className={styles.imageBlock}>
            {Array.isArray(children) &&
                children.map((child, index) => {
                    return (
                        <div
                            key={index}
                            className={classnames(styles.imageContainer, {
                                [styles.active]: index === activeIndex,
                            })}
                        >
                            {child}
                        </div>
                    );
                })}
            <div className={styles.pointsContainer}>
                <ImageBlockPoints numberOfPoints={numberOfSteps} activeIndex={activeIndex} />
            </div>
        </div>
    );
};

const ImageBlockPoints = ({
    activeIndex,
    numberOfPoints,
}: {
    numberOfPoints: number;
    activeIndex: number;
}) => {
    return (
        <div className={styles.points}>
            {[...Array(numberOfPoints)].map((_, index) => {
                return (
                    <div
                        key={index}
                        className={classnames(styles.point, {
                            [styles.active]: activeIndex === index,
                        })}
                    />
                );
            })}
        </div>
    );
};

export default MeetingsPromoDialog;
