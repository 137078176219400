import { useEffect, useCallback, useMemo } from 'react';
import { useOnboardingContext } from './useOnboardingContext';
import { createSetGroupStepCanRunAction } from './onboardingReducer';
export var useOnboardingStep = function useOnboardingStep(_ref) {
  var _groupFunctions$group, _groupFunctions$group2, _groupFunctions$group3;
  var groupId = _ref.groupId,
    stepId = _ref.stepId,
    _ref$sendAsCompletedA = _ref.sendAsCompletedAfterActive,
    sendAsCompletedAfterActive = _ref$sendAsCompletedA === void 0 ? true : _ref$sendAsCompletedA,
    _ref$considerFinished = _ref.considerFinishedAfterUnmount,
    considerFinishedAfterUnmount = _ref$considerFinished === void 0 ? true : _ref$considerFinished,
    _ref$disabled = _ref.disabled,
    disabled = _ref$disabled === void 0 ? false : _ref$disabled;
  var _useOnboardingContext = useOnboardingContext(),
    activeStep = _useOnboardingContext.activeStep,
    dispatch = _useOnboardingContext.dispatch,
    state = _useOnboardingContext.state,
    groupFunctions = _useOnboardingContext.groupFunctions;
  var group = state.groups[groupId];
  var hasGroup = !!group;
  var isStepActive = groupId === (activeStep === null || activeStep === void 0 ? void 0 : activeStep.groupId) && stepId === (activeStep === null || activeStep === void 0 ? void 0 : activeStep.stepId);
  var rawFinishStep = (_groupFunctions$group = groupFunctions[groupId]) === null || _groupFunctions$group === void 0 ? void 0 : _groupFunctions$group.finishStep;
  var finishAllSteps = (_groupFunctions$group2 = groupFunctions[groupId]) === null || _groupFunctions$group2 === void 0 ? void 0 : _groupFunctions$group2.finishAllSteps;
  var setFinishedInPersistentStore = (_groupFunctions$group3 = groupFunctions[groupId]) === null || _groupFunctions$group3 === void 0 ? void 0 : _groupFunctions$group3.setAsFinishedInPersistentStore;
  var hasMoreSteps = useMemo(function () {
    if (!group || !group.loaded) {
      return;
    }
    return Object.keys(group.steps).some(function (stepKey) {
      var step = group.steps[stepKey];
      return step.id !== stepId && step.canRun && !step.finished;
    });
  }, [group, stepId]);
  var finishStep = useCallback(function () {
    if (rawFinishStep) {
      rawFinishStep(stepId);
    }
  }, [rawFinishStep, stepId]);
  useEffect(function () {
    if (considerFinishedAfterUnmount && isStepActive) {
      return function () {
        return finishStep();
      };
    }
  }, [considerFinishedAfterUnmount, isStepActive, finishStep]);
  useEffect(function () {
    if (isStepActive && sendAsCompletedAfterActive && setFinishedInPersistentStore) {
      setFinishedInPersistentStore(stepId);
    }
  }, [isStepActive, sendAsCompletedAfterActive, setFinishedInPersistentStore, stepId]);
  useEffect(function () {
    if (hasGroup && !disabled) {
      dispatch(createSetGroupStepCanRunAction(groupId, stepId, true));
      return function () {
        dispatch(createSetGroupStepCanRunAction(groupId, stepId, false));
      };
    }
  }, [groupId, stepId, dispatch, hasGroup, disabled]);
  if (!rawFinishStep && !disabled) {
    throw new Error("Can't find finishStep method for ".concat(groupId, " group in onboardingContext."));
  }
  return {
    isStepActive: isStepActive && !disabled,
    finishStep: finishStep,
    hasMoreSteps: hasMoreSteps,
    finishAllSteps: finishAllSteps
  };
};