import { IS_BOX_MODE, WEBRTC_TEST_HOSTS } from '@wbnr/frontend-shared/lib/config';

type ErrorTextKey = {
    banner: string;
    title: string;
    text: string | [string, { hosts: string }];
};

const hosts = WEBRTC_TEST_HOSTS.join(', ');
export const errorTypeTextKeys: {
    [key in 'network' | 'network_blocked' | 'unknown' | 'browser']: ErrorTextKey;
} = {
    network: {
        banner: 'banners.webrtcTestNetworkBanner',
        title: 'banners.webrtcTestNetworkSummary',
        text: IS_BOX_MODE
            ? ['banners.webrtcTestNetworkDescBox', { hosts }]
            : 'banners.webrtcTestNetworkDesc',
    },
    network_blocked: {
        banner: 'banners.webrtcTestNetworkBlockedBanner',
        title: 'banners.webrtcTestNetworkBlockedSummary',
        text: IS_BOX_MODE
            ? ['banners.webrtcTestNetworkBlockedDescBox', { hosts }]
            : 'banners.webrtcTestNetworkBlockedDesc',
    },
    unknown: {
        banner: 'banners.webrtcTestNetworkBanner',
        title: 'banners.webrtcTestUnknownSummary',
        text: 'banners.webrtcTestUnknownDesc',
    },
    browser: {
        banner: 'banners.webrtcTestUnsupportedBrowserBanner',
        title: 'banners.webrtcTestUnsupportedBrowserSummary',
        text: 'banners.webrtcTestUnsupportedBrowserDesc',
    },
};
