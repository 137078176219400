import { useTranslation } from 'react-i18next';

import { useCustomDialog } from '@wbnr/frontend-shared/lib/dialogs';
import { injectTranslations } from '@wbnr/frontend-shared/lib/utils/i18n';
import { ComponentTestIdProps, createTestIdProps, dataTestIdKey } from '@wbnr/ui';

import { TRANS_NS } from '../../../constants';
import translations from '../../../translations.json';
import { EXTENSIONS } from '../../constants';
import { useApiLogic } from '../../hooks/useApiLogic';
import { ExtensionType } from '../../types';

import { ExtensionModal } from './ExtensionModal';

interface UseExtensionModal extends ComponentTestIdProps {
    extensionType: ExtensionType;
}

injectTranslations(TRANS_NS, translations);

export const useExtensionModal = ({
    extensionType,
    [dataTestIdKey]: dataTestId,
}: UseExtensionModal) => {
    const { t } = useTranslation(TRANS_NS);
    const { apiKey, isKeyRefreshing, onApiKeyRefresh } = useApiLogic({ extensionType });
    const { imagePath, nameKey, descriptionKey, helpLink } = EXTENSIONS[extensionType];

    return useCustomDialog(({ opened, closeDialog }) => {
        return (
            <ExtensionModal
                opened={opened}
                imagePath={imagePath}
                name={t(nameKey)}
                description={t(descriptionKey)}
                apiKey={apiKey}
                isKeyRefreshing={isKeyRefreshing}
                helpLink={helpLink}
                onClose={closeDialog}
                onApiKeyRefresh={onApiKeyRefresh}
                {...createTestIdProps(dataTestId)}
            />
        );
    }, {});
};
