import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { checkOrganizationSubdomain } from '@wbnr/frontend-shared/lib/api/business-api';
import { ORGANIZATIONS_MAIN_DOMAIN } from '@wbnr/frontend-shared/lib/config';
import { useField, useFormContext } from '@wbnr/frontend-shared/lib/forms';
import { useDebouncedCallback } from '@wbnr/frontend-shared/lib/hooks/useDebouncedCallback';
import { createTestIdProps, TextField, Typography } from '@wbnr/ui';

import { BASE_TEST_ID, TRANS_NS } from '../../constants';
import { textFieldCommonParams } from '../textFieldCommonParams';
import { IPropsField } from '../types';

export function SubdomainField({ handleUpdateOrganization }: IPropsField) {
    const [focused, setFocus] = useState(false);

    const { t } = useTranslation(TRANS_NS);

    const {
        control,
        formState: { dirtyFields },
        watch,
        setValue,
        clearErrors,
        setError,
    } = useFormContext();
    const subdomainField = useField(control, 'subdomain');
    const subdomain = watch('subdomain');

    const handleUpdateSubdomain = () => {
        if (focused && !subdomain) {
            setFocus(false);
        }

        if (subdomainField.error || !dirtyFields.subdomain) {
            return;
        }

        handleUpdateOrganization({ subdomain }, 'organizationDomainChanged');
    };

    const handleChangeSubdomain = (e: ChangeEvent<HTMLInputElement>) => {
        const subdomainValue = e.target.value.replace(/[^a-zA-Z0-9-]/g, '');

        if (subdomainValue.length > 20) {
            return;
        }

        setValue('subdomain', subdomainValue, { shouldDirty: true });
        handleCheckSubdomain(subdomainValue, subdomainField.helperText);
    };

    const [handleCheckSubdomain] = useDebouncedCallback(
        (subdomainValue: string, domainErrorCode?: string) => {
            if (subdomainValue) {
                checkOrganizationSubdomain(subdomainValue, 'webinar.ru')
                    .then(() => clearErrors('subdomain'))
                    .catch((error) =>
                        setError('subdomain', { message: t(`errors.domain${error.status}`) }),
                    );
            } else if (domainErrorCode) {
                clearErrors('subdomain');
            }
        },
        500,
        [],
    );

    return (
        <>
            <TextField
                {...subdomainField}
                label={t('organizationDomain')}
                title={t('organizationDomain')}
                onKeyDown={(e) => (e.keyCode === 13 ? handleUpdateSubdomain() : undefined)}
                onBlur={handleUpdateSubdomain}
                onFocus={() => setFocus(true)}
                onChange={handleChangeSubdomain}
                {...textFieldCommonParams}
                {...createTestIdProps(BASE_TEST_ID, 'domainField')}
            />
            {(focused || Boolean(subdomain)) && (
                <Typography variant="subtitle1">
                    {`${window.location.protocol}//${subdomain || ''}.${ORGANIZATIONS_MAIN_DOMAIN}`}
                </Typography>
            )}
        </>
    );
}
